import { useContext, useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { GoLocation, GoSettings } from "react-icons/go";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate, useLocation } from "react-router";
import ProfessionalCard from "../../components/ProfessionalCard/ProfessionalCard";
import Erreurs from "../../components/Erreurs/Erreurs";
import { UserContext } from "../../utils/Context/Context";
import {FirstUpperCase,isEmpty,objecttoFormData} from "../../utils/services/helpers";
import Principale from "../../utils/services/Principale";
import { useRecoilState } from "recoil";
import { loadingState } from "../../recoil/loadingAtom";
import ProFiltersModal from "../../components/ProFiltersModal/ProFiltersModal";

const FindProfessionals = () => {
  const url = process.env.REACT_APP_URL + "storage/";
  const { user } = useContext(UserContext);
  const [metierData, setMetierData] = useState([]);
  const [professionnelleData, setProfessionnelleData] = useState([]);
  const [metierDataDisplay, setMetierDataDisplay] = useState([]); //metier a affiche
  const [metierInFilter,setMetierInFilter] = useState([]);//Afficher le reste des metiers dans filter
  const [isLoading, setIsLoading] = useRecoilState(loadingState);
  const [indexmetier, setIndexMetier] = useState(0);
  const [erreurs, setErreurs] = useState([]);
  const [startPage,setStartPage] = useState(1);
  const [endPage, setEndPage] = useState(8);
  const [currentPage, setcurrentPage] = useState(0);
  const[totalPage,setTotalPage] = useState(0);
  const [isend, setIsEnd] = useState(false);
  const [metier_id, setMetier_id] = useState(0);
  const [preloadData, setPreloadData] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  
  const api = new Principale();
  const [candidat, setCandidat] = useState({
    prefecture_id: "",
    experience: "",
    metierName: "",
    commune_id: "",
    quartier_id: "",
    addresse:'',
    selectedMetiers:[]
  });
  const location = useLocation();
  const navigate = useNavigate();

  // ? Filters Modal
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);

  //Afficher tous les candidats
  const getmetiersjob = async () => {
     setErreurs([]);setIsLoading(true);
    //  const {status,metiers,messages} = await api.get('/client/getjobmetiers');
    //  const todisplaymetier = [];
    //  if(status === 200){
    //    setMetierData(metiers);
    //    metiers.forEach((value,index) => {
    //      if(index < 9)
    //        todisplaymetier.push(value);
    //      else {setIndexMetier(index);return false;};
    //    });
    //    setMetierDataDisplay(todisplaymetier);
    //  }else setErreurs(messages);
     api.get(`/client/getCandidatbymetier?start=${startPage}&end=${endPage}`).then((resp) => {
      setIsLoading(false);
        if(resp.status === 200){
          setProfessionnelleData(resp.candidats); setTotalPage(Math.round(resp.totalcandidats/8));
  
        }
        else setErreurs(resp.messages);  
     });
 
  }
  //afficher les candidats qui sont affiliés à ce métier
  const getCandidatsbymetiers = async (metier) => {
    setErreurs([]);
    setEndPage(8);
    setcurrentPage(1);
    setIsLoading(true);
    setIsSearch(false);
    setMetier_id(metier.metier_id);
    setIsEnd(false);
    setCandidat({
      ...candidat,
      prefecture_id: "",
      experience: "",
      metierName: "",
      commune_id: "",
      quartier_id: "",
      addresse:''
    });
    const { status, candidats, messages } = await api.get(
      `/client/getCandidatbymetier/${metier.metier_id}?start=1&end=8`
    );
 
    setIsLoading(false);
    if (status === 200) {
      setProfessionnelleData(candidats);
    } else setErreurs(messages);
  };
  // const contacter = (data) => {
  //   if (isEmpty(user)) {
  //     navigate("/login", { state: { page: "findpro" } });
  //   }
  //   // else {
  //   //   console.log(data)
  //   // }
  // };
  //les donnees prechargees
  const getPreloadData = async () => {
    setIsLoading(true);
    const{status,messages,data} = await api.get('/getPreloadData');
    setPreloadData([]);setErreurs([]);
    if(status === 200)
      setPreloadData(data)
    else setErreurs(messages);  
     api.get('/client/getjobmetiers').then((resp) => {
    
      const todisplaymetier = [];
      if(resp.status === 200){
        setMetierData(resp.metiers);
        resp.metiers.forEach((value,index) => {
          if(index < 10)
            todisplaymetier.push(value);
          else {
              setIndexMetier(index);};
        });
        setMetierDataDisplay(todisplaymetier);
      }else setErreurs(messages)
    });
     
    setIsLoading(false);    
  }
  //console.log(metierInFilter)
  //fonction de recherche
  const fnsearch = async () => {
      setIsLoading(true);
      setErreurs([]);
      setIsSearch(true);
      setIsEnd(false);
      var formData = objecttoFormData(candidat);
    
      if (location?.state || candidat.selectedMetiers.length === 0) {
        const metier = location.state?.metier;
        if (metier) {
          setCandidat({...candidat,metierName:metier});
          formData.append("metierName", metier);
        }else formData.append("metierName",candidat.metierName);
        formData.append("prefecture_id",candidat.prefecture_id);
        formData.append("experience",candidat.experience);
        formData.append("approndie",false);
     }
     else{
         if(candidat.commune_id != "" || candidat.quartier_id != "" || (Array.isArray(candidat.selectedMetiers) && candidat.selectedMetiers.length > 0)){
          formData.append("metiers",candidat.selectedMetiers)
          formData.append("approndie",true);
         }else{
            formData.append("approndie",false);
            formData.append('metierName',candidat.metierName); 
         }
      }
    //  console.log(candidat)
     const{status,messages,candidats,totalcandidats} = await api.post(`/client/search?start=1&end=8`,formData);
   
     setIsLoading(false);setcurrentPage(1);
     setTotalPage(Math.round(totalcandidats/8))
     if(status === 200)
       setProfessionnelleData(candidats);
      else setErreurs(messages);  
   
  };
  //console.log(totalPage);
  const handleInput = (e) => {
    if (e.target.name === "metierName"){
      setCandidat({
        ...candidat,
        [e.target.name]: FirstUpperCase(e.target.value),
      });
      if(location.state?.metier)
        location.state.metier = FirstUpperCase(e.target.value)
    }
    else setCandidat({ ...candidat, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    
      getPreloadData();
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

      if(location?.state){
         const metier = location.state?.metier;
       
         if(metier){
          setCandidat({...candidat,metierName:metier});
          fnsearch();
          
         }
      }
      else{
        getmetiersjob();
      }
      return (() => {
         window.onscroll = null;
     })
  },[]);
  // console.log(professionnelleData)
  //infini scroll
  const loadfn = async (metier_id = 0) => {
    setErreurs([]);
   
    const start = endPage + 1;
    const end = start + 7;
    let candts = [];
    let sts;
    let msg = "";
    setEndPage(end);setStartPage(start);
    if(currentPage <= totalPage ){
        setIsLoading(true);
        if (isSearch) {
        
          var formData = objecttoFormData(candidat)
          if(candidat.commune_id != "" || candidat.quartier_id != "" || (Array.isArray(candidat.selectedMetiers) && candidat.selectedMetiers.length > 0)){
            formData.append("metiers",candidat.selectedMetiers)
            formData.append("approndie",true);
          }
          else{
        
              formData.append("approndie",false);
              formData.append('metierName',candidat.metierName); 
          }
          // formData.append("prefecture_id", candidat.prefecture_id);
          // formData.append("experience", candidat.experience);
          // formData.append("metierName", candidat.metierName);
          const { status, messages, candidats } = await api.post(`/client/search?start=${start}&end=${end}`,formData);
          sts = status;
          candts = candidats;
          msg = messages;
        } else if (metier_id === 0) {
          const { status, candidats, messages } = await api.get(`/client/getCandidatbymetier?start=${start}&end=${end}`);
          sts = status;
          candts = candidats;
          msg = messages;
         setIsLoading(false);
        } else {
          const { status, candidats, messages } = await api.get(
            `/client/getCandidatbymetier/${metier_id}?start=${start}&end=${end}`
          );
        sts = status;
        candts = candidats;
        msg = messages;
     
      }
      // console.log(end - start)
      const currentpge = currentPage + 1;
      setcurrentPage(currentpge);
     
      if (sts === 200) {
        if (candts.length === 0) {setIsEnd(true);}
        const cdts = professionnelleData;
        candts.map((candidat) => {
          cdts.push(candidat);
        });
        setProfessionnelleData(cdts);
      } else setErreurs(msg);
      setIsLoading(false);
    }
  };
  // console.log(isend);
  window.onscroll = () => {
    if (!isend ) {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 24) {
        loadfn(metier_id);
        
      }
    }
  };
 //console.log(candidat)
  return (
    <div className="find-professionals page">
      <h1>Trouvez un professionnel</h1>
      <Erreurs validation={erreurs} />
      <ProFiltersModal isOpen={isFiltersModalOpen} setIsOpen={setIsFiltersModalOpen} 
        metiers={metierData} preloadData={preloadData} 
        candidat={candidat} 
        setCandidat={setCandidat}
        setProfessionnelleData={setProfessionnelleData}
        setIsSearch={setIsSearch}
        setErreurs={setErreurs}
        setIsLoading={setIsLoading}
        setEndPage={setEndPage}
        api={api}
        location={location}
        />
      <div className="search">
        <div className="job">
          <div className="icon">
            <FiSearch size={20} />
          </div>
          <input
            type="text"
            name="metierName"
            id="work"
            placeholder="Quel métier cherchez-vous ?"
            onChange={handleInput}
            value={candidat?.metierName ? candidat.metierName : ""}
          />
        </div>
        <div className="location">
          <div className="icon icon-left">
            <GoLocation size={20} />
          </div>
          <select
            name="prefecture_id"
            id="position"
            data-testid="position-select"
            value={candidat.prefecture_id}
            onChange={handleInput}
          >
            <option value="" data-testid="position-option">
              Préfecture ou Commune
            </option>
            {preloadData?.prefectures &&
              preloadData.prefectures.map((prefecture) => (
                <option
                  value={prefecture.prefecture_id}
                  key={prefecture.prefecture_id}
                >
                  {prefecture.nom}
                </option>
              ))}
            <option value={0} data-testid="position-option">
              Veuillez Choisir une Localité
            </option>
          </select>
          <div className="icon arrow-icon">
            <IoIosArrowDown />
          </div>
        </div>
        <div className="experience">
          <select
            name="experience"
            id="experience"
            data-testid="experience-select"
            value={candidat.experience}
            onChange={handleInput}
          >
            <option value="" data-testid="experience-option">
              Experience
            </option>
            <option value="Moins d'un an"> Moins d'un an</option>
            <option value="Un An"> Un An </option>
            <option value="Deux Ans"> Deux Ans </option>
            <option value="Trois Ans"> Trois Ans </option>
            <option value="Quatre Ans"> Quatre Ans </option>
            <option value="Cinq Ans"> Cinq Ans </option>
            <option value="Dix Ans"> Dix Ans </option>
            <option value="Quinze Ans"> Quinze Ans </option>
            <option value="Vingt Ans"> Vingt Ans </option>
            <option value="Vingt Cinq Ans"> Vingt Cinq Ans </option>
            <option value="Trente Ans"> Trente Ans </option>
            <option value="Plus de 30"> Plus de 30 </option>
          </select>
          <div className="icon arrow-icon">
            <IoIosArrowDown />
          </div>
        </div>
        <div className="button-and-filters">
          <button className="primary" onClick={fnsearch}>
            Chercher
          </button>
          <div className="filters" onClick={() => setIsFiltersModalOpen(true)}>
            <div className="icon">
              <GoSettings size={24} />
            </div>
          </div>
        </div>
      </div>

      {metierDataDisplay.length > 0 && (
        <div className="categories-badges">
          {metierDataDisplay.map((metier) => {
            return (
              <article
                key={metier.metier_id}
                onClick={() => getCandidatsbymetiers(metier)}
              >
                {metier.nom + "(" + metier.nbrepersonne + ")"}
              </article>
            );
          })}
          {/* {indexmetier > 12 && <article>autres categories</article>} */}
        </div>
      )}

      {/* <article>artisanat</article>
        <article>coiffure</article>
        <article>decoration</article>
        <article>electricité</article>
        <article>jardinage</article>
        <article>maçonnerie</article>
        <article>menuiserie</article>
        <article>autres categories</article> */}

      <div className="list">
        <div className="stats">{professionnelleData.length} trouvés</div>
        <div className="pro-cards">
          {professionnelleData.length > 0 && 
           professionnelleData.map((item) => (
            <ProfessionalCard
              key={item.candidat_id + Math.random()}
              photo={url + item.photoProfile.replace("public/", "")}
              name={item.prenomCandidat + " " + item.nomCandidat}
              job={item.metier}
              address={item.prefecture}
              experience={item.experience}
              description={`Agé(e) de  ${item.age} ans de sexe ${
                item.sexe === "M" ? "Masculin" : "Féminin"
              } 
                    habitant dans le quartier/district de ${
                      item.quartier
                    } de la commune de ${item.commune}.\n Diplôme: ${
                item.diplome
                  ? item.diplome.charAt(0).toUpperCase() + item.diplome.slice(1)
                  : "------"
              }`}
              candidat_id ={item.candidat_id}
              // contacter={() => contacter(item)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FindProfessionals;
