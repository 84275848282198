import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { FirstUpperCase } from '../../utils/services/helpers';
import { BsFillFileEarmarkCheckFill, BsFillFileLock2Fill } from 'react-icons/bs';
import Erreurs from '../Erreurs/Erreurs';

const StepTwo = ({ prevStep, nextStep,recrutement,setRecrutement}) => {

   const {register,handleSubmit,reset,formState: { errors },} = useForm({defaultValues: {recrutement},});
   const url = process.env.REACT_APP_URL + 'storage/';  
   useEffect(() => {
      reset(recrutement);
      window.scrollTo({ top: -50, left: 0, behavior: 'smooth' });
   },[])
   const openFile = (document) => {
    if(document === "piece")
      window.open(`${url}${recrutement.employeur.photo_pieceEmp.replace('public/','')}`);
    else if(document === 'nif')
      window.open(`${url}${recrutement.employeur.nif_photo.replace('public/','')}`); 
    else if(document === 'rccm_photo'){
      if(recrutement?.employeur?.rccm_photo)
        window.open(`${url}${recrutement?.employeur?.rccm_photo?.replace('public/','')}`);
    }   
    else if(document === 'autredoc')
      window.open(`${url}${recrutement.employeur.autre_doc.replace('public/','')}`); 
  }
  const handleInput = (e) =>{
     setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]:e.target.value}});
  }
  return (
    <div className='step-component'>
      {recrutement?.employeur &&
        <form onSubmit={handleSubmit(nextStep)}>
            {/* <h4>Informations générales</h4> */}
            <div className="input-group">
              <label>Type Employeur
                 <span className='input-read'>{FirstUpperCase(recrutement.employeur.typeEmp)}</span>
              </label>
              {recrutement.employeur.typeEmp === 'personne physique' &&
                <>
                  <label>Prenom et Nom
                     <span className='input-read'>{recrutement.employeur.prenomEmp + " "+ recrutement.employeur.nomEmp}</span>
                  </label>
                  <label>Profession
                    <span className='input-read'>{recrutement.employeur.profession}</span>
                  </label>
                  <label>Telephone
                     <span className='input-read'>{recrutement.employeur.telephone}</span>
                  </label>
                </> 
               }  
               {recrutement.employeur.typeEmp === 'societe' &&
                <>
                  <label>Nom de la Société
                     <span className='input-read'>{recrutement.employeur.societe}</span>
                  </label>
                  <label>Secteur d'activité
                     <span className='input-read'>{recrutement.employeur.secteur}</span>
                  </label>
                  <label>Prefecture
                     <span className='input-read'>{recrutement.employeur.prefecture}</span>
                  </label>
                </>
               }
            </div>
            {recrutement.employeur.typeEmp === 'personne physique' &&
               <div className='input-group'>
                 <label>Prefecture
                    <span className='input-read'>{recrutement.employeur.prefecture}</span>
                 </label> 
                 <label>Commune
                    <span className='input-read'>{recrutement.employeur.commune}</span>
                 </label> 
                 <label>Quartier/District
                    <span className='input-read'>{recrutement.employeur.quartier}</span>
                 </label> 
                 <label>Addresse
                    <span className='input-read'>{recrutement.employeur.addresse && recrutement.employeur.addresse !== 'null'?recrutement.employeur.addresse:'----------'}</span>
                 </label> 
               </div>
            }
            {recrutement.employeur.typeEmp === 'societe' &&
              <>
               <div className='input-group'>
                  <label>Commune
                     <span className='input-read'>{recrutement.employeur.commune}</span>
                  </label>
                  <label>Quartier/District
                     <span className='input-read'>{recrutement.employeur.quartier}</span>
                  </label>
                  <label>Addresse
                     <span className='input-read' style={{marginRight:'0px'}}>{recrutement.employeur.addresse}</span>
                  </label>
               </div>
               <div className='input-group'>
                <label>Representé par
                     <span className='input-read'>{recrutement.employeur.civilite +" "+recrutement.employeur.representant}</span>
                 </label>
                 <label>Email
                     <span className='input-read'>{recrutement.employeur.email?recrutement.employeur.email:'---------'}</span>
                  </label>
                  <label>Telephone
                     <span className='input-read' style={{color:'green'}}>{recrutement.employeur.telephone?recrutement.employeur.telephone:'---------'}</span>
                  </label>
               </div>
               <div className='input-group'>
                  <label>Type de pièce
                     <span className='input-read'>{recrutement.employeur.type_piece}</span>
                  </label>
                  <label>Numero de la pièce
                     <span className='input-read'>{recrutement.employeur.numero_piece}</span>
                  </label>
                  <label>Code NIF
                     <span className='input-read'>{recrutement.employeur.nif}</span>
                  </label>
                  <label>Rccm
                     <span className='input-read'>{recrutement.employeur.rccm}</span>
                  </label>
               </div> 
               <div className='input-group'>
                 <label style={{cursor:'pointer'}} onClick={() => openFile("piece")}>
                    <div className="file-wrapper">
                    <p style={{marginBottom:'10px'}}>Copie de la pièce</p>
                    
                    <div className='image bkviewImg'>
                        <BsFillFileEarmarkCheckFill size={50} />
                    </div>
                    </div>
                 </label>
                 <label style={{cursor:'pointer'}} onClick={() => openFile("nif")}>
                    <div className="file-wrapper">
                    <p style={{marginBottom:'10px'}}>Copie du NIF</p>
                    <div className='image bkviewImg'>
                        <BsFillFileEarmarkCheckFill size={50} />
                    </div>
                    </div>
                 </label>
                 <label style={{cursor:'pointer'}} onClick={() => openFile("rccm_photo")}>
                    <div className="file-wrapper">
                     <p style={{marginBottom:'10px'}}>Copie du RCCM</p>
                     {recrutement.employeur?.rccm_photo ?
                     <div className='image bkviewImg'>
                       <BsFillFileEarmarkCheckFill size={50} />
                      </div>:
                      <div className='image bkviewImg'>
                        <BsFillFileLock2Fill size={50} />
                       </div>
                     }
                    </div>
                 </label>
                 {recrutement.employeur.autre_doc !== null ?
                  <label style={{cursor:'pointer'}} onClick={() => openFile("autredoc")}>
                    <div className="file-wrapper">
                    <p style={{marginBottom:'10px'}}>Autre document</p>
                    <div className='image bkviewImg'>
                        <BsFillFileEarmarkCheckFill size={50} />
                    </div>
                    </div>
                  </label>:<label></label>}
                  <label></label>
               </div> 
              </>  
            }
            {recrutement.employeur.typeEmp === 'personne physique' &&
              <div className='input-group'>
                <label>Fonction
                  <span className='input-read'>{recrutement.employeur.fonction}</span>
                </label>
                <label>Email
                  <span className='input-read'>{recrutement.employeur.email}</span>
                </label>
                <label>Type de pièce
                  <span className='input-read'>{recrutement.employeur.type_piece}</span>
                </label>
                <label>Numero de la pièce
                  <span className='input-read'>{recrutement.employeur.numero_piece}</span>
                </label>
              </div>
            }
            {recrutement.employeur.typeEmp === 'personne physique' &&
              <div className='input-group'>
                <label style={{cursor:'pointer'}} onClick={() => openFile("piece")}>
                    <div className="file-wrapper">
                    <p style={{marginBottom:'10px'}}>Copie de la pièce</p>
                    <div className='image bkviewImg'>
                        <BsFillFileEarmarkCheckFill size={50} />
                    </div>
                    </div>
                </label>
              </div>
            }
            <div className='input-group' style={{marginTop:'10px',marginBottom:'10px'}}>
               <label>Continuez ou rejetez
                  <select name="statusTraitement" value={recrutement?.emploie?.statusTraitement?recrutement?.emploie?.statusTraitement:'continuer'}
                  onChange={handleInput}
                  >
                     <option value="continuer">Continuer</option>
                     <option value="rejeter">Rejeter</option>
                  </select>
               </label>
               {recrutement?.emploie?.statusTraitement === 'rejeter'?
                <label>Raison de Rejet
                  <input type="text"
                   name="raisonRejet" value={recrutement?.emploie?.raisonRejet?recrutement?.emploie?.raisonRejet:''}
                   {...register("raisonRejet", {
                     onChange: (e) => {
                       handleInput(e);
                     },
                     required: true,
                     minLength:5
                   })} 
                  />
                  {errors.raisonRejet && errors.raisonRejet?.type === "required" && (
                   <span className="error-msg">Ce champ est obligatoire.</span>
                 )}
                  {(errors.raisonRejet && errors.raisonRejet?.type === "minLength" )&& (
                      <span className="error-msg">Le caractère minimum pour la raison de rejet est cinq(5).</span>
                 )}
                </label>:<label></label>}
               <label></label>
            </div>
       
            <div className="buttons">
                <button className='secondary' onClick={prevStep} >Précédent</button>
                {recrutement?.emploie?.statusTraitement !== 'rejeter'? 
                 <button type='submit' >Suivant</button>:
                 <button type='submit' className='success'>Terminer</button>}
            </div>
        </form>
     } 
    </div>
  )
}

export default StepTwo