import { useRef, useState } from "react";
import { FaRegWindowClose } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import moment from "moment/moment";
import {  AiOutlineUpload } from "react-icons/ai";
import { useForm } from 'react-hook-form';
import profilephoto from '../../assets/images/avatar.png';
import { FirstUpperCase, objecttoFormData } from "../../utils/services/helpers";
import Principale from "../../utils/services/Principale";
import { toast } from "react-hot-toast";
import Erreurs from "../Erreurs/Erreurs";
const AddUserModal = ({ isOpen, setIsOpen ,setIsLoading,setIsAction,isAction}) => {
  const modalRef = useRef();
  const url = process.env.REACT_APP_URL + "storage/";
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setIsOpen(false);
    }
  };
  const [previews,setPreviews] = useState({profilePhoto:''});
  const api = new Principale();
  const [utilisateur,setUtilisateur] = useState({
    photoProfile:'',
    prenom:'',
    nom:'',
    sexe:'',
    telephone:'',
    email:'',
    password:'',
    confirmMdp:''
  });
  const [erreurs,setErreurs] = useState([]);
  const {register,handleSubmit,reset,setError,formState: { errors },} = useForm({defaultValues: {utilisateur,},}); 

  const handleInput = (e) => {
    if(e.target.name === "photoProfile"){
      setUtilisateur({...utilisateur,[e.target.name]:e.target.files[0]});
      setPreviews({...previews,profilePhoto:URL.createObjectURL(e.target.files[0])});
      setError("photoProfile", {
        type: "manual",
        message: ""
      });
    }
    else if(e.target.name === 'sexe' || e.target.name === 'email' || e.target.name === 'role' || e.target.name === 'password' || e.target.name === 'confirmMdp') 
      setUtilisateur({...utilisateur,[e.target.name]:e.target.value}); 
    else if(e.target.name === 'telephone'){
        const re = /^[0-9\b]+$/;
        if((e.target.value.length <= 9 && re.test(e.target.value) )|| e.target.value === '')
        setUtilisateur({...utilisateur,[e.target.name]:e.target.value});
      }
      
    else setUtilisateur({...utilisateur,[e.target.name]:FirstUpperCase(e.target.value)});
  }
  const inscrire = async () => {
    setErreurs([]);setIsLoading(true);
    var formData = objecttoFormData(utilisateur);
    const {status,messages} = await api.post('/admin/utilisateur/new',formData);
    if(status === 200){
       toast.success("Ajout d'utilisateur effectuée avec succès.");
       setIsAction(!isAction);setIsOpen(false);
    }else{
       setErreurs(messages);setIsLoading(false);
       toast.error("Erreur Ajout non effectué.");
    }
  }
  return (
    <AnimatePresence>
      {isOpen ? (
        <div
          className="recruiter-details-modal modal-component"
          onClick={closeModal}
          ref={modalRef}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, scale: 0 }}
            className="modal"
          >
            <div className="close" onClick={() => setIsOpen(false)}>
              <FaRegWindowClose size={24} id="close-icon" />
            </div>
            <div className="details-recruiter">
            <form onSubmit={handleSubmit(inscrire)}>
               <h2>Information Personnelle</h2> 
                <div className="input-group file-input photo-input">
                  <div className="group">
                    <span>Photo</span>
                    <div className="photo">
                       <img src={previews?.profilePhoto ?previews?.profilePhoto:profilephoto} alt="" />
                    </div>
                    <label className="file-input-label">
                        <AiOutlineUpload />
                        Importer un fichier
                        <input type="file" name="photoProfile" accept="image/*"
                          {...register("photoProfile", {
                            onChange: (e) => {
                              handleInput(e);
                            },
                            validate: (value) => value !== "",
                          })}
        
                          />
                    </label>
                    {errors.photoProfile && (
                      <span className="error-msg" style={{ color:'#e63946',fontWeight:'500'}}>La photo est obligatoire.</span>
                    )}
                </div>    
                </div>
                <div className="input-group">
                   <label>Prenom  <span className='required'></span>
                   <input type="text" name="prenom" id="prenom" placeholder="Prénom" 
                      value={utilisateur.prenom}
                      {...register("prenom", {
                        onChange: (e) => {
                          handleInput(e);
                        },
                        required: true,
                        minLength: 2,
                      })}
                    />
                   {errors.prenom && errors.prenom?.type === "required" && (
                     <span className="error-msg">Ce champ est obligatoire.</span>
                   )}
                    {errors.prenom && errors.prenom?.type === "minLength" && (
                    <span className="error-msg">
                        ce champ ne peut pas être inférieur à deux caractères.
                    </span>
                    )}
                   </label>
                   <label>Nom  <span className='required'></span>
                   <input type="text" name="nom" id="nom" placeholder="Prénom" 
                      value={utilisateur.nom}
                      {...register("nom", {
                        onChange: (e) => {
                          handleInput(e);
                        },
                        required: true,
                        minLength: 2,
                      })}
                    />
                   {errors.nom && errors.nom?.type === "required" && (
                     <span className="error-msg">Ce champ est obligatoire.</span>
                   )}
                    {errors.nom && errors.nom?.type === "minLength" && (
                    <span className="error-msg">
                        ce champ ne peut pas être inférieur à deux caractères.
                    </span>
                    )}
                   </label>
                   <label>Sexe  <span className='required'></span>
                      <select 
                       {...register("sexe", {
                        onChange: (e) => {
                          handleInput(e);
                        },
                        validate: (value) => value !== "",
                      })}
                      value={utilisateur.sexe}
                      >
                         <option value="">Selectionner le sexe</option>
                         <option value='Masculin'>Masculin</option>
                         <option value='Féminin'>Féminin</option>
                      </select>
                    {errors.sexe && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                   </label>
                </div>
                <div className="input-group">
               
                  <label>Téléphone <span className='required'></span>
                  <input type="tel" name="telephone" id="telephone" placeholder="Téléphone"
                     value={utilisateur.telephone}
                      {...register("telephone", {
                        onChange: (e) => {
                          handleInput(e);
                        },
                        required: true,
                        maxLength: 9,
                        minLength: 5,
                        pattern: /6[0-9]{8}$/g,
                   })} 
                    />
                    {errors.telephone && errors.telephone?.type === "required" && (
                        <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                    {errors.telephone && errors.telephone?.type === "minLength" && (
                        <span className="error-msg">
                        Ne peut pas être inférieur à 5 caractères.
                        </span>
                    )}
                    {errors.telephone && errors.telephone?.type === "maxLength" && (
                        <span className="error-msg">
                        Ne peut pas être supérieur à 9 caractères.
                        </span>
                    )}
                    {errors.telephone && errors.telephone.type === "pattern" && (
                        <span role="alert" className="error-msg">
                            Format invalide.ex: 620000000
                        </span>
                        )}
                  </label>
                  <label>Email  <span className='required'></span>
                   <input type="email" name="email" id="email" placeholder="monmail@domaine.com"
                    value={utilisateur.email}
                    {...register("email", 
                    {  onChange: (e) => {
                      handleInput(e)
                   },
                  required:true,
                  pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                    />
                  {(errors.email && errors.email?.type === "required") && (
                    <span className="error-msg">Le Champ email est obligatoire.</span>
                  )}
                   {(errors.email  && !errors.email?.type === "required")  && (
                    <span className="error-msg">Mauvais format d'email.Exemple(example@exemple.com).</span>
                  )}
                  </label>
                  <label>Rôle
                    <select name="role"
                       {...register("role", {
                        onChange: (e) => {
                          handleInput(e);
                        },
                        validate: (value) => value !== "",
                      })}
                       value={utilisateur.role}
                      >
                      <option value="">Selectionner le rôle</option>
                      <option value='Coordinateur'>Coordinateur</option>
                      <option value='Operateur'>Opérateur</option>
                    </select>
                    {errors.role && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                  </label>
                </div>
                <div className="input-group">
                  <label>Mot de passe  <span className='required'></span>
                  <input type="password" name="password" id="password" placeholder="Mot de passe" 
                      value={utilisateur.password}
                      {...register("password", {
                        onChange: (e) => {
                          handleInput(e);
                        },
                        required: true,
                        minLength: 5,
                     })}
                    />
                    {errors.password && errors.password?.type === "required" && (
                     <span className="error-msg">Ce champ est obligatoire.</span>
                   )}
                    {errors.password && errors.password?.type === "minLength" && (
                    <span className="error-msg">
                        Le mot de passe ne peut être inférieur à cinq (5) caractères.
                    </span>
                    )}
                  </label>
                  <label>Confirmer le mot de passe  <span className='required'></span>
                    <input type="password" name="confirmMdp" id="confirmMdp" placeholder="Confirmer le mot de passe" 
                      value={utilisateur.confirmMdp}
                      {...register("confirmMdp", {
                          onChange: (e) => {
                              handleInput(e);
                          },
                          validate: (value) => value === utilisateur.password,
                          })}
                      />
                     {errors.confirmMdp && (
                       <span className="error-msg">Les deux mot de passe doivent être identiques.</span>
                     )}
                  </label>

                </div>  
                <div className="input-group">
                  <Erreurs validation = {erreurs} />
                </div>
                <div className="input-group">
                  <div>
                     <button className='success'>S'inscrire</button>
                  </div>
                 
                </div>
                
              </form>  
            </div> 
          </motion.div>
        </div>
      ) : null}
    </AnimatePresence>
  );
};

export default AddUserModal;
