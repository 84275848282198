import { useEffect, useRef, useState } from "react";
import { FaRegWindowClose } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import moment from "moment/moment";

const UserDetailsModal = ({ isOpen, setIsOpen ,SelectedUser}) => {
  const modalRef = useRef();
  const url = process.env.REACT_APP_URL + "storage/";
  const[Prenom,setPrenom] = useState(null);
  const [roleName,setRoleName] = useState(null);
  const[Nom,setNom] = useState(null);
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setIsOpen(false);
    }
  };
 useEffect(() => {
  if(SelectedUser?.prenomCand && SelectedUser?.nomCand){
   setPrenom(SelectedUser?.prenomCand);setNom(SelectedUser?.nomCand)
  }
  else if(SelectedUser?.PrenomAdmin && SelectedUser?.nomAdmin) { 
    setPrenom(SelectedUser.PrenomAdmin);setNom(SelectedUser?.nomAdmin);
  }
  else if(SelectedUser?.prenomEmp && SelectedUser?.nomEmp){  
    setPrenom(SelectedUser?.prenomEmp);setNom(SelectedUser?.nomEmp);
  }
  if(SelectedUser?.role_id === 1)
    setRoleName('Administrateur');
  else if(SelectedUser?.role_id === 2)
    setRoleName('Opérateur')  
  else if(SelectedUser?.role_id === 3)
    setRoleName('Employeur');    
 else if(SelectedUser?.role_id === 4)   
   setRoleName('Candidat');   
 },[SelectedUser]);
  return (
    <AnimatePresence>
      {isOpen ? (
        <div
          className="recruiter-details-modal modal-component"
          onClick={closeModal}
          ref={modalRef}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, scale: 0 }}
            className="modal"
          >
            <div className="close" onClick={() => setIsOpen(false)}>
              <FaRegWindowClose size={24} id="close-icon" />
            </div>
            <div className="details-recruiter">
              <h2>Détails de l'utilisateur</h2>
       
               {(SelectedUser?.photoProfileCand && (SelectedUser.typeCompte == 'Client')) &&
                  <div className="logo">
                     <img
                      src= {url + SelectedUser?.photoProfileCand?.replace('public/','')}
                       alt="logo"
                     />
                   </div>
               }
              {(SelectedUser?.photoProfileAdmin && (SelectedUser.typeCompte == 'Client'))&&
                  <div className="logo">
                  <img
                   src= {url + SelectedUser?.photoProfileCand?.replace('public/','')}
                    alt="logo"
                  />
                </div>
              }
              <div className="divider">
                <h4>Informations générale</h4>
                <div className="info-group">
                  <div className="info-box">
                    <span className="label subtext">Civilité</span>
                    <p className="info">{SelectedUser?.sexe === 'M'?"Monsieur":"Madame"}</p>
                  </div>
    
                  <>
                    <div className="info-box">
                      <span className="label subtext">Prenom</span>
                      <p className="info">{Prenom?Prenom:'------'}</p>
                    </div>
                    <div className="info-box">
                      <span className="label subtext">Nom</span>
                      <p className="info">{Nom?Nom:'---------'}</p>
                    </div>
                    <div className="info-box">
                      <span className="label subtext">Profession</span>
                      <p className="info">{SelectedUser.profession?SelectedUser.profession:'-----------------'}</p>
                    </div>
                  </>
         
            
                </div>
                <div className="info-group">
                  <div className="info-box">
                    <span className="label subtext">Téléphone</span>
                    <p className="info">{SelectedUser.telephone}</p>
                  </div>
                  <div className="info-box">
                    <span className="label subtext">Préfecture</span>
                    <p className="info">{SelectedUser.prefecture?SelectedUser.prefecture:'----------'}</p>
                  </div>
                  <div className="info-box">
                    <span className="label subtext">Commune</span>
                    <p className="info">{SelectedUser.commune?SelectedUser.commune:'--------'}</p>
                  </div>
                  <div className="info-box">
                    <span className="label subtext">Quartier</span>
                    <p className="info">{SelectedUser.quartier?SelectedUser.quartier:'-----------'}</p>
                  </div>
                </div>
                <div className="info-group">
                  <div className="info-box">
                     <span className="label subtext">Email</span>
                     <p className="info">{SelectedUser.email?SelectedUser.email:'---------'}</p>
                  </div>
                  {SelectedUser?.datenaissance &&
                    <div className="info-box">
                     <span className="label subtext">Date Naissance</span>
                     <p className="info">{moment(SelectedUser.datenaissance).format('DD/MM/YYYY')}</p>
                  </div>}
                  {SelectedUser.experience &&
                    <div className="info-box">
                     <span className="label subtext">Expérience</span>
                     <p className="info">{SelectedUser.experience}</p>
                  </div>}
                  <div className="info-box">
                     <span className="label subtext">Rôle</span>
                     <p className="info">{roleName}</p>
                  </div>
                  {SelectedUser.diplome &&
                    <div className="info-box">
                     <span className="label subtext">Niveau Etude</span>
                     <p className="info">{SelectedUser.diplome}</p>
                  </div>}
                </div>
              </div>
              {(SelectedUser.typeCompte == 'Client') &&
             <>
              <div className="divider">
                <h4>Documents</h4>
                <div className="info-group">
              
       
                      <div className="info-box">
                      <span className="label subtext">Numero Pièce</span>
                      <p className="info">{SelectedUser?.numero_piece?SelectedUser?.numero_piece:'---------------'}</p>
                      </div>
                      {SelectedUser?.capture_piece &&
                        <div className="info-box">
                        <span className="label subtext">Copie de la Pièce</span>
                        <a href={SelectedUser?.capture_piece ? url + SelectedUser?.capture_piece?.replace('public/',''):'#'}className="info" target='_blank'>
                         Télécharger
                         </a>
                      </div>}
                 
                   
                 {SelectedUser?.capture_diplome &&
                  <div className="info-box">
                    <span className="label subtext">Copie du diplôme</span>
                    <a href={url + SelectedUser?.capture_diplome?.replace('public/','')}  target='_blank' className="info">
                      Télécharger
                    </a>
                  </div>}
                  {SelectedUser?.capture_cv &&
                    <div className="info-box">
                    <span className="label subtext">Copie du CV</span>
                    <a href={SelectedUser?.capture_cv?url + SelectedUser?.capture_cv?.replace('public/',''):''}  target='_blank' className="info">
                      Télécharger
                    </a>
                  </div>}
                 
               
                </div>
              
              </div>
              </>
              }
            </div>
          </motion.div>
        </div>
      ) : null}
    </AnimatePresence>
  );
};

export default UserDetailsModal;
