import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { useRecoilState } from "recoil";
import Erreurs from "../../components/Erreurs/Erreurs";
import { loadingState } from "../../recoil/loadingAtom";
import { FirstUpperCase, objecttoFormData } from "../../utils/services/helpers";
import Principale from "../../utils/services/Principale";

const Contact =  () => {
   const [contact,setContact] = useState({
      prenom_nom:'',
      email : '',
      telephone:'',
      message:'',

   });
   const {register,handleSubmit,reset,setError,formState: { errors },} = useForm({defaultValues: {contact},}); 
   const api = new Principale();
   const [erreurs,setErreurs] = useState([]);
   const [isLoading,setIsLoading] = useRecoilState(loadingState);
   const handleInput = (e) => {
      if(e.target.name === 'telephone'){
         const re = /^[0-9\b]+$/;
         if((e.target.value.length <= 9 && re.test(e.target.value)) || e.target.value === '')
           setContact({...contact,[e.target.name]:e.target.value});
      }
      else if(e.target.name === 'email')
       setContact({...contact,[e.target.name]:e.target.value});
      else if(e.target.name === 'message')  
       setContact({...contact,[e.target.name]:e.target.value});
      else
        setContact({...contact,[e.target.name]:FirstUpperCase(e.target.value)});
   }
   const contactForm = async () => {
      setIsLoading(true);setErreurs([]);
      var formData = objecttoFormData(contact);
      const{status,messages} = await api.post('/contactez-nous',formData);
      setIsLoading(false);
      if(status === 200){
         toast.success("Message Envoyé avec succès");
         setContact({ 
         prenom_nom:'',
         email : '',
         telephone:'',
         message:'',});
      }
      else
         setErreurs(messages);
      
  

   }
   useEffect(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      reset(contact)
   }, []);
   
    return (
        <div className="section-principal page">
          <div className="entete">
             <h2>Contactez-nous</h2>
             <p>Nous sommes déterminés à résoudre rapidement et efficacement votre problème. Faites-nous part de vos préoccupations en nous contactant, 
               et nous mettrons tout en œuvre pour trouver une solution dans les plus brefs délais.</p>
          </div>   
 
            <div className="corps">
            <form onSubmit={handleSubmit(contactForm)}>
               <div className="input">
                  <label>Prenom et Nom <span className='required'></span>
                     <input type='text' placeholder="Votre prenom et nom"
                     name="prenom_nom"
                     value={contact.prenom_nom}
                     {...register("prenom_nom", {
                        onChange: (e) => {
                        handleInput(e);
                        },
                        required: true,
                        minLength: 2,
                     })}
                     />
                     {errors.prenom_nom && errors.prenom_nom?.type === "required" && (
                        <span className="error-msg">Ce champ est obligatoire.</span>
                     )}
                     {errors.prenom_nom && errors.prenom_nom?.type === "minLength" && (
                     <span className="error-msg">
                           ce champ ne peut pas être inférieur à deux caractères.
                     </span>
                     )}
                  </label>
                  <label>Email <span className='required'></span>
                     <input type='email' placeholder="Votre Email"
                     value={contact.email} name="email"
                     {...register("email", 
                     {  onChange: (e) => {
                     handleInput(e)
                  },
                     required:true,
                     pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                     />
                     {(errors.email && errors.email?.type === "required") && (
                     <span className="error-msg">Le Champ email est obligatoire.</span>
                     )}
                     {(errors.email  && !errors.email?.type === "required")  && (
                     <span className="error-msg">Mauvais format d'email.Exemple(example@exemple.com).</span>
                     )}
                  </label>
                  <label>Téléphone <span className='required'></span>
                     <input type='tel' placeholder="Votre Téléphone"
                           value={contact.telephone} name="telephone"
                           {...register("telephone", {
                              onChange: (e) => {
                              handleInput(e);
                              },
                              required: true,
                              maxLength: 9,
                              minLength: 5,
                              pattern: /6[0-9]{8}$/g,
                           })} 
                     />
                     {errors.telephone && errors.telephone?.type === "required" && (
                           <span className="error-msg">Ce champ est obligatoire.</span>
                     )}
                     {errors.telephone && errors.telephone?.type === "minLength" && (
                           <span className="error-msg">
                           Ne peut pas être inférieur à 5 caractères.
                           </span>
                     )}
                     {errors.telephone && errors.telephone?.type === "maxLength" && (
                           <span className="error-msg">
                           Ne peut pas être supérieur à 9 caractères.
                           </span>
                     )}
                     {errors.telephone && errors.telephone.type === "pattern" && (
                           <span role="alert" className="error-msg">
                              Format invalide.ex: 620000000
                           </span>
                           )}
                  </label>
                  <label>Message <span className='required'></span>
                     <textarea rows={7} cols={5} name="message" value={contact.message}
                      placeholder="Votre Message ici"
                     {...register("message", {
                        onChange: (e) => {
                        handleInput(e);
                        },
                        required: true,
                        minLength: 10,
                     })}
                     />
                     {errors.message && errors.message?.type === "required" && (
                        <span className="error-msg">Ce champ est obligatoire.</span>
                     )}
                     {errors.message && errors.message?.type === "minLength" && (
                     <span className="error-msg">
                           ce champ ne peut pas être inférieur à dix caractères.
                     </span>
                     )} 
                  </label>
               </div>
              <div className="input-group">
                  <div>
                     <Erreurs validation = {erreurs} />
                  </div>
              </div>
              <div className="input-group">
                <div>
                   <button className="success" type="submit">Envoyez</button>
                </div>
                <div></div>
                <div></div>
              </div>   
            </form>   
       
            <div className="information">
               <h5>Conakry,KALOUM-TEMINETAYE</h5>
               <p>00 224 628 26 10 39</p>
               <div className="social">
               <FaFacebook size={24} />
               <FaInstagram size={24} />
               <FaTwitter size={24} />
               </div>
               <div id="map">
               <iframe
                  src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d3934.949259201954!2d-13.70560458513187!3d9.51313819318601!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0xf1cd0eb09180753%3A0x61562ef4dce66e2b!2sRue%20KA%20050%2C%20Conakry%2C%20Guin%C3%A9e!3m2!1d9.5130149!2d-13.7031288!5e0!3m2!1sfr!2s!4v1678026301759!5m2!1sfr!2s"
                  width="400"
                  height="250"
                  style={{ border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  ></iframe>

               </div>
            </div> 
            
            </div>
             
   
         
          
       </div>   
    )
}
export default Contact;