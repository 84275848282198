import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import {loadingState} from "../../recoil/loadingAtom";
import Principale from "../../utils/services/Principale";
import { UserContext } from '../../utils/Context/Context';
import Select from "react-select";
import moment from "moment";
import "moment/locale/fr";

const ProfileEmp = () => {
  const{user} = useContext(UserContext);
  //console.log(user);
  const[erreurs,setErreurs] = useState([]);
  const[employeur,setEmployeur] = useState(null);
  const [adminData,setAdminData] = useState(null);
  const[isLoading,setIsLoading] = useRecoilState(loadingState);
  const url = process.env.REACT_APP_URL + "storage/";

  const api = new Principale();
  const[status,setStatus]= useState("Disponible");
  const navigate = useNavigate();
  const statusColor =
    status === "Disponible"
      ? "green"
      : status === "Non disponible"
      ? "red"
      : "black";
  const getEmployeurDetails = async (employeur_id) => {
    setErreurs([]);setIsLoading(true);
    const {status,messages,employeur} = await api.get(`/employeur/getEmployeurDetails/${employeur_id}`);

    setIsLoading(false);
    if(status === 200){
      if(employeur.length > 0 )
        setEmployeur(employeur[0]);
    }
    else setErreurs(messages);  
  } 
  const ouvrirdocument = (document) => {
    if(document === "photo_piece")
      window.open(`${url}${employeur.photo_piece.replace('public/','')}`);
    else if(document === 'nif_photo'){
     window.open(`${url}${employeur.nif_photo.replace('public/','')}`); }
   else if(document === 'photo_rccm'){
      window.open(`${url}${employeur.photo_rccm.replace('public/','')}`); }
    else if(document === 'autre_document'){
      if(employeur.autre_doc !== "" && employeur.autre_doc !== null)
        window.open(`${url}${employeur.autre_doc.replace('public/','')}`);
     }   
 }
 const getAdminUser = async () => {
  setIsLoading(true);
   const{status,messages,userAdmin} = await api.get(`/admin/getAdmin/${user?.user_id}`);
  setIsLoading(false);  
   if(status === 200){
      if(userAdmin?.length > 0){
        setAdminData(userAdmin[0])
      }
   }else setErreurs(messages);

 }

  useEffect(() => {
    window.scrollTo({ top: -50, left: 0, behavior: 'smooth' });
    if(user?.role_id !== 1 && user?.role_id !== 2)
      getEmployeurDetails(user?.employeur_id);
    else 
    getAdminUser();
  },[user]);
 
  return (
    <div className="profile-pro page" style={{width:'65%'}}>
    
      {(employeur && user?.role_id !== 1 && user?.role_id !== 2 )&&
       <>
        {employeur.type === 'societe' &&
        <div className="left">
          <div className="photo">
            <img
              src={url + employeur?.logo?.replace('public/','')}
              alt=""
            />
            </div>
        </div>
        }
        <div className="right" style={{width:'100%'}}>
       {employeur.type === 'societe' && 
       <>
        <div className="group">
          <h1>Information Société</h1>
          <div className="line" style={{marginBottom:'5px'}}></div>
          <div className="sub-group">
            <strong>Nom</strong>
            <p className="subtext">{employeur?.societe}</p>
          </div>
          <div className="sub-group">
            <strong>Secteur</strong>
            <p className="subtext">{employeur?.secteur}</p>
          </div>
          <div className="sub-group">
            <strong>Representé par </strong>
            <p className="subtext">{employeur?.civilite + " "+employeur?.representant} de <strong>fonction</strong> {employeur.fonction}</p>
          </div>
          <div className="sub-group">
            <strong>Email</strong>
            <p className="subtext">{employeur?.email}</p>
          </div>
          <div className="sub-group">
            <strong>Telephone</strong>
            <p className="subtext">{employeur?.telephone}</p>
          </div>
          <div className="sub-group">
            <strong>Addresse</strong>
            <p className="subtext">{employeur?.prefecture+"/"+employeur?.commune+"/"+employeur?.quartier} - {employeur?.addresse}</p>
          </div>
        </div>

         <div className="group">
           <h1>Documents</h1>
           <div className="line" style={{marginBottom:'5px'}}></div>
           <div className="input-group">
             <label style={{fontWeight:'bold','color':'#082405'}}>Type de pièce
                <p className="subtext">{employeur?.type_piece}</p>
             </label>
             <label style={{fontWeight:'bold','color':'#082405'}}>Numero de la pièce
                <p className="subtext">{employeur?.numero_piece}</p>
             </label>
             <label style={{fontWeight:'bold','color':'#082405'}}>Rccm
                <p className="subtext">{employeur?.rccm}</p>
             </label>
             <label style={{fontWeight:'bold','color':'#082405'}}>Nif
                <p className="subtext">{employeur?.nif}</p>
             </label>
           </div>
           <div className="input-group" style={{marginTop:'10px'}}>
            
             
             <label></label>
             <label style={{fontWeight:'bold','color':'#082405',cursor:'pointer'}} onClick={() => ouvrirdocument('photo_piece')}>
                Voir Copie pièce
             </label>
             <label style={{fontWeight:'bold','color':'#082405',cursor:'pointer'}} onClick={() => ouvrirdocument('photo_rccm')}>
                Voir Copie RCCM
             </label>
             <label style={{fontWeight:'bold','color':'#082405',cursor:'pointer'}} onClick={() => ouvrirdocument('nif_photo')}>
                Voir Copie NIF
             </label>
             
             {employeur.autre_doc ?
             <label style={{fontWeight:'bold','color':'#082405',cursor:'pointer'}} onClick={() => ouvrirdocument('autre_document')}>
                Autre document
             </label>:<label></label>
             }
             <label></label>
           </div>
         </div>   
         </> 
        }    
        {employeur.type === 'personne physique' && 
           <div className="right">
             <h1>Information Personnelle</h1>
            <div className="line" style={{marginTop:'-10px'}}></div>
            <div className="sub-group">
               <strong>Prenom et Nom</strong>
               <p className="subtext">{employeur?.prenomemployeur + " "+employeur?.nomemployeur}</p>
            </div>
            <div className="sub-group">
               <strong>Civilité</strong>
               <p className="subtext">{employeur?.civilite}</p>
            </div>
            <div className="sub-group">
               <strong>Profession</strong>
               <p className="subtext">{employeur?.profession}</p>
            </div>
            <div className="sub-group">
              <strong>Email</strong>
              <p className="subtext">{employeur?.email}</p>
            </div>
           <div className="sub-group">
              <strong>Telephone</strong>
              <p className="subtext">{employeur?.telephone}</p>
           </div>
          <div className="sub-group">
            <strong>Addresse</strong>
            <p className="subtext">{employeur?.prefecture+"/"+employeur?.commune+"/"+employeur?.quartier} {(employeur?.addresse !== 'null' && employeur?.addresse)&& "-" + employeur?.addresse}</p>
          </div>
          <div className="group">
            <h1>Documents</h1>
             <div className="line" style={{marginBottom:'5px'}}></div>
              <div className="input-group">
                <label style={{fontWeight:'bold','color':'#082405'}}>Type de pièce
                    <p className="subtext">{employeur?.type_piece}</p>
                </label>
                <label style={{fontWeight:'bold','color':'#082405'}}>Numero de la pièce
                    <p className="subtext">{employeur?.numero_piece}</p>
                </label>
              </div>  
              <div className="input-group" style={{marginTop:'10px'}}>
                 <label style={{fontWeight:'bold','color':'#082405',cursor:'pointer'}} onClick={() => ouvrirdocument('photo_piece')}>
                    Voir Copie de la pièce
                </label>
                {employeur.autre_doc ?
                 <label style={{fontWeight:'bold','color':'#082405',cursor:'pointer'}} onClick={() => ouvrirdocument('autre_document')}>
                    Autre document
                  </label>:<label></label>
                 }
                 <label></label>
              </div>  
          </div>   
         </div>
        }

        </div>
       </>
      }
      {adminData && 
       <>
        <div className="left">
          <div className="photo">
            <img
              src={url + adminData?.photoProfile?.replace('public/','')}
              alt=""
            />
            </div>
        </div>
        <div className="right" style={{width:'100%'}}>
           <h1>Information</h1>
          <div className="line"></div>
          <div className='input-group'>
            <label>Prenom et Nom
                <span className='input-read'>{adminData.prenom + " "+ adminData.nom}</span>
             </label> 
             <label>Nom d'utilisateur
                <span className='input-read'>{adminData.username}</span>
             </label>
            </div>
          <div className='input-group'>
             <label>Téléphone
                <span className='input-read'>{adminData.telephone}</span>
             </label>
             <label>Email
                <span className='input-read'>{adminData.email}</span>
             </label>
             <label>Rôle
               <span className='input-read'>{adminData.role}</span>
             </label>
          </div>  
        </div>   
       </>
     
      }
    </div>
  );
};

export default ProfileEmp;
