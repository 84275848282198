import { useState,useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import RecruiterDetailsModal from "../../components/RecruiterDetailsModal/RecruiterDetailsModal";
import { useRecoilState } from "recoil";
import { loadingState } from "../../recoil/loadingAtom";
import Principale from "../../utils/services/Principale";
import moment from "moment/moment";
import Swal from "sweetalert2";
import UserDetailsModal from "../../components/UserDetailsModal/UserDetailsModal";
import { toast } from "react-hot-toast";
import AddUserModal from "../../components/AddUserModal/AddUserModal";

const UtilisateurList = () => {
  const [isRecruiterDetailsModalOpen, setIsRecruiterDetailsModalOpen] = useState(false);
  const [isLoading,setIsLoading] = useRecoilState(loadingState);
  const [erreurs,setErreurs] = useState([]);
  const [utilisateurs,setUtilisateurs] = useState([]);
  const [SelectedUser,setSelectedUser] = useState(null);
  const [addUserModalOpen,setAddUserModalOpen] = useState(false);
  const [isAction,setIsAction] = useState(false);

  const api = new Principale();
  // ? Agencies data

  const columns = [
    {
      field: "ord",
      headerName: "Ordre",
      flex: 1,
      maxWidth: 80,
    },
    {
      field: "typeCompte",
      headerName: "Type",
      flex: 1,
      minWidth: 80,
    },
    {
      field: "nomComplet",
      headerName: "Nom",
      flex: 1,
      minWidth: 150,
    },
  
    {
      field: "telephone",
      headerName: "Telephone",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "created_at",
      headerName: "Date Inscr.",
      flex: 1,
      minWidth: 150,
    },
   
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <div className="options">
            <button
              onClick={() => {
                setSelectedUser(params.row);
                setIsRecruiterDetailsModalOpen(true);
              }}
            >
              Détails
            </button>
            <button
              onClick={() => { StatusUser(params.row);
                // setIsRecruiterDetailsModalOpen(true);
              }}
              className={`${parseInt(params.row.status) === 1?'delete':'active'}`}
            >
              {parseInt(params.row.status) === 1 ? 'Desactivez':'Activez'}
            </button>
          </div>
        );
      },
    },
  ];
  const StatusUser =  (user) => {

   Swal.fire({
    title: "Êtes-vous sûr?",
    text: `${parseInt(user.status) === 1 ?'Voulez-Vous Desactivé cet utilisateur?':'Voulez-Vous Activé cet utilisateur?'}`,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: `Oui, ${parseInt(user.status) === 1 ? 'desactiver!':'activer!'}`,
    cancelButtonText: "Annuler",
  }).then((result) => {
    if (result.isConfirmed) {
      var formData = new FormData();setErreurs([]);
    
      formData.append("user_id", user.user_id);
      let gettingUser = [...utilisateurs];
       if(gettingUser[parseInt(user.id) - 1].status === 1)
           gettingUser[parseInt(user.id) - 1].status = 0
       else gettingUser[parseInt(user.id) - 1].status = 1
       setUtilisateurs(gettingUser);
       formData.append("status", gettingUser[parseInt(user.id) - 1].status);
      setIsLoading(true);
       api.post('/admin/utilisateur/statusUpdate',formData).then((resp) => {
        if(resp.status === 200)
          toast.success('Status Modifié avec succès.');
        else {
           setErreurs(resp.messages); 
           toast.error("Error !Modification non effectuée");
          } 
       });
       
      setIsLoading(false);
    }
  });

  }
  const getUtilisateurs = async () => {
    setIsLoading(true);setErreurs([]);
      const {status,messages,utilisateurData} = await api.get('/admin/getUtilisateurs');
    
    setIsLoading(false);
    if(status === 200){
         setUtilisateurs(utilisateurData.map((utilisateur,index) => {
            let nomComplet,numero_piece = '';
            if(utilisateur?.prenomCand && utilisateur?.nomCand)
              nomComplet = utilisateur.prenomCand + " "+ utilisateur.nomCand;
            else if(utilisateur.PrenomAdmin && utilisateur.nomAdmin)  
              nomComplet = utilisateur.PrenomAdmin + " " + utilisateur.nomAdmin;
            else if(utilisateur?.prenomEmp && utilisateur?.nomEmp)
               nomComplet = utilisateur.prenomEmp + " " + utilisateur.nomEmp; 
            else nomComplet='--------' ; 
            if(utilisateur?.numero_pieceCand)
            numero_piece = utilisateur?.numero_pieceCand;
            else if(utilisateur?.numero_pieceEmp)
              numero_piece = utilisateur?.numero_pieceEmp;  
            return {
              ord:index + 1,
              id:index + 1,
              user_id:utilisateur.user_id,
              role_id : utilisateur.role_id,
              typeCompte:utilisateur.typeRole.charAt(0).toUpperCase() + utilisateur.typeRole.slice(1),
              nomComplet: nomComplet, 
              PrenomAdmin:utilisateur?.PrenomAdmin,
              nomAdmin:utilisateur?.nomAdmin,
              prenomCand:utilisateur.prenomCand,
              nomCand:utilisateur?.nomCand,
              prenomEmp:utilisateur?.prenomEmp,
              nomEmp:utilisateur?.nomEmp,
              telephone:utilisateur.telephone,
              email:utilisateur.email,
              created_at:moment(utilisateur.created_at).format('Do MMMM YYYY'),
              photoProfileCand:utilisateur.photoProfileCand,
              prefecture:utilisateur.prefecture,
              commune:utilisateur.commune,
              quartier:utilisateur.quartier,
              numero_piece:numero_piece,
              photoProfileAdmin:utilisateur.photoProfileAdmin,
              capture_cv:utilisateur.capture_cv,
              capture_diplome:utilisateur.capture_diplome,
              capture_piece:utilisateur.capture_piece,
              typepiece:utilisateur.typepiece,
              profession:utilisateur.profession,
              experience:utilisateur.experience,
              datenaissance:utilisateur.datenaissance,
              niveau_etude:utilisateur.niveau_etude,
              sexe :utilisateur.sexeCand?utilisateur.sexeCand:utilisateur.sexeAdmin,
              diplome:utilisateur.diplome,
              status:utilisateur.status
       
            }
         }));
    }else setErreurs(messages);
 
  }
  useEffect(() => {
    getUtilisateurs()
  }, [isAction]);

  return (
    <div className="recruiters-list page utilisateur-list">
      <UserDetailsModal
        isOpen={isRecruiterDetailsModalOpen}
        setIsOpen={setIsRecruiterDetailsModalOpen}
        SelectedUser={SelectedUser}
      />
      <AddUserModal 
        isOpen={addUserModalOpen}
        setIsOpen={setAddUserModalOpen}
        setIsLoading={setIsLoading}
        setIsAction={setIsAction}
        isAction = {isAction}
      />
      <div className="header">
         <div>
           <h3>Liste des utilisteurs</h3>
           <p>{utilisateurs.length} utilisateur(s)</p>
         </div>
          
          <div className="btn-content">
             <button className="primary" onClick={() => setAddUserModalOpen(true)}>Ajouter un utilisateur</button>
          </div>
      </div>
      <div className="array">
        <Box sx={{ height: 579, width: "100%" }}>
          <DataGrid
            sx={{ borderRadius: 0 }}
            components={{ Toolbar: GridToolbar }}
            rows={utilisateurs}
            columns={columns}
            autoPageSize
            disableSelectionOnClick
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          />
        </Box>
      </div>
    </div>
  );
};

export default UtilisateurList;
