import { Navigate } from "react-router-dom";

const Unauthenticate = ({user,children}) => {
    const token = localStorage.getItem('token'); 
    if( user && token){
       localStorage.removeItem('item');
       if(user?.role_id === 1 || user?.role_id === 2)
         return <Navigate to="/admin/dashboard" replace/>
       else if(user?.role_id === 3)
         return children
       return children;
    }
    else 
      return children;
    
  }
  export default Unauthenticate;