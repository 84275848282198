import { useEffect } from "react";

const Video =  () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },[])
    return (
         <div className="page">
            <h2 style={{ textAlign:'center' }}>Page en construction</h2>
         </div>
        
    )
}
export default Video;