import React, { useRef } from "react";
import { Markup } from 'interweave';
import { formatStringNumber } from "../../utils/services/helpers";
import { ToWords } from 'to-words';
import moment from "moment";

import logo from "../../assets/images/logosig.png";

const ContratConsultance = React.forwardRef(({recrutement,setRecrutement,setError}, ref) => {

  const toWords = new ToWords({
    localeCode: 'fr-FR',
    converterOptions: {
      currency: false,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
    }
  });
  const handleInput = (e) => {
    setError(null);
    setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]:e.target.files[0]}});
  }
  return (
    <>
      <div style={{width:'40%',float:'right',height:'20px',marginTop:'-25px',marginRight:'5px'}} className="input-group">
       <label>Le Contrat signé
         <input type="file" name="copie_contrat"  accept=".pdf" onChange={(e) => handleInput(e)}/>
       </label> 
      </div>
      <div className="contract-doc" ref={ref}>
       <h1>Contrat de consultance</h1>
        <div className="presentation">
        <div className="logo">{logo?<img src={logo} style={{width:'85px',marginBottom:'20px',marginTop:'-23px'}}/>:'LOGO'}</div>
          <div className="employeur">
            <p>
              <strong>Entre les soussigné(e)s :</strong>
            </p>
            <p>
              La société <strong>Innovent Group Service SARLU </strong> au capitale de <strong>10 000 000 </strong>immatriculée au registre du
              commerce et des sociétés sous le numéro <strong>UI1038DEUIE</strong> dont le siège social
              est situé à <strong>Conakry/Kaloum/Manquepas</strong>, représentée par Monsieur <strong>Youssouf FOFANA</strong>,
              contact : <strong>628 26 10 39</strong>.
            </p>
            <p>
              D'une part, ci-après dénomée l'<strong>Employeur.</strong>
            </p>
          </div>
          <div className="client">
            <p>
              <strong>Et :</strong>
            </p>
            {recrutement?.employeur?.typeEmp === 'societe' &&
              <p>
              La société <strong>{recrutement?.employeur?.societe}</strong> au capitale de { !isNaN(recrutement?.employeur?.capital) === true ?<strong>{formatStringNumber(recrutement.employeur?.capital)} GNF</strong>:'-------'} immatriculée au registre du
              commerce et des sociétés sous le numéro <strong>{recrutement?.employeur?.nif}</strong> dont le siège social
              est situé à <strong>{recrutement?.employeur?.prefecture+"/"+recrutement?.employeur?.commune+"/"+recrutement?.employeur?.quartier}</strong>, représentée par {recrutement?.employeur?.civilite} <strong>{ recrutement?.employeur?.representant}</strong>
              , contact : <strong>{recrutement?.employeur?.telephone}</strong>.
            </p>}
            {recrutement?.employeur?.typeEmp === 'personne physique' && 
              <p>
                {recrutement?.employeur?.civilite} <strong>{ recrutement?.employeur?.prenomEmp + " "+ recrutement?.employeur?.nomEmp }</strong>
              , contact : <strong>{recrutement?.employeur?.telephone}</strong>, domicilié à l'addresse {recrutement?.employeur?.prefecture +"/" +recrutement?.employeur?.commune+"/"
                +recrutement?.employeur?.quartier}{(recrutement?.employeur?.addresse && recrutement?.employeur?.addresse !== 'null')?"/"+recrutement?.employeur?.addresse:""}
              </p>
            }
            <p>
              D'une part, ci-après dénomée le <strong>Client.</strong>
            </p>
          </div>
        </div>
        <p className="convention">Il a été convenu et arreté ce qui suit :</p>
        <div className="articles">
          <div className="article">
            <h4>Article 1 : Engagement</h4>
            <p>
              Le Consultant s’engage à fournir à la Société des services
              compétents et l’attention raisonnable afin de satisfaire le Client : <strong>{recrutement?.employeur.typeEmp === 'societe'?recrutement?.employeur?.societe:recrutement?.employeur?.civilite + " "+recrutement?.employeur?.prenomEmp +" "+ recrutement?.employeur?.nomEmp }</strong> durant toute la durée du présent contrat, dans le respect des
              termes et conditions prévus par celui-ci, sachant que la liste des
              services peut être modifiée à tout moment par la Société et qu’un
              tel changement n’affecte pas les autres clauses du présent contrat.
              Dans le cadre de cette mission, le Prestataire s'engage à mettre ses
              collaborateurs à la disposition du Client si cela est nécessaire
              pour la bonne exécution de la mission. Cependant, lesdits salariés
              resteront sous l'autorité et sous la responsabilité du Prestataire
              pendant leur intervention chez le Client.
            </p>
          </div>
          <div className="article">
            <h4>Article 2 : Durée du contrat</h4>
            <p>
              Ce Contrat est conclu pour une durée non déterminée. 
              Il entre en vigueur dès après la signature du present contrat et prendra fin
              dès après la prestation.  
              {/* le …./…… /……. et prend fin le …./…… /……. . Il pourra être renouvelé
              (….) fois en d'autres termes et conditions modifiées (si
              nécessaires) décidés d'un commun accord par les deux parties à moins
              que l'une des deux parties mettent fin à ce contrat en informant
              l'autre partie avec un préavis de deux (2) mois soit avant le 1er
              novembre. */}
            </p>
          </div>
          <div className="article">
            <h4>Article 3 : Mission</h4>
            <p>Au cours de cette période, la Société IGS à pour mission de :</p>
            <div><Markup content={recrutement?.emploie.taches} /></div>
            
          </div>
          <div className="article">
            <h4>Article 4 : Obligation du consultant</h4>
            <p>
              Il est rappelé que le consultant est tenu à une obligation de
              moyens. Il doit donc exécuter sa mission conformément aux règles en
              vigueur dans sa profession et en se conformant à toutes les données
              acquises dans son domaine de compétence.
            </p>
            <p>
              Il reconnaît que le Client lui a donné une information complète sur
              ses besoins et sur les impératifs à respecter.
            </p>
            <p>
              Il s'engage à se conformer au règlement intérieur et aux consignes
              de sécurité applicables chez le Client.
            </p>
            <p>
              Enfin, il s’engage à observer la confidentialité la plus totale en
              ce qui concerne le contenu de la mission et toutes les informations
              ainsi que tous les documents que le Client lui aura communiqués.
            </p>
          </div>
          <div className="article">
            <h4>Article 5 : Obligation du client</h4>
            <p>
              Afin de permettre au Prestataire de réaliser la mission dans de
              bonnes conditions, le Client s’engage à lui remettre tous les
              documents nécessaires dans le meilleur délai.
            </p>
          </div>
          <div className="article">
            <h4>Article 6 : Litige</h4>
            <p>
              Tout litige relevant de l’exécution du présent contrat sera réglé à
              l’amiable ; à défaut, il sera soumis à la juridiction compétente de
              CONAKRY.
            </p>
          </div>
          <div className="article">
            <h4>Article 7 : Lieu</h4>
            <p>La mission s'effectuera à <strong>{recrutement?.emploie?.lieuexecution}</strong>.</p>
          </div>
          <div className="article">
            <h4>Article 8 : Rémunération</h4>
            <p>
              Le client : {recrutement?.employeur.typeEmp === 'societe'? <strong>{recrutement?.employeur?.societe}</strong>:'' } a souscrit une garantie financière auprès de la
              Société IGS pour montant global de {recrutement?.emploie?.montantprestation?toWords.convert(parseInt(recrutement?.emploie?.montantprestation) * 0.18 + parseInt(recrutement?.emploie?.montantprestation)):"zero"} <strong>( {formatStringNumber(recrutement?.emploie?.montantprestation?parseInt(recrutement?.emploie?.montantprestation) * 0.18 + parseInt(recrutement?.emploie?.montantprestation):0)} )</strong>
              <strong> GNF</strong> soit <strong style={{color:'#76760d'}}>18 % de TVA</strong> versé dans le compte bancaire de la société IGS Nᵒ xxxx (Nom de la Banque).
            </p>
            <p>
              Conformément à l'article L. 1251-49 du Code du travail, tout
              entrepreneur de travail temporaire est tenu, à tout moment, de
              justifier d'une garantie financière assurant, en cas de défaillance
              de sa part, le paiement :
              <ul>
                <li>des salaires et de leurs accessoires</li>
                <li>des indemnités résultant du présent chapitre</li>
                <li>
                  des cotisations obligatoires dues à des organismes de Sécurité
                  sociale ou à des institutions sociales, le cas échéant, des
                  remboursements qui peuvent incomber aux employeurs à l'égard de
                  ces organismes et institutions dans les conditions prévues à
                  l'article L. 244-8 du Code de la Sécurité sociale.
                </li>
              </ul>
            </p>
          </div>
          <div className="article">
            <h4>Article 9 : Conclusion du contrat</h4>
            <p>
              Conformément aux dispositions de l'article L. 1251-42 et suivants du
              Code du travail, ce contrat doit être conclu par écrit au plus tard
              dans les 2 jours ouvrables suivant la mise à disposition.
            </p>
          </div>
          <p className="note">
            Fait en double exemplaire, dont un est remis à chacune des parties du
            présent avenant.
          </p>
          <p className="date">A Conakry, le {moment().format('Do MMMM YYYY')}</p>
          <p className="nb">
            Faire précéder la signature du client de la mention manuscrite "lu et
            approuvé".
          </p>
          <div className="signatures">
            <p>
              <strong>La société IGS</strong>
            </p>
            <p>
              Le client {recrutement?.employeur.typeEmp === 'societe'? <strong>{recrutement?.employeur.societe}</strong>:<strong>{recrutement?.employeur?.civilite + " " +recrutement?.employeur?.prenomEmp + " "+ recrutement?.employeur?.nomEmp}</strong>}
            </p>
          </div>
        </div>
       </div>
    </>
  );
});

export default ContratConsultance;
