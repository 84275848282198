import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../../utils/Context/Context';
import { objecttoFormData } from '../../utils/services/helpers';
import Principale from '../../utils/services/Principale';
import Erreurs from '../../components/Erreurs/Erreurs';
// const useFocus = () => {
//   const htmlElRef = useRef(null)
//   const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}
//   return [ htmlElRef, setFocus ] 
// }
const Login = () => {
 //declaration des variables   
  const location = useLocation();

  const[userInfo,setUserInfo] = useState({
    username:'',
    password:'',
    remember: false,
   });
   const [erreurs,setErreurs] = useState();
   const [isLoading,setIsLoading] = useState(false);
   const {setUser} = useContext(UserContext);
   const navigate = useNavigate();
  const {register,handleSubmit,reset,formState: { errors },} = useForm({defaultValues: {userInfo,}});
  // const [inputRef, setInputFocus] = useFocus();
  const api = new Principale();


  const handleInput = (e) => {
    if(e.target.name === "remember"){
       setUserInfo({...userInfo,remember:!userInfo.remember});return;
    }
    setUserInfo({...userInfo,[e.target.name]:e.target.value});
   }
  const authenticate = async () => {
    setIsLoading(true);
        var formData = objecttoFormData(userInfo);
        const{status,token,messages} = await  api.post('/auth',formData);
 
        if(status === 200){
            localStorage.setItem('token',token);
            new Principale().get('/profile').then((resp) => {
                if(resp?.status === 200){
                  setUser(resp.user);
                  if(resp?.user?.role_id === 1 || resp?.user?.role_id === 2 )
                    navigate('/admin/dashboard');
                }
            });
            // console.log(location)
            api.rememberme(userInfo);
           if(typeof location.state !== 'undefined'){
            if( typeof location.state?.page !== 'undefined')
               navigate(location.state?.page);
             else navigate('/');
            }
           else 
              navigate('/');
        }else setErreurs(messages);
    setIsLoading(false);     
  }
  useEffect(() => {
    if(localStorage.getItem('user') && typeof localStorage.getItem('user') !== 'undefined') {
        reset(JSON.parse(localStorage.getItem('user')));  
        setUserInfo(JSON.parse(localStorage.getItem('user')));
     }
     window.scrollTo({ top: 0, left: 0, behavior:'auto' });
  },[]);
  return (
    <div className="login-page page">
        <div className="header">
            <h2>Connexion</h2>
            <p className="subtext">connectez-vous ou créer votre compte pour continuer.</p>
        </div>
        <form onSubmit={handleSubmit(authenticate)}>
            <label>Email ou N°Téléphone
                <input type="text" name="username" id="username" placeholder="Votre email ou numero de telephone"
                  {...register("username", {
                        onChange: (e) => {
                          handleInput(e);
                        },
                        required: true,minLength:2
                   })}
                   value={userInfo.username?userInfo.username:''}
                   autoFocus
                />
                {errors.username && errors.username?.type === "required" && (
                  <span className="error-msg">Ce champ est obligatoire.</span>
                )}
                 {errors.username && errors.username?.type === "minLength" && (
                  <span className="error-msg">Le Caractère minimum est deux (2).</span>
                )}
            </label>
            <label className="password">Mot de passe
                <input type="password" name="password" id="password" placeholder="Mot de passe"
                 {...register("password", {
                    onChange: (e) => {
                      handleInput(e);
                    },
                    required: true,
                  })}
                 value={userInfo.password}
                />
                 {errors.password && errors.password?.type === "required" && (
                   <span className="error-msg">Le mot de passe est obligatoire.</span>
                )}
            </label>
            <div className="checkbox">
                <label>
                    <input type="checkbox" name="remember" id="remember" checked={userInfo.remember}
                      onChange={handleInput}/> Se souvenir de moi
                </label>
            </div>
            <Erreurs validation = {erreurs} />
            <button type='submit' disabled={isLoading}>Se connecter</button>
        </form>
        <div className="bottom-text">
            <p className="subtext" onClick={() => navigate('/password/reset')}>J'ai oublié mon mot de passe</p>
            <p className="subtext">Vous n'avez pas de compte ? <strong><Link to={"/signup/recruiter"}>Inscrivez-vous</Link></strong></p>
        </div>
    </div>
  )
}

export default Login