import { useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";

const CandidatCard = ({ candidat_id,photo, nom, experience, diplome,status,datenaissance,contrat_type, datedebut,datefin,contrat_copie }) => {

  const statusColor =
    status === "Terminé"
      ? "green"
      : status === "Refusé"
      ? "red"
      : status === "En cours"
      ? "blue"
      : "black";
  const navigate = useNavigate();
 
  const voirDetails = (candidat_id) => {
     navigate(`/profile/pro/details/${candidat_id}`, { state: { page: "details" } });
  }

  return (
    <div className="contract-card">
      <div className="contract-card-left">
        <div className="image">
          <img src={photo} alt="" />
        </div>
      </div>
      <div className="contract-card-right">
        <div className="top">
          <p className="nom"> {nom} </p>
          <p className="datenaissance">{"Date de Naissance: Le " + moment(datenaissance).format('Do MMMM YY')} </p>
          <p className="diplome">{"Diplôme : " + diplome?.toUpperCase()} </p>
          <p className="experience">{"Expérience : " + experience}</p>
          <p className="experience">{contrat_type ? "Type Contrat : "+contrat_type.toUpperCase():'---------'}</p>
          {(datedebut && datefin && status === "En cours") && <p className="date">
            Periode : Du {moment(datedebut).format('Do MMMM YYYY')} au {moment(datefin).format('Do MMMM YYYY') + " soit " 
            +moment(datefin).diff(datedebut,'months')+" mois restant(s)"}
          </p>}
          <p className="status">
            Status : <strong className={statusColor}> {status} </strong>
          </p>
        </div>
        <div className="bottom">
          <button className="primary" onClick={() => voirDetails(candidat_id)}>Voir Details</button>
        </div>
      </div>
    </div>
  );
};

export default CandidatCard;
