import "./styles/App.scss";
import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import Home from "./pages/Home/Home";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import SideMenu from "./components/SideMenu/SideMenu";
import FindProfessionals from "./pages/FindProfessionals/FindProfessionals";

import Login from "./pages/Login/Login";
import SignUp from "./pages/SignUp/SignUp";
import SignUpPro from "./pages/SignUpPro/SignUpPro";
import {UserContext} from './utils/Context/Context';
import Spinner from "./components/Spinner/Spinner";
import DashboardPro from "./pages/DashboardPro/DashboardPro";
import Principale from "./utils/services/Principale";
import Connected from "./components/Connected/Connected";
import FindRecruiters from "./pages/FindRecruiters/FindRecruiters";
import Unauthenticate from "./components/Unauthenticate/Unauthenticate";
import EditProfilePro from "./pages/EditProfilePro/EditProfilePro";
import AccountSettings from "./pages/AccountSettings/AccountSettings";
import ProfilePro from "./pages/ProfilePro/ProfilePro";
import MyContracts from "./pages/MyContracts/MyContracts";
import EditProfileRecruiter from "./pages/EditProfileRecruiter/EditProfileRecruiter";
import DashboardAdmin from "./pages/DashboardAdmin/DashboardAdmin";
import RecruitersList from "./pages/RecruitersList/RecruitersList";
import ProfileRecruiter from "./pages/ProfileRecruiter/ProfileRecruiter";
import NotFound from "./pages/NotFound/NotFound";
import ContractsList from "./pages/ContractsList/ContractsList";
import PrintContract from "./pages/PrintContract/PrintContract";
import NewRecrutement from "./pages/NewRecrutement/NewRecrutement";
import ProfileEmp from "./pages/ProfileEmp/ProfileEmp";
import Blog from "./pages/Blog/Blog";
import NavbarAdmin from "./components/NavbarAdmin/NavbarAdmin";
import UtilisateurList from "./pages/UtilisateurList/UtilisateurList";
import MetierList from "./pages/MetierList/MetierList";
import Contact from "./pages/Contact/Contact";
import About from "./pages/About/About";
import Video from "./pages/Video/Video";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import UpdatePassword from "./pages/UpdatePassword/UpdatePassword";
import AdminControl from "./Security/AdminControl";

function App() {
  const api = new Principale();
  const [user,setUser] = useState();
  const [globalState,setGlobalState] = useState(false)
  const getprofile = () => {
    if(localStorage.getItem('token')){
       api.get('/profile').then((resp) => {
        if(resp.status === 200)
          setUser(resp.user);
     
    });
    }
  } 
  useEffect(() => {
    getprofile();
  },[])
  return (
    <div className="App">
     <Toaster position="top-right" reverseOrder={false} />
     <UserContext.Provider value={{user,setUser}}> 
          <Spinner />
          <SideMenu />
          {(user?.role_id !== 1  || user?.role_id !== 2) && <Navbar />}
          {(user?.role_id === 1 || user?.role_id === 2) && <NavbarAdmin globalState={globalState} setGlobalState={setGlobalState}/>}
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Unauthenticate user={user}><Home /></Unauthenticate>}/>
            <Route path="login" element={<Unauthenticate user={user}><Login /></Unauthenticate>} />
            <Route path="blog" element={<Blog />}/>
            <Route path="otherRecrutment" element={<Blog />}/>
            <Route path="signup">
              <Route path="recruiter" element={<Unauthenticate user={user}><SignUp /></Unauthenticate>} />
              <Route path="pro" element={<Unauthenticate user={user}><SignUpPro /></Unauthenticate>} />
            </Route>
            <Route path="password">
               <Route path="reset" element={<Unauthenticate user={user}><ChangePassword /></Unauthenticate>} />
               <Route path="changed/:id" element={<Unauthenticate user={user}><UpdatePassword /></Unauthenticate>} />
            </Route>  
            <Route path="profile">
              <Route path="pro/details/:id" element={<Connected user={user}><ProfilePro /></Connected>} />
              {/* Ajouté */}
              <Route path="recruiter/details/:id" element={<ProfileRecruiter />} />
            </Route>
            <Route path="editprofile">
              <Route path="pro" element={<Connected user={user}><EditProfilePro /></Connected>}/>
              {/* Ajouté */}
              <Route path="recruiter" element={<EditProfileRecruiter />}/>
            </Route>
            <Route path="myaccount">
              <Route path="settings" element={<Connected user={user}><AccountSettings /></Connected>}/>
            </Route>
            <Route path="findprofessionals" element={<FindProfessionals />} />
            <Route path="findrecruiters" element={<FindRecruiters />} />
              {/* Ajouté */}
            <Route path="recruiters/list" element={<AdminControl user={user}><RecruitersList /></AdminControl>} />
              {/* Ajouté */}
            <Route path="contracts/list/termines" element={<ContractsList />} />
            <Route path="contracts/list" element={<Connected user={user}><ContractsList /></Connected>} />
            <Route path="pro/dashboard" element={<Connected user={user}><DashboardPro /></Connected>} />
            <Route path="recruter/profile" element={<Connected user={user}><ProfileEmp /></Connected>} />
              {/* fait */}
            <Route path="admin/dashboard" element={<AdminControl user={user}><DashboardAdmin /></AdminControl> } />
              {/* Ajouté */}
            <Route path="mycontracts" element={<MyContracts />} />
            <Route path="admin/recrutement/:emploie_id" element={<NewRecrutement  globalState={globalState} setGlobalState={setGlobalState}/>} />
              {/* Ajouté */}
            <Route path="impression-contrat" element={<PrintContract />} />
            <Route path="utilisateur">
              <Route path="list" element={<AdminControl user={user}><UtilisateurList /></AdminControl> } />
            </Route> 
            <Route path="metiers">
              <Route path="list" element={<AdminControl  user={user}><MetierList /></AdminControl>} />
            </Route>   
            <Route path="contact" element={<Contact />} />
            <Route path="about" element={<About />} />
            <Route path="videos" element={<Video />} />
          </Routes>
          {(user?.role_id === 3 || user?.role_id === 4 || typeof user?.role_id === 'undefined' ) &&  <Footer />}
        
      </UserContext.Provider>  
    </div>
  );
}

export default App;
