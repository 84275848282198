import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import {useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Select from "react-select";
import { compareTime, FirstUpperCase, formatStringNumber, validateDate } from '../../utils/services/helpers';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { ToWords } from 'to-words';
const StepThree = ({ prevStep, nextStep,recrutement,setRecrutement }) => {
 
  // const ref = useRef(null)
  const {register,handleSubmit,reset,formState: { errors },setValue,} = useForm({defaultValues: {recrutement},});  
  const toWords = new ToWords({
    localeCode: 'fr-FR',
    converterOptions: {
      currency: false,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
    }
  });
  const jours = [
    { value: "Lundi", label: "Lundi" },
    { value: "Mardi", label: "Mardi" },
    { value: "Mercredi", label: "Mercredi" },
    { value: "Jeudi", label: "Jeudi" },
    { value: "Vendredi", label: "Vendredi" },
    { value: "Samedi", label: "Samedi" },
    { value: "Dimanche", label: "Dimanche" }
  ];

  useEffect(() => {
    if(!recrutement?.emploie?.selectedjours){
      const getselectedjours = recrutement.emploie.jours_proposes.split("-");
      setRecrutement({...recrutement,emploie:{...recrutement.emploie,unitePaiement:'mensuel',selectedjours:getselectedjours.map((jour) => {
      return {
        value:jour,
        label:jour
      }
      })}});
    
    }
   
    window.scrollTo({ top: -50, left: 0, behavior: 'smooth' });
  },[]);
  useMemo(() => {
    reset(recrutement);
  },[])

const handleInput = (e) => {
  if(e?.target?.name === 'contrat_type_propose' || e?.target?.name === 'heure_debut' || e?.target?.name === 'heure_fin'){

    //  if(e.target.name === 'dure_unit'){
    //     if(e.target.value === 'heure' && parseInt(recrutement.emploie.nombre) >= 24){
    //       setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]:'jours',nombre: Math.round(parseInt(recrutement.emploie.nombre)/24)}});
    //     }
    //     else if(e.target.value === 'jour' && parseInt(recrutement.emploie.nombre) >= 365){
    //       setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]:'mois',nombre: Math.round(12*parseInt(recrutement.emploie.nombre)/365)}});
    //     }
    //     else if(e.target.value === 'mois' && parseInt(recrutement.emploie.nombre) >= 12){
    //       setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]:'année',nombre: Math.round(parseInt(recrutement.emploie.nombre)/12)}});
    //     }
    //     else 
    if(e?.target?.name === 'contrat_type_propose'){
         if(e.target.value === 'consultance')
           setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]:e.target.value,date_fin:'',date_debut:''}});
         else setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]:e.target.value}});  
           
    }else
     setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]:e.target.value}});
    //  }
      
    // else  setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]:e.target.value}});   
  }
  else if(e?.target?.name === "unitePaiement"){
     setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]:e.target.value}});   
  }
  else if(e?.target?.name === "nombre"){
    
    const re = /^[0-9\b]+$/;
    if((e.target.value.length <= 9 && re.test(e.target.value)) || e.target.value === '')
      if(e.target.value === '')
      setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]:0}});
      else {
        if(recrutement.emploie.dure_unit === 'heure'){
           if(parseInt(e.target.value) >= 1){
             if(parseInt(e.target.value) >= 24)
             setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]: Math.round(parseInt(e.target.value)/24),dure_unit:'jours'}});
             else  setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]:parseInt(e.target.value)}});   
            }
          
           //else setCustomErrorMsg("Les heures sont comprises entre 1 et 24.")  
          }
        else if(recrutement.emploie.dure_unit === 'jour'){
          if(parseInt(e.target.value) >= 1){
            if(parseInt(e.target.value) >= 365){
              setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]: Math.round(12*parseInt(e.target.value)/365),dure_unit:'mois'}});
            }
            else  setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]:parseInt(e.target.value)}});
          }    
         }
        else if(recrutement.emploie.dure_unit=== 'mois'){
          if(parseInt(e.target.value) >= 1){
            if(parseInt(e.target.value) >= 12){
              setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]: Math.round(parseInt(e.target.value)/12),dure_unit:'année'}});
            }
            else  setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]:parseInt(e.target.value)}});
          }    
        }
        else if(recrutement.emploie.dure_unit === 'année'){
          setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]:parseInt(e.target.value)}});
        }
        else if(recrutement.emploie.dure_unit === '')
        setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]:parseInt(e.target.value)}});   
       } 
  }
  else if(e?.target?.name === 'lieuexecution'){
    setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]:FirstUpperCase(e.target.value)}});  
  }
  else if(e?.target?.name === 'date_debut' || e?.target?.name === 'date_fin'){
    //if(validateDate(recrutement.emploie.date_debut) || validateDate(recrutement.emploie.date_fin))
      setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]:e.target.value}});
  }
  else if(e?.target?.name === "montantprestation" || e?.target?.name === 'autrefrais'){
    const re = /^[0-9\b]+$/;
    if(re.test(e.target.value) || e.target.value === '')
      setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]:e.target.value}});
     
  }
  else if(e?.target?.name === 'capital'){
    const re = /^[0-9\b]+$/;
    if(re.test(e.target.value) || e.target.value === '')
      setRecrutement({...recrutement,employeur:{...recrutement.employeur,[e.target.name]:e.target.value}});
  }
  // else if(e?.target?.name === "taches"){
  //   console.log('ddkdk')
  //   setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]:e.target.value}});
  // }
  else{
 
     setRecrutement({...recrutement,emploie:{...recrutement.emploie,selectedjours:e}});}
    
  // else {
  //   setRecrutement({...employeur,selected_jours:e});
  //  setError("selectedJours");
  //   unregister("selected_jours");
  // }

}

//console.log(recrutement.emploie);
const editor = useRef();

// The sunEditor parameter will be set to the core suneditor instance when this function is called
const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
};

const isSelectedJours = Array.isArray(recrutement?.emploie?.selectedjours) && recrutement?.emploie?.selectedjours?.length > 0;
//  console.log(recrutement.employeur.typeEmp)
  return (
    <div>
      <div className='step-component'>
        <h4>Details du Contrat</h4>
         <form onSubmit={handleSubmit(nextStep)}>
         
          <div className="input-group">
            <label>Type de Contrat
            <select
                {...register("contrat_type_propose", {
                  onChange: (e) => {
                      handleInput(e);
                  },
                  validate: (value) => value !== "" ,
                  })}
                  value={recrutement.emploie?.contrat_type_propose}
              >
                  <option value="">Selectionnez le type de contrat</option>
                  <option value="interim">Interim</option>
                  <option value="consultance">Consultance</option>
                  <option value="recrutement">Recrutement</option>
                  {/* <option value="saisonnier">Saisonnier</option> */}
                  <option value="mise à disposition">Mise à disposition</option>
                  <option value="tâcheronat">Tâcheronat</option>
                  {/* <option value="contrat à durée determinée">Contrat à durée déterminée</option>
                  <option value="contrat à durée indeterminée">Contrat à durée indeterminée</option> */}
                  {/* <option value="contrat de mission">contrat de mission</option> */}
                </select>
            </label>
            <label>Proposition des Jours de Travaux
                  <Select
                    name="selectedjours" 
                    placeholder={<div>Selectionnez les jours de Travail</div>}
                    // value={selectedOptions}
                    value={recrutement?.emploie?.selectedjours?recrutement.emploie.selectedjours:[]}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    {...register("selectedjours", {
                      validate:() => isSelectedJours,
                      })}
                    onChange={(e) => handleInput(e)}
                    // onFocus={() => setIsEditor(false)}
                    // onMenuClose={() => setIsEditor(true)}  
                    options={jours}  
                    // styles={{ menuPortal:base => ({...base,alignItems:"baseline",backgroundColor:'black'})}}
                  />
                   {errors.selectedjours && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                 )}
                </label>
                {recrutement?.employeur?.typeEmp === "societe" &&
                  <label>Capital Initial
                  <input type="text" name="capital"
                      value={recrutement.employeur?.capital?recrutement.employeur?.capital:''}
                      {...register("capital", {
                      onChange: (e) => {
                        handleInput(e);
                      },
                      required: true,
                      pattern: /^[0-9]+$/,
                    })} 
                  />
                {errors.capital && (
                  <span className="error-msg">Le Capital Initial est obligatoire.</span>
                )}
                {(recrutement.employeur?.capital && !isNaN(recrutement.employeur?.capital))&& <div><strong>{toWords.convert(recrutement.employeur?.capital)}</strong>( {formatStringNumber(recrutement.employeur?.capital)} )</div>}
                  </label>
                }
             
               
            {/* <label>
              {recrutement.emploie?.contrat_type_propose === "tâcheronat" ? "Nombre de Tâche":"Unité de temps en"}
                  {recrutement.emploie?.contrat_type_propose  !== "tâcheronat" && 
                      <>
                      <select
                        {...register("dure_unit", {
                          onChange: (e) => {
                              handleInput(e);
                          },
                          validate: (value) => value !== "" ,
                          })}
                          value={recrutement.emploie.dure_unit}
                         // ref={inputRef1}
                      >
                        <option value="">Selectionnez l'unité de temps</option>
                        <option value="heure" disabled={recrutement.emploie?.contrat_type_propose === "recrutement"}>Heure</option>
                        <option value="jour" disabled={recrutement.emploie?.contrat_type_propose === "recrutement"}>Jour</option>
                        <option value="mois" disabled={recrutement.emploie?.contrat_type_propose === "recrutement"}>mois</option>
                        <option value="année" disabled={recrutement.emploie?.contrat_type_propose === "recrutement"}>Année</option>
                        {recrutement.emploie?.contrat_type_propose === "recrutement" && <option value="forfait">Forfait</option>}
                        {recrutement?.employeur?.contrat_type_propose === "tâcheronat"  && <option value="tâche" disabled={recrutement?.employeur?.contrat_type_propose === "recrutement"}>Tâche</option>}
                      </select>
                      {errors.dure_unit && (
                        <span className="error-msg">Ce champ est obligatoire.</span>
                      )}
                      </>
                   
                    }
                 {recrutement.emploie?.contrat_type_propose  === "tâcheronat" &&
                  <>
                  <input type="number" min= {1} name ="nombre" value={recrutement.emploie?.nombre} 
                    {...register("nombre", {
                      onChange: (e) => {
                        handleInput(e);
                      },
                      required: true,
                      pattern: /^[0-9]+$/,
                      min:1
                    })} 
                  />
                   {errors.nombre && (
                   <span className="error-msg">Ce champ est obligatoire.</span>
                   )}
                  </>
                 }  
                
            </label> */}
            {/* <label>Nombre d'heures de travail
                <input type="text" name="nombre" value={recrutement.emploie.nombre}
                  {...register("nombre", {
                    onChange: (e) => {
                      handleInput(e);
                    },
                    required: true,
                    pattern: /^[0-9]+$/,
                    min:1,
                  })} 

                  //ref={inputRef}
                />
              {(errors.nombre && errors.nombre?.type !== "min") && (
                  <span className="error-msg">Ce champ est obligatoire.</span>
              )}
              {errors.nombre && errors.nombre?.type === "min" && (
                  <span className="error-msg">Le minimum est un (1)</span>
              )}
               
            </label> */}
          </div>
          <div className="input-group">
          <label>Heure debut
                  <input type="time" value={recrutement.emploie.heure_debut}
                   {...register("heure_debut", {
                    onChange: (e) => {
                      handleInput(e);
                    },
                    required: true,
                    validate:(value) => compareTime(value,recrutement.emploie.heure_fin) === -1 && recrutement.emploie.heure_fin !== ''
                  })} 
                  />
                {errors.heure_debut && errors.heure_debut?.type === "required" && (
                  <span className="error-msg">Ce champ est obligatoire.</span>
                )}
                {(errors.heure_debut && errors.heure_debut?.type !== "required" )&& (
                      <span className="error-msg">L'heure de debut doit être inférieur à l'heure de fin.</span>
                 )}
                </label>
                <label>Heure Fin
                  <input type="time" value={recrutement.emploie.heure_fin}
                  {...register("heure_fin", {
                    onChange: (e) => {
                      handleInput(e);
                    },
                    required: true,
                    validate:(value) => compareTime(recrutement.emploie.heure_debut,value) === -1 && recrutement.emploie.heure_debut !== ''
                  })} 
                  />
                {errors.heure_fin && errors.heure_fin?.type === "required" && (
                  <span className="error-msg">Ce champ est obligatoire.</span>
                )}
                 {(errors.heure_fin && errors.heure_fin?.type !== "required" )&& (
                      <span className="error-msg">L'heure de fin doit être supérieur à l'heure de debut.</span>
                 )}
                </label>
                <label>Lieu d'execution
                   <input type="text" name="lieuexecution" value={recrutement?.emploie?.lieuexecution?recrutement?.emploie?.lieuexecution:''}
                    {...register("lieuexecution", {
                      onChange: (e) => {
                        handleInput(e);
                      },
                      required: true,
                      minLength:2
                    })} 
                   />
                 {errors.lieuexecution && errors.lieuexecution?.type === "required" && (
                   <span className="error-msg">Ce champ est obligatoire.</span>
                 )}
                  {(errors.lieuexecution && errors.lieuexecution?.type === "minLength" )&& (
                      <span className="error-msg">Le caractère minimum pour le lieu d'execution du contrat est obligatoire.</span>
                 )}
                </label>
          </div>
       
          <div className='line'>
            <span style={{fontWeight:'bold', fontSize:'14px' ,fontFamily:'Fraunces',paddingBottom:'8px',display:'block'}}>Information de paiement</span>
          </div>
          <div className='input-group'>
            <label>Montant de la Prestation
              <input type="text" 
                name="montantprestation"
                value={recrutement.emploie?.montantprestation?recrutement.emploie?.montantprestation:''}
                {...register("montantprestation", {
                onChange: (e) => {
                  handleInput(e);
                },
                required: true,
                pattern: /^[0-9]+$/,
              })} 
              />
            {errors.montantprestation && (
              <span className="error-msg">Ce champ est obligatoire.</span>
            )}
            {(recrutement.emploie?.montantprestation && !isNaN(recrutement.emploie?.montantprestation))&& <div><strong>{toWords.convert(recrutement.emploie?.montantprestation)}</strong>( {formatStringNumber(recrutement.emploie?.montantprestation)} )</div>}
          </label>   
          
            <label>Autres Frais
                <input type="text" 
                  name="autrefrais"
                  value={recrutement.emploie?.autrefrais}
                  {...register("autrefrais", {
                    onChange: (e) => {
                      handleInput(e);
                  },
                required: false,
                pattern: /^[0-9]+$/,
              })} 
                />
              {errors.autrefrais && (
                  <span className="error-msg">Ce champ est obligatoire.</span>
              )}
                              {(recrutement.emploie?.autrefrais && !isNaN(recrutement.emploie?.autrefrais))&& <div><strong>{toWords.convert(recrutement.emploie?.autrefrais)}</strong> ( {formatStringNumber(recrutement.emploie?.autrefrais)} )</div>}
            </label>
            <label>Mode Paiement
                <select name="unitePaiement"  value={recrutement?.emploie?.unitePaiement?recrutement?.emploie?.unitePaiement:''}
                  {...register("unitePaiement", {
                  onChange: (e) => {
                      handleInput(e);
                  },
                  validate: (value) => value !== "" ,
                  })}
                  >
                    {/* <option value="">Selectionner l'unite de temps</option> */}
                    <option value="mensuel">Mensuel</option>
                    <option value="annuel">Annuel</option>
                </select>
              
              {errors.unitePaiement && (
                  <span className="error-msg">Ce champ est obligatoire.</span>
              )}
            </label> 
          </div>
           <div className='input-group'>
             <label> Date de debut
                 <input type="date" 
                  name="date_debut"
                  value={recrutement.emploie?.date_debut?recrutement.emploie?.date_debut:''}
                  {...register("date_debut", {
                  onChange: (e) => {
                    handleInput(e);
                  },
                   required:"La date de debut est obligatoire.",
                  })} 
                 />
                 <ErrorMessage
                    errors={errors}
                    name="date_debut"
                    render={({ message }) => <span className="error-msg">{message}</span>}
                 />
             </label>
             <label> Date de fin
                 <input type="date" 
                   name="date_fin"
                   value={recrutement.emploie?.date_fin?recrutement.emploie?.date_fin:''}
                   {...register("date_fin", {
                   onChange: (e) => {
                     handleInput(e);
                   },
                   required:"La date de fin est obligatoire.",
                   validate: (value) => new Date(recrutement.emploie?.date_debut).getTime() <= new Date(value).getTime() || "La date de fin doit être supérieur ou égale à la date de depart."
                  })} 
                 />
                 <ErrorMessage
                    errors={errors}
                    name="date_fin"
                    render={({ message }) => <span className="error-msg">{message}</span>}
                 />
             </label>
           </div>
          <div className="input-group">
            <label>Resumé des Tâches à exécuter
            {/* <textarea onChange={handleInput} value={recrutement.emploie.taches} name="taches" /> */}
            <SunEditor
                lang="fr"
                setContents={recrutement?.emploie?.taches} 
                placeholder="Resumé des tâches à exécuter."
                name="taches"
                setDefaultStyle="font-size: 16px;" 
                setOptions={{
                  buttonList: [
                      // default
                      ['undo', 'redo'],
                      ['bold', 'underline', 'italic', 'list','paragraphStyle','fontColor','align'],
                      ['table', 'link'],
                      ['preview','print'],
                      ['fullScreen','font']
                  ]
              
              }}
                onChange={(e) => setRecrutement({...recrutement,emploie:{...recrutement.emploie,taches:e}})}
                getSunEditorInstance={getSunEditorInstance}
                />
              
            </label>
          </div>
      <div className="buttons">
        <button className="secondary" onClick={prevStep}>Précédent</button>
        <button type='submit'>Suivant</button>
      </div>
         </form>
      </div>
    </div>
  )
}

export default StepThree