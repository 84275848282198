import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

function BarChart({ chartData }) {
  const options = {
    scales: {
      y: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          drawBorder: false,
        }
      },
      x: {
        ticks: {
            display: false,
          },
          grid: {
          drawBorder: false,
          display: false
        }
      }
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return <Bar data={chartData} options={options} />;
}

export default BarChart;