import { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { FirstUpperCase } from "../../utils/services/helpers";

const HomeSearchBar = ({metiers,setSearchResult}) => {
  const [candidat,setCandidat] = useState({
    metierName:''
  });
  const navigate = useNavigate();
  const handleInput = (e) => {
    setCandidat({metierName:FirstUpperCase(e.target.value)});
    if(e.target.value?.length === 0)
      setSearchResult([]);
    else
      setSearchResult(metiers.filter((m) => m.nom.toLowerCase().startsWith(e.target.value.toLowerCase())));
  }

  const search = () => {
    navigate('/findprofessionals', {state: {metier: candidat.metierName,}});
  }
  return (
    <div className="home-searchbar">
      <div className="icon">
        <FiSearch size={24} />
      </div>
      <input type="text" name="metierName" value={candidat.metierName} placeholder="Quel métier cherchez-vous ?"
        onChange={handleInput} autoComplete ="true"/>
      
      <button className="primary" onClick={search}>Chercher</button>
    </div>
  );
};

export default HomeSearchBar;
