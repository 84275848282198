import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import {loadingState} from "../../recoil/loadingAtom";
import Principale from "../../utils/services/Principale";
import { UserContext } from '../../utils/Context/Context';
import Select from "react-select";

import moment from "moment";
import "moment/locale/fr";
import DisponibiliteModal from "../../components/DisponibiliteModal/DisponibiliteModal";

const ProfilePro = () => {
  const{user} = useContext(UserContext);
  //console.log(user);
  const[erreurs,setErreurs] = useState([]);
  const[candidatDetails,setCandidatDetails] = useState();
  const[isLoading,setIsLoading] = useRecoilState(loadingState);
  const[candidatContract,setCandidatContract] = useState([]);

  const [isSelected,setIsSelected] = useState(false);
  const[isDetailDisponibiliteModal,setIsDetailDisponibiliteModal] = useState(false);
  const[employeur,setEmployeur] = useState({
    candidat_id:'',
    employeur_id:'',
    type_contrat:'',
    statusCandidat:'',
    selected_jours:[],
    dure_unit:'',
    nombre: 0,
    heure_debut:'',
    heure_fin:'',
    taches:''
  })
  const [selectedOptions, setSelectedOptions] = useState([]);
  const url = process.env.REACT_APP_URL + "storage/";
 
  const {id} = useParams();
  const location = useLocation();
  const api = new Principale();
  const[status,setStatus]= useState("Disponible");
  const navigate = useNavigate();

  const statusColor =
    status === "Disponible"
      ? "green"
      : status === "Non disponible"
      ? "red"
      : "black";
    
  const getCandidatDetails = async (candidat_id) => {
    try{
       setErreurs([]);setIsLoading(true);
         const{status,candidat,messages} = await api.get(`/client/details/${candidat_id}`);
        //  const rep = await api.get(`/client/details/${candidat_id}`);
        // console.log(rep)
       if(status === 200){ 
         if(Array.isArray(candidat) && candidat.length > 0){
           setCandidatDetails(candidat[0]);

           if(candidat[0]?.isdisponible === 1){
             setStatus("Disponible");
             if(user?.role_id === 3)
               setEmployeur({...employeur,candidat_id:candidat[0].candidat_id,employeur_id:user?.employeur_id,statusCandidat:candidat[0]?.isdisponible})
           }
           else setStatus("Non disponible");
           if(location?.state?.page !== 'details'){
              api.get(`/client/candidat/candidatContractAchieve/${candidat_id}`).then((resp) => {
                  
                  if(resp.status === 200){
                    setCandidatContract(resp?.candidatContract);
                  }
                  
              });
           }else {
            api.get(`/client/candidat/candidatContractAchieve/${candidat_id}`).then((resp) => {            
              if(resp.status === 200){
                
                setCandidatContract(resp?.candidatContract);
              }
              
          });
           }

         }else  navigate('/findprofessionals');
       }
       else{
         setErreurs(messages);toast.error("Candidat non trouvé ou rafraichissez la page."); navigate('/findprofessionals');
      };
      setIsLoading(false);
    }
    catch(ex){
        toast.error("Candidat non trouvé ou rafraichissez la page."); navigate('/findprofessionals');
    }
  } 
  useEffect(() => {
    getCandidatDetails(id);
  
    window.scrollTo({ top: -50, left: 0, behavior: 'smooth' });
  },[user]);
  return (
    
    <div className="profile-pro page">
      <DisponibiliteModal 
       isOpen={isDetailDisponibiliteModal}
       setIsOpen={setIsDetailDisponibiliteModal} 
       employeur={employeur}
       setEmployeur = {setEmployeur}
      />
      {candidatDetails &&
       <>
        <div className="left">
        <div className="photo">
          <img
            src={url + candidatDetails?.photoProfile.replace('public/','')}
            alt=""
          />
        </div>
      </div>
      <div className="right">
        <div className="group">
          <h1>{candidatDetails?.prenomCandidat + " "+candidatDetails?.nomCandidat}</h1>
          <p>{candidatDetails?.metier}, {candidatDetails?.sexe === "M"?"Inscrit":"Inscrite"} depuis {moment(candidatDetails?.datecreation).format("YYYY")}</p>
          <p className="subtext">{candidatDetails?.experience }<strong> d'expérince(s)</strong></p>
        </div>
        {location?.state?.page !== 'details' &&
          <div className="group">
           <div className="status subtext">
             Status :{" "}
             <span className={`badge ${statusColor}`}>{status}</span>
           </div>
        </div>}
        <div className="group">
          <h3>Informations personnelles</h3>
          <div className="sub-group">
            <strong>Sexe et âge</strong>
            <p className="subtext">{candidatDetails?.sexe === "M"?"Homme":"Femme"}, {candidatDetails?.age} ans</p>
          </div>
          <div className="sub-group">
            <strong>Date de naisssance</strong>
            <p className="subtext">{moment(candidatDetails?.datenaissance).format("Do-MMMM-YYYY")}</p>
          </div>
          <div className="sub-group">
            <strong>Nationalité</strong>
            <p className="subtext">{candidatDetails?.nationalite}</p>
          </div>
          <div className="sub-group">
            <strong>Adresse</strong>
            <p className="subtext">{candidatDetails?.prefecture},{candidatDetails?.commune}/{candidatDetails?.quartier}</p>
          </div>
        </div>
        {candidatContract.length > 0 &&
          <div className="group">
          <h3>Derniers contrats réalisés</h3>
          {candidatContract.map((employeur) => (
            <div className="sub-group" key={employeur.candidat_id}>
              {/* <strong>Menuiserie</strong> */}
              <p className="company">
                 {employeur?.societe?"Avec la Société ou l'Organisme : " + employeur?.societe:"Avec : "+employeur.prenom + " "+ employeur.prenom}
              </p>
               <p className="subtext">{moment(employeur.date_debut).format('Do-MMMM-YYYY')} au {moment(employeur?.date_fin).format("Do-MMMM-YYYY")}</p>
             </div>
          )) }
         
          </div>
          }
          {/* Proposition des Jours de Travails pour une nouvelle fenetre */}
        
        <div className="group">
          <h3>Documents</h3>
          {/* <p className="link" style={{cursor:'pointer'}} onClick={() => { if(candidatDetails?.capture_cv) window.open( url + candidatDetails.capture_cv.replace("public/","")) }}>Télécharger CV</p> */}
         
           <p className="link" style={{cursor:'pointer'}} 
          onClick={() => { if(candidatDetails?.capture_diplome) 
          window.open(url + candidatDetails.capture_diplome.replace("public/","")) }}>Télécharger diplôme ou Certificat</p>
        </div>

        {(status === 'Disponible' && user?.role_id === 3 && location?.state?.page !== 'details') &&
         <div className="group">
           <button className="big accent" onClick={() => setIsDetailDisponibiliteModal(true)}>Demander son recrutement</button>
         </div>}
      </div>
       </>
      }
    </div>
  );
};

export default ProfilePro;
