
import { Navigate } from "react-router-dom";
const AdminControl = ({user,children}) => {
    const token = localStorage.getItem('token'); 
    if(typeof user !== 'undefined'){
        if( (user?.role_id !== 1 && user?.role_id !== 2 &&  user?.role_id !== 3)){
            return <Navigate to="/" replace />;
            
         }
         else 
           return children;
    }
    else if(token && (user?.role_id === 1 && user?.role_id === 2 &&  user?.role_id === 3)){ 
        
      return <Navigate to="/admin/dashboard" replace />;
    }
    else return <Navigate to="/" replace />;
 
    
      
    
  }
  export default AdminControl;