import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import Erreurs from '../../components/Erreurs/Erreurs';
import { loadingState } from '../../recoil/loadingAtom';
import { objecttoFormData } from '../../utils/services/helpers';
import Principale from '../../utils/services/Principale';

function ChangePassword() {
 const [user,setUser] = useState({
    email:'',
    port:window.location.port,
 }) ;
 const [isLoading,setIsLoading] = useRecoilState(loadingState);  
 const navigate = useNavigate();
 const[erreurs,setErreurs] = useState([]);
 const {register,handleSubmit,reset,formState: { errors },} = useForm({defaultValues: {user}});
 const api = new Principale();
 const handleInput = (e) =>{
    setUser({...user,[e.target.name]:e.target.value});
 }
 const Valider = async () =>{
    var formData = objecttoFormData(user);
    setIsLoading(true);setErreurs([]);
    const{status,messages} = await  api.post('/resetPassword',formData);
    setIsLoading(false)
    if(status === 200){
      toast.success("Un email vous a été envoyé.");
      navigate('/');
    }
    else {
        toast.error("Erreur");
        setErreurs(messages);
    }
         
 }
  return (
    
    <div className="login-page page">
        <div className="header">
            <h2>Mot de passe oublié</h2>
            <p className="subtext">Entrez votre email pour réeinitialiser votre mot de passe.</p>
        </div>
        <form  onSubmit={handleSubmit(Valider)} autoComplete="off">
            <label>Email
                <input type="email" name="email" id="email" placeholder="Votre email"
                {...register("email", {
                    onChange: (e) => {
                    handleInput(e);
                    },
                    required: true,minLength:2
                })}
                value={user.email}
                autoFocus
             
                />
                {errors.email && errors.email?.type === "required" && (
                <span className="error-msg">Ce champ est obligatoire.</span>
                )}
                {errors.email && errors.email?.type === "minLength" && (
                <span className="error-msg">Le Caractère minimum est deux (2).</span>
                )}
            </label>
            <Erreurs validation = {erreurs} />
            <button type='submit' disabled={isLoading}>Valider</button>
        </form>
       
    </div>    
  )
}

export default ChangePassword