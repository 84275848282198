import { useState ,useContext} from 'react';
import { useRecoilState } from 'recoil';
import { sideMenuState } from '../../recoil/sideMenuAtom';
import { Link, NavLink,useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../../utils/Context/Context';
import { isEmpty } from '../../utils/services/helpers';
import Principale from '../../utils/services/Principale';
import { IoIosArrowDown } from 'react-icons/io'
import UserMenuDropdown from '../UserMenuDropdown/UserMenuDropdown'
import OutsideClickHandler from "react-outside-click-handler";
import logo from '../../assets/images/logo.png';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(sideMenuState)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate()
  const {user,setUser} = useContext(UserContext);
  const url = process.env.REACT_APP_URL + 'storage/';
 // console.log(user);
  return (
    <nav className={`navbar ${pathname === '/' && 'gray'}`}>
      <div className="content">
        <div className="left">
          <div className={`menu ${isMenuOpen && 'menu-open'}`} onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <div className="bar top"></div>
            <div className="bar bottom"></div>
          </div>
          <div className="logo">
          <Link to='/'>
            <img src={logo} alt="" />
          </Link>
          </div>
        </div>
        <div className="center">
          <ul>
            {/* {(user?.role_id === 3 || isEmpty(user))&&<li><NavLink className={(navData) => navData.isActive ? "link-activee" : ""} to='/findprofessionals'>Trouvez un pro</NavLink></li>} */}
            <li><NavLink className={(navData) => navData.isActive ? "link-active" : ""} to='/findprofessionals'>Choisir un professionnel</NavLink></li>
            <li><NavLink className={(navData) => navData.isActive ? "link-active" : ""} to='/findrecruiters'>Ils nous font confiance</NavLink></li>
            <li><NavLink className={(navData) => navData.isActive ? "link-active" : ""} to='/otherRecrutment'>Autre Recrutement</NavLink></li>
            {/* {(user?.role_id === 3 || isEmpty(user)) && <li><NavLink className={(navData) => navData.isActive ? "link-active" : ""} to='/findrecruiters'>Employeurs</NavLink></li>} */}
            {/* <li><NavLink className={(navData) => navData.isActive ? "link-active" : ""} to='/blog'>Blog</NavLink></li> */}
            <li><NavLink className={(navData) => navData.isActive ? "link-active" : ""} to='/contact'>Contact</NavLink></li>
            <li><NavLink className={(navData) => navData.isActive ? "link-active" : ""} to='/about'>À Propos</NavLink></li>
          </ul>
        </div>
        <div className="right">
          {!isEmpty(user) ?
          <OutsideClickHandler onOutsideClick={() => setIsDropdownOpen(false)}>
            <div className="user-menu" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              {!isEmpty(user) && user?.role_id === 4 &&
              <div className="profile-image">
                {user?.photo && <img src={url + user.photo.replace('public/',"")} alt="" />}
              </div>}
              {(!isEmpty(user) && (user?.role_id === 1 || user?.role_id === 2))&&
                <div className="profile-image">
                 {(user?.photo && user?.photo !== null)?<img src={url + user?.photo?.replace('public/',"")} alt={user?.username} />:<img src={url + user.photoprofileAdmin.replace('public/',"")} alt={user?.username} />}
                </div>
                }
              {!isEmpty(user) && user?.type === 'societe' && <div>{user.societe}</div>}
              {!isEmpty(user) && user?.type === 'personne physique' && <div>{user.nomemployeur}</div>}
              <div className={`arrow-icon ${isDropdownOpen && 'open'}`}>
                <IoIosArrowDown />
              </div>
              <UserMenuDropdown isOpen={isDropdownOpen} setIsOpen={setIsDropdownOpen} user={user} setUser={setUser}/>
            </div>
          </OutsideClickHandler>
          :<button className='login-btn alt' onClick={() => navigate('/login')}>Connexion</button>  
        }
        </div>
      </div>
    </nav>
  )
}

export default Navbar