import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import cover from '../../assets/images/about/cover.jpg'
import { motion } from "framer-motion";
const About =  () => {
   const navigate = useNavigate();
   const sizeWindow = window.innerWidth;

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },[])
    return (
        <>
         <div className="page">
         <div className="principal-about-header">
          <div className="page-header-left">
             <h5>A Propos de Nous</h5>
         
             <div className="title">
                <h3>
                  Présentation de la plateforme
                </h3>
             </div>
             <hr />
             <div className="description">
               <p>
                  <Link to='/' className="link">www.wolikefanyi.com </Link>est une plateforme de INNOVENT GROUP SERVICES, créée en 2018. INNOVENT GROUP SERVICES, est spécialisée dans la gestion des ressources humaines en l’occurrence des métiers professionnels au nombre d’une quarantaine pour cette première phase, 
                  répertoriés dans une base de données gérée par la plateforme <Link to="/" className="link">www.wolikefanyi.com.</Link>
               </p>
               <p>
                 Cette plateforme a pour objectif d’enregistrer ou recenser 100 000 ouvriers qualifiés dans les dix prochaines années et de créer 50 000 emplois.
               </p>
               <p>
                  Il faut noter que cette plateforme vise à faciliter d’une part, l’obtention d’emplois pour les acteurs du secteur et d’autre part, la réponse aux besoins de la clientèle.
               </p>
               <button className="success success-about" onClick={() => navigate('/signup/recruiter')}>Inscrivez-vous</button>
             </div>
          </div>  
          <div className="page-header-right">
            <div className="about-image">
               <img src={cover} alt="cover"/> 
            </div>
             
          </div> 
         </div>   
        <div className="main-wrapper"> 
            <div className="main-about">
           {sizeWindow > 700 ?
             <div className="title-left">
                <motion.h2 
                   whileInView={{ x:0 }}
                   initial={{ x: -500 }}
                   transition={{ 
                     type:'spring',
                     stiffness:30
                    }}
                >Qui sommes nous ?</motion.h2>
            </div>
            :
            <div className="title-left">
              <h2>Qui sommes nous ?</h2>
            </div>
            }
            <div className="divider-vertical"></div>
            <div className="main-description">
            {sizeWindow > 700 ? <motion.div
                    whileInView={{ x:0 }}
                    initial={{ x: 680 }}
                    transition={{
                      type:'spring',
                      stiffness:30
                    }}
                
               >
                 <p>
                    Nous sommes une société leader dans le domaine des métiers en Guinée
                  <span className="bold"> WOLIKEFANYI</span> est une plateforme spécialisée dans les ressources humaines qui se distingue par sa vision innovante et sa capacité à offrir des opportunités exceptionnelles. Avec une expertise solide dans l'intérim, le placement, le recrutement, la mise à disposition, le tâcheronnat et la consultance ainsi que dans les formations, la mise à niveau, le perfectionnement, la reconversion et la certification de compétence en rapport avec le ministère de l’enseignement professionnel et technique, notre plateforme inédite est une véritable banque d'opportunités pour tous les profils.
                  </p>
                  <p>
                    Nous sommes passionnés par notre mission de trouver des solutions innovantes pour répondre aux besoins de nos clients et de nos candidats, en offrant un service personnalisé et une approche humaine. Avec notre engagement envers l'excellence et notre détermination à faire une différence, nous sommes convaincus que nous pouvons aider chacun à atteindre ses objectifs professionnels.
                  </p>
               </motion.div>:
               <>
                <p>
                  Nous sommes une société leader dans le domaine des métiers en Guinée
                <span className="bold"> WOLIKEFANYI</span> est une plateforme spécialisée dans les ressources humaines qui se distingue par sa vision innovante et sa capacité à offrir des opportunités exceptionnelles. Avec une expertise solide dans l'intérim, le placement, le recrutement, la mise à disposition, le tâcheronnat et la consultance ainsi que dans les formations, la mise à niveau, le perfectionnement, la reconversion et la certification de compétence en rapport avec le ministère de l’enseignement professionnel et technique, notre plateforme inédite est une véritable banque d'opportunités pour tous les profils.
                </p>
                <p>
                  Nous sommes passionnés par notre mission de trouver des solutions innovantes pour répondre aux besoins de nos clients et de nos candidats, en offrant un service personnalisé et une approche humaine. Avec notre engagement envers l'excellence et notre détermination à faire une différence, nous sommes convaincus que nous pouvons aider chacun à atteindre ses objectifs professionnels.
                </p>
              </>
             }
               
            </div>
          
          
           
            <hr />
         
        </div>
        <div className="divider"></div>
        <div className="main-experience">
            <div className="experience">
                <h4>10 +</h4>
                <p>Avec une expérience de près d’une décennie, <Link to='/' className="link">www.wolikefanyi.com </Link> s’impose comme une référence dans le cadre de la recherche de l’emploi et de la mise en relation entre les employeurs et hommes de métiers.</p>
            </div>
            <div className="experience">
                <h4>100 000 +</h4>
                <p>Notre ambition est de créer au minimum, 100 mille emplois en 10 ans. Soit 5 mille emplois chaque année.</p>
            </div>
            <div className="experience">
                <h4>38</h4>
                <p><Link to='/' className="link">www.wolikefanyi.com </Link> compte couvrir l’ensemble du territoire national à travers des antennes dans les 33 préfectures, 38 communes urbaines et rurales et les 303 sous-préfectures du pays.</p>
            </div>
        </div> 
           
        </div>  
        <div className="photo-illustration">
        <div className="about-image">
               <img src="https://images.unsplash.com/photo-1614127938540-a1139bee1841?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2232&q=80"/> 
            </div>
        </div>
        <div className="curious">
           <div className="curious-content">
              <div>
                <h4>Curieux de voir quelques videos de nos prestations?</h4>
                <p>
                  Visionnez quelques videos de nos differentes prestations en cliquant sur le bouton.
                </p>
              </div>
              <div>
                <button className="success success-about" onClick={() => navigate('/videos')}>Voir la video</button>
              </div>

           </div>
        </div>
         </div>   
         <div className="photo-illustration2">
            <img src="https://images.unsplash.com/photo-1600557349801-cd8c589254ae?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"/>
         </div>
         <div className="histoire">
           <div className="histoire-titre">
             <h4>Notre Histoire</h4>
           </div>
           <div className="histoire-sous-titre">
              <p>
                 Avec 200 millions d’habitants âgés de 15 à 24 ans, l’Afrique est de loin le continent le plus jeune au monde et ce chiffre devrait doubler d’ici 2045. La Guinée n’est pas en reste, avec 70 % de ses 13 millions d’habitants qui ont moins de 35 ans. Dès lors, se pose la question des opportunités économiques offertes à ces jeunes qui sont chaque année de plus en plus nombreux à entrer sur le marché de l’emploi.  On estime que plus de 60 % des jeunes diplômés guinéens sont au chômage. Cela tient souvent à l’inadéquation entre leurs compétences et les attentes des employeurs. 
               </p>
               <p>
                 Pour corriger cette situation en vue d’offrir plus d’opportunité aux jeunes <Link to='/' className="link">www.wolikefanyi.com </Link> se propose comme une alternative sûre à travers la création de l’emploi et la mise en relation des acteurs du secteur.
               </p>
           </div>
           
         </div>
        </> 
    )
}
export default About;