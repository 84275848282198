import React, {useEffect,useRef, useState} from 'react'
import { useForm } from 'react-hook-form';
import ContratConsultance from '../ContratConsultance/ContratConsultance';
import ContratInterim from '../ContratInterim/ContratInterim';
import ContratRecrutement from '../ContratRecrutement/ContratRecrutement';
import ContratSaisonnier from '../ContratSaisonnier/ContratSaisonnier';
import ContratMiseADisposition from '../ContratMiseADisposition/ContratMiseADisposition';
import ContratTacheronat from '../ContratTacheronat/ContratTacheronat';
import ContratMission from '../ContratMission/ContratMission';
import { useReactToPrint } from "react-to-print";
import { BiUpload } from 'react-icons/bi';
import { useRecoilState } from 'recoil';
import { loadingState } from '../../recoil/loadingAtom';
import Principale from '../../utils/services/Principale';
import Erreurs from '../Erreurs/Erreurs';
import { objecttoFormData } from '../../utils/services/helpers';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';


const StepFour = ({ prevStep, nextStep,recrutement,setRecrutement,globalState,setGlobalState}) => {
  const {register,handleSubmit,reset,setError,formState: { errors }} = useForm({defaultValues: {recrutement},});
  const[erreurs,setErreurs] = useState([]);
  const[isLoading,setIsLoading] = useRecoilState(loadingState);
  const componentRef = useRef();
  const api = new Principale();
  const navigate = useNavigate();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    reset(recrutement)
  },[]);
 const SubmitContratDetails = async (e) => {
  e.preventDefault();
  setError(null);setErreurs([]);
 
    if(recrutement?.emploie?.copie_contrat === '' || typeof recrutement.emploie.copie_contrat === 'undefined'){
      setError("copie_contrat", {
        type: "manual",
        message: "La copie du contrat signé est obligatoire."
      });
  }else{
    
       setIsLoading(true);
       var formData = new FormData();
       formData.append('candidat_id',recrutement?.candidat?.candidat_id);formData.append('employeur_id',recrutement?.employeur?.employeur_id);
       formData.append('id',recrutement?.emploie?.id);formData.append('date_debut',recrutement?.emploie?.date_debut);formData.append('date_fin',recrutement?.emploie?.date_fin);
       formData.append('contrat_type_propose',recrutement?.emploie?.contrat_type_propose);formData.append('heure_debut',recrutement?.emploie?.heure_debut);
       formData.append('heure_fin',recrutement?.emploie?.heure_fin);formData.append('lieuexecution',recrutement?.emploie?.lieuexecution);
       formData.append('unitePaiement',recrutement?.emploie?.unitePaiement?recrutement?.emploie?.unitePaiement:'mensuel');formData.append('montantprestation',recrutement?.emploie?.montantprestation);
       formData.append('autrefrais',recrutement?.emploie?.autrefrais);formData.append('copie_contrat',recrutement?.emploie?.copie_contrat);
       formData.append('selectedjours',JSON.stringify(recrutement?.emploie?.selectedjours));formData.append('taches',recrutement?.emploie?.taches);
       formData.append('email',recrutement?.employeur?.email);formData.append('user_id',recrutement?.employeur?.user_id);
       formData.append('contrat_type_propose',recrutement?.emploie?.contrat_type_propose);
       const { status,messages } = await api.post('/admin/contrats/details/new',formData);
       setIsLoading(false);
       if(status === 200){
         setGlobalState(!globalState);
         toast.success("Contrat Envoyé avec succès.");navigate('/admin/dashboard');

       }
       else setErreurs(messages);
      // const resp = await api.post('/admin/contrats/details/new',formData);
      // console.log(resp)
      // const rep = await api.post('/admin/contrats/details/new',formData);
      // console.log(rep)
     
  }
 }
  return (
    <div className='step-component'>
     
     <div style={{float:'right',display:'flex'}}>
       <button style={{marginTop:'16px',marginRight:'10px'}} className="big accent" onClick={handlePrint}>Imprimer</button> 
       {/* <input type="file" name="image4"  accept="image/*"/> */}
    </div>  
      <form>
        {/* <h4>Le Contrat</h4> */}

        <div className="input-group">
        <label>

          {recrutement?.emploie.contrat_type_propose === "consultance" && 
              <ContratConsultance recrutement={recrutement} 
              setRecrutement={setRecrutement} 
              setError={setError}
              ref={componentRef}/>
          }
          {recrutement?.emploie.contrat_type_propose === "interim" && 
              <ContratInterim recrutement={recrutement} 
              setRecrutement={setRecrutement} 
              setError={setError}
              ref={componentRef}
              />
          }
          {recrutement?.emploie.contrat_type_propose === "recrutement" && 
              <ContratRecrutement recrutement={recrutement} 
              setRecrutement={setRecrutement} 
              setError={setError}
              ref={componentRef}/>
          }
          {recrutement?.emploie.contrat_type_propose === "saisonnier" && 
              <ContratSaisonnier recrutement={recrutement} 
               setRecrutement={setRecrutement} ref={componentRef}
               setError={setError}
               />
          }
          {recrutement?.emploie?.contrat_type_propose === "mise à disposition" && 
            <ContratMiseADisposition recrutement={recrutement} 
             setRecrutement={setRecrutement}
             setError={setError} 
             ref={componentRef}/>
          }
          {recrutement?.emploie?.contrat_type_propose === "tâcheronat" && 
            <ContratTacheronat recrutement={recrutement} 
            setRecrutement={setRecrutement}
            setError={setError}
            ref={componentRef}/>
          }
          {recrutement?.emploie?.contrat_type_propose === "contrat de mission" && 
            <ContratMission recrutement={recrutement} setRecrutement={setRecrutement} setError={setError} ref={componentRef}/>
          }
        </label>
         
        </div>
        {errors.copie_contrat && <span className="error-msg">{errors.copie_contrat.message}</span>}
        <Erreurs validation = {erreurs} /> 
        <div className="buttons">
          <button className="secondary" onClick={prevStep}>Précédent</button>
          <button  className='success' onClick={SubmitContratDetails}>Terminer</button>
        </div>
      </form>
    </div>
  )
}

export default StepFour