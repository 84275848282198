import moment from 'moment/moment';
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { BsFillFileEarmarkCheckFill } from 'react-icons/bs';
import Principale from '../../utils/services/Principale';
const StepOne = ({ nextStep,recrutement,setRecrutement }) => {
 
  const {register,handleSubmit,reset,formState: { errors },} = useForm({defaultValues: {recrutement},});
  const url = process.env.REACT_APP_URL + 'storage/';

  useEffect(() => {
    reset(recrutement);
  },[]);
  const openFile = (document) => {
    if(document === "piece")
      window.open(`${url}${recrutement.candidat.capture_piece.replace('public/','')}`);
    else if(document === 'diplome')
      window.open(`${url}${recrutement.candidat.capture_diplome.replace('public/','')}`); 
    else if(document === 'cv')
      window.open(`${url}${recrutement.candidat.capture_cv.replace('public/','')}`); 
  }
  // console.log(recrutement)
  return (
    <div className='step-component'>
      {/* <h4>Information du Candidat</h4> */}
      <form onSubmit={handleSubmit(nextStep)}>
        {recrutement?.candidat && 
         <>
           <div className="input-group file-input photo-input">
                <div className="group">
                    <span>Photo</span>
                    <div className="photo">
                      <img src={recrutement.candidat.photo?url+recrutement.candidat.photo.replace('public/',""):""} alt={recrutement.candidat.prenom+ " "+ recrutement.candidat.nom} />
                    </div>
                </div>
           </div>
           <div className='input-group'>
             <label>Prenom et Nom
                <span className='input-read'>{recrutement.candidat.prenom + " "+ recrutement.candidat.nom}</span>
             </label> 
             <label>Date de Naissance
                <span className='input-read'>{moment(recrutement.candidat.datenaissance).format('DD/MM/YYYY')}</span>
             </label>
             <label>Nationalité
               <span className='input-read'>{recrutement.candidat.nationalite}</span>
             </label>
             <label>Sexe
                <span className='input-read'>{recrutement.candidat.sexe == "M"?"Masculin":'Feminin'}</span>
             </label>
           </div>
           <div className='input-group'>
             <label>Profession
                <span className='input-read'>{recrutement.candidat.profession?recrutement.candidat.profession:'-----------'}</span>
             </label>
             <label>Metier Exercé
                <span className='input-read'>{recrutement.candidat.metier}</span>
             </label>
             <label>Experience
               <span className='input-read'>{recrutement.candidat.experience}</span>
             </label>
             <label>Téléphone
               <span className='input-read'>{recrutement.candidat.telephone}</span>
             </label>
             
           </div>
           <div className='input-group'>
             <label>Email
               <span className='input-read'>{recrutement.candidat.email?recrutement.candidat.email:'---------'}</span>
             </label>
              <label>Prefecture
                 <span className='input-read'>{recrutement.candidat.prefecture}</span>
              </label>
              <label>Commune
                <span className='input-read'>{recrutement.candidat.commune}</span>
              </label>
              <label>Quartier/District
                <span className='input-read'>{recrutement.candidat.quartier}</span>
              </label>
           </div>
          {/* <div className='input-group'>
            <label>Status
               <select value={recrutement.candidat.isdisponible} onChange={(e) => setRecrutement({...recrutement,candidat:{...recrutement.candidat,isdisponible:parseInt(e.target.value)}})}>
                  <option value={1}>Disponible</option>
                  <option value={0}>Non Disponible</option>
                </select>
             </label>
             <label></label>
             <label></label>
             <label></label>
          </div> */}

              {recrutement.personneReferences && 
                 recrutement.personneReferences.map((reference,index) => (
                  <div className='input-group' key={index}>
                    <label key={Math.random()}>Prenom et Nom Personne Referente
                      <span className='input-read'>{reference.prenometnom}</span>
                    </label>
                    <label key={Math.random()}>Telephone
                    <span className='input-read'>{reference.telephone}</span>
                    </label>
                    <label key={Math.random()}>Profession
                      <span className='input-read'>{reference.post}</span>
                    </label>
                  </div>
               ))
            
             
              }
            

           <div className='input-group'>
             <label>Type de pièce
               <span className='input-read'>{recrutement.candidat.typepiece?recrutement.candidat.typepiece:'----------'}</span>
             </label>
             <label>Numero de la pièce
                <span className='input-read'>{recrutement.candidat.numero_piece?recrutement.candidat.numero_piece:'-------------'}</span>
             </label>
             <label>Niveau Etude
                 <span className='input-read'>{recrutement.candidat.niveau_etude?recrutement.candidat.niveau_etude:'--------'}</span>
             </label>
           </div>
           <div className='input-group'>
             <label style={{cursor:'pointer'}} onClick={() => openFile("piece")}>
                <div className="file-wrapper">
                  <p style={{marginBottom:'10px'}}>Capture d'identité</p>
                  <div className='image bkviewImg'>
                    <BsFillFileEarmarkCheckFill size={50} />
                  </div>
                </div>
             </label>
             <label style={{cursor:'pointer'}} onClick={() => openFile("diplome")}>
                <div className="file-wrapper">
                  <p style={{marginBottom:'10px'}}>Capture de Diplôme</p>
                  <div className='image bkviewImg'>
                    <BsFillFileEarmarkCheckFill size={50} />
                  </div>
                </div>
             </label>
             {recrutement.candidat.capture_cv? 
              <>
              <label style={{cursor:'pointer'}} onClick={() => openFile("cv")}>
                <div className="file-wrapper">
                  <p style={{marginBottom:'10px'}}>Capture de CV</p>
                  <div className='image bkviewImg'>
                    <BsFillFileEarmarkCheckFill size={50} />
                  </div>
                </div>
             </label><label></label></>:<><label></label><label></label></>}
           </div>
           {recrutement.emploie.status === 'En cours' && <div><span className='error-msg'>Ce candidat n'est pas disponible.Vous ne pouvez pas continuer!</span></div>}
          <div className="buttons">
            <button type='submit' disabled={recrutement.emploie.status === 'En cours' ? true:false}>Suivant</button>
          </div>
         </> 
        }
      </form>
    </div>
  )
}

export default StepOne