import { useContext, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import CandidatCard from "../../components/CandidatCard/CandidatCard";
import ContractCard from "../../components/ContractCard/ContractCard";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import { loadingState } from "../../recoil/loadingAtom";
import { UserContext } from "../../utils/Context/Context";
import Principale from "../../utils/services/Principale";

const DashboardPro = () => {
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useRecoilState(loadingState);
  const url = process.env.REACT_APP_URL + "storage/";
  const api = new Principale();
  const [candidatJob, setCandidatJob] = useState([]);
  const [candidatEmp, setCandidatEmp] = useState([]);
  const [candidatEmps,setCandidatEmps] = useState([]) //for search
  const [candidatDetails, setCandidatDetails] = useState();
  const [employeurDetails, setEmployeurDetails] = useState({
    employeur_id: 0,
    prenomemployeur: "",
    nomemployeur: "",
    role_id: "",
    societe: "",
    type: "",
    user_id: "",
    email: "",
    logo: "",
  });
  const [erreurs, setErreurs] = useState([]);

  const getDataCandidat = async (id, role_id) => {
    setErreurs([]);
    if (role_id === 4) {
      const { status, candidat, messages } = await api.get(
        `/client/detailProfile/${id}`
      );
     // console.log(candidat)
      if (status === 200) {
        if (Array.isArray(candidat) && candidat.length > 0)
          setCandidatDetails(candidat[0]);
      } else setErreurs(messages);
    } else if (role_id === 3) {
      setErreurs([]);
      setIsLoading(true);
      const { status, employeur, messages } = await api.get(
        `/employeur/getEmployeurDetails/${id}`
      );
      setIsLoading(false);
      if (status === 200) {
        const emp = employeur[0];
        let empDetail = "";

        setEmployeurDetails({
          ...employeurDetails,
          prenomemployeur: emp.prenomemployeur,
          nomemployeur: emp.nomEmployeur,
          role_id: emp.role_id,
          societe: emp.societe,
          type: emp.type,
          email: emp.email,
          logo: emp.logo,
          user_id: emp.user_id,
          civilite: emp.civilite,
          prefecture: emp.prefecture,
          commune: emp.commune,
          quartier: emp.quartier,
          secteur: emp.secteur,
          fonction: emp.fonction,
          representant: emp.representant,
        });
      } else setErreurs(messages);
    }
  };
  //tous les employes du candidat
  const getEmpCandidats = async (employeur_id) => {
    const { status, candidats, messages } = await api.get(
      `/employeur/getEmployes/${employeur_id}`
    );
    //console.log(candidats)
    if (status === 200) {setCandidatEmp(candidats);setCandidatEmps(candidats)}
  };
  const CandidatEmploieDetails = async (candidat_id) => {
    setErreurs([]);
    const { status, candidatEmploies, messages } = await api.get(
      `/client/detailsCandidatEmploie/${candidat_id}`
    );
  
    //console.log(candidatEmploies)
    if (status === 200) {
      if (Array.isArray(candidatEmploies) && candidatEmploies.length > 0)
        setCandidatJob(candidatEmploies);
    } else setErreurs(messages);
  };
  const handleChange = (e) => {
    setIsLoading(true);
    if(e.target.value !== '')
     setCandidatEmp(candidatEmps.filter((c) => c.contrat_type_propose === e.target.value || c.contrat_type_valide === e.target.value));
   else setCandidatEmp(candidatEmps);  
   setIsLoading(false);  
  }
  useEffect(() => {
    if (user?.candidat_id && user?.role_id === 4) {
      setIsLoading(true);
      getDataCandidat(user?.candidat_id, user?.role_id);
      CandidatEmploieDetails(user?.candidat_id);
      setIsLoading(false);
    } else if (user?.employeur_id && user?.role_id === 3) {
      getDataCandidat(user?.employeur_id, user?.role_id);
      getEmpCandidats(user?.employeur_id);
    }
  }, [user]);

 
  return (
    <div className="dashboard-pro page">
      <div className="left">
        {user?.role_id === 3 && <ProfileCard profileData={employeurDetails} />}
        {user?.role_id === 4 && <ProfileCard profileData={candidatDetails} />}
      </div>
      <div className="right">
      {(user?.role_id === 1 || user?.role_id === 2) && <h1>Bonjour {user?.prenomadmin.charAt(0).toUpperCase()+user?.prenomadmin.slice(1)},</h1>}
        {user?.role_id === 4 && <h1>Bonjour {user?.prenomclient},</h1>}
        {user?.role_id === 3 && (
          <h1>
            Bonjour {user?.societe ? user?.societe : user?.prenomemployeur},
          </h1>
        )}
      
        <div style={{display:'flex',flexDirection:'column'}}>
          <p className="subtext">Voici un aperçu de vos dernières activités.</p>
          <select style={{width:'320px'}} onChange={handleChange}>
              <option value="">Filtrer par Categorie de Contrat</option>
              <option value="interim">Interim</option>
              <option value="consultance">Consultance</option>
              <option value="recrutement">Recrutement</option>
              <option value="saisonnier">Saisonnier</option>
              <option value="mise à disposition">Mise à disposition</option>
              <option value="tâcheronat">Tâcheronat</option>
              <option value="contrat de mission">Contrat de mission</option>
          </select>
        </div>
        <div className="contracts">
          {candidatJob.length > 0 && <h3>Vos derniers contrats</h3>}
          <div className="contracts-list">
            {(user?.role_id === 4 && candidatJob.length > 0)&& candidatJob.map((contract) => (
              <ContractCard
                key={contract.employeur_id}
                company_image={url + contract.logo.replace("public/","")}
                company_name={contract.prenomEmployeur?contract.prenomEmployeur+ " "+contract.nomEmployeur:contract.societe}
                company_address={contract.prefecture+"/"+contract.commune+"/"+contract.quartier}
                contrat_type={contract?.contrat_type_valide?contract?.contrat_type_valide:contract?.contrat_type_propose}
                start_date={contract.date_debut}
                end_date={contract.date_fin}
                status={contract.status}
                contrat_copie = {url + contract.contrat_copie.replace("public/","")}
              />
            ))}
             {(user?.role_id === 3 && candidatEmp.length > 0)?candidatEmp.map((candidat) => (
              <CandidatCard
                candidat_id ={candidat.candidat_id}
                key={candidat.id + Math.random()}
                photo={url + candidat.photo.replace("public/","")}
                nom={candidat.prenom + " "+candidat.nom}
                datenaissance={candidat?.datenaissance}
                diplome = {candidat.diplome}
                experience = {candidat.experience}
                contrat_type={candidat?.contrat_type_valide?candidat?.contrat_type_valide:candidat?.contrat_type_propose}
                datedebut={candidat.date_debut}
                datefin = {candidat.date_fin}
                status={candidat.status}
              />
            )):<p>Pas de contrat correspondant</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPro;
