import React, { useEffect, useState } from "react";
import { ToWords } from 'to-words';
import moment from "moment";
import logo from "../../assets/images/logosig.png";
import { formatStringNumber } from "../../utils/services/helpers";
import { Markup } from 'interweave';
const ContratTacheronat = React.forwardRef(({recrutement,setRecrutement,setError}, ref) => {
  const toWords = new ToWords({
    localeCode: 'fr-FR',
    converterOptions: {
      currency: false,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
    }
  });
  const [mois,setMois] = useState(0);
  const[diffHour,setDiffHour] = useState(0);
  useEffect(() => {
    if(recrutement.emploie.date_debut && recrutement.emploie.date_fin)
      setMois(Math.abs(getDate(recrutement?.emploie?.date_debut).diff(getDate(recrutement?.emploie?.date_fin), 'months')));
      const end = moment(recrutement.emploie.heure_fin,'HH:mm a');
      const start = moment(recrutement.emploie.heure_debut,'HH:mm a');
    
     var duration = moment.duration(end.diff(start));
     var hours = duration.asHours();  
     setDiffHour(hours);
  },[]);
  const getDate = (date) => moment(date).startOf('month');
  const handleInput = (e) => {
    setError(null);
    setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]:e.target.files[0]}});
  }
  return (
    <>
     <div style={{width:'40%',float:'right',height:'20px',marginTop:'-25px',marginRight:'5px'}} className="input-group">
        <label>Le Contrat signé
          <input type="file" name="copie_contrat"  accept=".pdf" onChange={(e) => handleInput(e)}/>
        </label> 
      </div>
      <div className="contract-doc" ref={ref}>
      <h1>Contrat de tacheronat</h1>
      <div className="presentation">
       <div className="logo">{logo?<img src={logo} style={{width:'85px',marginBottom:'20px',marginTop:'-23px'}}/>:'LOGO'}</div>
        <div className="employeur">
          <p>
            <strong>Entre les soussigné(e)s :</strong>
          </p>
          <p>
            La société <strong>Innovent Group Service SARLU </strong> au capitale de 10 0000 GNF immatriculée au registre du
            commerce et des sociétés sous le numéro <strong>UI1038DEUIE</strong> dont le siège social
            est situé à xxx, représentée par  Monsieur <strong>Youssouf FOFANA</strong>, contact : <strong>628 26 10 39</strong>.
          </p>
          <p>
            D'une part, ci-après dénomée l'<strong>Employeur.</strong>
          </p>
        </div>
        <div className="client">
          <p>
            <strong>Et :</strong>
          </p>
          {recrutement?.employeur.typeEmp === 'societe' &&
              <p>
              La société <strong>{recrutement?.employeur?.societe}</strong> au capitale de { !isNaN(recrutement?.employeur?.capital) === true ?<strong>{formatStringNumber(recrutement.employeur?.capital)} GNF</strong>:'-------'} immatriculée au registre du
              commerce et des sociétés sous le numéro <strong>{recrutement?.employeur?.nif}</strong> dont le siège social
              est situé à <strong>{recrutement?.employeur?.prefecture+"/"+recrutement?.employeur?.commune+"/"+recrutement?.employeur?.quartier}</strong>, représentée par {recrutement?.employeur?.civilite} <strong>{ recrutement?.employeur?.representant}</strong>
              , contact : <strong>{recrutement?.employeur?.telephone}</strong>.
            </p>}
            {recrutement.employeur.typeEmp === 'personne physique' && 
              <p>
                {recrutement?.employeur?.civilite} <strong>{ recrutement?.employeur?.prenomEmp + " "+ recrutement?.employeur?.nomEmp }</strong>
              , contact : <strong>{recrutement?.employeur?.telephone}</strong>, domicilié à l'addresse {recrutement?.employeur?.prefecture +"/" +recrutement?.employeur?.commune+"/"
                +recrutement?.employeur?.quartier}{(recrutement?.employeur?.addresse && recrutement?.employeur?.addresse !== 'null')?"/"+recrutement?.employeur?.addresse:""}
              </p>
            }
          <p>
            D'une part, ci-après dénomée le <strong>Client.</strong>
          </p>
        </div>
      </div>
      <p className="convention">Il a été convenu et arreté ce qui suit :</p>
      <div className="articles">
        <div className="article">
          <h4>Article 1 : Objet du contrat</h4>
          <p>
            Le présent contrat est un contrat de prestation de conseil ayant
            pour objet la mission définie au cahier des charges annexé au
            présent contrat et en faisant partie intégrante conformément à la
            législation du travail, portant Code du Travail de la République de
            Guinée, et les textes pris pour son application.
          </p>
        </div>
        <div className="article">
          <h4>Article 2 : Durée du contrat</h4>
          <p>
            Ce contrat est passé pour une durée de <strong> {mois <= 1 ?Math.ceil((new Date(recrutement?.emploie?.date_fin) - new Date(recrutement?.emploie?.date_debut))/(1000 * 3600 * 24))+" jour (s)":mois+" mois"}</strong> pour la mission de <strong>{recrutement?.candidat?.generique ? recrutement?.candidat?.generique : recrutement?.candidat?.metier}</strong>. Il prendra
            effet le {recrutement?.emploie?.date_debut?moment(recrutement?.emploie?.date_debut).format("DD/MM/YYYY"):'…. /…../…..'} et arrivera à son terme le {recrutement?.emploie?.date_fin?moment(recrutement?.emploie?.date_fin).format("DD/MM/YYYY"):'…. /…../…..'}.
          </p>
        </div>
        <div className="article">
          <h4>Article 3 : Exécution des taches</h4>
          <p>
            Le prestataire s'engage à mener à bien la tâche précisée à l'Article
            premier, conformément aux règles de l'art et de la meilleure
            manière.
          </p>
          <p>
            A cet effet, il constituera l'équipe nécessaire à la réalisation de
            la mission et remettra, avant le rapport terminal, une pré-étude, au
            plus tard le {recrutement?.emploie?.date_fin ? moment(recrutement?.emploie?.date_fin).add(1, 'months').format('DD/MM/YYYY'):'-----------'}.
          </p>
        </div>
        <div className="article">
          <h4>Article 4 : Période d'essai</h4>
          <p>
            Le prestataire effectuera une période d'essai non applicable.
          </p>
        </div>
        <div className="article">
          <h4>Article 5 : Lieu d'exécution</h4>
          <p>
            Le prestataire effectuera sa mission à <strong>{recrutement?.emploie?.lieuexecution}</strong>.
          </p>
        </div>
        <div className="article">
          <h4>Article 6 : Rémunération</h4>
          <p>
            L'entreprise utilisatrice (cliente) a souscrit une garantie
            financière auprès de la Société IGS pour un montant de : {recrutement?.emploie?.montantprestation?toWords.convert(parseInt(recrutement?.emploie?.montantprestation) * 0.18 + parseInt(recrutement?.emploie?.montantprestation)):"zero"} <strong>( {formatStringNumber(recrutement?.emploie?.montantprestation?parseInt(recrutement?.emploie?.montantprestation) * 0.18 + parseInt(recrutement?.emploie?.montantprestation):0)} )</strong> GNF/
             mois soit <strong style={{color:'#76760d'}}>18 % de TVA</strong> versé dans le compte bancaire N° -----------
            ------------- (Nom de la banque).
          </p>
          <p>
            Un paiement en quinzaine s’effectuera après validation de la fiche
            de suivi des tâches. Conformément à l'article L. 1251-49 du Code du
            travail, tout entrepreneur de travail temporaire est tenu, à tout
            moment, de justifier d'une garantie financière assurant, en cas de
            défaillance de sa part, le paiement :
            <ul>
              <li>des salaires et de leurs accessoires</li>
              <li>des indemnités résultant du présent chapitre</li>
              <li>
                des cotisations obligatoires dues à des organisations de
                Sécurité sociale ou à des institutions sociales, le cas échéant,
                des remboursements qui peuvent incomber aux employeurs à l'égard
                de ces organismes et institutions dans les conditions prévues à
                l'article L.244-8 du Code de la Sécurité sociale.
              </li>
            </ul>
          </p>
        </div>
        <div className="article">
          <h4>Article 7 : Litige</h4>
          <p>
            Tout litige relevant de l'exécution du présent contrat sera réglé à
            l'amiable : à défaut, il sera soumis à la juridiction compétente de
            CONAKRY.
          </p>
        </div>
        <div className="article">
          <h4>Article 8 : Conclusion du contrat</h4>
          <p>
            Conformément aux dispositions de l'article L.1251-42 et suivants du
            Code du travail, ce contrat doit être conclu par écrit au plus tard
            dans les 2 jours ouvrables suivant la mise à disposition.
          </p>
        </div>
        <p className="note">
          Fait en double exemplaire, dont un est remis à chacune des parties du
          présent avenant.
        </p>
        <p className="date">A Conakry, le {moment().format('Do MMMM YYYY')}</p>
        <p className="nb">
          Faire précéder la signature du client de la mention manuscrite "lu et
          approuvé".
        </p>
        <div className="signatures">
          <p>
            <strong>La société IGS</strong>
          </p>
          <p>
           Le client {recrutement?.employeur.typeEmp === 'societe'? <strong>{recrutement?.employeur.societe}</strong>:<strong>{recrutement?.employeur?.civilite + " " +recrutement?.employeur?.prenomEmp + " "+ recrutement?.employeur?.nomEmp}</strong>}
          </p>
        </div>
      </div>
      </div>
    </>
  );
});

export default ContratTacheronat;
