import { Navigate } from "react-router-dom";

const Connected = ({user,children}) => {
    const token = localStorage.getItem('token'); 
    if( !user || !token){
       return <Navigate to="/" replace />;
    }
    else 
      return children;
    
  }
  export default Connected;