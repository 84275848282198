import { useState,useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import RecruiterDetailsModal from "../../components/RecruiterDetailsModal/RecruiterDetailsModal";
import { useRecoilState } from "recoil";
import { loadingState } from "../../recoil/loadingAtom";
import Principale from "../../utils/services/Principale";
import moment from "moment/moment";
import Swal from "sweetalert2";
import AddMetierModal from "../../components/AddMetierModal/AddMetierModal";
import MetierDetailsModal from "../../components/MetierDetailsModal/MetierDetailsModal";
import UpdateMetierModal from "../../components/UpdateMetierModal/UpdateMetierModal";
import { toast } from "react-hot-toast";

const MetierList = () => {
  const [isRecruiterDetailsModalOpen, setIsRecruiterDetailsModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMetierModalOpen,setIsMetierModalOpen] = useState(false);
  const [isMetierUpdateModalOpen,setIsMetierUpdateModalOpen] = useState(false);
  const [isLoading,setIsLoading] = useRecoilState(loadingState);
  const [erreurs,setErreurs] = useState([]);
  const [SelectedMetier,setSelectedMetier] = useState(null);
  const [ListMetiers,setListMetiers] = useState([]);
  const [isAction,setIsAction] = useState(false);
  
  const[metier,setMetier] = useState({
    nom:'',
    generique:'',
    description:''
  });
  const api = new Principale();
  // ? Agencies data

  const columns = [
    {
      field: "ord",
      headerName: "Ordre",
      flex: 1,
      maxWidth: 80,
    },
    {
      field: "nom",
      headerName: "Nom",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 250,
    },
  
    {
      field: "generique",
      headerName: "Generique",
      flex: 1,
      minWidth: 160,
    },
    {
      field: "updated_at",
      headerName: "Date MS",
      flex: 1,
      minWidth: 130,
    },
    {
      field: "created_at",
      headerName: "Date Création",
      flex: 1,
      minWidth: 130,
    },
  
   
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 1,
      minWidth: 250,
      renderCell: (params) => {
        return (
          <div className="options">
            <button
              onClick={() => {
                setSelectedMetier(params.row);
                setIsMetierModalOpen(true);
            
              }}
            >
              Détails
            </button>
            <button className="primary"
             onClick={() => {
              setMetier(params.row);
              setIsMetierUpdateModalOpen(true);
            }}
            >
              Modifier
            </button>
            <button className="delete" onClick={() => supprimer(params.row.id)}>
              Supprimer
            </button>
          </div>
        );
      },
    },
  ];
 // console.log(SelectedMetier)
  const supprimer = (metier_id) => {
    Swal.fire({
      title: "Êtes-vous sûr?",
      text: 'Voulez-vous supprimer ce métier?',
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Oui, Supprimer`,
      cancelButtonText: "Annuler",
    }).then((result) => {
      if(result.isConfirmed){
          var formData = new FormData();
          formData.append('metier_id',metier_id);
          setIsLoading(true);
          api.post('/admin/metiers/delete',formData).then((resp) => {
        
          if(resp.status === 200){
            toast.success("Suppression effectuée avec succès.");
            setIsAction(!isAction);
          }else{
        
            toast.error(resp.messages.metier_id[0]);
            setIsLoading(false);
          }
        })
      }
    
    })
  }
  const getListMetiers = async () => {
    setIsLoading(true);setErreurs([]);
    const {status,messages,metiers} = await api.get('/admin/metiers/list');
    setListMetiers(metiers.map((metier,index) => {
      return{
        id:metier.metier_id,
        ord : index + 1,
        nom:metier.nom,
        description:metier.description,
        generique:metier.generique?metier.generique:'----------',
        created_at: moment(metier.created_at).format('Do MMMM YYYY'),
        updated_at:moment(metier.updated_at).format('Do MMMM YYYY')
      }
    }))
    setIsLoading(false);

  }
  useEffect(() => {
    getListMetiers();
  }, [isAction]);

  return (
    <div className="recruiters-list page utilisateur-list">
      <MetierDetailsModal
        isOpen={isMetierModalOpen}
        setIsOpen={setIsMetierModalOpen}
        SelectedMetier={SelectedMetier}
      />
      <AddMetierModal 
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        setIsLoading = {setIsLoading}
        setIsAction = {setIsAction}
        isAction = {isAction}
      />
      <UpdateMetierModal 
        isOpen={isMetierUpdateModalOpen}
        setIsOpen={setIsMetierUpdateModalOpen}
        setIsLoading = {setIsLoading}
        setIsAction = {setIsAction}
        isAction = {isAction}
        setMetier = {setMetier}
        metier = {metier}
      />
      <div className="header">
         <div>
           <h3>Liste des métiers</h3>
           <p>{ListMetiers.length} métier(s)</p>
         </div>
          
          <div className="btn-content">
             <button className="primary" onClick={() => setIsModalOpen(true)}>Ajouter un metier</button>
          </div>
      </div>
      <div className="array">
        <Box sx={{ height: 579, width: "100%" }}>
          <DataGrid
            sx={{ borderRadius: 0 }}
            components={{ Toolbar: GridToolbar }}
            rows={ListMetiers}
            columns={columns}
            autoPageSize
            disableSelectionOnClick
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          />
        </Box>
      </div>
    </div>
  );
};

export default MetierList;
