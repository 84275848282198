import { useRecoilState } from "recoil";
import { Link, useLocation } from "react-router-dom";

import { sideMenuState } from "../../recoil/sideMenuAtom";
import { useContext } from "react";
import { UserContext } from "../../utils/Context/Context";

const SideMenu = () => {
  const [isOpen, setIsOpen] = useRecoilState(sideMenuState);
  const { pathname } = useLocation()
  const {user} = useContext(UserContext);
  return (
    <nav className={`side-menu ${isOpen && "open"} ${pathname === "/" && "gray"}`}>

      {(!user  || user?.role_id === 3 || user?.role_id === 4) &&
        <ul>
        <li>
          <Link to="/findprofessionals" onClick={() => setIsOpen(false)}>Trouver un professionnel</Link>
        </li>
        <li>
          <Link to="/findrecruiters" onClick={() => setIsOpen(false)}>Ils nous font confiance</Link>
        </li>
        <li>
          <Link to="/otherRecrutment" onClick={() => setIsOpen(false)}>Autre Recrutement</Link>
        </li>
        <li>
          <Link to="/contact" onClick={() => setIsOpen(false)}>Contact</Link>
        </li>
        <li>
          <Link to="/about" onClick={() => setIsOpen(false)}>À Propos</Link>
        </li>
      </ul>
      }
      {(user && (user?.role_id === 1 || user?.role_id === 2))&&
        <ul>
          <li>
            <Link to="/admin/dashboard" onClick={() => setIsOpen(false)}>Tableau de board</Link>
          </li>
          <li>
            <Link to="/contracts/list" onClick={() => setIsOpen(false)}>Liste de Candidature</Link>
          </li>
          <li>
            <Link to="/recruiters/list" onClick={() => setIsOpen(false)}>Employeur</Link>
          </li>
          <li>
            <Link to="/utilisateur/list" onClick={() => setIsOpen(false)}>Utilisateurs</Link>
          </li>
          <li>
            <Link to="/metiers/list" onClick={() => setIsOpen(false)}>Métiers</Link>
          </li>
       </ul>
      }
    </nav>
  );
}

export default SideMenu;
