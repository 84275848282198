import React, { useState,useEffect, useContext } from "react";
import { BsBuilding } from "react-icons/bs";
import { CgFileDocument } from "react-icons/cg";
import { GiSandsOfTime } from "react-icons/gi";
import { TfiUser } from "react-icons/tfi";
import { fakeChartData } from "../../utils/fakeData";
import BarChart from "../../components/BarChart/BarChart";
import LineChart from "../../components/LineChart/LineChart";
import { Link } from "react-router-dom";
import Principale from "../../utils/services/Principale";
import { useRecoilState } from "recoil";
import { loadingState } from "../../recoil/loadingAtom";
import { UserContext } from "../../utils/Context/Context";
import Erreurs from "../../components/Erreurs/Erreurs";
import moment from "moment";
import "moment/locale/fr";
import cartegn from '../../assets/map/gn.svg'
import GuineaMap from "../../components/Map/GuineaMap";

const DashboardAdmin = () => {
  const[resume,setResume] = useState('');
  const[isLoading,setIsLoading] = useRecoilState(loadingState);
  const[erreurs,setErreurs] = useState([]);
  // const [mois,setMois] = useState([{libelle:'Janvier',valeur:'01'},{libelle:'Fevrier',valeur:'02'},{libelle:'Mars',valeur:'03'},{libelle:'Avril',valeur:'04'},
  // {libelle:'Mai',valeur:'05'},{libelle:'Juin',valeur:'06'},{libelle:'Juillet',valeur:'07'},{libelle:'Août',valeur:'08'},{libelle:'Septembre',valeur:'09'},
  // {libelle:'Octobre',valeur:'10'},{libelle:'Novembre',valeur:'11'},{libelle:'Décembre',valeur:'12'}
  // ]);
  // const [selectedMonthGraph1,setSelectedMonthGraph1] = useState(null);
  // const [selectedMonthGraph2,setSelectedMonthGraph2] = useState(null);
  // const [selectedMonthGraph3,setSelectedMonthGraph3] = useState(null);
  const [graphs,setGraphs] = useState(null);
  const api = new Principale();
  const {user} = useContext(UserContext);
  const [statData,setStatData] = useState([]);
  const getResume = async () => {
     setIsLoading(true);
      const{status,messages,statistique} = await api.get('/admin/statistique/dashboard');
    
     if(status === 200){
       setResume(statistique);
       api.get('/admin/statistique/graph/globale').then((resp) => {
         if(resp.status === 200){
            setGraphs(resp.graphs)
         }
         else setErreurs(resp.messages);
       });
       api.get('/admin/map/denombrementprefecture').then((resp) => {
         if(resp.status === 200){
           setStatData(resp.sdata);
         }else setErreurs(resp.messages);
       })
      }
     else setErreurs(messages);  
     setIsLoading(false); 
     }
  useEffect(() => {
     getResume();
    //  setSelectedMonthGraph1(moment().format('MM'));
    //  setSelectedMonthGraph2(moment().format('MM'));
    //  setSelectedMonthGraph3(moment().format('MM'));
  },[user]);
  
  const userDataLine = {
    labels: graphs?.graph1.map((data) => (data.contrat_type_valide)),
    datasets: [
      {
        label: "Type de Contrat",
        data: graphs?.graph1.map((data) => data.total),
        backgroundColor: [
          "#efefd0",
          "#f7c59f",
          "#ff9670",
          "#3ea5f4",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 1,
        pointRadius: 5,
        pointHoverRadius: 15,
        lineTension: 0.4,
        radius: 6,
      },
    ],
  };

  const userDataBar = {
    labels: graphs?.graph2.map((data) => moment(data.annee).format('YYYY')),
    datasets: [
      {
        label: "Employé",
        data: graphs?.graph2.map((data) => data.totalcandidat),
        backgroundColor: [
          "#efefd0",
          "#f7c59f",
          "#ff9670",
          "#3ea5f4",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 1,
        pointRadius: 5,
        pointHoverRadius: 15,
        lineTension: 0.4,
        radius: 6,
      },
    ],
  };
  const userDataBar2 = {
    labels: graphs?.graph3.map((data) => data.annee),
    datasets: [
      {
        label: "Employeur",
        data: graphs?.graph3.map((data) => data.totalemployeur),
        backgroundColor: [
          "#efefd0",
          "#f7c59f",
          "#ff9670",
          "#3ea5f4",
          "#2a71d0",
        ],
        borderWidth: 0,
      },
    ],
  };
 
  return (
    <div className="dashboard-admin page">
      <div className="greetings">
      <Erreurs validation = {erreurs} />
        <h3>Bonjour et bienvenue, </h3>
        <span>Voici un rapport de vos dernières activités.</span>
      </div>
      <div className="admin-stats">
        <div className="box">
          <div className="icon">
            <CgFileDocument size={20} />
          </div>
          <div className="text">
            <div className="title">
              <span>Total contrats Expirés</span>
            </div>
            <div className="value">
              <strong>{resume?.totalContrat?resume?.totalContrat:0}</strong>
            </div>
            <div className="more">
              <Link to='/contracts/list/termines' state={{ status: "Terminé", }}>Voir détails</Link>
            </div>
          </div>
        </div>
        <div className="box">
          <div className="icon">
            <GiSandsOfTime size={20} />
          </div>
          <div className="text">
            <div className="title">
              <span>Contrats en attente</span>
            </div>
            <div className="value">
              <strong>{resume?.totalContratenattente?resume?.totalContratenattente:0}</strong>
            </div>
            <div className="more">
              <Link to='/contracts/list' state={{ status: "En attente", }}>Voir détails</Link>
            </div>
          </div>
        </div>
        <div className="box">
          <div className="icon">
            <TfiUser size={20} />
          </div>
          <div className="text">
            <div className="title">
              <span>Total professionnels</span>
            </div>
            <div className="value">
              <strong>{resume?.totalpro?resume?.totalpro:0}</strong>
            </div>
            <div className="more">
              <Link to='/contracts/list'>Voir détails</Link>
            </div>
          </div>
        </div>
        <div className="box">
          <div className="icon">
            <BsBuilding size={20} />
          </div>
          <div className="text">
            <div className="title">
              <span>Total employeurs</span>
            </div>
            <div className="value">
              <strong>{resume?.totalemp?resume?.totalemp:0}</strong>
            </div>
            <div className="more">
              <Link to='/recruiters/list'>Voir détails</Link>
            </div>
          </div>
        </div>
      </div>

      <div className="charts">
        <h4>Statistiques</h4>
        <div className="charts-boxes">
          <div className="box">
            <div className="top">
              <strong>Type de contrats</strong>
              {/* <select name="graphoption1" value={selectedMonthGraph1 !== null && selectedMonthGraph1}
              
              >
                {mois && mois.map((ms) => (<option value={ms.valeur} key={ms.valeur + Math.random()}>{ms.libelle}</option>))}
              </select> */}
            </div>
            <div className="bottom">
              <BarChart chartData={userDataLine} />
            </div>
          </div>
          <div className="box">
            <div className="top">
              <strong>Professionnels Actifs</strong>
              {/* <select name="graphoption2" id="graphoption3" value={selectedMonthGraph2 !== null &&selectedMonthGraph2}>
               {mois && mois.map((ms) => (<option value={ms.valeur} key={ms.valeur + Math.random()}>{ms.libelle}</option>))}
              </select> */}
            </div>
            <div className="bottom">
              <LineChart chartData={userDataBar} />
            </div>
          </div>
          <div className="box">
            <div className="top">
              <strong>Employeurs</strong>
              {/* <select name="graphoption3" id="graphoption3" value={selectedMonthGraph3 !== null &&selectedMonthGraph3}
              >
                {mois && mois.map((ms) => (<option value={ms.valeur} key={ms.valeur + Math.random()}>{ms.libelle}</option>))}
              </select> */}
            </div>
            <div className="bottom">
              <BarChart chartData={userDataBar2} />
            </div>
          </div>
        </div>
      </div>

      <div className="carte-conteneur">
         <div className="stat">
          
         </div>
         <div className="carte">
           <GuineaMap data={statData} />
         </div>
      </div>
    </div>
  );
};

export default DashboardAdmin;
