export function isEmpty(obj) {
    if(typeof obj === 'undefined' || !obj) return true;
    return Object.keys(obj).length === 0 
}
export function objecttoFormData(elements){
    var formData = new FormData(); 
    Object.keys(elements).forEach((key) => {
       if(typeof elements[key] === 'object' && typeof elements[key]?.name === 'undefined')
          formData.append(key, JSON.stringify(elements[key]));
       else if(typeof elements[key] === 'function')
           formData.append(key,elements[key]())       
        else  formData.append(key, elements[key]);
      });
    return formData;
  } 

 export function validateDate(date){
    var regex=new RegExp("([0-9]{4}[-](0[1-9]|1[0-2])[-]([0-2]{1}[0-9]{1}|3[0-1]{1})|([0-2]{1}[0-9]{1}|3[0-1]{1})[-](0[1-9]|1[0-2])[-][0-9]{4})");
    var dateOk=regex.test(date);
    if(dateOk){
        return true
    }else{
        return false;
    }
  } 
  export const FirstUpperCase = (caracteres) => {
    if(caracteres.length === 0)
       return caracteres.toUpperCase();
    else {
     var splitStr = caracteres.toLowerCase().split(' ') ;
     for (var i = 0; i < splitStr.length; i++) {
         // You do not need to check if i is larger than splitStr length, as your for does that for you
         // Assign it back to the array
         splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
     }
     return splitStr.join(' '); 
    }     
 }
 export function compareTime(str1, str2){
  if(str1 === str2){
      return 0;
  }
  var time1 = str1.split(':');
  var time2 = str2.split(':');
  if(parseInt(time1[0]) > parseInt(time2[0])){
      return 1;
  } else if(parseInt(time1[0]) === parseInt(time2[0]) && parseInt(time1[1]) > parseInt(time2[1])) {
      return 1;
  } else {
      return -1;
  }
}
export function formatStringNumber(x) {
    return isNaN(x)?"":parseInt(Math.round(x)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
 }
  
  
