import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import ContratConsultance from "../../components/ContratConsultance/ContratConsultance";
import ContratInterim from "../../components/ContratInterim/ContratInterim";
import ContratMiseADisposition from "../../components/ContratMiseADisposition/ContratMiseADisposition";
import ContratMission from "../../components/ContratMission/ContratMission";
import ContratRecrutement from "../../components/ContratRecrutement/ContratRecrutement";
import ContratSaisonnier from "../../components/ContratSaisonnier/ContratSaisonnier";
import ContratTacheronat from "../../components/ContratTacheronat/ContratTacheronat";

const PrintContract = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="print-contract-page page w-60">
      <button className="big accent" onClick={handlePrint}>
        Imprimer
      </button>
      <ContratConsultance ref={componentRef} />
      {/* <ContratInterim ref={componentRef} />
      <ContratMiseADisposition ref={componentRef} />
      <ContratMission ref={componentRef} />
      <ContratRecrutement ref={componentRef} />
      <ContratSaisonnier ref={componentRef} />
      <ContratTacheronat ref={componentRef} /> */}
    </div>
  );
};

export default PrintContract;
