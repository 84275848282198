import { AiOutlineUser } from "react-icons/ai";
import { GrDocumentVerified } from "react-icons/gr";
import { FiSearch } from "react-icons/fi";
import { RiLightbulbFlashLine } from "react-icons/ri";
import { GoPaintcan } from "react-icons/go";
import { BiWater } from 'react-icons/bi';
import { GiDeadWood } from 'react-icons/gi';
import { BsArrowUpRightCircle } from 'react-icons/bs';

const instructions = [
    {
        id: 1,
        icon: <AiOutlineUser size={24} />,
        title: "Créez votre compte",
        desc: "Créez et activer votre compte pour commencer votre aventure"
    },
    {
        id: 2,
        icon: <FiSearch size={24} />,
        title: "Trouvez le professionnel qu'il vous faut",
        desc: "Cherchez dans notre catalogue de personnes qualifiées"
    },
    {
        id: 3,
        icon: <GrDocumentVerified size={24} />,
        title: "Nous nous occupons du reste",
        desc: "Vous avez trouvé la personne qu’il vous faut ? Laissez-nous faire le reste, nous vous contacterons"
    },
]

const homeCategories = [
    {
        id: 1,
        icon: <RiLightbulbFlashLine size={32} />,
        title: "Electricité",
    },
    {
        id: 2,
        icon: <GoPaintcan size={32} />,
        title: "Peintre",
    },
    {
        id: 3,
        icon: <BiWater size={32} />,
        title: "Plomberie",
    },
    {
        id: 4,
        icon: <GiDeadWood size={32} />,
        title: "Menuiserie",
    },
    {
        id: 5,
        icon: <BsArrowUpRightCircle size={32} />,
        title: "Autres",
    },
]

export { instructions, homeCategories }