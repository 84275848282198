import React, { useEffect, useState } from "react";
import { ToWords } from 'to-words';
import moment from "moment";
import { Markup } from 'interweave';
import logo from "../../assets/images/logosig.png";
import { formatStringNumber } from "../../utils/services/helpers";
const ContratMission = React.forwardRef(({recrutement,setRecrutement,setError}, ref) => {
  const toWords = new ToWords({
    localeCode: 'fr-FR',
    converterOptions: {
      currency: false,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
    }
  });
  const [mois,setMois] = useState(0);
  const[diffHour,setDiffHour] = useState(0);
  useEffect(() => {
    if(recrutement.emploie.date_debut && recrutement.emploie.date_fin)
      setMois(Math.abs(getDate(recrutement?.emploie?.date_debut).diff(getDate(recrutement?.emploie?.date_fin), 'months')));
      const end = moment(recrutement.emploie.heure_fin,'HH:mm a');
      const start = moment(recrutement.emploie.heure_debut,'HH:mm a');
    
     var duration = moment.duration(end.diff(start));
     var hours = duration.asHours();  
     setDiffHour(hours);
  },[]);
  const getDate = (date) => moment(date).startOf('month');
  const handleInput = (e) => {
    setError(null);
    setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]:e.target.files[0]}});
  }
  return (
    <>
     <div style={{width:'40%',float:'right',height:'20px',marginTop:'-25px',marginRight:'5px'}} className="input-group">
       <label>Le Contrat signé
         <input type="file" name="copie_contrat"  accept=".pdf" onChange={(e) => handleInput(e)}/>
       </label> 
      </div>
      <div className="contract-doc" ref={ref}>
      <h1>Contrat de mission</h1>
      <div className="presentation">
        <div className="logo">{logo?<img src={logo} style={{width:'85px',marginBottom:'20px',marginTop:'-23px'}}/>:'LOGO'}</div>
        <div className="employeur">
          <p>
            <strong>Entre les soussigné(e)s :</strong>
          </p>
          <p>
            La société <strong>Innovent Group Service SARLU </strong> au capitale de 10 0000 GNF immatriculée au registre du
            commerce et des sociétés sous le numéro <strong>UI1038DEUIE</strong> dont le siège social
            est situé à xxx, représentée par  Monsieur <strong>Youssouf FOFANA</strong>, contact : <strong>628 26 10 39</strong>.
          </p>
          <p>
            D'une part, ci-après dénomée l'<strong>Employeur.</strong>
          </p>
        </div>
        <div className="client">
          <p>
            <strong>Et :</strong>
          </p>
          <p>M. ou Mme : {recrutement?.candidat?.sexe === 'M'?"Monsieur":"Madame/Mademoiselle"}</p>
          <p>Né(e) le : {moment(recrutement?.candidat?.datenaissance).format("DD/MM/YYYY")}</p>
          <p>Nationalité  : {recrutement?.candidat?.nationalite}</p>
          <p>Démeurant à : {recrutement?.candidat?.prefecture+"/"+recrutement?.candidat?.commune+"/"+recrutement?.candidat?.quartier}</p>
          <p>CIN Numéro : {recrutement?.candidat?.numero_piece}</p>
          <p>Contact : (+224) {recrutement?.candidat?.telephone}</p>
          <p>
            D'une part, ci-après dénomée le <strong>Salarié.</strong>
          </p>
        </div>
      </div>
      <p className="convention">Il a été convenu et arreté ce qui suit :</p>
      <div className="articles">
        <div className="article">
          <h4>Article 1 : Objet du contrat</h4>
          <p>
            La Société IGS affecte un de ses agents au compte de {recrutement?.employeur.typeEmp === 'societe'?<strong>{" la société " +recrutement?.employeur.societe+" "}</strong>:<strong>{recrutement?.employeur?.civilite +" "+recrutement?.employeur?.prenomEmp + " "+ recrutement?.employeur?.nomEmp}</strong>}
            pour les fonctions {recrutement?.candidat?.generique ? recrutement?.candidat?.generique : recrutement?.candidat?.metier}, le niveau hiérarchique : {recrutement?.emploie?.niveau_etude?recrutement?.emploie?.niveau_etude:"------"},
            conformément à la legislation du travail, portant Code du Travail de
            la République de Guinée, et les textes pris pour son application
          </p>
        </div>
        <div className="article">
          <h4>Article 2 : Durée du contrat</h4>
          <p>
            Ce contrat prend effet à la date du {recrutement?.emploie?.date_debut?moment(recrutement?.emploie?.date_debut).format("DD/MM/YYYY"):'…. /…../…..'} au  {recrutement?.emploie?.date_fin?moment(recrutement?.emploie?.date_fin).format("DD/MM/YYYY"):'…. /…../…..'} pour une période
            de <strong>{mois > 0?mois+" mois":((Math.ceil((new Date(recrutement?.emploie?.date_fin) - new Date(recrutement?.emploie?.date_debut))/(1000 * 3600 * 24)))+ 1)+" jour (s)"}</strong>. Il pourra être renouvelé en d'autres termes
            et conditions modifiées (si nécessaires) décidés d'un commun accord
            par les deux parties suivant une période de <strong>{mois > 0?mois+" mois":((Math.ceil((new Date(recrutement?.emploie?.date_fin) - new Date(recrutement?.emploie?.date_debut))/(1000 * 3600 * 24)))+ 1)+" jour (s)"}</strong> à moins que
            l'une des deux parties mettent fin à ce contrat en informant l'autre
            partie avec un préavis de deux mois soit avant le 1er novembre.
          </p>
        </div>
        <div className="article">
          <h4>Article 3 : Mission</h4>
          <p>L'agent a pour mission de :</p>
          <p> <Markup content={recrutement?.emploie.taches} /></p>
        </div>
        <div className="article">
          <h4>Article 4 : Horaire de la mission</h4>
          <p>
            La durée hebdomadaire du travail est de <strong>{diffHour > 0 ? diffHour +" heure(s)":moment.duration(moment(recrutement.emploie.heure_fin,'HH:mm a').diff(moment(recrutement.emploie.heure_debut,'HH:mm a'))).asMinutes()}</strong> soit
             <strong>{Array.isArray(recrutement?.emploie?.selectedjours) && recrutement?.emploie?.selectedjours.length > 0 ?" " +recrutement?.emploie?.selectedjours.length + " jour(s)/semaine(s)":' ------------'}</strong>.
            Toutefois cette durée peut être modifié et allongée si les
            conditions de travail l’exigent. De ce fait, le salarié intérim doit
            être indemnisé pour ses heures supplémentaires.
          </p>
        </div>
        <div className="article">
          <h4>Article 5 : Période d'essai</h4>
          <p>
            L’agent mis à disposition effectuera une période d'essai d'une semaine à compter du {moment(recrutement?.emploie?.date_debut).format("DD/MM/YYYY")} au {moment(recrutement?.emploie?.date_debut).add(8, 'days').format('DD/MM/YYYY')}.
          </p>
        </div>
        <div className="article">
          <h4>Article 6 : Poste</h4>
          <p>
            L’agent sera affecté et employé {recrutement?.employeur.typeEmp === 'societe'?<strong>{" à la société " +recrutement?.employeur.societe+" "}</strong>:<strong>{" chez "+recrutement?.employeur?.civilite +" "+recrutement?.employeur?.prenomEmp + " "+ recrutement?.employeur?.nomEmp}</strong>}, en qualité de
            {" "+recrutement?.candidat?.metier}.
          </p>
        </div>
        <div className="article">
          <h4>Article 7 : Lieu de la mission</h4>
          <p>
            La mission dont l’agent est chargé s'effectuera au niveau de <strong>{recrutement?.emploie?.lieuexecution}</strong>
            
          </p>
        </div>
        <div className="article">
          <h4>Article 8 : Litige</h4>
          <p>
            Tout litige relevant de l’exécution du présent contrat sera réglé à
            l’amiable ; à défaut, il sera soumis à la juridiction compétente de
            CONAKRY.
          </p>
        </div>
        <div className="article">
          <h4>Article 9 : Rémunération</h4>
          <p>
            L'entreprise de travail temporaire a souscrit une garantie
            financière auprès du salarié.
          </p>
          <p>
            Un paiement automatique sera effectué lorsque le client valide la
            fiche de supervision.
          </p>
          <p>
            Conformément à l'article L. 1251-49 du Code du travail, tout
            entrepreneur de travail temporaire est tenu, à tout moment, de
            justifier d'une garantie financière assurant, en cas de défaillance
            de sa part, le paiement :
            <ul>
              <li>des salaires et de leurs accessoires</li>
              <li>des indemnités résultant du présent chapitre</li>
              <li>
                des cotisations obligatoires dues à des organismes de Sécurité
                sociale ou à des institutions sociales, le cas échéant, des
                remboursements qui peuvent incomber aux employeurs à l'égard de
                ces organismes et institutions dans les conditions prévues à
                l'article L. 244-8 du Code de la Sécurité sociale.
              </li>
            </ul>
          </p>
        </div>
        <div className="article">
          <h4>Article 10 : Rupture anticipée du contrat</h4>
          <p>
            Après la période d'essai, le présent contrat ne pourra être résilié
            avant le terme convenu, sauf accord des parties, qu'en cas de faute
            grave, lourde, de force majeure ou si le client peut justifier de la
            conclusion d'un contrat à durée indéterminée.
          </p>
        </div>
       
        <div className="article">
          <h4>Article 12 : Conclusion du contrat</h4>
          <p>
            Conformément aux dispositions de l'article L. 1251-42 et suivants du
            Code du travail, ce contrat doit être conclu par écrit au plus tard
            dans les 2 jours ouvrables suivant la mise à disposition.
          </p>
        </div>
       
        {recrutement?.personneReferences?.length > 0 && 
          <>
          <div className="article">
            <h4>Article 11 : Garants</h4>
            <p>Pour conclure, {recrutement?.candidat?.sexe === 'M'?"Monsieur " +recrutement?.candidat?.prenom+" "+recrutement?.candidat?.nom:"Madame/Mademoiselle "+recrutement?.candidat?.prenom+" "+recrutement?.candidat?.nom}. envoie deux garants qui sont :</p>
          </div>
           {recrutement?.personneReferences.map((referent,index) => {
             return(
              <div className="article">
                <p>
                 <strong>Garant {index} :</strong>
                </p>
              <p>Prenom et Nom : {referent.prenometnom}</p>
              <p>Fonction: {referent.post}</p>
              <p>Contacts : {referent.telephone}</p>
           </div>
           )
         })}
          <p>
            Ils s’engagent à prendre toutes responsabilités liées à ce présent
            contrat en cas de litige.
          </p>
         </>
        }
        <p className="note">
          Fait en double exemplaire, dont un est remis à chacune des parties du
          présent avenant.
        </p>
        <p className="date">A Conakry, le {moment().format('Do MMMM YYYY')}</p>
        <p className="nb">
          Faire précéder la signature du client de la mention manuscrite "lu et
          approuvé".
        </p>
        <div className="signatures">
          <p>
            <strong>La société IGS</strong>
          </p>
          <p>
            <strong>Le salarié</strong>
          </p>
        </div>
        <div className="signatures">
          <p>
            <strong>Garant 1</strong>
          </p>
          <p>
            <strong>Garant 2</strong>
          </p>
        </div>
      </div>
      </div>
    </>
  );
});

export default ContratMission;
