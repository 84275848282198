import { useEffect, useMemo, useRef, useState } from "react";
import StepOne from "../../components/NewRecrutementStep/StepOne";
import StepTwo from "../../components/NewRecrutementStep/StepTwo";
import StepThree from "../../components/NewRecrutementStep/StepThree";
import StepFour from "../../components/NewRecrutementStep/StepFour";
import Stepper from "../../components/Stepper/Stepper";
import { recrutementStep } from "../../utils/texts";
import { useRecoilState } from "recoil";
import { loadingState } from "../../recoil/loadingAtom";
import Erreurs from "../../components/Erreurs/Erreurs";
import Principale from "../../utils/services/Principale";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import moment from "moment/moment";
import { BsFillFileEarmarkCheckFill } from 'react-icons/bs';

const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

  return [ htmlElRef, setFocus ] 
}
const NewRecrutement = ({globalState,setGlobalState}) => {
 
  const[recrutement,setRecrutement] = useState({
    candidat:null,
    employeur:null,
    emploie:null,
    personneReferences:null

  });
  const [inputRef, setInputFocus] = useFocus();
  const [activeStep, setActiveStep] = useState(1);
  const[isLoading,setIsLoading] = useRecoilState(loadingState);
  const[erreurs,setErreurs] = useState([]);
  const {emploie_id} = useParams();
  const api = new Principale();
  const url = process.env.REACT_APP_URL + 'storage/';
  const navigate = useNavigate();
  const recrutementInfo = async () => {
    setIsLoading(true);
     const{status,messages,emploie,candidat,employeur,personneReferences} = await api.get(`/admin/emploie/data/${emploie_id}`);
    setIsLoading(false);  
    if(status === 200){
      setRecrutement({...recrutement,candidat:candidat[0],employeur:employeur[0],emploie:emploie,personneReferences:personneReferences});
    }
    else setErreurs(messages);  
  
   
  }
  //console.log(recrutement)
  const submitRejet  = async () => {
    setErreurs([]);setIsLoading(true);
    var formData = new FormData();
    formData.append('employeur_id',recrutement.employeur.employeur_id);
    formData.append('candidat_id',recrutement.candidat.candidat_id);
    formData.append('status','rejeter');
    formData.append('raisonRejet',recrutement?.emploie?.raisonRejet);
    const {status,messages} = await api.post('/admin/employeur/traitement/statusRejet',formData);
     setIsLoading(false);
    if(status === 200){
      toast.success('Vous avez rejété avec succès cette demande');navigate('/contracts/list');
    }
    else setErreurs(messages);
  }
  useEffect(() => {
    if(!recrutement?.emploie?.selectedjours)
      recrutementInfo();
  },[emploie_id])
  //console.log(recrutement);
  const nextStep = () => {
    if(activeStep === 2){
      if(recrutement?.emploie?.statusTraitement === 'rejeter'){
        submitRejet();
        return
      }
    }
     
    setActiveStep(activeStep + 1);
  };

  const prevStep = () => {
    setActiveStep(activeStep - 1);
  };
  const openFile = (document) => {
    if(document === "contrat")
      window.open(`${url}${recrutement.emploie.contrat_copie.replace('public/','')}`);
  }
  const handleInput = (e) => {
    setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]:e.target.files[0]}})
  }
  const submitContrat = async (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append('emploie_id',recrutement.emploie.id);
    formData.append('candidat_id',recrutement.candidat.candidat_id);
    formData.append('employeur_id',recrutement.employeur.employeur_id);
    formData.append('contrat_copie',recrutement.emploie.copie_contrat?recrutement.emploie.copie_contrat:'');
    setErreurs([]);setIsLoading(true);
    const{status,messages} = await api.post('/admin/contrats/validationFinale',formData);
    setIsLoading(false);
    if(status === 200){
       toast.success("Contrat validé avec succès.");
       setGlobalState(!globalState);
       navigate('/contracts/list');
    }else setErreurs(messages);
      
  }

  //console.log(recrutement?.emploie?.copie_contrat);
  return (
   
    <div className="besc-page page">
    
      {recrutement?.emploie?.status === 'En attente' &&
       <>
        { <Erreurs validation = {erreurs} />}
        <h3>Nouveau Recrutement</h3>
        <div className="stepper-zone">
          <Stepper 
            steps={recrutementStep} 
            activeStep={activeStep} 
            
          />
        </div>
        <div className="mrn-form">
          {activeStep === 1 && (
            <StepOne  
              nextStep={nextStep}  
              prevStep={prevStep}
              recrutement={recrutement}
              setRecrutement={setRecrutement}
              />
          )}
          {activeStep === 2 && (
            <StepTwo 
              nextStep={nextStep}
              prevStep={prevStep}
              recrutement={recrutement}
              setRecrutement={setRecrutement}
              />
          )}
          {activeStep === 3 && (
            <StepThree 
              prevStep={prevStep}  
              nextStep={nextStep} 
              recrutement={recrutement}
              setRecrutement={setRecrutement}
              />
          )}
          {activeStep === 4 && (
            <StepFour 
              prevStep={prevStep}  
              nextStep={nextStep} 
              recrutement={recrutement}
              setRecrutement={setRecrutement}
              globalState={globalState}
              setGlobalState={setGlobalState}
              />
          )}
      
        </div>
       </>
       }
       {recrutement?.emploie?.status === 'Contrat en attente' &&
        <>
          <h3>Validation Finale du Contrat</h3>
          <form onSubmit={submitContrat}>
             <div className="input-group file-input photo-input">
               <div className="group">
                  <span>Photo</span>
                  <div className="photo">
                    <img src={recrutement.candidat.photo?url+recrutement.candidat.photo.replace('public/',""):""} alt={recrutement.candidat.prenom+ " "+ recrutement.candidat.nom} />
                  </div>
               </div>
            </div>
            <div className="left">
              <div className="group">
                 <h4>Information du Candidat</h4>
                 <div className="input-group">
                   <label>Prenom et Nom
                      <strong style={{padding:'4.5px',paddingLeft:'8px',border:'1px solid #e2d9d9',display:'block'}}>{recrutement.candidat.prenom+ " "+ recrutement.candidat.nom}</strong>
                    </label> 
                    <label>Sexe
                       <strong style={{padding:'4.5px',paddingLeft:'8px',border:'1px solid #e2d9d9',display:'block'}}>{recrutement.candidat.sexe === 'M'?"Masculin":"Feminin"}</strong>
                    </label> 
                    <label>Date de Naissance
                       <strong style={{padding:'4.5px',paddingLeft:'8px',border:'1px solid #e2d9d9',display:'block'}}>{moment(recrutement.candidat.datenaissance).format("Do MMMM YYYY")}</strong>
                    </label> 
                    <label>Telephone
                       <strong style={{padding:'4.5px',paddingLeft:'8px',border:'1px solid #e2d9d9',display:'block'}}>{recrutement.candidat.telephone}</strong>
                    </label> 
                 </div>
                 <div className="input-group">
                    <label>Niveau Etude
                       <strong style={{padding:'4.5px',paddingLeft:'8px',border:'1px solid #e2d9d9',display:'block'}}>{recrutement.candidat.niveau_etude?recrutement.candidat.niveau_etude:'---------'}</strong>
                    </label>
                    <label>Metier
                       <strong style={{padding:'4.5px',paddingLeft:'8px',border:'1px solid #e2d9d9',display:'block'}}>{recrutement.candidat.metier}</strong>
                    </label>
                    <label>Experience
                       <strong style={{padding:'4.5px',paddingLeft:'8px',border:'1px solid #e2d9d9',display:'block'}}>{recrutement.candidat.experience}</strong>
                    </label>
                 </div>
                 <div className="input-group">
                    <label>Prefecture
                       <strong style={{padding:'4.5px',paddingLeft:'8px',border:'1px solid #e2d9d9',display:'block'}}>{recrutement.candidat.prefecture}</strong>
                    </label>
                    <label>Commune
                       <strong style={{padding:'4.5px',paddingLeft:'8px',border:'1px solid #e2d9d9',display:'block'}}>{recrutement.candidat.commune}</strong>
                    </label>
                    <label>Quartier/District
                       <strong style={{padding:'4.5px',paddingLeft:'8px',border:'1px solid #e2d9d9',display:'block'}}>{recrutement.candidat.quartier}</strong>
                    </label>

                 </div>
              </div>
            </div> 
            <div style={{borderBottom:'1px solid #d1bebea8',width:'100%',marginTop:'16px'}}></div>
            <div className="left">
               <h3>Information sur l'employeur</h3>
               <div className="input-group">
                 {recrutement.employeur.typeEmp === 'personne physique' &&
                  <label>Prenom et Nom 
                      <strong style={{padding:'4.5px',paddingLeft:'8px',border:'1px solid #e2d9d9',display:'block'}}>{recrutement.employeur.civilite + " " +recrutement.employeur.prenomEmp+ " "+ recrutement.employeur.nomEmp}</strong>
                  </label>} 
                  {recrutement.employeur.typeEmp === 'societe' &&
                   <label> Societe
                      <strong style={{padding:'4.5px',paddingLeft:'8px',border:'1px solid #e2d9d9',display:'block'}}>{recrutement.employeur.societe}</strong>
                   </label>
                  }
                   <label>Telephone
                      <strong style={{padding:'4.5px',paddingLeft:'8px',border:'1px solid #e2d9d9',display:'block'}}>{recrutement.employeur.telephone}</strong>
                   </label>
                   <label>email
                      <strong style={{padding:'4.5px',paddingLeft:'8px',border:'1px solid #e2d9d9',display:'block'}}>{recrutement.employeur.email}</strong>
                   </label>
               </div>
               <div className="input-group">
                 <label>Prefecture
                    <strong style={{padding:'4.5px',paddingLeft:'8px',border:'1px solid #e2d9d9',display:'block'}}>{recrutement.employeur.prefecture}</strong>
                 </label>
                 <label>Commune
                    <strong style={{padding:'4.5px',paddingLeft:'8px',border:'1px solid #e2d9d9',display:'block'}}>{recrutement.employeur.commune}</strong>
                 </label>
                 <label>Quartier
                    <strong style={{padding:'4.5px',paddingLeft:'8px',border:'1px solid #e2d9d9',display:'block'}}>{recrutement.employeur.quartier}</strong>
                 </label>
                 <label>Addresse
                    <strong style={{padding:'4.5px',paddingLeft:'8px',border:'1px solid #e2d9d9',display:'block'}}>{recrutement.employeur.addresse && recrutement.employeur.addresse!== 'null'?recrutement.employeur.addresse:'---------'}</strong>
                 </label>
               </div>
            </div>    
            <div style={{borderBottom:'1px solid #d1bebea8',width:'100%',marginTop:'16px'}}></div>
            <div className="left">
               <h3>Information sur le contrat</h3>
               <div className="input-group">
                  <label>Type de Contrat
                     <strong style={{padding:'4.5px',paddingLeft:'8px',border:'1px solid #e2d9d9',display:'block'}}>{recrutement.emploie.contrat_type_valide}</strong>
                   </label>
                   <label>Date de debut 
                     <strong style={{padding:'4.5px',paddingLeft:'8px',border:'1px solid #e2d9d9',display:'block'}}>{moment(recrutement.emploie.date_debut).format("Do MMMM YYYY")}</strong>
                   </label>
                   <label>Date fin
                     <strong style={{padding:'4.5px',paddingLeft:'8px',border:'1px solid #e2d9d9',display:'block'}}>{moment(recrutement.emploie.date_fin).format("Do MMMM YYYY")}</strong>
                   </label>
               </div>
               <div className='input-group'>
                <label style={{cursor:'pointer'}} onClick={() => openFile("contrat")}>
                    <div className="file-wrapper">
                    <p style={{marginBottom:'10px'}}>Copie du contrat signé par IGS</p>
                    <div className='image bkviewImg'>
                        <BsFillFileEarmarkCheckFill size={50} />
                    </div>
                    </div>
                </label>
                <label>Joindre la copie du contrat Signé de l'employeur
                    <input type="file" onChange={handleInput} name="copie_contrat" accept=".pdf" />
                </label>
              </div>
              <div style={{borderBottom:'1px solid #d1bebea8',width:'100%',marginTop:'16px',marginBottom:'10px'}}></div>
              { <Erreurs validation = {erreurs} />}
              <div className="buttons">
                <button className='secondary' onClick={()=> navigate('/contracts/list')}>Précédent</button>
                <button type='submit' className='success'>Terminer</button>
              </div>
            </div> 
          </form>
       
        </>
       }
    </div>
  );
};

export default NewRecrutement;
