import { useRef } from "react";
import { FaRegWindowClose } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";

const MetierDetailsModal = ({ isOpen, setIsOpen ,SelectedMetier}) => {
  const modalRef = useRef();
  const url = process.env.REACT_APP_URL + "storage/";
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setIsOpen(false);
    }
  };
  return (
    <AnimatePresence>
      {isOpen ? (
        <div
          className="recruiter-details-modal modal-component"
          onClick={closeModal}
          ref={modalRef}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, scale: 0 }}
            className="modal"
          >
            <div className="close" onClick={() => setIsOpen(false)}>
              <FaRegWindowClose size={24} id="close-icon" />
            </div>
            <div className="details-recruiter details-metier">
              <h2>Détails du Métier</h2>
              <div className="divider">
                <h4>Informations générale</h4>
                <div className="info-group">
                  <div className="info-box">
                    <span className="label subtext">Nom du Métier</span>
                    <p className="info">{SelectedMetier.nom}</p>
                  </div>
                  <div className="info-box">
                    <span className="label subtext">Nom Générique</span>
                    <p className="info">{SelectedMetier.generique}</p>
                  </div>
                  <div className="info-box">
                    <span className="label subtext">Date de Création</span>
                    <p className="info">{SelectedMetier.created_at}</p>
                  </div>
                  <div className="info-box">
                    <span className="label subtext">Date de Modification</span>
                    <p className="info">{SelectedMetier.updated_at}</p>
                  </div>
                </div>
               
               <div className="info-group description-group">
                <div className="info-box">
                    <span className="label subtext">Description</span>
                </div> 
                <div className="description">
                   <div className="description-text">
                      {SelectedMetier.description}
                   </div>
                </div>   
              </div>
              </div>
           
            </div>
          </motion.div>
        </div>
      ) : null}
    </AnimatePresence>
  );
};

export default MetierDetailsModal;
