import { useState ,useContext} from 'react';
import { useRecoilState } from 'recoil';
import { sideMenuState } from '../../recoil/sideMenuAtom';
import { Link, NavLink,useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../../utils/Context/Context';
import { isEmpty } from '../../utils/services/helpers';
import Principale from '../../utils/services/Principale';
import { BiBell } from "react-icons/bi";
import { IoIosArrowDown } from 'react-icons/io'
import UserMenuDropdown from '../UserMenuDropdown/UserMenuDropdown'
import OutsideClickHandler from "react-outside-click-handler";
import { loadingState } from "../../recoil/loadingAtom";
import logo from '../../assets/images/logo.png';
import { useEffect } from 'react';

const NavbarAdmin = ({globalState,setGlobalState}) => {
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(sideMenuState)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const[isLoading,setIsLoading] = useRecoilState(loadingState);
  
  const [erreurs,setErreurs] = useState([]);
  const { pathname } = useLocation();
  const navigate = useNavigate()
  const {user,setUser} = useContext(UserContext);
  const url = process.env.REACT_APP_URL + 'storage/';
  const [messageOpen,setMessageOpen] = useState(false);
  const [candidats,setCandidats] = useState([]);
  const api = new Principale();
  const getContratEmp = async () => {
    setIsLoading(true);setErreurs([]);
      const{status,messages,candidatEmps} = await api.get('/admin/employeur/contract');
    setIsLoading(false)
    if(status === 200)
      setCandidats(candidatEmps.filter((c) => c.StatusEmploie === 'En attente'));
    else 
      setErreurs(messages);   

  }
  useEffect(() => {
    getContratEmp();
  },[globalState]);

  return (
    <nav className={`navbar ${pathname === '/' && 'gray'}`}>
      <div className="content" >
        <div className="left">
          <div className={`menu ${isMenuOpen && 'menu-open'}`} onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <div className="bar top"></div>
            <div className="bar bottom"></div>
          </div>
          <div className="logo">
          <Link to='/admin/dashboard'>
            <img src={logo} alt="" />
          </Link>
          </div>
        </div>
        <div className="center">
          <ul>
            {/* {(user?.role_id === 3 || isEmpty(user))&&<li><NavLink className={(navData) => navData.isActive ? "link-activee" : ""} to='/findprofessionals'>Trouvez un pro</NavLink></li>} */}
            <li><NavLink className={(navData) => navData.isActive ? "link-active" : ""} to='/admin/dashboard'>Tableau de board</NavLink></li>
            <li><NavLink className={(navData) => navData.isActive ? "link-active" : ""} to='/contracts/list'>Liste de Candidature</NavLink></li>
            <li><NavLink className={(navData) => navData.isActive ? "link-active" : ""} to='/recruiters/list'>Employeur</NavLink></li>
            <li><NavLink className={(navData) => navData.isActive ? "link-active" : ""} to='/utilisateur/list'>Utilisateurs</NavLink></li>
            <li><NavLink className={(navData) => navData.isActive ? "link-active" : ""} to='/metiers/list'>Métiers</NavLink></li>
            {/* {(user?.role_id === 3 || isEmpty(user)) && <li><NavLink className={(navData) => navData.isActive ? "link-active" : ""} to='/findrecruiters'>Employeurs</NavLink></li>} */}
            {/* <li><NavLink className={(navData) => navData.isActive ? "link-active" : ""} to='/blog'>Blog</NavLink></li>
            <li><NavLink className={(navData) => navData.isActive ? "link-active" : ""} to='/contact'>Contact</NavLink></li>
            <li><NavLink className={(navData) => navData.isActive ? "link-active" : ""} to='/about'>À Propos</NavLink></li> */}
          </ul>
        </div>
        <div className="right">
          {!isEmpty(user) ?
          <>
            <div className='notifaction' onClick={() => setMessageOpen(!messageOpen)}>
                <div className='notification-icon'>
                  <BiBell size={24} />
                  <div className='notification-number'>
                    {candidats.length <= 9 ?candidats.length:"9+"}
                </div>
            </div>
         
            {messageOpen &&
             <OutsideClickHandler onOutsideClick={() => setMessageOpen(false)}>
               {candidats.length > 0 &&
                <div className='notification-message' id='notification-message' onClick={() => setMessageOpen(!messageOpen)}>
                  <div className='fleche'>
                  </div>
                  <ul>
                     {candidats.map((candidat) => {
                      return(
                       <li key={candidat.id}>
                        <Link to={`/admin/recrutement/${candidat.id}`}>
                        <div className='user-profile'>
                          <div className='user-picture'>
                            <img src={url + candidat?.photoCandidat?.replace('public/','')}/>
                          </div>
                          <div className='user-detail'>
                              <p>{candidat.prenom+" "+ candidat.nom}</p>
                              <p>{candidat.sexe === 'M' ? "Masculin":"Feminin"}</p>
                              <p>{candidat.prefecture},{candidat.commune}-{candidat.quartier}</p>
                              <p>{candidat.metier}</p>
                          </div>
                        </div>
                        </Link>
                      </li>
                      )
                     })
                      }
                      {/* <li>
                        <Link to="#">
                        <div className='user-profile'>
                          <div className='user-picture'>
                            <img src='https://images.unsplash.com/photo-1573497491765-dccce02b29df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80'/>
                          </div>
                          <div className='user-detail'>
                              <p>Aboubacar Sidiki KOUROUMA</p>
                              <p>Masculin,24 ans</p>
                              <p>Dixinn,Dixinn-Belle-Vue</p>
                              <p>Plomberie</p>
                          </div>
                        </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                        <div className='user-profile'>
                          <div className='user-picture'>
                            <img src='https://images.unsplash.com/photo-1573497491765-dccce02b29df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80'/>
                          </div>
                          <div className='user-detail'>
                              <p>Aboubacar Sidiki KOUROUMA</p>
                              <p>Masculin,28 ans</p>
                              <p>Dixinn,Dixinn-Belle-Vue</p>
                              <p>Plomberie</p>
                          </div>
                        </div>
                        </Link>
                      </li> */}
                  </ul>
                </div>
                }
              </OutsideClickHandler>
            }
            
          </div>
          <OutsideClickHandler onOutsideClick={() => setIsDropdownOpen(false)}>
            <div className="user-menu" onClick={() => {setIsDropdownOpen(!isDropdownOpen);setMessageOpen(false);}}>
              
              <div className="profile-image">
                {<img src={url + user?.photoprofileAdmin?.replace('public/',"")} alt={''} />}
              </div>
            
              <div className={`arrow-icon ${isDropdownOpen && 'open'}`}>
                <IoIosArrowDown />
              </div>
             
              <UserMenuDropdown isOpen={isDropdownOpen} setIsOpen={setIsDropdownOpen} user={user} setUser={setUser}/>
            
            </div>
          </OutsideClickHandler>
          </>
          :<button className='login-btn alt' onClick={() => navigate('/login')}>Connexion</button>  
      
        }
       
        </div>
      </div>
    </nav>
  )
}

export default NavbarAdmin