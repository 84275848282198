import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import ContractDetailsModal from "../../components/ContractDetailsModal/ContractDetailsModal";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { loadingState } from "../../recoil/loadingAtom";
import Principale from "../../utils/services/Principale";
import Erreurs from "../../components/Erreurs/Erreurs";
import moment from "moment";
import { FirstUpperCase } from "../../utils/services/helpers";

const ContractsList = () => {
  const [isContractDetailsModalOpen, setIsContractDetailsModalOpen] =
    useState(false);
  
  const [contratEmp,setContratEmp] = useState([]);
  const[isLoading,setIsLoading] = useRecoilState(loadingState);
  const [erreurs,setErreurs] = useState([]);
  const api = new Principale();  
  const url = process.env.REACT_APP_URL + 'storage/';
  const {pathname} = useLocation();
  const[emploieCandidat,setEmploieCandidat] = useState(null);
  const[selectedRow,setSelectedRow] = useState(null);
  const [status,setStatus] = useState(null);
  const navigate = useNavigate();
  const {state} = useLocation();
  const newEmploie = (emploie_id) => {
    navigate(`/admin/recrutement/${emploie_id}`);
  }
  const Columns = [
    {
      field: "ordre",
      headerName: "N°Ord.",
      flex: 1,
      minWidth: 60,
    },
    {
      field: "photo",
      headerName: "photo",
      flex: 1,
      minWidth: 80,
      renderCell: (params) => {
        return(
           <img src={params.row.photo ? url + params.row.photo:''} style={{width:'45px',borderRadius:'100%'}}/>)
      }
    },
    {
      field: "nomCandidat",
      headerName: "Prenom & Nom Cand.",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "metier",
      headerName: "Metier",
      flex: 1,
      minWidth: 130,
    },
    {
      field: "typeemp",
      headerName: "Type Emp.",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "employeur",
      headerName: "Employeur",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "dateDemande",
      headerName: "Date Demande",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "date_debut",
      headerName: "D.Debut",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "date_fin",
      headerName: "D.Fin",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "StatusEmploie",
      headerName: "Status",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        
        return (
          <div className="options">
           {pathname === '/contracts/list/termines' &&
            <button
              onClick={() => {
                setIsContractDetailsModalOpen(true);
                setSelectedRow(params.row);
              }}
            >Details
            </button>}
            
           {(params.row.StatusEmploie === 'En attente' || params.row.StatusEmploie === 'Contrat en attente') &&
             <button onClick={() => {newEmploie(params.row.id)}}
            >Traitez</button>}
            {(params.row.StatusEmploie === 'En cours' || params.row.StatusEmploie === 'Terminé')&& 
               <button onClick={() => window.open(`${url}${params.row.contrat_copie.replace('public/','')}`)}>Voir contrat</button>
            }
          </div>
        );
      },
    },
  ]
  const getContratEmp = async () => {
    setIsLoading(true);setErreurs([]);
      const{status,messages,candidatEmps} = await api.get('/admin/employeur/contract');
     // console.log(candidatEmps)
    setIsLoading(false); 
    if(status === 200){
      let i = 0;
      var emploies = [];
      if(pathname === '/contracts/list/termines')
        emploies = candidatEmps.filter((c) => c.StatusEmploie === 'Terminé');
      else {
        
           emploies = candidatEmps.filter((c) => c.StatusEmploie === 'En attente' || c.StatusEmploie === 'Contrat en attente' || c.StatusEmploie === 'En cours' || c.StatusEmploie === 'Terminé');
           setEmploieCandidat(emploies);
         }
         
       
        
      //console.log(emploies)
      if(state?.status)
        setContratEmp(emploies.filter((c) => c.StatusEmploie === state?.status).map((contrat) => {
        let employeur = '';i++;
        if(contrat.typeemp === "societe")
          employeur = contrat.societe;
        else employeur = contrat.nomEmployeur;   
        return{
          ordre:i,
          id:contrat.id,
          employeur:employeur,
          photo:contrat.photoCandidat?contrat.photoCandidat.replace('public/',''):'',
          nomCandidat:contrat.prenom + " " +contrat.nom,
          metier:contrat.metier,
          dateDemande:moment(contrat.updated_at).format('Do MMMM YYYY'),
          date_debut:contrat.date_debut ? moment(contrat.date_debut).format('Do MMMM YYYY'):'Non Fournie',
          date_fin:contrat.date_fin ? moment(contrat.date_fin).format('Do MMMM YYYY'):'Non Fournie',
          StatusEmploie:contrat.StatusEmploie,
          typeemp:FirstUpperCase(contrat.typeemp),
          contrat_type_propose:contrat.contrat_type_propose,
          contrat_type_valide:contrat.contrat_type_valide,
          PhotoEmployeur:contrat.PhotoEmployeur,
          contrat_copie:contrat.contrat_copie,
          employeur_id:contrat.employeur_id,
          candidat_id:contrat.candidat_id,
          jours_valides:contrat.jours_valides,
          description:contrat.description,
          lieuexecution:contrat.lieuexecution,
          montantPrestation:contrat.montantPrestation,
        }
       }));
      else 
      setContratEmp(emploies.map((contrat) => {
        let employeur = '';i++;
        if(contrat.typeemp === "societe")
          employeur = contrat.societe;
        else employeur = contrat.nomEmployeur;   
        return{
          ordre:i,
          id:contrat.id,
          employeur:employeur,
          photo:contrat.photoCandidat?contrat.photoCandidat.replace('public/',''):'',
          nomCandidat:contrat.prenom + " " +contrat.nom,
          metier:contrat.metier,
          dateDemande:moment(contrat.updated_at).format('Do MMMM YYYY'),
          date_debut:contrat.date_debut ? moment(contrat.date_debut).format('Do MMMM YYYY'):'Non Fournie',
          date_fin:contrat.date_fin ? moment(contrat.date_fin).format('Do MMMM YYYY'):'Non Fournie',
          StatusEmploie:contrat.StatusEmploie,
          typeemp:FirstUpperCase(contrat.typeemp),
          contrat_type_propose:contrat.contrat_type_propose,
          contrat_type_valide:contrat.contrat_type_valide,
          PhotoEmployeur:contrat.PhotoEmployeur,
          contrat_copie:contrat.contrat_copie,
          employeur_id:contrat.employeur_id,
          candidat_id:contrat.candidat_id,
          jours_valides:contrat.jours_valides,
          description:contrat.description,
          lieuexecution:contrat.lieuexecution,
          montantPrestation:contrat.montantPrestation,
        }
       })); 
    }
    else setErreurs(messages);
   
  }
  const handleInput = (e) => {
    let i = 0;setIsLoading(true);
    setStatus(e.target.value);
    if(e.target.value === 'tous'){
      setContratEmp(emploieCandidat.map((contrat) => {
        let employeur = '';i++;
        if(contrat.typeemp === "societe")
          employeur = contrat.societe;
        else employeur = contrat.nomEmployeur;   
        return{
          ordre:i,
          id:contrat.id,
          employeur:employeur,
          photo:contrat.photoCandidat?contrat.photoCandidat.replace('public/',''):'',
          nomCandidat:contrat.prenom + " " +contrat.nom,
          metier:contrat.metier,
          dateDemande:moment(contrat.updated_at).format('Do MMMM YYYY'),
          date_debut:contrat.date_debut ? moment(contrat.date_debut).format('Do MMMM YYYY'):'Non Fournie',
          date_fin:contrat.date_fin ? moment(contrat.date_fin).format('Do MMMM YYYY'):'Non Fournie',
          StatusEmploie:contrat.StatusEmploie,
          typeemp:FirstUpperCase(contrat.typeemp),
          contrat_type_propose:contrat.contrat_type_propose,
          contrat_type_valide:contrat.contrat_type_valide,
          PhotoEmployeur:contrat.PhotoEmployeur,
          contrat_copie:contrat.contrat_copie,
          employeur_id:contrat.employeur_id,
          candidat_id:contrat.candidat_id,
          jours_valides:contrat.jours_valides,
          description:contrat.description,
          lieuexecution:contrat.lieuexecution,
          montantPrestation:contrat.montantPrestation,
        }
      }));
      setIsLoading(false);
    }
    else {
      setIsLoading(true);
      setContratEmp(emploieCandidat.filter((c) => c.StatusEmploie === e.target.value).map((contrat) => {
        let employeur = '';i++;
        if(contrat.typeemp === "societe")
          employeur = contrat.societe;
        else employeur = contrat.nomEmployeur;   
        return{
          ordre:i,
          id:contrat.id,
          employeur:employeur,
          photo:contrat.photoCandidat?contrat.photoCandidat.replace('public/',''):'',
          nomCandidat:contrat.prenom + " " +contrat.nom,
          metier:contrat.metier,
          dateDemande:moment(contrat.updated_at).format('Do MMMM YYYY'),
          date_debut:contrat.date_debut ? moment(contrat.date_debut).format('Do MMMM YYYY'):'Non Fournie',
          date_fin:contrat.date_fin ? moment(contrat.date_fin).format('Do MMMM YYYY'):'Non Fournie',
          StatusEmploie:contrat.StatusEmploie,
          typeemp:FirstUpperCase(contrat.typeemp),
          contrat_type_propose:contrat.contrat_type_propose,
          contrat_type_valide:contrat.contrat_type_valide,
          PhotoEmployeur:contrat.PhotoEmployeur,
          contrat_copie:contrat.contrat_copie,
          employeur_id:contrat.employeur_id,
          candidat_id:contrat.candidat_id,
          jours_valides:contrat.jours_valides,
          description:contrat.description,
          lieuexecution:contrat.lieuexecution,
          montantPrestation:contrat.montantPrestation,
        }
      }));
      setIsLoading(false);
    }
  }
  useEffect(() => {
    getContratEmp();
  },[]);
  //console.log(selectedRow)
  // console.log(contratEmp)
  return (
    <div className="contracts-list page">
      <Erreurs validation = {erreurs} />
      <ContractDetailsModal
        isOpen={isContractDetailsModalOpen}
        setIsOpen={setIsContractDetailsModalOpen}
        selectedRow={selectedRow}
      />
      <div className="header">
        <h3>Liste des Candidats</h3>
         <label><p>{contratEmp.length} Candidats</p></label>
      
          <div className="input-group">
           
            <label>
              <div>Status </div> 
              <select name="status"  style={{display:'inline'}} onChange={handleInput} value={!status?state?.status:status}>
                 <option value="tous">Tous</option>
                 <option value="En attente">En attente</option>
                 <option value="Contrat en attente">Contrat en attente de Validation</option>
                 <option value="En cours">En cours</option>
                 <option value="Terminé">Expiré</option>
              </select>
               
           </label>
           <div></div>
           <div></div>
        </div>
        
      </div>
      <div className="array">
        <Box sx={{ height: 579, width: "100%" }}>
          <DataGrid
            sx={{ borderRadius: 0 }}
            components={{ Toolbar: GridToolbar }}
            rows={contratEmp}
            columns={Columns}
            autoPageSize
            disableSelectionOnClick
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          />
        </Box>
      </div>
    </div>
  );
};

export default ContractsList;
