import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil';
import Erreurs from '../../components/Erreurs/Erreurs';
import { loadingState } from '../../recoil/loadingAtom';
import { objecttoFormData } from '../../utils/services/helpers';
import Principale from '../../utils/services/Principale';

function UpdatePassword() {
  const { id } = useParams();
  const [isLoading,setIsLoading] = useRecoilState(loadingState);
  const [erreurs,setErreurs] = useState([]);
  const [user,setUser] = useState({
    password:'',
    confirmMdp:'',
    token:id,
  });
  const api = new Principale();
  const {register,handleSubmit,reset,formState: { errors },} = useForm({defaultValues: {user}});
  const navigate = useNavigate();
  const handleInput = (e) => {
    setUser({...user,[e.target.name]:e.target.value});
  }
  const getValideUrl = async (id) =>{
    const {status} = await api.get(`/valideUrl/${id}`);
    if(status !== 200){
        toast.error("Mauvais lien");
        navigate('/');
    }  
  }

  useEffect(() =>{
    getValideUrl(id);
  },[id])
  const Valider = async () =>{
    var formData = objecttoFormData(user);
    setErreurs([]);setIsLoading(true);
    const{status,messages} = await api.post('/updatePassword',formData);
    setIsLoading(false);
    if(status === 200){
        toast.success('Mot de passe modifié avec succès.');
        navigate('/');
    }
    else {
        toast.error("Erreur dans la saisie du mot de passe.");
        setErreurs(messages); 
    }
  }
  return (
    <div className="login-page page">
        <div className="header">
            <h2>Changement de mot de passe</h2>
            <p className="subtext">Entrée votre mot de passe et confirmer-le.</p>
        </div>
        <form  onSubmit={handleSubmit(Valider)}>
            <label>Password
                <input type="password" name="password" id="password" placeholder="Nouveau Mot de passe"
                {...register("password", {
                    onChange: (e) => {
                    handleInput(e);
                    },
                    required: true,  minLength: 5,
                })}
                value={user.password}
                autoFocus
                />
                {errors.password && errors.password?.type === "required" && (
                <span className="error-msg">Ce champ est obligatoire.</span>
                )}
                {errors.password && errors.password?.type === "minLength" && (
                <span className="error-msg">Le Caractère minimum est cinq (5).</span>
                )}
            </label>
            <label>Confirmez le mot de passe
                <input type="password" name="confirmMdp" id="confirmMdp" placeholder="Confirmer le mot de passe"
                {...register("confirmMdp", {
                    onChange: (e) => {
                    handleInput(e);
                    },
                    validate: (value) => value === user.password,
                })}
                value={user.confirmMdp}
                
                />
                {errors.confirmMdp && (
                 <span className="error-msg">Les deux mot de passe doivent être identiques.</span>
                )}
            </label>
            <Erreurs validation = {erreurs} />
            <button type='submit' disabled={isLoading}>Valider</button>
        </form>
    </div>
  )
}

export default UpdatePassword