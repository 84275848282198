import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import swal from 'sweetalert';
import Erreurs from '../../components/Erreurs/Erreurs';
import { loadingState } from '../../recoil/loadingAtom';
import { UserContext } from '../../utils/Context/Context'
import { objecttoFormData } from '../../utils/services/helpers';
import Principale from '../../utils/services/Principale';

const AccountSettings = () => {
  const {user,setUser} = useContext(UserContext);
  const [isLoading,setIsLoading] = useRecoilState(loadingState);
  const [erreurs,setErreurs] = useState([]);
  const[candidat,setCandidat] = useState({
    user_id:user?.user_id,
    role_id:user?.role_id,
    oldpassword:'',
    password:'',
    confirmpassword:'',
  });
  const {register,handleSubmit,reset,setError,formState: { errors },} = useForm({defaultValues: {candidat,},}); 
  const api = new Principale();
  const navigate = useNavigate();
  //console.log(candidat)
  const handleInput = (e) => {
     if(erreurs.length > 0) setErreurs([]);
     setCandidat({...candidat,[e.target.name]:e.target.value});
  }
  const chanpassword = async () => {
     var formData = objecttoFormData(candidat);setIsLoading(true);setErreurs([]);
     const {status,messages} = await api.post('/user/changepassword',formData);
     setIsLoading(false);
     if(status === 200){
       toast.success("Modification de mot effectué avec succès."); 
       api.logout();setUser('');navigate('/login');
     }else setErreurs(messages);  
  }
  const deleteaccount = () => {
    swal({
        title: "êtes-vous sûr?",
        text: "Voulez-vous supprimer votre compte sur notre plateforme.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          var formData = objecttoFormData(candidat);  
          setIsLoading(true); setErreurs([]);
          api.post('/user/deleteaccount',formData).then((resp) => {
           setIsLoading(false);
            if(resp.status === 200){
                toast.success("Compte supprimé avec succès.");
                api.logout();setUser('');navigate('/login');
            }else {
                setErreurs(resp?.messages);
                toast.error("Compte non supprimé.");
                window.scrollTo({ top: -20, left: 0, behavior: 'smooth' });
            }  
          })
        } 
      });
  }
  return (
    <div className='account-settings page'>
        <h1>Paramètres du compte</h1>
        <p className='question-phrase'>Voulez-vous modifier votre compte?</p>
        <form onSubmit={handleSubmit(chanpassword)}>
            <label>Mot de passe actuel
                <input type="password" name="oldpassword" id="oldpassword" 
                 value={candidat.oldpassword}
                 {...register("oldpassword", {
                   onChange: (e) => {
                     handleInput(e);
                   },
                   required: true,
                   minLength: 5,
                })}
                />
                {errors.oldpassword && errors.oldpassword?.type === "required" && (
                    <span className="error-msg">Ce champ est obligatoire.</span>
                )}
                {errors.oldpassword && errors.oldpassword?.type === "minLength" && (
                <span className="error-msg">
                    Le mot de passe ne peut être inférieur à cinq (5) caractères.
                </span>
                )}
            </label>
            <label>Nouveau mot de passe
                <input type="password" name="password" id="password"
                  value={candidat.password}
                   {...register("password", {
                    onChange: (e) => {
                      handleInput(e);
                    },
                    required: true,
                    minLength: 5,
                 })}
                />
                 {errors.password && errors.password?.type === "required" && (
                    <span className="error-msg">Ce champ est obligatoire.</span>
                )}
                {errors.password && errors.password?.type === "minLength" && (
                <span className="error-msg">
                    Le mot de passe ne peut être inférieur à cinq (5) caractères.
                </span>
                )}
            </label>
            <label>Confirmez mot de passe
                <input type="password" name="confirmpassword" id="confirmpassword" 
                 value={candidat.confirmpassword}
                 {...register("confirmpassword", {
                    onChange: (e) => {
                      handleInput(e);
                    },
                    required: true,
                    validate: (value) => value === candidat.password
                 })}
                />
               
                {errors.confirmpassword && (
                  <span className="error-msg">Les deux mot de passe doivent être identiques.</span>
               )}
            </label>
            <Erreurs validation = {erreurs} />
            <button className='big accent'>Enregistrer</button>
        </form>
        <div className="delete-account">
            <h4>Voulez-vous supprimer votre compte ?</h4>
            <p className='link' onClick={deleteaccount} style={{cursor:'pointer'}}>Supprimer mon compte</p>
        </div>
    </div>
  )
}

export default AccountSettings