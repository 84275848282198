import moment from "moment";
import "moment/locale/fr";

const ContractCard = ({ company_image, company_name, company_address, contract_type, start_date, end_date, status,contrat_copie }) => {

  const statusColor =
    status === "Terminé"
      ? "green"
      : status === "Refusé"
      ? "red"
      : status === "En cours"
      ? "blue"
      : "black";
  const voir = (contrat_copie) => {
    window.open(contrat_copie);
  };

  return (
    <div className="contract-card">
      <div className="contract-card-left">
        <div className="image">
          <img src={company_image} alt="" />
        </div>
      </div>
      <div className="contract-card-right">
        <div className="top">
          <p className="company_name"> {company_name} </p>
          {company_address && <p className="company_address"> {company_address} </p>}
          <p className="contract_type"> {contract_type} </p>
         {(end_date && start_date) && <p className="date">
            Du {moment(start_date).format('Do MMMM YYYY')} au {moment(end_date).format('Do MMMM YYYY') + " soit " 
            +moment(end_date).diff(start_date,'months')+" mois restant(s)"}
             
          </p>}
          <p className="status">
            Status : <strong className={statusColor}> {status} </strong>
          </p>
        </div>
        {contrat_copie && 
        <div className="bottom">
          <button className="primary" onClick={() => voir(contrat_copie)}>Voir contrat</button>
        </div>}
      </div>
    </div>
  );
};

export default ContractCard;
