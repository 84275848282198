import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineUpload } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { loadingState } from "../../recoil/loadingAtom";
import { FirstUpperCase, objecttoFormData, validateDate } from "../../utils/services/helpers";
import Principale from "../../utils/services/Principale";
import profilephoto from '../../assets/images/avatar.png';
import toast from "react-hot-toast";
import Erreurs from "../../components/Erreurs/Erreurs";
const EditProfilePro = () => {
  const[candidatInfo,setCandidatInfo] = useState();
  const [preloadData,setPreloadData]= useState([]);
  const [isShow,setIsShow] = useState(false);
  const[candidatEdit,setCandidatEdit] = useState({
    civilite:'Monsieur',
    id:'',
    prenom:'',
    nom :'',  
    birthday:'',
    telephone:'',
    prefecture_id:0,
    commune_id:0,
    quartier_id:0,
    nationalite_id: 94,
    profession:'',
    metier_id:0,
    experience:0,
    email:'',
    password:'',
    confirmMdp:'',
    doctype: 0,
    num_rc_file:'',
    numpiece:'',
    prenomnomref:[],
    postref:[],
    telref:[],
    cv_file:'',
    niveau_etude:'',
    diplome_file:'',
    age:function (){
       return false
    },
    photoProfile:'',
   });
   const navigate = useNavigate();
  const[selectedCommunes,setSelectedCommunes] = useState([]);
  const[selectedQuartiers,setSelectedQuartiers] = useState([]);
  const[metiers,setMetiers] = useState([]);
  const url = process.env.REACT_APP_URL + "storage/";
  const getPreloadData = async () => {
    const{status,messages,data} = await api.get('/getPreloadData');
    setPreloadData([]);setErreurs([]);

    if(status === 200)
      setPreloadData(data)
    else setErreurs(messages);  

  }
  const {state} = useLocation();
  const api = new Principale();
  const[erreurs,setErreurs] = useState([]);
  const [isLoading,setIsLoading] = useRecoilState(loadingState);
  const [previews,setPreviews] = useState({profilePhoto:''});
  const {register,handleSubmit,reset,setError,formState: { errors },} = useForm({defaultValues: {candidatEdit,},}); 

  const getCandidat = async (candidat_id) => {
     setErreurs([]);
    
   
     const{status,candidat,messages} = await api.get(`/client/details/${candidat_id}`);
     if(status === 200){
      if(candidat.length > 0){
         const cand = candidat[0];  
         setCandidatEdit({...candidatEdit,
          candidat_id:cand.candidat_id,
          id:cand.user_id,
          civilite:cand.sexe === "M" ?"Masculin":"Feminin",
          prenom:cand.prenomCandidat,
          nom:cand.nomCandidat,
          nationalite_id:cand.nationalite_id,
          birthday:cand.datenaissance,
          telephone:cand.telephone,
          prefecture_id:cand.prefecture_id,
          commune_id:cand.commune_id,
          quartier_id:cand.quartier_id,
          profession:cand.profession,
          metier_id:cand.metier_id,
          experience:cand.experience, 
          photoProfile:cand.photoProfile,
          doctype:cand.typepiece,
          numpiece:cand.numero_piece,
          niveau_etude:cand.niveau_etude,
          num_rc_file:cand.capture_piece,
          diplome_file:cand.copie_nve,
          cv_file:cand.capture_cv,
          doctype:cand.typepiece,
          age:function(){
            if(cand.datenaissance.length > 0 ){
                var today = new Date();
                var birthDate = new Date(cand.datenaissance);
                var age = today.getFullYear() - birthDate.getFullYear();
                var m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
                {
                    age--;
                }
            return age;  
         }
         return 0;
        }
         });
        setIsShow(!isShow);
       }
    
      }
      else setErreurs(messages);
  
  }
  //les hanlers
  const handleInput = (e) => {
    setErreurs([]);
  if(e.target.name === 'prefecture_id'){
       setSelectedCommunes([]);setSelectedQuartiers([]);
      setCandidatEdit({...candidatEdit,[e.target.name]:e.target.value,commune_id:0,quartier_id:0});
      if(preloadData.communes)
      setSelectedCommunes(preloadData.communes.filter((c) => c.prefecture_id === parseInt(e.target.value)).map((commune) => (commune)))
  }else if(e.target.name === 'commune_id'){
    setSelectedQuartiers([]);setCandidatEdit({...candidatEdit,[e.target.name]:e.target.value,quartier_id:0});
      if(preloadData.quartiers)
      setSelectedQuartiers(preloadData.quartiers.filter((q) => q.commune_id === parseInt(e.target.value)).map((quartier) => (quartier)));
  }else if(e.target.name === 'birthday'){
      setCandidatEdit({...candidatEdit,[e.target.name]:e.target.value,age:function(){
          if(e.target.value.length > 0 ){
              var today = new Date();
              var birthDate = new Date(e.target.value);
              var age = today.getFullYear() - birthDate.getFullYear();
              var m = today.getMonth() - birthDate.getMonth();
              if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
              {
                  age--;
              }
          return age;  
       }
       return 0;
      }});  
  }else if(e.target.name === "num_rc_file" || e.target.name === "photoProfile" || e.target.name === 'cv_file' || e.target.name === 'diplome_file'){
    setCandidatEdit({...candidatEdit,[e.target.name]:e.target.files[0]});
    if(e.target.name === "photoProfile")
      setPreviews({...previews,profilePhoto:URL.createObjectURL(e.target.files[0])});
  }
  else if(e.target.name === 'numpiece')
    setCandidatEdit({...candidatEdit,[e.target.name]:e.target.value.toUpperCase()});
  else if(e.target.name === 'niveau_etude'){
     if(e.target.value === '' || e.target.value === "Pas de diplôme(N.A)"){
      setCandidatEdit({...candidatEdit,[e.target.name]:e.target.value,copie_nve:''});
     }
     else{
      setCandidatEdit({...candidatEdit,[e.target.name]:e.target.value})
     }
  }
  else if(e.target.name === 'telephone'){
     const re = /^[0-9\b]+$/;
     if(e.target.value.length <= 9 && re.test(e.target.value))
     setCandidatEdit({...candidatEdit,[e.target.name]:e.target.value});
  }
  else if(e.target.name === 'email' || e.target.name === 'experience'){
    setCandidatEdit({...candidatEdit,[e.target.name]:e.target.value});
  } 

  else
  setCandidatEdit({...candidatEdit,[e.target.name]:FirstUpperCase(e.target.value)});
  
   }
  const hanleReference = (index,e) => {
    //setError({...errors,});
    if(e.target.name === 'prenomnomref'){
      var prenomnomref = candidatEdit.prenomnomref;prenomnomref[index] = FirstUpperCase(e.target.value);
      setCandidatEdit({...candidatEdit,[e.target.name]:prenomnomref});
    }
    else if(e.target.name === 'postref'){
      var postref = candidatEdit.postref;postref[index] = FirstUpperCase(e.target.value); 
      setCandidatEdit({...candidatEdit,[e.target.name]:postref});
    }
    else if(e.target.name === 'telref'){
      const re = /^[0-9\b]+$/;
     if(e.target.value.length <= 9 && re.test(e.target.value) ){
       var telref = candidatEdit.telref;telref[index] = FirstUpperCase(e.target.value); 
       setCandidatEdit({...candidatEdit,[e.target.name]:telref});
     }
   }
  
  }
  //submit update du candidat
 
  const updateCandidat = async () => {
    let is_error = false;
    if(candidatEdit.photoProfile === '' || candidatEdit.photoProfile === null){
      setError("photoProfile", {
        type: "manual",
        message: "La photo de profile est obligatoire."
      });
     is_error = true;
   }
  
   if(candidatEdit.niveau_etude !== "Pas de diplôme(N.A)"){
     if(candidatEdit.diplome_file === null || candidatEdit.diplome_file === ''){
     
        setError("diplome_file", {
          type: "focus",
          message: "La copie de diplôme est obligatoire."
        });
       is_error = true;
     }
   }
   if(is_error) return ;
   var formData = objecttoFormData(candidatEdit);
   setErreurs([]);setIsLoading(true);
    const {status,messages} = await api.post('/client/candidat/update',formData);
    setIsLoading(false);
    if(status === 200){
       toast.success("Modification effectuée avec succès");navigate('/pro/dashboard');
    }else setErreurs(messages);

  }
  //ouvrir les documents
  const ouvrirdocument = (document) => {
     if(document === "piece")
       window.open(`${url}${candidatEdit.num_rc_file.replace('public/','')}`);
     else if(document === 'diplome'){
      window.open(`${url}${candidatEdit.diplome_file.replace('public/','')}`); }
     else if(document === 'cv'){
       if(candidatEdit.cv_file !== "" && candidatEdit.cv_file !== null)
         window.open(`${url}${candidatEdit.cv_file.replace('public/','')}`);
      }   
  }
  // console.log(candidatEdit.num_rc_file)
  //les states
  useEffect(() => {
    if(state?.candidat_id){
      setIsLoading(true);
        getCandidat(state.candidat_id);
        getPreloadData();
      
     setIsLoading(false);
    }else navigate('/pro/dashboard')
  },[state?.candidat_id]);

  useMemo(() => {
    if( candidatEdit?.prefecture_id !== 0){
        reset(candidatEdit); 
        setSelectedCommunes(preloadData?.communes?preloadData?.communes.filter((c) => c.prefecture_id === parseInt(candidatEdit?.prefecture_id)):[]);
        setSelectedQuartiers(preloadData?.quartiers?preloadData.quartiers.filter((q) => q.commune_id === parseInt(candidatEdit?.commune_id)):[]);
        setMetiers(preloadData?.metiers);
        api.get(`/client/candidat/referents/${candidatEdit?.candidat_id}`).then((resp) => {
            if(resp.status === 200){
              const prenomref = Array();
              const postref = Array();
              const telref = Array();
              if(resp.referents.length > 0){
                for (var i = 0; i < resp.referents.length; i++) {
                  prenomref.push(resp.referents[i].prenometnom)
                  postref.push(resp.referents[i].post);
                  telref.push(resp.referents[i].telephone)
                }
                setCandidatEdit({...candidatEdit,prenomnomref:prenomref,postref:postref,telref:telref})
              }
            }
        });
    }
    
  },[preloadData,isShow]);
  
  //console.log(candidatEdit.diplome_file)
  // console.log(candidatEdit);
  return (
    <div className="edit-profile-pro page">
      <h1>Modifier mon profil</h1>
      {candidatEdit?.candidat_id &&
        <form className="content" onSubmit={handleSubmit(updateCandidat)}>
        <div className="left">
          <div className="input-group file-input photo-input">
            <div className="group">
              <span>Photo</span>
              <div className="photo">
             
               {(candidatEdit?.photoProfile && typeof candidatEdit?.photoProfile === 'string') && <img
                   src={url + candidatEdit?.photoProfile.replace('public/',"")}
                  alt=""/>

                }
                {(candidatEdit?.photoProfile && typeof candidatEdit?.photoProfile === 'object' && 
                    <img src={previews?.profilePhoto ?previews?.profilePhoto:profilephoto} alt="" />
                )}
                
              </div>
              <label className="file-input-label">
                <AiOutlineUpload />
                Importer une photo
                <input type="file" name="photoProfile" accept="image/*" onChange={handleInput} 
                />
                {erreurs?.photoProfile &&  <span className="error-msg">{erreurs.photoProfile.message}</span>}
              </label>
            </div>
          </div>
        </div>
        <div className="right">
          <h4>Informations personnelles</h4>
          <div className="divider">
            <div className="input-group">
              <label>
                Sexe
                <select name="civilite" id="civilite" value={candidatEdit?.civilite}
                 {...register("civilite", {
                  onChange: (e) => {
                    handleInput(e);
                  },
                  validate: (value) => value !== "",
                })}
         
                >
                  <option value="Masculin">Masculin</option>
                  <option value="Feminin">Feminin</option>
                  {/* <option value="Mademoiselle">Mademoiselle</option> */}
                </select>
              </label>
              <label>
                Prénom
                <input
                  type="text"
                  name="prenom"
                  id="prenom"
                  placeholder="Prénom"
                  value={candidatEdit?.prenom}
                  {...register("prenom", {
                    onChange: (e) => {
                      handleInput(e);
                    },
                    required: true,
                    minLength: 2,
                  })}
                />
                {errors.prenom && errors.prenom?.type === "required" && (
                  <span className="error-msg">Ce champ est obligatoire.</span>
                )}
                {errors.prenom && errors.prenom?.type === "minLength" && (
                <span className="error-msg">
                    ce champ ne peut pas être inférieur à deux caractères.
                </span>
                )}
              </label>
              <label>
                Nom
                <input type="text" name="nom" id="nom" placeholder="Nom" 
                  value={candidatEdit?.nom}
                  {...register("nom", {
                    onChange: (e) => {
                      handleInput(e);
                    },
                    required: true,
                    minLength: 2,
                  })}
                />
                {errors.nom && errors.nom?.type === "required" && (
                     <span className="error-msg">Ce champ est obligatoire.</span>
                   )}
                 {errors.nom && errors.nom?.type === "minLength" && (
                  <span className="error-msg">
                     ce champ ne peut pas être inférieur à deux caractères.
                  </span>
                )}
              </label>
            </div>
            <div className="input-group">
              <label>
                Nationalité
                <select name="nationalite_id" id="nationalite_id" value={candidatEdit?.nationalite_id}
                  {...register("nationalite_id", {
                    onChange: (e) => {
                        handleInput(e);
                    },
                    validate: (value) => parseInt(value) !== 0,
                    })}
                  >
                  <option value={0}>Nationalité</option>
                  {preloadData?.nationalites && preloadData.nationalites.map((nationalite) => (<option value={nationalite.nationalite_id} key={nationalite.nationalite_id}>{nationalite.nom}</option>))}
                </select>
                {errors.nationalite_id && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                 )}
              </label>
              <label>
                Date de naissance
                <input type="date" name="birthday" id="birthday" value={candidatEdit?.birthday}
                 {...register('birthday', {
                  onChange: (e) => {
                      handleInput(e)
                   },
                  validate: (value) => candidatEdit.age() > 18 && validateDate(value)},
                  )}
                />
                 {errors.birthday && (
                     <span className="error-msg"> La date de naissance choisie n'est pas valide.Elle doit être supérieur ou égale à 18 ans</span>
                 )} 
              </label>
              <label>
                Téléphone
                <input type="tel" name="telephone" id="tel" placeholder="Téléphone" value={candidatEdit?.telephone}
                {...register("telephone", {
                  onChange: (e) => {
                    handleInput(e);
                  },
                  required: true,
                  maxLength: 9,
                  minLength: 5,
                  pattern: /6[0-9]{8}$/g,
                })} 
                />
                 {errors.telephone && errors.telephone?.type === "required" && (
                        <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                    {errors.telephone && errors.telephone?.type === "minLength" && (
                        <span className="error-msg">
                        Ne peut pas être inférieur à 5 caractères.
                        </span>
                    )}
                    {errors.telephone && errors.telephone?.type === "maxLength" && (
                        <span className="error-msg">
                        Ne peut pas être supérieur à 9 caractères.
                        </span>
                    )}
                    {errors.telephone && errors.telephone.type === "pattern" && (
                        <span role="alert" className="error-msg">
                            Format invalide.ex: 620000000
                        </span>
                    )}
              </label>
            </div>
            <div className="input-group">
              <label>
                Préfecture/Commune
                <select name="prefecture_id" id="prefecture_id" value={candidatEdit?.prefecture_id}
                  {...register("prefecture_id", {
                    onChange: (e) => {
                      handleInput(e);
                    },
                    validate: (value) => parseInt(value) !== 0,
                  })}
                 >
                  <option value={0}>Selectionner la Préfecture/Commune</option>
                  {preloadData?.prefectures && preloadData.prefectures.map((prefecture) => (<option value={prefecture.prefecture_id} key={prefecture.prefecture_id}>{prefecture.nom}</option>))}
                </select>
                {errors.prefecture_id && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                )}
              </label>
              <label>
                Commune
                <select name="commune_id" id="commune_id" value={candidatEdit?.commune_id}
                  {...register("commune_id", {
                  onChange: (e) => {
                    handleInput(e);
                  },
                  validate: (value) => parseInt(value) !== 0,
                 })}
                >
                  <option value={0}>Selectionner la Commune</option>
                  {selectedCommunes && selectedCommunes.map((commune) => (<option value={commune.commune_id} key={commune.commune_id}>{commune.nom}</option>))}
                </select>
                {errors.commune_id && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
              </label>
              <label>
                Quartier
                <select name="quartier_id" id="quartier_id" value={candidatEdit?.quartier_id}
                 {...register("quartier_id", {
                  onChange: (e) => {
                    handleInput(e);
                  },
                  validate: (value) => parseInt(value) !== 0,
                  })}
                  >
                  <option value={0}>Selectionner le Quartier/District</option>
                  {selectedQuartiers && selectedQuartiers.map((quartier) => (<option value={quartier.quartier_id} key={quartier.quartier_id}>{quartier.nom}</option>))}
                </select>
                {errors.quartier_id && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
              </label>
            </div>
          </div>

          <div className="divider">
            <h4>Experience professionnelle</h4>
            <div className="input-group">
              <label>
                Profession
                <input
                  type="text"
                  name="profession"
                  id="profession"
                  placeholder="prefession"
                  value={candidatEdit?.profession?candidatEdit?.profession:''}
                  {...register("profession", {
                    onChange: (e) => {
                      handleInput(e);
                    },
                    required: true,
                    minLength: 2,
                  })}
                />
                {errors.profession && errors.profession?.type === "required" && (
                    <span className="error-msg">Ce champ est obligatoire.</span>
                  )}
                  {errors.profession && errors.profession?.type === "minLength" && (
                  <span className="error-msg">
                      ce champ ne peut pas être inférieur à deux caractères.
                  </span>
                  )}
              </label>
              <label>Parcours Academique
                <select name='niveau_etude' value={candidatEdit?.niveau_etude}
                    {...register("niveau_etude", {
                      onChange: (e) => {
                        handleInput(e);
                      },
                      validate: (value) => value !== "",
                      })}
                   >
                      <option value="">Selectionnez votre Parcours Academique</option>
                      <option value="Diplôme">Diplôme</option>
                      <option value="Certificat">Certificat</option>
                      <option value="Attestation">Attestation</option>
                      <option value="Pas de diplôme(N.A)">Pas de diplôme(N.A)</option>
                   </select>
                   {errors.niveau_etude && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
              </label>
              <label>
                Métier
                <select name="metier_id" id="metier_id" value={candidatEdit?.metier_id}
                 {...register("metier_id", {
                  onChange: (e) => {
                      handleInput(e);
                  },
                  validate: (value) => parseInt(value) !== 0,
                  })}
                >
                  <option value={0}>Métier Exercé</option>
                   {metiers && metiers.map((metier) => (<option value={metier.metier_id} key={metier.metier_id}>{metier.nom}</option>))}
                </select>
                {errors.metier_id && (
                   <span className="error-msg">Ce champ est obligatoire.</span>
                 )}
              </label>
              <label>
                Expérience
                <select name="experience" id="experience" value={candidatEdit?.experience}
                  {...register("experience", {
                    onChange: (e) => {
                        handleInput(e);
                    },
                    validate: (value) => parseInt(value) !== 0,
                    })}
                >
                  <option value={0}>Veuillez choisir votre expérience </option>
                  <option value="Moins d'un an">Moins d'un an </option>
                  <option value="Un An"> Un An </option>
                  <option value="Plus d'un An"> Plus d'un an </option>
                  <option value="Deux Ans"> Deux Ans </option>
                  <option value="Plus de deux Ans"> Plus de deux ans </option>
                  <option value="Trois Ans"> Trois Ans </option>
                  <option value="Plus de trois Ans"> Plus de trois ans</option>
                  <option value="Quatre Ans"> Quatre Ans</option>
                  <option value="Plus de quatre Ans">Plus de quatre ans</option>
                  <option value="Cinq Ans">Cinq Ans</option>
                  <option value="Plus de cinq Ans">Plus de cinq ans</option>
                </select>
                {errors.experience && (
                    <span className="error-msg">Ce champ est obligatoire.</span>
                 )}
              </label>
            </div>
            <div className="input-group">
              <label>Prénom et Nom du référent 1
                <input type="text" name="prenomnomref" id="ref1"
                 value={candidatEdit?.prenomnomref.length > 0 ?candidatEdit.prenomnomref[0]:'' } 
                  onChange={(e) => hanleReference(0,e)}
                 />
              </label>
              <label>Poste du référent
                <input type="text" name="postref" id="ref2"
                 value={candidatEdit?.postref.length > 0 ? candidatEdit.postref[0]:''}
                  onChange={(e) => hanleReference(0,e)}
                />
              </label>
              <label>Téléphone du référent
                <input type="text" name="telref" id="ref3" 
                   value={candidatEdit?.telref.length > 0 ? candidatEdit.telref[0]:''}
                   onChange={(e) => hanleReference(0,e)}
                />
              </label>
            </div>
          {((candidatEdit?.prenomnomref[0] !== '' && typeof candidatEdit?.prenomnomref[0] !== 'undefined') 
               && (candidatEdit?.postref[0] !== '' && typeof candidatEdit?.postref[0] !== 'undefined') 
               && (candidatEdit.telref[0] !== '' && typeof candidatEdit.telref[0]!== 'undefined'))&&
             <div className="input-group">
              <label>Prénom et Nom du référent 2
                <input type="text" name="prenomnomref" id="ref4" 
                 value={candidatEdit.prenomnomref.length > 1?candidatEdit.prenomnomref[1]:'' } 
                 onChange={(e) => hanleReference(1,e)}
                />
              </label>
              <label>Poste du référent
                <input type="text" name="postref" id="ref5" 
                    value={candidatEdit.postref.length > 1 ?candidatEdit.postref[1]:'' } 
                    onChange={(e) => hanleReference(1,e)}
                />
              </label>
              <label>Téléphone du référent
                <input type="text" name="telref" id="ref6" 
                   value={candidatEdit.telref.length > 1 ?candidatEdit.telref[1]:'' } 
                   onChange={(e) => hanleReference(1,e)}
                />
              </label>
             </div>}
          </div>
          <div className="divider">
            <h4>Documents</h4>
            <div className="input-group">
              <label>
                Type de document
                <select name="doctype" id="doctype" value={candidatEdit?.doctype}
                 {...register("doctype", {
                  onChange: (e) => {
                      handleInput(e);
                  },
                  validate: (value) => parseInt(value) !== 0,
                  })}
                >
                  <option value={0}>Selectionner le type de pièce</option>
                  <option value="Carte D'identité">Carte D'identité</option>
                  <option value="Passeport">Passeport</option>
                  <option value="Carte Scolaire">Carte Scolaire</option>
                </select>
                {errors.doctype && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
              </label>
              <label>
                Num de pièce d'identité
                <input
                  type="text"
                  name="numpiece"
                  id="numpiece"
                  placeholder="Numéro de la pièce"
                  value={candidatEdit?.numpiece}
                  {...register("numpiece", {
                    onChange: (e) => {
                      handleInput(e);
                    },
                    required: true,
                    minLength: 4,
                 })}
                />
                {errors.numpiece && errors.numpiece?.type === "required" && (
                    <span className="error-msg">Ce champ est obligatoire.</span>
                  )}
                  {errors.numpiece && errors.numpiece?.type === "minLength" && (
                  <span className="error-msg">
                      ce champ ne peut pas être inférieur à quatre(5) caractères.
                  </span>
                  )}
              </label>
            </div>

            <div className="input-group docs-input-group">
              <label className="file-input">
                Pièce d'identité
                <input type="file" name="num_rc_file" accept=".jpg,.jpeg,.png,.pdf" 
                {...register("num_rc_file", {
                  onChange: (e) => {
                    handleInput(e);
                  },
                  validate: (value) => (value !== "" && value.length !== 0) || candidatEdit.num_rc_file !== ''
               })}
                />
                 {((typeof candidatEdit.num_rc_file === 'string'  && candidatEdit.num_rc_file !== null && candidatEdit.num_rc_file !=='')) ? <span style={{display:'block',color:'green',cursor:"pointer"}} onClick={() => ouvrirdocument('piece')}><strong>Fichier Présent </strong></span>:<span><strong style={{color:'red',display:'block'}}>Fichier Absent </strong></span>} 
                 {(typeof candidatEdit.num_rc_file === 'object' && candidatEdit.num_rc_file !== null) && <span style={{display:'block'}}><strong>Fichier selectionné : </strong>{candidatEdit.num_rc_file?.name}</span>} 
                 {(typeof errors.num_rc_file !== 'undefined' && (candidatEdit.num_rc_file === null || candidatEdit.num_rc_file ===''))&& (
                  <span className="error-msg" style={{color:'red'}}>Veuillez joindre la copie de la piece d'identité.</span>
                 )}
              </label>
           
             
              
              <label className="file-input">
                Diplôme
                <input type="file" name="diplome_file" accept=".jpg,.jpeg,.png,.pdf"
                 {...register("diplome_file", {
                  onChange: (e) => {
                    handleInput(e);
                  },
                  validate: (value) => (value !== "" && value?.length !== 0) || candidatEdit.diplome_file !== "Pas de diplôme(N.A)"
               })}
                />
              {(typeof candidatEdit.diplome_file === 'string'  &&  candidatEdit.diplome_file !== null && candidatEdit.diplome_file !== '') &&<span style={{display:'block',color:'green',cursor:"pointer"}} onClick={() => ouvrirdocument('diplome')}><strong>Fichier Présent </strong></span>} 
                {(typeof erreurs.diplome_file === 'undefined' && (candidatEdit.diplome_file === null || candidatEdit.diplome_file === '')) ? <span><strong style={{color:'red',display:'block'}}>Fichier Absent </strong></span>:''}
                {(candidatEdit.diplome_file !== null && typeof candidatEdit.diplome_file === 'object') && <span><strong>Fichier selectionné : </strong>{candidatEdit.diplome_file?.name}</span>}  
                {(typeof errors.diplome_file !== 'undefined')&& (
                  <span className="error-msg" style={{color:'red'}}>Veuillez choisir la copie du niveau d'etude joint.</span>
              )}
              </label>

              
              <label className="file-input">
                CV
                <input type="file" name="cv_file" accept=".jpg,.jpeg,.png,.pdf" onChange={handleInput}/>
               {(candidatEdit.cv_file !== null && candidatEdit.cv_file !== '') ? <span style={{color:'green',display:'block',cursor:"pointer"}} onClick={() => ouvrirdocument('cv')}><strong>Fichier Présent </strong></span>:<span style={{color:'red',display:'block'}}><strong>Fichier Absent </strong></span>} 
               {(typeof candidatEdit.cv_file === 'object' && candidatEdit.cv_file !== null) && <span><strong>Fichier selectionné : </strong>{candidatEdit.cv_file?.name}</span>}
              </label>

            </div>

          
          </div>
          <Erreurs validation = {erreurs} />
          <div className="divider">
            <button className="accent big">Enregistrer</button>
          </div>
        </div>
        </form>
        }
    </div>
  );
};

export default EditProfilePro;
