import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useRecoilState } from "recoil";
import ContractCard from "../../components/ContractCard/ContractCard";
import Erreurs from "../../components/Erreurs/Erreurs";
import { loadingState } from "../../recoil/loadingAtom";
import { UserContext } from "../../utils/Context/Context";
import { fakeContracts } from "../../utils/fakeData";
import Principale from "../../utils/services/Principale";

const MyContracts = () => {
  const [contracts,setContracts] = useState([]);
  const{user} = useContext(UserContext);
  const [isLoading,setIsLoading] = useRecoilState(loadingState);
  const [erreurs,setErreurs] = useState([]);
  const[mycontractStat,setMycontractStat] = useState(null);
  const [totalContract,setTotalContract] = useState(0);
  const api = new Principale();
  const url = process.env.REACT_APP_URL + "storage/";
  const getContracts = async (employeur_id) => {
    setErreurs([]);setIsLoading(true);
     const{status,candidats,messages,mycontractStat} = await api.get(`/employeur/getEmployes/${employeur_id}`);
 
    setIsLoading(false);
    if(status === 200){
      setContracts(candidats.filter((c) => c.status.toUpperCase() === ('En cours').toUpperCase()));
      setMycontractStat(mycontractStat);
      let totalcnt = 0;
      if(mycontractStat.length > 0){
        for(let i = 0;i <mycontractStat.length;i++)
          totalcnt = totalcnt + mycontractStat[i].nombre;
       setTotalContract(totalcnt);   
      }
    }else setErreurs(messages);
  } 
  useEffect(() => {
   getContracts(user?.employeur_id);
  },[user?.employeur_id]);

  //console.log(user);
  return (
    <div className="my-contracts-page page">
      {contracts?.length > 0 ?<h1>Mes contrats en cours</h1>:<h1>Pas de contrat en cours</h1>}
      <Erreurs validation = {erreurs} />
      <main>
        <div className="contracts">
          <div className="contracts-list">
            {contracts.map((contract) => (
              <ContractCard
                key={contract.id}
                company_image={url + contract.photo.replace('public/','')}
                company_name={contract.prenom+" "+contract.nom}
                // company_address={contract.company_address}
                contract_type={contract.contrat_type_propose.charAt(0).toUpperCase() + contract.contrat_type_propose.slice(1)}
                // start_date={contract.start_date}
                // end_date={contract.end_date}
                status={contract.status}
                contrat_copie={contract?.contrat_copie ? url + contract.contrat_copie.replace('public/',''):''}
              />
            ))}
          </div>
        </div>
        <div className="stats">
            <h4>Statistiques</h4>
           
              <p key={10}>
                  Nombre total de contrat : <strong>{totalContract}</strong>
               </p>
            {mycontractStat && mycontractStat.map((contract,index) => {        
               if(contract.status === 'En cours')
                 return (<p key={index}>
                        En cours : <strong>{contract.nombre}</strong>
                      </p>);
               else if(contract.status === 'En attente')    
                return (<p key={index}>
                  En attente : <strong>{contract.nombre}</strong>
                  </p>);   
               else if(contract.status === 'Terminé') 
                return (<p key={index}>
                  Terminé : <strong>{contract.nombre}</strong>
                  </p>); 
              else if(contract.status === "Refusé") 
                return (<p key={index}>
                  Refusé : <strong>{contract.nombre}</strong>
                  </p>);         
           
            })}
         
        </div>
      </main>
    </div>
  );
};

export default MyContracts;
