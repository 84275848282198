import { FiMapPin } from "react-icons/fi";
import { isEmpty } from "../../utils/services/helpers";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../utils/Context/Context";

const ProfileCard = ({ profileData }) => {
  const navigate = useNavigate();
  const url = process.env.REACT_APP_URL + "storage/";
  const{user} = useContext(UserContext);
  const edit = () => {
      if(user?.role_id === 4){
         navigate('/editprofile/pro' ,{ state: { candidat_id: profileData.candidat_id } });
      }
      else if(user?.role_id === 3)
          navigate(`/editprofile/recruiter`,{state:{ employeur_id:user?.employeur_id}});
        //implementation non effectuée
  }

  return (
    <div className="profile-card">
      {(!isEmpty(profileData) && profileData?.role_id === 4) &&
        <>
        <div className="top">
         {user.role_id !== 3 &&
          <div className="image">
            <img src={url + profileData.photoProfile.replace('public/',"")} alt="" />
          </div>
         }
        <div className="infos">
          <p className="name">{profileData.prenomCandidat + " " + profileData.nomCandidat }<span style={{fontWeight:'normal'}}> {"(" +profileData.age +" ans)"}</span></p>
          <p className="job">{profileData.prefecture}</p>
          <div className="badges">
            <div className="address">
              <div className="icon">
                <FiMapPin size={10} />
              </div>
              <span>{profileData.commune} </span>
            </div>
            <div className="experience-badge">{profileData.experience} d'exp.</div>
          </div>
        </div>
        </div>
        <div className="bottom">
          <button className="primary" >Voir profil</button>
          <button className="alt" onClick={edit} >Modifier profil</button>
        </div>
        </>
      }
      {(!isEmpty(profileData) && profileData?.role_id === 3) &&
         <>
          <div className="top">
            <div className="image">
              <img src={ profileData.logo && url + profileData.logo.replace('public/',"")} alt="" />
            </div>
            <div className="infos">
               {profileData.type === 'societe' && <p className="name"><span> {profileData?.societe}</span><span style={{fontWeight:'normal'}}> ({profileData?.type}) </span></p>}
               {profileData.type === 'personne physique' && <p className="name"><span> {profileData?.prenomemployeur?profileData?.prenomemployeur:'' + " "+ profileData?.nomemployeur?profileData?.nomemployeur:''}</span><span style={{fontWeight:'normal'}}> ({profileData?.type}) </span></p>}
               <p className="job">{profileData?.secteur}</p>
             <div className="badges">
               <div className="address">
                 <div className="icon">
                   <FiMapPin size={10} />
                 </div>
                  <span>{profileData?.prefecture + "/" +profileData?.commune+"/"+profileData?.quartier}</span>
                </div>
               <div className="experience-badge"></div>
             </div>
             <div className="details">
               <div className="email">
                  <span>{(profileData?.email !== 'null' && profileData?.email) ?profileData?.email:user.telephone}</span>
               </div>
               {profileData.type === 'personne physique' &&
                 <>
                   <div className="type">
                    <span>{profileData?.representant}</span>
                   </div>
                   <div className="service">
                      <span>{profileData?.service}</span>
                   </div>
                 </>   
                  }
               <div>

               </div>
             </div>
           </div>
          </div> 
          <div className="bottom">
            <button className="primary" onClick={() => navigate('/recruter/profile')}>Voir profil</button>
            <button className="alt" onClick={edit} >Modifier profil</button>
        </div>
         </>
       }
    </div>
  )
}

export default ProfileCard