import React, { useEffect, useState } from 'react';
import { ComposableMap, Geographies, Geography ,Marker,ZoomableGroup} from 'react-simple-maps';
import guinea from '../../assets/map/gadm41_GIN_2.json';
import coord from '../../assets/map/coordonnate.json';
import './map.css';
const GuineaMap = ({ data }) => {
    function getFillColor(prefecture) {
        if(typeof prefecture !== 'undefined'){
        const population = prefecture.population;
        if (population > 100 && population <=10000) {
          return '#092375';
        } else if (population > 10000 && population <=100000) {
          return '#1c853d';
        } else {
          return '#E18A4E';
        }
    }
    return '#525453'
   }
   const [tooltipContent, setTooltipContent] = useState("");
   const[colors,setColors] = useState([]);
   const [selectedColor,setSelectedColor] = useState([]);
   const fnsetColors = () =>{
    var rgb = [];var oldcolors = colors;
   for(var i=0; i <= 2;i++){ 
    rgb = [];
    for(var j = 0; j < 3; j++)
        rgb.push(Math.floor(Math.random() * 255));
    oldcolors.push(rgb);
    setColors(oldcolors);   
    }
   
   }

   useEffect(() => {
    fnsetColors();

    for(var i = 0;i <= 1;i++){
      var oldcolor = selectedColor;
      oldcolor.push(colors[Math.floor(Math.random()*colors.length)])
      setSelectedColor(oldcolor);
    }
   },[]);

   return (
     <ComposableMap
       projection="geoMercator"
       projectionConfig={{ scale: 2700, center: [-11.5, 10.5] }}
       width={420}
       height={320}
     >
     <ZoomableGroup>
     <Geographies geography={guinea}>
       
       {({ geographies }) =>
       
         geographies.map((geo,index) => {
           const prefectureData = data.find((d) => d.prefecture === geo.properties.NAME_2);
          //  console.log(`${geo.properties.NAME_1}:${geo.properties.NAME_2}`)
     
           return (
             <Geography
               key={geo.rsmKey}
               geography={geo}
               fill={getFillColor(prefectureData)}
               stroke="#969696"
               strokeWidth={1}
               onMouseEnter={() => {
                 if (prefectureData) {
                   setTooltipContent(`${prefectureData.prefecture}: ${prefectureData.population}`);
                 }
               }}
               onMouseLeave={() => {
                 setTooltipContent("");
               }}
               style={{
                                                  
                  hover: {
                    fill: selectedColor.length > 0 ? 'rgba('+ selectedColor[1].join(',') +',0.3)':"cyan",
                    outline: "none",
                    cursor:'pointer'
                  },
                  pressed: {
                    fill: "cyan",
                    outline: "none"
                  },
                  default: {
                      outline: 'none'
                  }
                  
                }}
             >
                 
            </Geography>
           );
         })
       }
               
     </Geographies>
     {guinea.features.map((geo,index) => {
   
        const coordonnate = coord.find((d) => d.city === geo.properties.NAME_2);
       if( coordonnate?.lng  && coordonnate?.lat){
          const prefectureData = data.find((d) => d.prefecture.toUpperCase() == geo.properties.NAME_2.toUpperCase());
        
          return(
          <Marker coordinates={[coordonnate?.lng, coordonnate?.lat]}  key={index}>
              {prefectureData &&
              <g
              fill="none"
              stroke={selectedColor.length > 0 ? 'rgb('+ selectedColor[0].join(',') +')':'#DDDDD'}
              strokeWidth="1"
              strokeLinecap="round"
              strokeLinejoin="round"
              transform="translate(-12, -24)"
           >
          <circle cx="12" cy="10" r="1" />
         
             {/* <path class="" style="background-color: rgba(0, 0, 0, .5);fill: rgba(0, 0, 0, .5);" d="M100 0 ,Q 0 0 0 100, L0 0, Z"></path> */}
             <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" style={{ fill:'rgba(0, 0, 0, .4)' }}
             ></path>
        
         
        </g>}
        
            <text textAnchor="middle" fill='#333233'  y={5} style={{ fontSize:'5px',fontWeight:'bold', }}>
              {prefectureData ? `${prefectureData.prefecture} : ${prefectureData.population}` : ""}
            </text>
            {/* {tooltipContent && (
             <text className="tooltip">{tooltipContent}</text>
             )}  */}
              
          </Marker>
          )
        }
      })
     
      }
     </ZoomableGroup>
      
       
     </ComposableMap>);
    
    // return (
    //     <ComposableMap projection="geoMercator" 
    //       projectionConfig={{ scale: 2600, center: [-11.5, 10.5] }}
    //       width={420}
    //       height={320}>
    //         <Geographies geography={guinea}>
    //         {({ geographies }) =>
    //             geographies.map(geo => (
    //             const prefectureData = (data.find(p => p.prefecture === geo.properties['NAME_2']);    
    //             <Geography
    //                 key={geo.rsmKey}
    //                 geography={geo}
    //                 fill={getFillColor(prefectureData)}
    //                 stroke="#FFF"
    //             />
    //             ))
    //         }
    //         </Geographies>
    //   </ComposableMap>
    // );
  
}

export default GuineaMap;
