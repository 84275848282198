import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer>
      <div className="content">
        <h1>Soyons partenaire !</h1>
        <button className="big accent" onClick={() => navigate('/contact')}>Contactez-nous</button>
        <div className="infos">
          <div className="left">
            <p>Avenue de la Teminetaye, Conakry</p>
            <p>support@wolikefanyi.com</p>
            <p>(00 224) 612 18 18 20/612 10 74 74</p>
            <div className="social">
              <FaFacebook size={24} onClick={() => window.open('https://www.facebook.com/wolikefanyi?mibextid=ZbWKwL','_blank')}/>
              <FaLinkedin size={24} onClick={() => window.open('https://www.linkedin.com/company/wolikefanyi/','_blank')}/>
              <FaTwitter size={24} />
            </div>
          </div>
          <div className="right">
            <nav>
              <Link to="/">Accueil</Link>
              <Link to="/findprofessionals">Choisir un professionnel</Link>
              <Link to="/findrecruiters">Ils nous font confiance</Link>
              <Link to="/otherRecrutment">Autre Recrutement</Link>
            </nav>
            <nav>
              <Link to="/contact">Contact</Link>
              <Link to="/about">A Propos</Link>
            </nav>
          </div>
        </div>
        <div className="copyright">WOLIKEFANYI - Tous droits réservés - 2022</div>
      </div>
    </footer>
  );
};

export default Footer;
