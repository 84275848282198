import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineUpload } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import Erreurs from "../../components/Erreurs/Erreurs";
import { loadingState } from "../../recoil/loadingAtom";
import { FirstUpperCase, objecttoFormData } from "../../utils/services/helpers";
import Principale from "../../utils/services/Principale";
import igslogo from '../../assets/images/logosig.png'
import toast from "react-hot-toast";
const EditProfileRecruiter = () => {
  const {state} = useLocation();
  //console.log(state)
  const [employeurDetail,setEmployeurDetail] = useState([]);
  const [preloadData,setPreloadData]= useState([]);
  const {register,handleSubmit,reset,formState: { errors },} = useForm({defaultValues: {employeurDetail,},}); 
  const[selectedcommunes,setSelectedcommunes] = useState([]);
  const[selectedquartiers,setSelectedquartiers] = useState([]);
  const [isLoading,setIsLoading] = useRecoilState(loadingState);
  const[erreurs,setErreurs] = useState([]);
  const url = process.env.REACT_APP_URL + "storage/";
  const [previews,setPreviews] = useState({logo:''});
  const api = new Principale();
  const navigate = useNavigate();
  const getEmployeurData = async (employeur_id) => {
    setIsLoading(true);
    const {status, employeur, messages} = await api.get(`/employeur/getEmployeurDetails/${employeur_id}`);
    if(status === 200){
       if(Array.isArray(employeur) && employeur.length > 0){
        setEmployeurDetail(employeur[0])
       }
    }
    else setErreurs(messages);
  }
  const getPreloadData = async () => {
    const{status,messages,data} = await api.get('/getPreloadData');
    setIsLoading(false)  
    setPreloadData([]);setErreurs([]);
    if(status === 200)
        setPreloadData(data)
    else setErreurs(messages);  
  }
  const ouvrirdocument = (document) => {
    if(document === "photo_piece")
      window.open(`${url}${employeurDetail.photo_piece.replace('public/','')}`);
    else if(document === 'nif_photo'){
     window.open(`${url}${employeurDetail.nif_photo.replace('public/','')}`); }
    else if(document === 'photo_rccm'){
      window.open(`${url}${employeurDetail.photo_rccm.replace('public/','')}`); }  
    else if(document === 'autre_document'){
      if(employeurDetail.autre_doc !== "" && employeurDetail.autre_doc !== null)
        window.open(`${url}${employeurDetail.autre_doc.replace('public/','')}`);
     }   
 }
  const handleInput  = (e) => {
    setErreurs([]);
    if(e.target.name === 'prefecture_id'){
        setSelectedcommunes([]);setSelectedquartiers([]);
        setEmployeurDetail({...employeurDetail,[e.target.name]:e.target.value,commune_id:0,quartier_id:0});
        if(preloadData.communes)
          setSelectedcommunes(preloadData.communes.filter((c) => c.prefecture_id === parseInt(e.target.value)).map((commune) => (commune)))
    }
    else if(e.target.name === 'commune_id'){
      setSelectedquartiers([]);setEmployeurDetail({...employeurDetail,[e.target.name]:e.target.value,quartier_id:0});
      if(preloadData.quartiers)
        setSelectedquartiers(preloadData.quartiers.filter((q) => q.commune_id === parseInt(e.target.value)).map((quartier) => (quartier)));
    }
    else if(e.target.name === 'email' || e.target.name === 'civilite' || e.target.name === 'type_piece'){
      setEmployeurDetail({...employeurDetail,[e.target.name]:e.target.value})
    }
    else if(e.target.name === 'societe' || e.target.name === 'secteur' || e.target.name === 'type_piece' 
      || e.target.name === 'representant' || e.target.name === 'fonction' || e.target.name === 'nomemployeur' 
      || e.target.name === 'prenomemployeur' || e.target.name === 'profession'){
       setEmployeurDetail({...employeurDetail,[e.target.name]:FirstUpperCase(e.target.value)})
    }
    else if(e.target.name === 'numero_piece' || e.target.name === 'nif' || e.target.name === 'rccm' ){
      setEmployeurDetail({...employeurDetail,[e.target.name]:e.target.value.toUpperCase()});
    }
    else if(e.target.name === 'photo_piece' || e.target.name == "nif_photo" || e.target.name === 'autre_doc' || e.target.name === 'photo_rccm' || e.target.name === 'logo'){
      if(e.target.name === 'photo_piece'){
        setEmployeurDetail({...employeurDetail,photo_piece:e.target.files[0]}) ;
      }
      else if(e.target.name === 'nif_photo'){
        setEmployeurDetail({...employeurDetail,nif_photo:e.target.files[0]})
      }
      else if(e.target.name === 'autre_doc'){
        setEmployeurDetail({...employeurDetail,autre_doc:e.target.files[0]})
      }
      else if(e.target.name === 'photo_rccm'){
        setEmployeurDetail({...employeurDetail,photo_rccm:e.target.files[0]}) ;
      }
      else if( e.target.name === 'logo'){
        setEmployeurDetail({...employeurDetail,logo:e.target.files[0]})
       setPreviews({...previews,logo:URL.createObjectURL(e.target.files[0])});
      }
    }
    else if(e.target.name === 'telephone'){
        const re = /^[0-9\b]+$/;
        // if(e.target.value.length <= 9 && re.test(e.target.value))
             setEmployeurDetail({...employeurDetail,[e.target.name]:e.target.value});
     }    
    else
      setEmployeurDetail({...employeurDetail,[e.target.name]:e.target.value})
    
    
  }
  useEffect(() => {
      getEmployeurData(state?.employeur_id);
       reset(employeurDetail);

      getPreloadData();
     
   
  },[state?.employeur_id]);
  const updateEmployeur = async () => {
    setIsLoading(true);
    var formData = new objecttoFormData(employeurDetail);
    const {status,messages} = await api.post('/employeur/update',formData);
    setIsLoading(false);
   
    if(status === 200){
      toast.success("Modification de compte effectuée avec succès.");
      navigate('/pro/dashboard')
    }
    else
      setErreurs(messages)
    
   // const  resp = await api.post('/employeur/update',formData);
  
  }
  useMemo(() => {
    if(employeurDetail?.prefecture_id)
       setSelectedcommunes(preloadData.communes.filter((c) => c.prefecture_id === parseInt(employeurDetail?.prefecture_id)).map((commune) => (commune)));
    if(preloadData.quartiers)
       setSelectedquartiers(preloadData.quartiers.filter((q) => q.commune_id === parseInt(employeurDetail?.commune_id)).map((quartier) => (quartier)));
    reset(employeurDetail)       
  },[preloadData])
  // console.log(employeurDetail)
  return (
    <div className="edit-profile-pro page">
      <h1>Modifier mon profil</h1>
       {employeurDetail?.type === "societe" &&
         <form className="content" onSubmit={handleSubmit(updateEmployeur)}>
          <div className="left">
            <div className="input-group file-input photo-input">
              <div className="group">
                <span>Photo</span>
                <div className="photo">
                  {(employeurDetail?.logo && employeurDetail?.logo !== null && typeof employeurDetail?.logo !== 'object') &&  <img src={url + employeurDetail?.logo.replace('public/',"")} alt="Photo compagnie" />}
                  {(!employeurDetail?.logo || employeurDetail?.logo === null) &&  <img src={igslogo} alt="" />}
                  {(employeurDetail?.logo && typeof employeurDetail?.logo === 'object' && 
                    <img src={previews?.logo ?previews?.logo:''} alt="" />
                )}
                </div>
                <label className="file-input-label">
                  <AiOutlineUpload />
                   Importer le logo
                  <input type="file" name="logo" accept="image/*" onChange={(e) => handleInput(e)}/>
                </label>
              </div>
            </div>
          </div>
           <div className="right">
            <div className="divider">
              <h4>Informations sur la société</h4>
              <div className="input-group">
                <label>Nom de la société
                   <input type="text" name="societe" id="societe" placeholder="Nom de la société"
                       value={employeurDetail.societe}
                       {...register("societe", {
                         onChange: (e) => {
                           handleInput(e);
                         },
                         required: true,
                         minLength: 2,
                         })}
                        />
                      {errors.societe && errors.societe?.type === "required" && (
                        <span className="error-msg">Ce champ est obligatoire.</span>
                      )}
                     {errors.societe && errors.societe?.type === "minLength" && (
                     <span className="error-msg">
                        ce champ ne peut pas être inférieur à deux caractères.
                     </span>
                     )}
                 </label>
                 <label>Secteur d'activité
                       <input type="text" name="secteur" id="secteur" placeholder="Secteur d'activité" 
                        value={employeurDetail.secteur}
                        {...register("secteur", {
                         onChange: (e) => {
                           handleInput(e);
                         },
                         required: true,
                           minLength: 2,
                         })}
                       />
                      {errors.activite && errors.activite?.type === "required" && (
                        <span className="error-msg">Ce champ est obligatoire.</span>
                      )}
                     {errors.activite && errors.activite?.type === "minLength" && (
                     <span className="error-msg">
                        ce champ ne peut pas être inférieur à deux caractères.
                     </span>
                     )}
                     </label>
                     <label>
                        Préfecture ou Commune
                        <select name="prefecture_id" id="prefecture_id1"
                        {...register("prefecture_id", {
                            onChange: (e) => {
                            handleInput(e);
                            },
                            validate: (value) => parseInt(value) !== 0,
                        })}
                        value={employeurDetail.prefecture_id}
                        >
                            <option value={0}>Choisir la Préfecture ou Commune</option>
                            {preloadData?.prefectures && preloadData.prefectures.map((prefecture) => (<option value={prefecture.prefecture_id} key={prefecture.prefecture_id}>{prefecture.nom}</option>)) }
                        </select>
                        {errors.prefecture_id && (
                          <span className="error-msg">Ce champ est obligatoire.</span>
                        )}
                     </label>
              
              </div>
              <div className="input-group">
                 <label>Commune
                       <select name="commune_id" id="commune_id"
                          {...register("commune_id", {
                              onChange: (e) => {
                              handleInput(e);
                              },
                              validate: (value) => parseInt(value) !== 0,
                          })}
                      value={employeurDetail.commune_id}
                      >
                      <option value={0}>Choisir la Commune</option>
                      {selectedcommunes.length > 0 && selectedcommunes.map((commune) => (<option value={commune.commune_id} key={commune.commune_id}> {commune.nom}</option>))}
                      </select>
                      {errors.commune_id && (
                          <span className="error-msg">Ce champ est obligatoire.</span>
                      )}
                  </label>
                  <label>Quartier
                    <select name="quartier_id" id="quartier_id"
                            value={employeurDetail.quartier_id}
                            {...register("quartier_id", {
                                onChange: (e) => {
                                handleInput(e);
                                },
                                validate: (value) => parseInt(value) !== 0,
                            })}
                        >
                        <option value={0}>Choisir le Quartier/District</option>
                        {selectedquartiers.length > 0 && selectedquartiers.map((quartier) => (<option value={quartier.quartier_id} key={quartier.quartier_id}> {quartier.nom}</option>))}
                        </select>
                        {errors.quartier_id && (
                        <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                 </label>
                 <label>Addresse
                    <input type="text" name="addresse" value={employeurDetail?.addresse} id="addresse" placeholder='Addresse de la société'
                      onChange={handleInput}
                    />
                </label>
                <label>Téléphone
                    <input type="tel" name="telephone" id="telephone" placeholder="Téléphone" 
                      value={employeurDetail.telephone}
                      {...register("telephone", {
                      onChange: (e) => {
                          handleInput(e);
                      },
                      required: true,
                      maxLength: 9,
                      minLength: 5,
                      pattern: /6[0-9]{8}$/g,
                      })} 
                    />
                    {errors.telephone && errors.telephone?.type === "required" && (
                        <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                    {errors.telephone && errors.telephone?.type === "minLength" && (
                      <span className="error-msg">
                         Ne peut pas être inférieur à 5 caractères.
                      </span>
                    )}
                    {errors.telephone && errors.telephone?.type === "maxLength" && (
                        <span className="error-msg">
                            Ne peut pas être supérieur à 9 caractères.
                        </span>
                    )}
                    {errors.telephone && errors.telephone.type === "pattern" && (
                        <span role="alert" className="error-msg">
                            Format invalide.ex: 620000000
                        </span>
                        )}
                </label>
              </div>
              <div className="input-group">
                 <label>Representé par 
                   <select name="civilite" id="civilite"
                      {...register("civilite", {
                          onChange: (e) => {
                          handleInput(e);
                          },
                          validate: (value) => value !== "",
                      })}
                      value={employeurDetail.civilite}
                     >
                        <option value="Monsieur">Monsieur</option>
                        <option value="Madame">Madame</option>
                        <option value="Mademoiselle">Mademoiselle</option>
                    </select>
                    {errors.civilite && (
                    <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                 </label>
                 <label> Representant(Prenom et Nom)
                            <input type="text" name="representant" id="representant" placeholder="Representant" 
                                value={employeurDetail.representant}
                                {...register("representant", {
                                    onChange: (e) => {
                                    handleInput(e);
                                    },
                                    required: true,
                                    minLength: 2,
                                })}
                                />
                            {errors.representant && errors.representant?.type === "required" && (
                            <span className="error-msg">Ce champ est obligatoire.</span>
                            )}
                            {errors.representant && errors.representant?.type === "minLength" && (
                            <span className="error-msg">
                                ce champ ne peut pas être inférieur à deux caractères.
                            </span>
                            )}
                  </label>
                     
                      <label>Fonction
                        <input type="text" name="fonction" id="fonction" placeholder="Fonction du representant" 
                            value={employeurDetail.fonction}
                            {...register("fonction", {
                            onChange: (e) => {
                                handleInput(e);
                            },
                            required: true,
                            minLength: 2,
                            })}
                            />
                        {errors.fonction && errors.fonction?.type === "required" && (
                            <span className="error-msg">Ce champ est obligatoire.</span>
                        )}
                        {errors.fonction && errors.fonction?.type === "minLength" && (
                            <span className="error-msg">
                            ce champ ne peut pas être inférieur à deux caractères.
                            </span>
                            )}
                      </label> 
              </div>  
            </div>
            <div className="divider">
              <h4>Informations sur le compte</h4>
              <div className="input-group">
              <label>Email
                    <input type="email" name="email" id="email" placeholder="monmail@domaine.com"
                    value={(employeurDetail?.email === 'null' && employeurDetail?.email)?employeurDetail.email:''}
                    {...register("email", 
                    {  onChange: (e) => {
                      handleInput(e)
                   },
                     required:true,
                     pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                    />
                    {(errors.email && errors.email?.type === "required") && (
                    <span className="error-msg">Le Champ email est obligatoire.</span>
                    )}
                    {(errors.email  && !errors.email?.type === "required")  && (
                    <span className="error-msg">Mauvais format d'email.Exemple(example@exemple.com).</span>
                    )}
                    </label>
                    <label>Rôle
                        <strong style={{padding:'4.5px',paddingLeft:'8px',border:'1px solid #e2d9d9',display:'block'}}>{employeurDetail.role}</strong>
                    </label>
          
              </div>
            </div>
            <div className="divider">
              <h4>Documents</h4>
              <div className="input-group">
                  <label>Type de Pièce
                    <select name="type_piece" id="type_piece"
                      value={employeurDetail.type_piece !== null && employeurDetail.type_piece}
                      {...register("type_piece", {
                        onChange: (e) => {
                            handleInput(e);
                        },
                        validate: (value) => parseInt(value) !== 0 && value !== null,
                        })}
                    >
                        <option value={0}>Selectionner le type de pièce</option>
                        <option value="Carte d'identité">Carte d'identité</option>
                        <option value="Passeport">Passeport</option>
                        <option value="Carte de séjour">Carte de séjour</option>
                    </select>
                    {errors.type_piece && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                  </label>
                  <label>Num de pièce d'identité
                        <input type="text" name="numero_piece" id="numero_piece" placeholder="Numero" 
                          value={employeurDetail.numero_piece.toUpperCase()}
                          {...register("numero_piece", {
                          onChange: (e) => {
                            handleInput(e);
                           },
                          required: true,
                          minLength: 4,
                         })}
                        />
                     {errors.numero_piece && errors.numero_piece?.type === "required" && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                     )}
                    {errors.numero_piece && errors.numero_piece?.type === "minLength" && (
                    <span className="error-msg">
                        ce champ ne peut pas être inférieur à quatre(4) caractères.
                    </span>
                    )}
                  </label>
              </div>
              <div className="input-group">
                  <label>Code NIF
                      <input type="text" name="nif" id="nif" placeholder="Code NIF"
                        value={employeurDetail.nif.toUpperCase()}
                        {...register("nif", {
                        onChange: (e) => {
                          handleInput(e);
                        },
                        required: true,
                        minLength: 4,
                      })}
                      />
                    {errors.nif && errors.nif?.type === "required" && (
                    <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                  {errors.nif && errors.nif?.type === "minLength" && (
                  <span className="error-msg">
                      ce champ ne peut pas être inférieur à quatre(4) caractères.
                  </span>
                  )}
                  
                  </label>
                  <label>RCCM
                        <input type="text" name="rccm" id="rccm" placeholder="Numero RCCM"
                          value={employeurDetail.rccm.toUpperCase()}
                          {...register("rccm", {
                          onChange: (e) => {
                          handleInput(e);
                          },
                          required: true,
                          minLength: 4,
                          })}
                        />
                    {errors.rccm && errors.rccm?.type === "required" && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                    {errors.rccm && errors.rccm?.type === "minLength" && (
                    <span className="error-msg">
                        ce champ ne peut pas être inférieur à quatre(4) caractères.
                    </span>
                    )}
                  </label>
              </div>
            
              <div className="input-group docs-input-group">
                 <label className="file-input">Copie de la Pièce
                <input type="file" name="photo_piece" accept=".jpg,.jpeg,.png,.pdf"
                  {...register("photo_piece", {
                    onChange: (e) => {
                      handleInput(e);
                    },
                    validate: (value) => (value !== "" && value?.length !== 0)
                  })}
                  />
                 {(typeof employeurDetail.photo_piece === 'string'  &&  employeurDetail.photo_piece !== null && employeurDetail.photo_piece !== '' && employeurDetail.photo_piece !== '') &&<span style={{display:'block',color:'green',cursor:"pointer"}} onClick={() => ouvrirdocument('type_piece_file')}><strong>Fichier Présent </strong></span>} 
                 {(typeof erreurs.photo_piece === 'undefined' && (employeurDetail.photo_piece === null || employeurDetail.photo_piece === '')) ? <span><strong style={{color:'red',display:'block'}}>Fichier Absent </strong></span>:''}
                 {(employeurDetail.photo_piece !== null && typeof employeurDetail.photo_piece === 'object') && <span><strong>Fichier selectionné : </strong>{employeurDetail.photo_piece?.name}</span>}  
                 {(typeof errors.photo_piece !== 'undefined')&& (
                  <span className="error-msg" style={{color:'red'}}>Veuillez choisir la copie du type de piece.</span>
                 )}
                 </label>

                <label className="file-input">
                  NIF Copie
                  <input type="file" name="nif_photo" accept=".jpg,.jpeg,.png,.pdf"
                  {...register("nif_photo", {
                    onChange: (e) => {
                      handleInput(e);
                    },
                    validate: (value) => (value !== "" && value?.length !== 0)
                  })}
                  />
                 {(typeof employeurDetail.nif_photo === 'string'  &&  employeurDetail.nif_photo !== null && employeurDetail.nif_photo !== '') &&<span style={{display:'block',color:'green',cursor:"pointer"}} onClick={() => ouvrirdocument('nif_photo')}><strong>Fichier Présent </strong></span>} 
                 {(typeof erreurs.nif_photo === 'undefined' && (employeurDetail.nif_photo === null || employeurDetail.nif_photo === '')) ? <span><strong style={{color:'red',display:'block'}}>Fichier Absent </strong></span>:''}
                 {(employeurDetail.nif_photo !== null && typeof employeurDetail.nif_photo === 'object') && <span><strong>Fichier selectionné : </strong>{employeurDetail.nif_photo?.name}</span>}  
                 {(typeof errors.nif_photo !== 'undefined')&& (
                  <span className="error-msg" style={{color:'red'}}>Veuillez choisir la copie du nif.</span>
                 )}
                </label>
              </div>
              <div className="input-group docs-input-group">
                <label className="file-input">Copie du rccm
                 <input type="file" name="photo_rccm" accept=".jpg,.jpeg,.png,.pdf"
                  {...register("photo_rccm", {
                    onChange: (e) => {
                      handleInput(e);
                    },
                    validate: (value) => (value !== "" && value?.length !== 0)
                  })}
                  />
                 {(typeof employeurDetail.photo_rccm === 'string'  &&  employeurDetail.photo_rccm !== null && employeurDetail.photo_rccm !== '' && employeurDetail.photo_rccm !== '') &&<span style={{display:'block',color:'green',cursor:"pointer"}} onClick={() => ouvrirdocument('type_piece_file')}><strong>Fichier Présent </strong></span>} 
                 {(typeof erreurs.photo_rccm === 'undefined' && (employeurDetail.photo_rccm === null || employeurDetail.photo_rccm === '' || typeof employeurDetail.photo_rccm === 'undefined')) ? <span><strong style={{color:'red',display:'block'}}>Fichier Absent </strong></span>:''}
                 {(employeurDetail.photo_rccm !== null && typeof employeurDetail.photo_rccm === 'object') && <span><strong>Fichier selectionné : </strong>{employeurDetail.photo_rccm?.name}</span>}  
                 {(typeof errors.photo_rccm !== 'undefined')&& (
                  <span className="error-msg" style={{color:'red'}}>Veuillez choisir la copie du type de piece.</span>
                 )}
                 </label>
               <label className="file-input">
                  Autre document
                  <input type="file" name="autre_doc" accept=".jpg,.jpeg,.png,.pdf" onChange={handleInput}/>
                  {(employeurDetail.autre_doc !== null && employeurDetail.autre_doc !== '') ? <span style={{color:'green',display:'block',cursor:"pointer"}} onClick={() => ouvrirdocument('autre_document')}><strong>Fichier Présent </strong></span>:<span style={{color:'red',display:'block'}}><strong>Fichier Absent </strong></span>} 
                  {(typeof employeurDetail.autre_doc === 'object' && employeurDetail.autre_doc !== null) && <span><strong>Fichier selectionné : </strong>{employeurDetail.autre_doc?.name}</span>}
                </label>
              </div>
            </div>
            <Erreurs validation = {erreurs} />
            <div className="divider">
              <button className="accent big" disabled={isLoading}>Enregistrer</button>
            </div>
          </div>
         </form>
        }
      {employeurDetail?.type === "personne physique" && 
        <form className="content" onSubmit={handleSubmit(updateEmployeur)}>
          <div className="right">
            <div className="divider">
              <div className="input-group">
                 <label>Civilité
                   <select name="civilite" id="civilite"
                      {...register("civilite", {
                          onChange: (e) => {
                          handleInput(e);
                          },
                          validate: (value) => value !== "",
                      })}
                      value={employeurDetail.civilite}
                     >
                        <option value="Monsieur">Monsieur</option>
                        <option value="Madame">Madame</option>
                        <option value="Mademoiselle">Mademoiselle</option>
                    </select>
                    {errors.civilite && (
                    <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                 </label>
                 <label>Prenom
                     <input type="text" value={employeurDetail.prenomemployeur} name="prenom"
                      {...register("prenomemployeur", {
                      onChange: (e) => {
                      handleInput(e);
                      },
                      required: true,
                      minLength: 2,
                      })}
                    />
                    {errors.prenomemployeur && errors.prenomemployeur?.type === "required" && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                    {errors.prenomemployeur && errors.prenomemployeur?.type === "minLength" && (
                    <span className="error-msg">
                        ce champ ne peut pas être inférieur à deux(2) caractères.
                    </span>
                    )}
        
                 </label>
                 <label>Nom
                     <input type="text" value={employeurDetail.nomemployeur}
                        {...register("nomemployeur", {
                          onChange: (e) => {
                          handleInput(e);
                          },
                          required: true,
                          minLength: 2,
                          })}
                     />
                   {errors.nomemployeur && errors.nomemployeur?.type === "required" && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                    {errors.nomemployeur && errors.nomemployeur?.type === "minLength" && (
                    <span className="error-msg">
                        ce champ ne peut pas être inférieur à deux(2) caractères.
                    </span>
                    )}
                 </label>
                 <label>Profession
                     <input type="text" value={employeurDetail?.profession?employeurDetail?.profession:''}
                        {...register("profession", {
                          onChange: (e) => {
                          handleInput(e);
                          },
                          required: false,
                          minLength: 2,
                          })}
                     />
                 
                    {errors.profession && errors.profession?.type === "minLength" && (
                    <span className="error-msg">
                        ce champ ne peut pas être inférieur à deux(2) caractères.
                    </span>
                    )}
                 </label>
              </div>
             <div className="input-group">
             <label> Telephone
                <input type="tel" name="telephone" id="telephone" placeholder="Téléphone" 
                      value={employeurDetail.telephone}
                      {...register("telephone", {
                      onChange: (e) => {
                          handleInput(e);
                      },
                      required: true,
                      maxLength: 9,
                      minLength: 5,
                      pattern: /6[0-9]{8}$/g,
                      })} 
                    />
                    {errors.telephone && errors.tel?.type === "required" && (
                        <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                    {errors.telephone && errors.telephone?.type === "minLength" && (
                      <span className="error-msg">
                         Ne peut pas être inférieur à 5 caractères.
                      </span>
                    )}
                    {errors.telephone && errors.telephone?.type === "maxLength" && (
                        <span className="error-msg">
                            Ne peut pas être supérieur à 9 caractères.
                        </span>
                    )}
                    {errors.telephone && errors.telephone.type === "pattern" && (
                        <span role="alert" className="error-msg">
                            Format invalide.ex: 620000000
                        </span>)}
                </label>  
                   <label>
                    Préfecture ou Commune
                    <select name="prefecture_id" id="prefecture_id1"
                    {...register("prefecture_id", {
                        onChange: (e) => {
                        handleInput(e);
                        },
                        validate: (value) => parseInt(value) !== 0,
                    })}
                    value={employeurDetail.prefecture_id}
                    >
                        <option value={0}>Choisir la Préfecture ou Commune</option>
                        {preloadData?.prefectures && preloadData.prefectures.map((prefecture) => (<option value={prefecture.prefecture_id} key={prefecture.prefecture_id}>{prefecture.nom}</option>)) }
                    </select>
                    {errors.prefecture_id && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
               </label>
                <label>Commune
                       <select name="commune_id" id="commune_id"
                          {...register("commune_id", {
                              onChange: (e) => {
                              handleInput(e);
                              },
                              validate: (value) => parseInt(value) !== 0,
                          })}
                      value={employeurDetail.commune_id}
                      >
                      <option value={0}>Choisir la Commune</option>
                      {selectedcommunes.length > 0 && selectedcommunes.map((commune) => (<option value={commune.commune_id} key={commune.commune_id}> {commune.nom}</option>))}
                      </select>
                      {errors.commune_id && (
                          <span className="error-msg">Ce champ est obligatoire.</span>
                      )}
                  </label>
                  <label>Quartier
                    <select name="quartier_id" id="quartier_id"
                            value={employeurDetail.quartier_id}
                            {...register("quartier_id", {
                                onChange: (e) => {
                                handleInput(e);
                                },
                                validate: (value) => parseInt(value) !== 0,
                            })}
                        >
                        <option value={0}>Choisir le Quartier/District</option>
                        {selectedquartiers.length > 0 && selectedquartiers.map((quartier) => (<option value={quartier.quartier_id} key={quartier.quartier_id}> {quartier.nom}</option>))}
                        </select>
                        {errors.quartier_id && (
                        <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                 </label>
                 {employeurDetail?.role_id !== 3 &&
                  <label>Addresse
                    <input type="text" name="addresse" value={employeurDetail?.addresse} id="addresse" placeholder='Addresse de la société'
                      onChange={handleInput}
                    />
                </label>}
              </div>
              <div className="input-group">
                 {employeurDetail?.role_id !== 3 &&
                   <label>Fonction
                        <input type="text" name="fonction" id="fonction" placeholder="Fonction du representant" 
                            value={employeurDetail.fonction?employeurDetail.fonction:''}
                            {...register("fonction", {
                            onChange: (e) => {
                                handleInput(e);
                            },
                            required: true,
                            minLength: 2,
                            })}
                            />
                        {errors.fonction && errors.fonction?.type === "required" && (
                            <span className="error-msg">Ce champ est obligatoire.</span>
                        )}
                        {errors.fonction && errors.fonction?.type === "minLength" && (
                            <span className="error-msg">
                            ce champ ne peut pas être inférieur à deux caractères.
                            </span>
                            )}
                   </label> 
                  }
                 {employeurDetail?.role_id === 3 && 
                   <label>Addresse
                    <input type="text" name="addresse" value={employeurDetail?.addresse?employeurDetail?.addresse:''} id="addresse" placeholder='Addresse de la société'
                     onChange={handleInput}
                    />
                  </label>
                  }
                  {employeurDetail?.role_id === 3 ? 
                   <label>Email
                    <input type="email" name="email" id="email" placeholder="monmail@domaine.com"
                    value={(employeurDetail?.email && employeurDetail?.email !=='null')?employeurDetail.email:''}
                    {...register("email", 
                    {  onChange: (e) => {
                      handleInput(e)
                   },
                     required:false,
                     pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                    />
                  
                    {(errors.email  && !errors.email?.type === "required")  && (
                    <span className="error-msg">Mauvais format d'email.Exemple(example@exemple.com).</span>
                    )}
                   </label>:
                    <label>Email
                    <input type="email" name="email" id="email" placeholder="monmail@domaine.com"
                    value={(employeurDetail?.email && employeurDetail?.email !=='null')?employeurDetail.email:''}
                    {...register("email", 
                    {  onChange: (e) => {
                      handleInput(e)
                   },
                     required:true,
                     pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                    />
                    {(errors.email && errors.email?.type === "required") && (
                    <span className="error-msg">Le Champ email est obligatoire.</span>
                    )}
                    {(errors.email  && !errors.email?.type === "required")  && (
                    <span className="error-msg">Mauvais format d'email.Exemple(example@exemple.com).</span>
                    )}
                   </label>
                   } 
                    <label>Rôle
                        <strong style={{padding:'4.5px',paddingLeft:'8px',border:'1px solid #e2d9d9',display:'block'}}>{employeurDetail.role}</strong>
                    </label>   
              </div>
             <div className="input-group">
              <label>Type de Pièce
                    <select name="type_piece" id="type_piece"
                      value={employeurDetail.type_piece !== null && employeurDetail.type_piece}
                      {...register("type_piece", {
                        onChange: (e) => {
                            handleInput(e);
                        },
                        validate: (value) => parseInt(value) !== 0 && value !== null,
                        })}
                    >
                        <option value={0}>Selectionner le type de pièce</option>
                        <option value="Carte d'identité">Carte d'identité</option>
                        <option value="Passeport">Passeport</option>
                        <option value="Carte de séjour">Carte de séjour</option>
                    </select>
                    {errors.type_piece && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                  </label>
                  <label>Num de pièce d'identité
                        <input type="text" name="numero_piece" id="numero_piece" placeholder="Numero" 
                          value={employeurDetail.numero_piece.toUpperCase()}
                          {...register("numero_piece", {
                          onChange: (e) => {
                            handleInput(e);
                           },
                          required: true,
                          minLength: 4,
                         })}
                        />
                     {errors.numero_piece && errors.numero_piece?.type === "required" && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                     )}
                    {errors.numero_piece && errors.numero_piece?.type === "minLength" && (
                    <span className="error-msg">
                        ce champ ne peut pas être inférieur à quatre(4) caractères.
                    </span>
                    )}
                  </label>
                  <div className="input-group docs-input-group" style={{marginTop:'0px'}}>
                      <label className="file-input">Copie de la Pièce
                        <input type="file" name="photo_piece" accept=".jpg,.jpeg,.png,.pdf"
                        {...register("photo_piece", {
                          onChange: (e) => {
                            handleInput(e);
                          },
                          validate: (value) => (value !== "" && value?.length !== 0)
                        })}
                        />
                      {(typeof employeurDetail.photo_piece === 'string'  &&  employeurDetail.photo_piece !== null && employeurDetail.photo_piece !== '' && employeurDetail.photo_piece !== '') &&<span style={{display:'block',color:'green',cursor:"pointer"}} onClick={() => ouvrirdocument('type_piece_file')}><strong>Fichier Présent </strong></span>} 
                      {(typeof erreurs.photo_piece === 'undefined' && (employeurDetail.photo_piece === null || employeurDetail.photo_piece === '')) ? <span><strong style={{color:'red',display:'block'}}>Fichier Absent </strong></span>:''}
                      {(employeurDetail.photo_piece !== null && typeof employeurDetail.photo_piece === 'object') && <span><strong>Fichier selectionné : </strong>{employeurDetail.photo_piece?.name}</span>}  
                      {(typeof errors.photo_piece !== 'undefined')&& (
                        <span className="error-msg" style={{color:'red'}}>Veuillez choisir la copie du type de piece.</span>
                      )}
                      </label>
                  </div>    
              </div>
            </div>   
            <Erreurs validation = {erreurs} />
            <div className="divider">
              <button className="accent big" disabled={isLoading}>Enregistrer</button>
            </div>
          </div>
        
        </form>

      }  
    </div>
  );
};

export default EditProfileRecruiter;
