import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { AiOutlineUpload, AiOutlineWarning } from 'react-icons/ai'
import { Link, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil';
import Erreurs from '../../components/Erreurs/Erreurs';
import { loadingState } from '../../recoil/loadingAtom';
import { UserContext } from '../../utils/Context/Context';
import { FirstUpperCase, objecttoFormData } from '../../utils/services/helpers';
import Principale from '../../utils/services/Principale';

const SignUp = () => {
    const [preloadData,setPreloadData]= useState([]);
    const[erreurs,setErreurs] = useState([]);
    const[isLoading,setIsLoading] = useRecoilState(loadingState);
    const[selectedcommunes,setSelectedcommunes] = useState([]);
    const[selectedquartiers,setSelectedquartiers] = useState([]);
    const {setUser} = useContext(UserContext);
    const [employeur,setEmployeur] = useState({
        prefecture_id:0,commune_id:0,quartier_id :0,typeCompte: '1',civilite:'Monsieur',prenom:'',nom:'',profession:'',tel:'',societe:'',activite:'',fonction:'',
        addresse:'',doctype:'',numpiece:'',num_rc_file:'',cn_rc_file:'',numcn:'',rccm:'',password:'',confirmMdp:'',rccm_file:'',
        logo:'',

      })
    const {register,handleSubmit,reset,formState: { errors },} = useForm({defaultValues: {employeur,},}); 
    const navigate = useNavigate();
    const api = new Principale();
    const getPreloadData = async () => {
        setIsLoading(true);
        const{status,messages,data} = await api.get('/getPreloadData');
        setPreloadData([]);setErreurs([]);
        if(status === 200)
            setPreloadData(data);
        else setErreurs(messages);  
        setIsLoading(false)
   }
   
   const handleInput = (e) => {
    setErreurs([]);
    if(e.target.name === 'prefecture_id'){
        setSelectedcommunes([]);setSelectedquartiers([]);
        setEmployeur({...employeur,[e.target.name]:e.target.value,commune_id:0,quartier_id:0});
        if(preloadData.communes)
          setSelectedcommunes(preloadData.communes.filter((c) => c.prefecture_id === parseInt(e.target.value)).map((commune) => (commune)))
    }else if(e.target.name === 'commune_id'){
        setSelectedquartiers([]);setEmployeur({...employeur,[e.target.name]:e.target.value,quartier_id:0});
        if(preloadData.quartiers)
          setSelectedquartiers(preloadData.quartiers.filter((q) => q.commune_id === parseInt(e.target.value)).map((quartier) => (quartier)));
    }
    else if(e.target.name === "personne"){
        setEmployeur({...employeur, typeCompte:e.target.value});
    }else if(e.target.name === 'num_rc_file' || e.target.name == "cn_rc_file" || e.target.name === 'logo' || e.target.name === 'rccm_file'){
        setEmployeur({...employeur,[e.target.name]:e.target.files[0]});
    }
    else if(e.target.name === 'tel'){
        const re = /^[0-9\b]+$/;
    
        if((e.target.value.length <= 9 && re.test(e.target.value)) || e.target.value === '')
           setEmployeur({...employeur,[e.target.name]:e.target.value});
     }
    else if(e.target.name === 'email'){
        setEmployeur({...employeur,[e.target.name]:e.target.value});
    } 
    else if(e.target.name === 'numpiece' || e.target.name === 'numcn' || e.target.name === 'rccm'){
        setEmployeur({...employeur,[e.target.name]:e.target.value.toUpperCase()});
    }
    else if(e.target.name === 'doctype'){
        setEmployeur({...employeur,[e.target.name]: e.target.value}); 
    }
    else{
        setEmployeur({...employeur,[e.target.name]: FirstUpperCase(e.target.value)});
    }
  }
  const inscription =  () => {
    var formData = objecttoFormData(employeur);
    setErreurs([]);
    setIsLoading(true);
    // const {status,messages,token} = await api.post('/employeur/new',formData);
    api.post('/employeur/new',formData).then((resp) => {
        if(resp?.status === 200){
        api.setToken(resp?.token);
        new Principale().get('/profile').then((resp) => {
           if(resp?.status === 200)
             setUser(resp?.user);
       });
       setIsLoading(false);toast.success('Inscription effectuée avec succès.');navigate('/');

    }
    else {
  
        setErreurs(resp?.messages); setIsLoading(false);
    }
   })
   
  }
   useEffect(() => {
      getPreloadData();
   },[]);
  return (
    <div className="sign-up page">
        <div className="header">
            <h2>Inscription / Employés-Recruteurs</h2>
            <p className="subtext">
                Vous êtes une
                personne physique ou une société à la recherche d'un professionnel du
                métier ? Merci de renseigner les champs suivants pour vous inscrire.
            </p>
            <div className="alt">
                    <span className="icon">
                        <AiOutlineWarning size={18} />
                    </span>
                <p className="subtext">
                    Vous êtes un professionnel du métier voulant proposer vos services ?
                    <strong className='link'><Link to={"/signup/pro"}> Cliquez-ici pour vous inscrire (Employé).</Link></strong>
                </p>
            </div>
        </div>
      <form onSubmit={handleSubmit(inscription)}>
        <div className="divider account-type">
          <div className="left">
            <h4>Type de compte(Employeur)</h4>
            <p className="subtext">
              Choisissez le type de compte que vous voulez créer
            </p>
          </div>
          <div className="right">
            <div className="input-group">
              <div className="radio-button">
                <label>
                  <input type="radio" name="personne" id="personne1" value="1" onChange={handleInput} checked={employeur.typeCompte === '1'} /> Particulier
                </label>
              </div>
              <div className="radio-button">
                <label>
                  <input type="radio" name="personne" id="personne2" value="2" onChange={handleInput} checked={employeur.typeCompte === '2'}/> Personne morale (Société,Entreprise,Institution,...)
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="divider">
           {employeur.typeCompte === '1' &&
            <div className="left">
                <h4>Informations personnelles</h4>
               <p className="subtext">Merci de nous donner quelques renseignements sur votre personne.</p>
            </div>}
            <div className="right">
               {employeur.typeCompte === '1' &&
               <>
                <div className="input-group">  
                    <label>Civilité <span className='required'></span>
                        <select name="civilite" id="civilite"
                        {...register("civilite", {
                            onChange: (e) => {
                            handleInput(e);
                            },
                            validate: (value) => value !== "",
                        })}
                        value={employeur.civilite}
                        >
                            <option value="Monsieur">Monsieur</option>
                            <option value="Madame">Madame</option>
                            <option value="Mademoiselle">Mademoiselle</option>
                        </select>
                    {errors.civilite && (
                        <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                    </label>
                    
                    <label>Profession <span className='required'></span>
                        <input type="text" name="profession" id="profession" placeholder="Profession"
                        value={employeur.profession}
                        {...register("profession", {
                        onChange: (e) => {
                            handleInput(e);
                        },
                        required: true,
                        minLength: 2,
                        })}
                        />
                    {errors.profession && errors.profession?.type === "required" && (
                        <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                    {errors.profession && errors.profession?.type === "minLength" && (
                    <span className="error-msg">
                        ce champ ne peut pas être inférieur à deux caractères.
                    </span>
                    )}
                    </label>
                </div>
                <div className='input-group'>
                <label>Prénom <span className='required'></span>
                        <input type="text" name="prenom" id="prenom" placeholder="Prénom" 
                        value={employeur.prenom}
                        {...register("prenom", {
                            onChange: (e) => {
                            handleInput(e);
                            },
                            required: true,
                            minLength: 2,
                        })}
                        />
                    {errors.prenom && errors.prenom?.type === "required" && (
                    <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                    {errors.prenom && errors.prenom?.type === "minLength" && (
                    <span className="error-msg">
                        ce champ ne peut pas être inférieur à deux caractères.
                    </span>
                    )}
                    </label>
                    <label>Nom <span className='required'></span>
                        <input type="text" name="lastName" id="lastName" placeholder="Nom" 
                        value={employeur.nom}
                        {...register("nom", {
                        onChange: (e) => {
                            handleInput(e);
                        },
                        required: true,
                        minLength: 2,
                        })}
                        />
                    {errors.nom && errors.nom?.type === "required" && (
                        <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                    {errors.nom && errors.nom?.type === "minLength" && (
                    <span className="error-msg">
                        ce champ ne peut pas être inférieur à deux caractères.
                    </span>
                    )}
                    </label>
                </div>
                <div className="input-group">
                    <label>Préfecture ou Commune <span className='required'></span>
                        <select name="prefecture_id" id="prefecture_id1"
                        {...register("prefecture_id", {
                            onChange: (e) => {
                            handleInput(e);
                            },
                            validate: (value) => parseInt(value) !== 0,
                        })}
                        value={employeur.prefecture_id}
                        >
                            <option value={0}>Choisir la Préfecture ou Commune</option>
                            {preloadData?.prefectures && preloadData.prefectures.map((prefecture) => (<option value={prefecture.prefecture_id} key={prefecture.prefecture_id}>{prefecture.nom}</option>)) }
                        </select>
                        {errors.prefecture_id && (
                          <span className="error-msg">Ce champ est obligatoire.</span>
                       )}
                    </label>
                    <label>Commune <span className='required'></span>
                        <select name="commune_id" id="commune_id1"
                        {...register("commune_id", {
                            onChange: (e) => {
                            handleInput(e);
                            },
                            validate: (value) => parseInt(value) !== 0,
                        })}
                        value={employeur.commune_id}
                        >
                        <option value={0}>Choisir la Commune</option>
                        {selectedcommunes.length > 0 && selectedcommunes.map((commune) => (<option value={commune.commune_id} key={commune.commune_id}> {commune.nom}</option>))}
                        </select>
                        {errors.commune_id && (
                         <span className="error-msg">Ce champ est obligatoire.</span>
                        )}
                    </label>
                     <label>Quartier <span className='required'></span>
                        <select name="quartier" id="quartier1"
                            value={employeur.quartier_id}
                            {...register("quartier_id", {
                                onChange: (e) => {
                                handleInput(e);
                                },
                                validate: (value) => parseInt(value) !== 0,
                            })}
                        >
                        <option value={0}>Choisir le Quartier/District</option>
                        {selectedquartiers.length > 0 && selectedquartiers.map((quartier) => (<option value={quartier.quartier_id} key={quartier.quartier_id}> {quartier.nom}</option>))}
                        </select>
                        {errors.quartier_id && (
                         <span className="error-msg">Ce champ est obligatoire.</span>
                       )}
                    </label>
                
                </div>
                <div className='input-group'>
                  <label>Addresse
                        <input name="addresse" type='text' value={employeur.addresse} 
                             onChange={handleInput}
                        />
                    </label>
                    <div></div>
              
                </div> 
               </>
                   
                }
            </div>
        </div>

        {employeur.typeCompte === '2' &&
         <div className="divider">
            <div className="left">
                <h4>Informations sur la société</h4>
              
                {/* <p className="subtext">Merci de nous fournir la fonction que vous representez au sein de la société et l'addresse. </p>: */}
                <p className="subtext">Merci de nous fournir plus de renseignements sur la société. </p>
                 
            </div>
            <div className="right">
                <div className="input-group">
                  {employeur.typeCompte === '2' && 
                     <label>Nom de la société <span className='required'></span>
                        <input type="text" name="societe" id="societe" placeholder="Nom de la société"
                        value={employeur.societe}
                        {...register("societe", {
                         onChange: (e) => {
                           handleInput(e);
                         },
                         required: true,
                         minLength: 2,
                         })}
                        />
                      {errors.societe && errors.societe?.type === "required" && (
                        <span className="error-msg">Ce champ est obligatoire.</span>
                      )}
                     {errors.societe && errors.societe?.type === "minLength" && (
                     <span className="error-msg">
                        ce champ ne peut pas être inférieur à deux caractères.
                     </span>
                     )}
                     </label>
                    }
                   {employeur.typeCompte === '2' &&
                     <label>Secteur d'activité <span className='required'></span>
                       <input type="text" name="activite" id="activite" placeholder="Secteur d'activité" 
                        value={employeur.activite}
                        {...register("activite", {
                         onChange: (e) => {
                           handleInput(e);
                         },
                         required: true,
                         minLength: 2,
                         })}
                       />
                      {errors.activite && errors.activite?.type === "required" && (
                        <span className="error-msg">Ce champ est obligatoire.</span>
                      )}
                     {errors.activite && errors.activite?.type === "minLength" && (
                     <span className="error-msg">
                        ce champ ne peut pas être inférieur à deux caractères.
                     </span>
                     )}
                     </label>
                    }
                   
                 
                    
                    
                    {employeur.typeCompte === '2' && 
                      <label>
                        Préfecture ou Commune <span className='required'></span>
                        <select name="prefecture_id" id="prefecture_id1"
                        {...register("prefecture_id", {
                            onChange: (e) => {
                            handleInput(e);
                            },
                            validate: (value) => parseInt(value) !== 0,
                        })}
                        value={employeur.prefecture_id}
                        >
                            <option value={0}>Choisir la Préfecture ou Commune</option>
                            {preloadData?.prefectures && preloadData.prefectures.map((prefecture) => (<option value={prefecture.prefecture_id} key={prefecture.prefecture_id}>{prefecture.nom}</option>)) }
                        </select>
                        {errors.prefecture_id && (
                          <span className="error-msg">Ce champ est obligatoire.</span>
                        )}
                      </label>
                    }
                </div>
                {employeur.typeCompte === '2' && 
                  <>
                    <div className="input-group">
                        <label>Commune <span className='required'></span>
                            <select name="commune_id" id="commune_id1"
                            {...register("commune_id", {
                                onChange: (e) => {
                                handleInput(e);
                                },
                                validate: (value) => parseInt(value) !== 0,
                            })}
                        value={employeur.commune_id}
                        >
                        <option value={0}>Choisir la Commune</option>
                        {selectedcommunes.length > 0 && selectedcommunes.map((commune) => (<option value={commune.commune_id} key={commune.commune_id}> {commune.nom}</option>))}
                        </select>
                        {errors.commune_id && (
                            <span className="error-msg">Ce champ est obligatoire.</span>
                        )}
                        </label>
                        
                    
                        <label>Quartier <span className='required'></span>
                        <select name="quartier" id="quartier1"
                                value={employeur.quartier_id}
                                {...register("quartier_id", {
                                    onChange: (e) => {
                                    handleInput(e);
                                    },
                                    validate: (value) => parseInt(value) !== 0,
                                })}
                            >
                            <option value={0}>Choisir le Quartier/District</option>
                            {selectedquartiers.length > 0 && selectedquartiers.map((quartier) => (<option value={quartier.quartier_id} key={quartier.quartier_id}> {quartier.nom}</option>))}
                            </select>
                            {errors.quartier_id && (
                            <span className="error-msg">Ce champ est obligatoire.</span>
                        )}
                        </label>
                        <label>Addresse
                            <input type="text" name="addresse" id="addresse" placeholder='Addresse de la société'
                              onChange={handleInput}
                            />
                        </label>
                  
                        
                    </div>
                    <div className="input-group">
                       <label>Representé par  <span className='required'></span>
                         <select name="civilite" id="civilite"
                            {...register("civilite", {
                                onChange: (e) => {
                                handleInput(e);
                                },
                                validate: (value) => value !== "",
                            })}
                            value={employeur.civilite}
                            >
                            <option value="Monsieur">Monsieur</option>
                            <option value="Madame">Madame</option>
                            <option value="Mademoiselle">Mademoiselle</option>
                        </select>
                       {errors.civilite && (
                        <span className="error-msg">Ce champ est obligatoire.</span>
                       )}
                       </label>
                       <label> Prénom <span className='required'></span>
                            <input type="text" name="prenom" id="prenom" placeholder="Prénom" 
                                value={employeur.prenom}
                                {...register("prenom", {
                                    onChange: (e) => {
                                    handleInput(e);
                                    },
                                    required: true,
                                    minLength: 2,
                                })}
                                />
                            {errors.prenom && errors.prenom?.type === "required" && (
                            <span className="error-msg">Ce champ est obligatoire.</span>
                            )}
                            {errors.prenom && errors.prenom?.type === "minLength" && (
                            <span className="error-msg">
                                ce champ ne peut pas être inférieur à deux caractères.
                            </span>
                            )}
                       </label>
                       <label>Nom <span className='required'></span>
                         <input type="text" name="nom" id="nom" placeholder="Nom" 
                            value={employeur.nom}
                            {...register("nom", {
                            onChange: (e) => {
                                handleInput(e);
                            },
                            required: true,
                            minLength: 2,
                            })}
                            />
                        {errors.nom && errors.nom?.type === "required" && (
                            <span className="error-msg">Ce champ est obligatoire.</span>
                        )}
                        {errors.nom && errors.nom?.type === "minLength" && (
                        <span className="error-msg">
                            ce champ ne peut pas être inférieur à deux caractères.
                        </span>
                        )}
                      </label>
                      <label>Fonction <span className='required'></span>
                        <input type="text" name="fonction" id="fonction" placeholder="Fonction du representant" 
                            value={employeur.fonction}
                            {...register("fonction", {
                            onChange: (e) => {
                                handleInput(e);
                            },
                            required: true,
                            minLength: 2,
                            })}
                            />
                        {errors.fonction && errors.fonction?.type === "required" && (
                            <span className="error-msg">Ce champ est obligatoire.</span>
                        )}
                        {errors.fonction && errors.fonction?.type === "minLength" && (
                            <span className="error-msg">
                            ce champ ne peut pas être inférieur à deux caractères.
                            </span>
                            )}
                      </label> 
                    </div>
                 </>
                 
                
                }


            </div>
        </div>}

        <div className="divider">
            <div className="left">
                <h4>Identifiants</h4>
                <p className="subtext">Une fois votre compte créé, ces informations vous permettront de vous connecter sur la plateforme.</p>
            </div>
            <div className="right">
                <div className="input-group">
                <label>Téléphone <span className='required'></span>
                        <input type="tel" name="tel" id="tel" placeholder="Téléphone" 
                        value={employeur.tel}
                        {...register("tel", {
                        onChange: (e) => {
                            handleInput(e);
                        },
                        required: true,
                        maxLength: 9,
                        minLength: 5,
                        pattern: /6[0-9]{8}$/g,
                        })} 
                        />
                    {errors.tel && errors.tel?.type === "required" && (
                        <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                    {errors.tel && errors.tel?.type === "minLength" && (
                    <span className="error-msg">
                        Ne peut pas être inférieur à 5 caractères.
                    </span>
                    )}
                    {errors.tel && errors.tel?.type === "maxLength" && (
                        <span className="error-msg">
                            Ne peut pas être supérieur à 9 caractères.
                        </span>
                    )}
                    {errors.tel && errors.tel.type === "pattern" && (
                        <span role="alert" className="error-msg">
                            Format invalide.ex: 620000000
                        </span>
                        )}
                 </label>
                 {employeur.typeCompte === '2' ?
                   <label>Email <span className='required'></span>
                    <input type="email" name="email" id="email" placeholder="monmail@domaine.com"
                    value={employeur?.email?employeur.email:''} autoComplete="off"
                    {...register("email", 
                    {  onChange: (e) => {
                      handleInput(e)
                   },
                     required:true,
                     pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                    />
                    {(errors.email && errors.email?.type === "required") && (
                    <span className="error-msg">Le Champ email est obligatoire.</span>
                    )}
                    {(errors.email  && !errors.email?.type === "required")  && (
                    <span className="error-msg">Mauvais format d'email.Exemple(example@exemple.com).</span>
                    )}
                   </label>:
                      <label>Email
                         <input type="email" name="email" id="email" placeholder="monmail@domaine.com"
                         value={employeur?.email?employeur.email:''} autoComplete="off"
                         {...register("email", 
                         {  onChange: (e) => {
                           handleInput(e)
                        },
                          required:false,
                          pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                         />
                
                        </label>
                   }
                </div>
                <div className='input-group'>
                  <label>Mot de passe <span className='required'></span>
                        <input type="password" name="password" id="mdp" placeholder="Mot de passe" 
                            value={employeur.password}
                            {...register("password", {
                            onChange: (e) => {
                                handleInput(e);
                            },
                            required: true,
                            minLength: 5,
                        })}
                        />
                     {errors.password && errors.password?.type === "required" && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                     )}
                    {errors.password && errors.password?.type === "minLength" && (
                    <span className="error-msg">
                        Le mot de passe ne peut être inférieur à cinq (5) caractères.
                    </span>
                    )}
                    </label>
                    <label>Confirmez mot de passe <span className='required'></span>
                        <input type="password" name="confirmMdp" id="confirmMdp" placeholder="Confirmez le Mot de passe" 
                           value={employeur.confirmMdp}
                          {...register("confirmMdp", {
                            onChange: (e) => {
                                handleInput(e);
                            },
                            validate: (value) => value === employeur.password,
                            })}
                        />
                      {errors.confirmMdp && (
                         <span className="error-msg">Les deux mot de passe doivent être identiques.</span>
                      )}
                    </label>
                </div>
            </div>
        </div>

        <div className="divider">
            <div className="left">
                <h4>Documents</h4>
                <p className="subtext">Merci d'ajouter les documents demandés.</p>
            </div>
            <div className="right">
                <div className="input-group">
                    <label>Type de Pièce <span className='required'></span>
                        <select name="doctype" id="doctype"
                         value={employeur.doctype}
                         {...register("doctype", {
                            onChange: (e) => {
                                handleInput(e);
                            },
                            validate: (value) => parseInt(value) !== 0,
                            })}
                        >
                            <option value={0}>Selectionner le type de pièce</option>
                            <option value="Carte d'identité">Carte d'identité</option>
                            <option value="Passeport">Passeport</option>
                            <option value="Carte de séjour">Carte de séjour</option>
                           {employeur.typeCompte === '1' && 
                           <option value="Permis de conduire">Permis de conduire</option>}
                        </select>
                        {errors.doctype && (
                         <span className="error-msg">Ce champ est obligatoire.</span>
                       )}
                    </label>
                    <label>Num de pièce d'identité <span className='required'></span>
                        <input type="text" name="numpiece" id="numpiece" placeholder="Numero" 
                          value={employeur.numpiece}
                          {...register("numpiece", {
                          onChange: (e) => {
                            handleInput(e);
                           },
                          required: true,
                          minLength: 4,
                         })}
                        />
                     {errors.numpiece && errors.numpiece?.type === "required" && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                     )}
                    {errors.numpiece && errors.numpiece?.type === "minLength" && (
                    <span className="error-msg">
                        ce champ ne peut pas être inférieur à quatre(4) caractères.
                    </span>
                    )}
                    </label>
                </div>
                {employeur.typeCompte === '2' &&
                  <div className="input-group">
                    <label>Code NIF <span className='required'></span>
                        <input type="text" name="numcn" id="numcn" placeholder="Code NIF"
                         value={employeur.numcn}
                         {...register("numcn", {
                         onChange: (e) => {
                           handleInput(e);
                          },
                         required: true,
                         minLength: 4,
                        })}
                        />
                     {errors.numcn && errors.numcn?.type === "required" && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                     )}
                    {errors.numcn && errors.numcn?.type === "minLength" && (
                    <span className="error-msg">
                        ce champ ne peut pas être inférieur à quatre(4) caractères.
                    </span>
                    )}
                    
                    </label>
                    <label>RCCM <span className='required'></span>
                         <input type="text" name="rccm" id="rccm" placeholder="Numero RCCM"
                            value={employeur.rccm}
                            {...register("rccm", {
                            onChange: (e) => {
                            handleInput(e);
                            },
                            required: true,
                            minLength: 4,
                            })}
                         />
                     {errors.rccm && errors.rccm?.type === "required" && (
                       <span className="error-msg">Ce champ est obligatoire.</span>
                      )}
                      {errors.rccm && errors.rccm?.type === "minLength" && (
                      <span className="error-msg">
                         ce champ ne peut pas être inférieur à quatre(4) caractères.
                      </span>
                      )}
                    </label>
                  </div>
                  }
                <div className="input-group file-input">
                    <div className="group">
                        <span className='required'>Copie Pièce</span>
                        <label className="file-input-label">
                            <AiOutlineUpload />
                            Importer la Pièce
                            <input type="file" name="num_rc_file"
                             {...register("num_rc_file", {
                                onChange: (e) => {
                                  handleInput(e);
                                },
                                validate: (value) => value !== "" && value.length !== 0
                             })}
                            />
                          
                        </label>

                        {errors.num_rc_file && (
                          <span className="error-msg" style={{color:'red'}}>Veuillez choisir la copie de la pièce jointe.</span>
                        )}
                       {employeur.num_rc_file !== '' && <span><strong>Fichier selectionné : </strong>{employeur.num_rc_file?.name}</span>}
                    </div>
                    {employeur.typeCompte === '2' &&
                      <>
                        <div className="group">
                            <span className='required'>Copie NIF </span>
                            <label className="file-input-label">
                                <AiOutlineUpload />
                                Importer le NIF
                                <input type="file" name="cn_rc_file" 
                                 {...register("cn_rc_file", {
                                    onChange: (e) => {
                                      handleInput(e);
                                    },
                                    validate: (value) => value !== "" && value.length !== 0
                                 })}
                                />
                            </label>
                            {errors.cn_rc_file && (
                                  <span className="error-msg" style={{color:'red'}}>Veuillez choisir la copie de la pièce jointe.</span>
                             )}
                             {employeur.cn_rc_file !== '' && <span><strong>Fichier selectionné : </strong>{employeur.cn_rc_file?.name}</span>}
                        </div>
                        <div className="group">
                            <span className='required'>Copie RCCM </span>
                            <label className="file-input-label">
                                <AiOutlineUpload />
                                Importer le RCCM
                                <input type="file" name="rccm_file" 
                                 {...register("rccm_file", {
                                    onChange: (e) => {
                                      handleInput(e);
                                    },
                                    validate: (value) => value !== "" && value.length !== 0
                                 })}
                                />
                            </label>
                            {errors.rccm_file && (
                                  <span className="error-msg" style={{color:'red'}}>Veuillez choisir la copie de la pièce jointe.</span>
                             )}
                             {employeur.rccm_file !== '' && <span><strong>Fichier selectionné : </strong>{employeur.rccm_file?.name}</span>}
                        </div>
                        <div className="group">
                            <span>Logo</span>
                            <label className="file-input-label">
                                <AiOutlineUpload />
                                Importer le logo
                                <input type="file" name="logo"  accept='image/*'
                                   onChange={handleInput}
                                />
                            </label>
                            {employeur.logo !== '' && <span><strong>Fichier selectionné : </strong>{employeur.logo?.name}</span>}
                        </div>
                      </>
                        
                    }
                </div>
            </div>
        </div>
        <Erreurs validation = {erreurs} />
        <div className="divider submit">
            <div className="left"></div>
            <div className="right">
                <p className="subtext">En vous inscrivant, vous acceptez nos <strong className='link'><Link to={"/cgu"}>conditions générales d'utilisation</Link></strong></p>
                <button className='primary'>Enregistrer</button>
            </div>
        </div>
      </form>
    </div>
  );
};

export default SignUp;
