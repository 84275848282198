import { Link, useNavigate } from "react-router-dom";
import {
  AiOutlineHome,
  AiOutlineUser,
  AiOutlineContainer,
  AiOutlineSetting,
  AiOutlinePoweroff,
  AiOutlineBook,
} from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";
import Principale from "../../utils/services/Principale";

const UserMenuDropdown = ({ isOpen, user, setUser }) => {
  const navigate = useNavigate();
  const api = new Principale();
  const disconnect = () => {
    api.logout();
    setUser("");
    navigate("/");
    return;
  };
  if(!user) return null;

  return (
    <AnimatePresence>
      {isOpen && (
       
        <motion.nav
          className="user-menu-dropdown"
          initial={{ opacity: 0, top: "30%" }}
          animate={{ opacity: 1, top: "140%" }}
          transition={{ duration: 0.2 }}
          exit={{ opacity: 0, top: "10%" }}
        >
         {(user?.role_id === 3 || user?.role_id === 4) &&
  
          <Link to="/pro/dashboard">
            <span className="icon">
              <AiOutlineHome />
            </span>
            <span className="navigation">Tableau de bord</span>
          </Link>}
          {(parseInt(user?.role_id) === 1 || parseInt(user?.role_id) === 2) &&
            <Link to="/admin/dashboard">
              <span className="icon">
                <AiOutlineHome />
              </span>
              <span className="navigation">Tableau de bord</span>
            </Link>
          }
         {user?.role_id === 3 &&
          <Link to="/recruter/profile">
            <span className="icon">
              <AiOutlineUser />
            </span>
            <span className="navigation">Mon profil</span>
          </Link>}
        
          {/* {(user?.role_id === 3)&&
            <Link to="#">
              <span className="icon">
                <AiOutlineBook />
              </span>
              <span className="navigation">Contrat en Attente</span>
            </Link>
          } */}
          {(user?.role_id !== 1 && user?.role_id !== 2)&&
           <Link to="/mycontracts">
            <span className="icon">
              <AiOutlineContainer />
            </span>
            <span className="navigation">Mes contrats</span>
          </Link>}
          <Link to="/myaccount/settings">
            <span className="icon">
              <AiOutlineSetting />
            </span>
            <span className="navigation">Paramètres</span>
          </Link>
          <Link to="/">
            <span className="icon">
              <AiOutlinePoweroff />
            </span>
            <span className="navigation" onClick={disconnect}>
              Déconnexion
            </span>
          </Link>
        </motion.nav>
      )}
    </AnimatePresence>
  );
};

export default UserMenuDropdown;
