import axios from 'axios';
export default class Principale{
    constructor(){
        this.api_token = localStorage.getItem('token')?.trim();
        this.api_url = process.env.REACT_APP_URL;
        this.client = null;
        this.headers = {
            Accept: "application/json",
          };
          if (this.api_token) {
            this.headers.Authorization = `Bearer ${this.api_token}`;
          }
      
          this.client = axios.create({
            baseURL: this.api_url,
            //timeout: 31000,
            headers: this.headers,
          });
    }
    init = () =>{
        return this.client
    }
    get = async (url,data = '') => {
      const result = await this.init().get(url,data).then((resp) => {
           return resp.data;
        }).catch((ex) => localStorage.removeItem('token'));
      return result;
    }
    post = async (url,data) => {
        const result = await this.init().post(url,data).then((resp) => {
            return resp.data;
          }).catch((ex) => localStorage.removeItem('token'));
          return result;
    }
   logout = async () => {
      if(localStorage.getItem('token')){
        const resp = await this.get('/user/logout');
        if(resp.status === 200)
          localStorage.removeItem('token');
        else localStorage.removeItem('token'); 
        this.api_token = '';
      }
  
   }

   rememberme = (user) => {
    if(user.remember){
       const userData = {
        username:user.username,
         remember:user.remember,
         password:''
       };
      localStorage.setItem('user',JSON.stringify(userData));
    }
    else 
     localStorage.removeItem('user');   
    
}
setToken = (token) => {
   localStorage.setItem('token',token);
   this.api_token = token;
 }

}