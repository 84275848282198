import { useRecoilValue } from "recoil";
import { loadingState } from "../../recoil/loadingAtom";

const Spinner = () => {
  const isLoading = useRecoilValue(loadingState);
  return (
    <>
      {isLoading && (
        <div className="spinner">
          <div className="container">
            <div className="rolling-cube"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default Spinner;
