import { useRef } from "react";
import { FaRegWindowClose } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";

const RecruiterDetailsModal = ({ isOpen, setIsOpen ,selectedRecruiter}) => {
  const modalRef = useRef();
  const url = process.env.REACT_APP_URL + "storage/";
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setIsOpen(false);
    }
  };
 //console.log(selectedRecruiter)
  return (
    <AnimatePresence>
      {isOpen ? (
        <div
          className="recruiter-details-modal modal-component"
          onClick={closeModal}
          ref={modalRef}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, scale: 0 }}
            className="modal"
          >
            <div className="close" onClick={() => setIsOpen(false)}>
              <FaRegWindowClose size={24} id="close-icon" />
            </div>
            <div className="details-recruiter">
              <h2>Détails du recruteur</h2>
              {selectedRecruiter?.type === 'societe' &&
               <div className="logo">
                <img
                  src= {url + selectedRecruiter?.logo?.replace('public/','')}
                  alt="logo"
                />
               </div>}
              <div className="divider">
                <h4>Informations générale</h4>
                <div className="info-group">
                  <div className="info-box">
                    <span className="label subtext">Civilité</span>
                    <p className="info">{selectedRecruiter?.civilite}</p>
                  </div>
                  {selectedRecruiter?.type === 'personne physique' &&
                  <>
                    <div className="info-box">
                      <span className="label subtext">Prenom</span>
                      <p className="info">{selectedRecruiter?.prenom}</p>
                    </div>
                    <div className="info-box">
                      <span className="label subtext">Nom</span>
                      <p className="info">{selectedRecruiter.nom1}</p>
                    </div>
                    <div className="info-box">
                      <span className="label subtext">Profession</span>
                      <p className="info">{selectedRecruiter.profession?selectedRecruiter.profession:'-----------------'}</p>
                    </div>
                  </>
                  }
                {selectedRecruiter?.type === 'societe' &&
                 <>
                   <div className="info-box">
                      <span className="label subtext">Société</span>
                      <p className="info">{selectedRecruiter?.nom}</p>
                    </div>
                    <div className="info-box">
                      <span className="label subtext">Representé par</span>
                      <p className="info">{selectedRecruiter?.representant}</p>
                    </div>
                    <div className="info-box">
                      <span className="label subtext">Fonction</span>
                      <p className="info">{selectedRecruiter?.fonction}</p>
                    </div>
                 </>
                }
                </div>
               
                <div className="info-group">
                  <div className="info-box">
                    <span className="label subtext">Téléphone</span>
                    <p className="info">{selectedRecruiter.telephone}</p>
                  </div>
                  <div className="info-box">
                    <span className="label subtext">Préfecture</span>
                    <p className="info">{selectedRecruiter.prefecture}</p>
                  </div>
                  <div className="info-box">
                    <span className="label subtext">Commune</span>
                    <p className="info">{selectedRecruiter.commune}</p>
                  </div>
                  <div className="info-box">
                    <span className="label subtext">Quartier</span>
                    <p className="info">{selectedRecruiter.quartier}</p>
                  </div>
                </div>
                {selectedRecruiter?.type === 'societe' &&
                <div className="info-group">
                   <div className="info-box">
                      <span className="label subtext">Secteur</span>
                      <p className="info">{selectedRecruiter?.secteur}</p>
                    </div>
                </div>  }
              </div>
              
              {/* {selectedRecruiter.type === 'societe' &&
                <div className="divider">
                <h4>Informations sur la société </h4>
                <div className="info-group">
                  <div className="info-box">
                    <span className="label subtext">Nom de la société</span>
                    <p className="info">Orange</p>
                  </div>
                  <div className="info-box">
                    <span className="label subtext">Secteur d'activité</span>
                    <p className="info">Telecoms</p>
                  </div>
                  <div className="info-box">
                    <span className="label subtext">Préfecture ou commune</span>
                    <p className="info">Kipé</p>
                  </div>
                  <div className="info-box">
                    <span className="label subtext">Quartier</span>
                    <p className="info">Kipé</p>
                  </div>
                </div>
                <div className="info-group">
                  <div className="info-box">
                    <span className="label subtext">Téléphone</span>
                    <p className="info">611969156</p>
                  </div>
                  <div className="info-box">
                    <span className="label subtext">Représenté par</span>
                    <p className="info">Monsieur</p>
                  </div>
                  <div className="info-box">
                    <span className="label subtext">Nom</span>
                    <p className="info">Aboubacar Mansaré</p>
                  </div>
                  <div className="info-box">
                    <span className="label subtext">Fonction</span>
                    <p className="info">Directeur</p>
                  </div>
                </div>
                </div>
              } */}
              <div className="divider">
                <h4>Documents</h4>
                <div className="info-group">
                  <div className="info-box">
                    <span className="label subtext">{selectedRecruiter.type_piece}</span>
                    <a href={url + selectedRecruiter.photo_piece.replace('public/','')}  target='_blank'  className="info">
                      Télécharger
                    </a>
                  </div>
                {selectedRecruiter.type === 'societe' &&
                  <>
                    <div className="info-box">
                        <span className="label subtext">NIF:{selectedRecruiter.nif}</span>
                        <a href={url + selectedRecruiter.nif_photo.replace('public/','')}  target='_blank'  className="info">
                          Télécharger
                        </a>
                      </div>
                      <div className="info-box">
                        <span className="label subtext">RCCM:{selectedRecruiter.rccm}</span>
                        <a href="#" className="info">
                          Télécharger
                        </a>
                      </div>
                    </>
                  }
                  <div className="info-box">
                    <span className="label subtext">Autre document</span>
                    <a href={selectedRecruiter.autre_doc?url + selectedRecruiter.autre_doc.replace('public/',''):'#'} className="info">
                      Télécharger
                    </a>
                  </div>
                  <div className="info-box"> </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      ) : null}
    </AnimatePresence>
  );
};

export default RecruiterDetailsModal;
