import { useEffect, useState,useContext } from 'react';
import { useRecoilState } from 'recoil';
import { loadingState } from '../../recoil/loadingAtom';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { AiOutlineUpload } from 'react-icons/ai'
import { Link, useNavigate } from 'react-router-dom'
import Erreurs from '../../components/Erreurs/Erreurs';
import { UserContext } from '../../utils/Context/Context';
import { FirstUpperCase, objecttoFormData, validateDate } from '../../utils/services/helpers';
import Principale from '../../utils/services/Principale';
// import Profile from '../../components/Profile/Profile';
import profilephoto from '../../assets/images/avatar.png';
import { type } from '@testing-library/user-event/dist/type';

const SignUpPro = () => {
  const [candidat,setCandidat] = useState({
     civilite:'Monsieur',
     prenom:'',
     nom :'',  
     birthday:'',
     tel:'',
     prefecture_id:0,
     commune_id:0,
     quartier_id:0,
     nationalite_id: 94,
     profession:'',
     metier_id:0,
     experience:0,
     email:'',
     password:'',
     confirmMdp:'',
     doctype: 0,
     num_rc_file:'',
     numpiece:'',
     prenomnomref:[],
     postref:[],
     telref:[],
     cv:'',
     niveau_etude:'',
     copie_nve:'',
     age:function (){
        return false
     },
     photoProfile:'',
    }
  );
  const {register,handleSubmit,reset,setError,formState: { errors },} = useForm({defaultValues: {candidat,},}); 
  const [preloadData,setPreloadData]= useState([]);
  const[erreurs,setErreurs] = useState([]);
  const [erreurReferent,setErreurReferent] = useState([])
  const[selectedcommunes,setSelectedcommunes] = useState([]);
  const[selectedquartiers,setSelectedquartiers] = useState([]);
  const api = new Principale();
  const {setUser} = useContext(UserContext);
  const [isLoading,setIsLoading] = useRecoilState(loadingState);
  const [previews,setPreviews] = useState({profilePhoto:''});
  const navigate = useNavigate();
  const getPreloadData = async () => {
    const{status,messages,data} = await api.get('/getPreloadData');
    setPreloadData([]);setErreurs([]);
    if(status === 200)
      setPreloadData(data)
    else setErreurs(messages);  
  }
 
  Array.prototype.delete = function(pos){
    this[pos] = undefined;
    var len = this.length - 1;
    for(var a = pos;a < this.length - 1;a++){
      this[a] = this[a+1];
    }
    this.pop();
  }
// localStorage.removeItem('token')
  useEffect(() => {
    getPreloadData();
    reset(candidat);
    window.scrollTo({ top: -50, left: 0, behavior: 'smooth' });
  },[]);
  const handleInput = (e) => {
      setErreurs([]);
    if(e.target.name === 'prefecture_id'){
        setSelectedcommunes([]);setSelectedquartiers([]);
        setCandidat({...candidat,[e.target.name]:e.target.value,commune_id:0,quartier_id:0});
        if(preloadData.communes)
          setSelectedcommunes(preloadData.communes.filter((c) => c.prefecture_id === parseInt(e.target.value)).map((commune) => (commune)))
    }else if(e.target.name === 'commune_id'){
        setSelectedquartiers([]);setCandidat({...candidat,[e.target.name]:e.target.value,quartier_id:0});
        if(preloadData.quartiers)
          setSelectedquartiers(preloadData.quartiers.filter((q) => q.commune_id === parseInt(e.target.value)).map((quartier) => (quartier)));
    }else if(e.target.name === 'birthday'){
        setCandidat({...candidat,[e.target.name]:e.target.value,age:function(){
            if(e.target.value.length > 0 ){
                var today = new Date();
                var birthDate = new Date(e.target.value);
                var age = today.getFullYear() - birthDate.getFullYear();
                var m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
                {
                    age--;
                }
            return age;  
         }
         return 0;
        }});  
    }else if(e.target.name === "num_rc_file" || e.target.name === "photoProfile" || e.target.name === 'cv' || e.target.name === 'copie_nve'){
      setCandidat({...candidat,[e.target.name]:e.target.files[0]});
      if(e.target.name === "photoProfile"){
        setPreviews({...previews,profilePhoto:URL.createObjectURL(e.target.files[0])});
        setError("photoProfile", {
          type: "manual",
          message: ""
        });
      }
    }
    else if(e.target.name === 'numpiece')
      setCandidat({...candidat,[e.target.name]:e.target.value.toUpperCase()});
    else if(e.target.name === 'niveau_etude'){
       if(e.target.value === '' || e.target.value === "Pas de diplôme(N.A)"){
        setCandidat({...candidat,[e.target.name]:e.target.value,copie_nve:''});
       }
       else{
        setCandidat({...candidat,[e.target.name]:e.target.value})
       }
    }
    else if(e.target.name === 'tel'){
      const re = /^[0-9\b]+$/;
      if((e.target.value.length <= 9 && re.test(e.target.value)) || e.target.value === '')
        setCandidat({...candidat,[e.target.name]:e.target.value});
    }
    else if(e.target.name === 'email' || e.target.name === 'experience'){
      setCandidat({...candidat,[e.target.name]:e.target.value});
    } 
    else if(e.target.name === 'doctype')
     setCandidat({...candidat,[e.target.name]:e.target.value})
    else
        setCandidat({...candidat,[e.target.name]:FirstUpperCase(e.target.value)});
    
  }
  const hanleReference = (index,e) => {
     const refeErr = erreurReferent;
     if(e.target.name === 'prenomnomref'){
       var prenomnomref = candidat.prenomnomref;prenomnomref[index] = FirstUpperCase(e.target.value);
       setCandidat({...candidat,[e.target.name]:prenomnomref});
       if(refeErr[index]?.prenomnom) refeErr[index].prenomnom = '';
     }
     else if(e.target.name === 'postref'){
       var postref = candidat.postref;postref[index] = FirstUpperCase(e.target.value); 
       setCandidat({...candidat,[e.target.name]:postref});
       if(refeErr[index]?.post) refeErr[index].post = '';
         
     }
     else if(e.target.name === 'telref'){
      if(e.target.value.length <= 9){
        var telref = candidat.telref;telref[index] = FirstUpperCase(e.target.value); 
        if(refeErr[index]?.tel) refeErr[index].tel = '';

        setCandidat({...candidat,[e.target.name]:telref});
      }
    }
   setErreurReferent(refeErr);
  }
  const inscription = async () => {
    let is_error = false;
   
   if(candidat.niveau_etude !== '' && candidat.niveau_etude !== "Pas de diplôme(N.A)"){
     if(candidat.copie_nve === ''){
        setError("copie_niveau", {
          type: "manual",
          message: "La copie de diplôme est obligatoire."
        });
       is_error = true;
     }
   }
   const referreur = [{
    prenomnom:'',
    post:'',
    tel:''
   },
   {
    prenomnom:'',
    post:'',
    tel:''
   },
  ]
  if(typeof candidat.prenomnomref[0] === 'undefined' || candidat?.prenomnomref[0].trim() === ''){
    referreur[0].prenomnom = 'Le prenom et nom sont obligatoire';is_error = true;
  }
  if(typeof candidat.postref[0] === 'undefined' || candidat.postref[0].trim() === ''){
    referreur[0].post = 'Le profil du referent est obligatoire';is_error = true;
  }
  if(typeof candidat.telref[0] === 'undefined' || candidat.telref[0].trim() === ''){
    referreur[0].tel = 'Le telephone du referent est obligatoire';is_error = true;
  }
  if(typeof candidat.prenomnomref[1] === 'undefined' || candidat?.prenomnomref[1].trim() === ''){
    referreur[1].prenomnom = 'Le prenom et nom sont obligatoire';is_error = true;
  }
  if(typeof candidat.postref[1] === 'undefined' || candidat.postref[1].trim() === ''){
    referreur[1].post = 'Le profil du referent est obligatoire';is_error = true;
  }
  if(typeof candidat.telref[1] === 'undefined' || candidat.telref[1].trim() === ''){
    referreur[1].tel = 'Le telephone du referent est obligatoire';is_error = true;
  }

  setErreurReferent(referreur);
  if(is_error) return;

      var formData = objecttoFormData(candidat);
      setErreurs([]);setIsLoading(true);
      const {status,messages,token} = await api.post('/client/candidat/new',formData);
      if(status === 200){
          api.setToken(token);
          new Principale().get('/profile').then((resp) => {
              if(resp?.status === 200)
                setUser(resp?.user);
          });
          setIsLoading(false);toast.success('Inscription effectuée avec succès.');
          navigate('/');
      }
      else {
          setErreurs(messages); setIsLoading(false);
      }
  }

  return (
    <div className="sign-up page">
    <div className="header">
        <h2>Inscription - Professionnels</h2>
        <p className="subtext">
            Vous êtes un professionnel du métier voulant proposer vos services ? Merci de renseigner les champs suivants pour vous inscrire.
        </p>
    </div>
  <form onSubmit={handleSubmit(inscription)}>
    <div className="divider">
        <div className="left">
            <h4>Informations personnelles</h4>
            <p className="subtext">Merci de nous donner quelques renseignements sur votre personne.</p>
        </div>
        <div className="right">
          <div className="input-group file-input photo-input">
                  <div className="group">
                      <span>Photo</span>
                      <div className="photo">
                        <img src={previews?.profilePhoto ?previews?.profilePhoto:profilephoto} alt="" />
                      </div>
                      <label className="file-input-label">
                          <AiOutlineUpload />
                           Importer Photo
                          <input type="file" 
                             name="photoProfile" 
                             accept="image/*" 
                          {...register("photoProfile", {
                            onChange: (e) => {
                              handleInput(e);
                            },
                            validate: (value) => value !== "" && value.length !== 0,
                          })}
        
                          />
                      </label>
                      {(errors.photoProfile && errors.photoProfile?.type === 'validate') && (
                        <span className="error-msg" style={{ color:'#e63946',fontWeight:'500'}}>La photo est obligatoire.</span>
                       )}
                  </div>
                  {/* {errors?.photoProfile !== "" &&  <span className="error-msg">La photo est obligatoire.</span>} */}
                  {/* <Profile /> */}
            </div>
            <div className="input-group">
                <label >Civilité <span className='required'></span>
                    <select name="civilite" id="civilite" 
                      {...register("civilite", {
                        onChange: (e) => {
                          handleInput(e);
                        },
                        validate: (value) => value !== "",
                      })}
                      value={candidat.civilite}
                     >
                        <option value="Monsieur">Monsieur</option>
                        <option value="Madame">Madame</option>
                        <option value="Mademoiselle">Mademoiselle</option>
                    </select>
                    {errors.civilite && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                </label>
                <label>Prénom <span className='required'></span>
                    <input type="text" name="prenom" id="prenom" placeholder="Prénom" 
                      value={candidat.prenom}
                      {...register("prenom", {
                        onChange: (e) => {
                          handleInput(e);
                        },
                        required: true,
                        minLength: 2,
                      })}
                    />
                   {errors.prenom && errors.prenom?.type === "required" && (
                     <span className="error-msg">Ce champ est obligatoire.</span>
                   )}
                    {errors.prenom && errors.prenom?.type === "minLength" && (
                    <span className="error-msg">
                        ce champ ne peut pas être inférieur à deux caractères.
                    </span>
                    )}
                </label>
                <label>Nom <span className='required'></span>
                    <input type="text" name="nom" id="nom" placeholder="Nom"
                      value={candidat.nom}
                       {...register("nom", {
                        onChange: (e) => {
                          handleInput(e);
                        },
                        required: true,
                        minLength: 2,
                      })}
                    />
                   {errors.nom && errors.nom?.type === "required" && (
                     <span className="error-msg">Ce champ est obligatoire.</span>
                   )}
                    {errors.nom && errors.nom?.type === "minLength" && (
                    <span className="error-msg">
                        ce champ ne peut pas être inférieur à deux caractères.
                    </span>
                    )}
                </label>
                <label>Date de naissance <span className='required'></span>
                    <input type="date" name="birthday" id="birthday" 
                     value={candidat.birthday}
                     {...register('birthday', {
                        onChange: (e) => {
                            handleInput(e)
                        },
                        validate: (value) => candidat.age() > 18 && validateDate(value)},
                       )}
                    />
                    {errors.birthday && (
                        <span className="error-msg"> La date de naissance choisie n'est pas valide.Elle doit être supérieur ou égale à 18 ans</span>
                    )} 
                </label>
            </div>

            <div className="input-group">
      
                <label>Préfecture/Commune <span className='required'></span>
                    <select name="prefecture_id" id="prefecture_id"
                       {...register("prefecture_id", {
                         onChange: (e) => {
                           handleInput(e);
                         },
                         validate: (value) => parseInt(value) !== 0,
                       })}
                       value={candidat.prefecture_id}
                    >
                     <option value={0}>Selectionner la Préfecture/Commune</option>
                     {preloadData?.prefectures && preloadData.prefectures.map((prefecture) => (<option value={prefecture.prefecture_id} key={prefecture.prefecture_id}>{prefecture.nom}</option>)) }
                    </select>
                    {errors.prefecture_id && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                </label>
                <label>Commune <span className='required'></span>
                    <select name="commune_id" id="commune_id"
                     {...register("commune_id", {
                        onChange: (e) => {
                          handleInput(e);
                        },
                        validate: (value) => parseInt(value) !== 0,
                      })}
                      value={candidat.commune_id}
                    >
                        <option value={0}>Selectionner la Commune</option>
                        {selectedcommunes.length > 0 && selectedcommunes.map((commune) => (<option value={commune.commune_id} key={commune.commune_id}> {commune.nom}</option>))}
                    </select>
                    {errors.commune_id && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                </label>
                <label>Quartier <span className='required'></span>
                    <select name="quartier_id" id="quartier_id"
                    value={candidat.quartier_id}
                    {...register("quartier_id", {
                        onChange: (e) => {
                          handleInput(e);
                        },
                        validate: (value) => parseInt(value) !== 0,
                      })}
                    >
                        <option value={0}>Selectionner le Quartier/District</option>
                        {selectedquartiers.length > 0 && selectedquartiers.map((quartier) => (<option value={quartier.quartier_id} key={quartier.quartier_id}> {quartier.nom}</option>))}
                    </select>
                    {errors.quartier_id && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                </label>
            </div>
        </div>
    </div>

    <div className="divider">
        <div className="left">
            <h4>Experience professionnelle</h4>
            <p className="subtext">Merci de nous fournir des renseignements sur votre experience professionnelle.</p>
        </div>
        <div className="right">
            <div className="input-group">
            <label>Nationalité <span className='required'></span>
                <select name="nationalite_id" id="nationalite_id"
                    {...register("nationalite_id", {
                    onChange: (e) => {
                        handleInput(e);
                    },
                    validate: (value) => parseInt(value) !== 0,
                    })}
                    value={candidat.nationalite_id}
                 >
                    <option value={0}>Nationalité</option>
                    {preloadData?.nationalites && preloadData.nationalites.map((nationalite) => (<option value={nationalite.nationalite_id} key={nationalite.nationalite_id}>{nationalite.nom}</option>))}
                </select>
                {errors.nationalite_id && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                 )}
             </label>
                <label>Profession
                    <input type="text" name="profession" id="profession" placeholder='prefession' 
                     value={candidat.profession}
                     {...register("profession", {
                      onChange: (e) => {
                        handleInput(e);
                      },
                      required: true,
                      minLength: 2,
                    })}
                    />
                   {errors.profession && errors.profession?.type === "required" && (
                     <span className="error-msg">Ce champ est obligatoire.</span>
                   )}
                    {errors.profession && errors.profession?.type === "minLength" && (
                    <span className="error-msg">
                        ce champ ne peut pas être inférieur à deux caractères.
                    </span>
                    )}
                </label>
                <label>Métier <span className='required'></span>
                    <select name="metier_id" id="metier_id"
                     {...register("metier_id", {
                        onChange: (e) => {
                            handleInput(e);
                        },
                        validate: (value) => parseInt(value) !== 0,
                        })}
                        value={candidat.metier_id}
                     >
                        <option value={0}>Métier Exercé</option>
                        {preloadData?.metiers && preloadData.metiers.map((metier) => (<option value={metier.metier_id} key={metier.metier_id}>{metier.nom}</option>))}
                    </select>
                    {errors.metier_id && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                </label>
                <label>Expérience <span className='required'></span>
                    <select name="experience" id="experience"
                     value={candidat.experience}
                     {...register("experience", {
                        onChange: (e) => {
                            handleInput(e);
                        },
                        validate: (value) => parseInt(value) !== 0,
                        })}
                     >
                       <option value={0}>Veuillez choisir votre expérience </option>
                        {/* <option value="Moins d'un an">Moins d'un an </option>
                        <option value="Un An"> Un An </option>
                        <option value="Plus d'un An"> Plus d'un An </option>
                        <option value="Deux Ans"> Deux Ans </option>
                        <option value="Plus de deux Ans"> Plus de deux Ans </option>
                        <option value="Trois Ans"> Trois Ans </option>
                        <option value="Plus de trois Ans"> Plus de trois Ans</option>
                        <option value="Quatre Ans"> Quatre Ans</option>
                        <option value="Plus de quatre Ans">Plus de quatre Ans</option>
                        <option value="Cinq Ans">Cinq Ans</option>
                        <option value="Plus de cinq Ans">Plus de cinq Ans</option> */}
                     <option value="Moins d'un an"> Moins d'un an</option>
                     <option value="Un An"> Un An </option>
                     <option value="Deux Ans"> Deux Ans </option>
                     <option value="Trois Ans"> Trois Ans </option>
                     <option value="Quatre Ans"> Quatre Ans </option>
                     <option value="Cinq Ans"> Cinq Ans </option>
                     <option value="Dix Ans"> Dix Ans </option>
                     <option value="Quinze Ans"> Quinze Ans </option>
                     <option value="Vingt Ans"> Vingt Ans </option>
                     <option value="Vingt Cinq Ans"> Vingt Cinq Ans </option>
                     <option value="Trente Ans"> Trente Ans </option>
                     <option value="Plus de 30"> Plus de 30 </option>
          
                    </select>
                    {errors.experience && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                </label>
            </div>
            <div className="input-group">
              <label>Prénom et Nom du référent 1 <span className='required'></span>
                <input type="text" name="prenomnomref" id="ref1"
                 value={candidat?.prenomnomref.length > 0 ?candidat.prenomnomref[0]:'' } 
                 onChange={(e) => hanleReference(0,e)}/>
                 {(typeof erreurReferent[0]?.prenomnom !== 'undefined' && erreurReferent[0]?.prenomnom)&&
                  <span className="error-msg">{erreurReferent[0]?.prenomnom }</span>}
              </label>
              <label>Profil du référent <span className='required'></span>
                <input type="text" name="postref" id="ref2"
                 value={candidat?.postref.length > 0 ? candidat.postref[0]:''}
                 onChange={(e) => hanleReference(0,e)}
                />
                  {(typeof erreurReferent[0]?.post !== 'undefined' && erreurReferent[0]?.post) &&
                  <span className="error-msg">{erreurReferent[0]?.post }</span>}
              </label>
              <label>Téléphone du référent <span className='required'></span>
                <input type="text" name="telref" id="ref3" 
                   value={candidat?.telref.length > 0 ? candidat.telref[0]:''}
                   onChange={(e) => hanleReference(0,e)}
                />
                {(typeof erreurReferent[0]?.tel !== 'undefined' && erreurReferent[0]?.tel) &&
                  <span className="error-msg">{erreurReferent[0]?.tel }</span>}
              </label>
            </div>
            {((candidat?.prenomnomref[0] !== '' && typeof candidat?.prenomnomref[0] !== 'undefined') 
               && (candidat?.postref[0] !== '' && typeof candidat?.postref[0] !== 'undefined') 
               && (candidat.telref[0] !== '' && typeof candidat.telref[0]!== 'undefined'))&&
             <div className="input-group">
              <label>Prénom et Nom du référent 2 <span className='required'></span>
                <input type="text" name="prenomnomref" id="ref4" 
                 value={candidat.prenomnomref.length > 1?candidat.prenomnomref[1]:'' } 
                 onChange={(e) => hanleReference(1,e)}
                />
                {(typeof erreurReferent[1]?.prenomnom !== 'undefined' && erreurReferent[1]?.prenomnom) &&
                  <span className="error-msg">{erreurReferent[1]?.prenomnom }</span>}
              </label>
              <label>Profil du référent <span className='required'></span>
                <input type="text" name="postref" id="ref5" 
                    value={candidat.postref.length > 1 ?candidat.postref[1]:'' } 
                    onChange={(e) => hanleReference(1,e)}
                />
                {(typeof erreurReferent[1]?.post !== 'undefined' && erreurReferent[1]?.post) &&
                  <span className="error-msg">{erreurReferent[1]?.post }</span>}
              </label>
              <label>Téléphone du référent <span className='required'></span>
                <input type="text" name="telref" id="ref6" 
                   value={candidat.telref.length > 1 ?candidat.telref[1]:'' } 
                   onChange={(e) => hanleReference(1,e)}
                />
                {erreurReferent[1]?.tel &&
                  <span className="error-msg">{erreurReferent[1]?.tel }</span>}
              </label>
             </div>}
        </div>
    </div>

    <div className="divider">
        <div className="left">
            <h4>Identifiants</h4>
            <p className="subtext">Une fois votre compte créé, ces informations vous permettront de vous connecter sur la plateforme.</p>
        </div>
        <div className="right">
            <div className="input-group">
              <label>Téléphone <span className='required'></span>
                    <input type="tel" name="tel" id="tel" placeholder="Téléphone"
                     value={candidat.tel}
                      {...register("tel", {
                        onChange: (e) => {
                          handleInput(e);
                        },
                        required: true,
                        maxLength: 9,
                        minLength: 5,
                        pattern: /6[0-9]{8}$/g,
                      })} 
                    />
                    {errors.tel && errors.tel?.type === "required" && (
                        <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                    {errors.tel && errors.tel?.type === "minLength" && (
                        <span className="error-msg">
                        Ne peut pas être inférieur à 5 caractères.
                        </span>
                    )}
                    {errors.tel && errors.tel?.type === "maxLength" && (
                        <span className="error-msg">
                        Ne peut pas être supérieur à 9 caractères.
                        </span>
                    )}
                    {errors.tel && errors.tel.type === "pattern" && (
                        <span role="alert" className="error-msg">
                            Format invalide.ex: 620000000
                        </span>
                        )}
                </label>
                <label>Email
                 <input type="email" name="email" id="email" placeholder="monmail@domaine.com"
                    value={candidat.email}
                    {...register("email", 
                    {  onChange: (e) => {
                      handleInput(e)
                   },
                  required:false,
                  pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                    />
                  {/* {(errors.email && errors.email?.type === "required") && (
                    <span className="error-msg">Le Champ email est obligatoire.</span>
                  )} */}
                   {(errors.email  && !errors.email?.type === "required")  && (
                    <span className="error-msg">Mauvais format d'email.Exemple(example@exemple.com).</span>
                  )}
                </label>
            </div>
            <div className='input-group'>
              <label>Mot de passe <span className='required'></span>
                    <input type="password" name="password" id="password" placeholder="Mot de passe" 
                      value={candidat.password}
                      {...register("password", {
                        onChange: (e) => {
                          handleInput(e);
                        },
                        required: true,
                        minLength: 5,
                     })}
                    />
                    {errors.password && errors.password?.type === "required" && (
                     <span className="error-msg">Ce champ est obligatoire.</span>
                   )}
                    {errors.password && errors.password?.type === "minLength" && (
                    <span className="error-msg">
                        Le mot de passe ne peut être inférieur à cinq (5) caractères.
                    </span>
                    )}
                </label>
                <label>Confirmez mot de passe <span className='required'></span>
                    <input type="password" name="confirmMdp" id="confirmMdp" placeholder="Confirmer le mot de passe" 
                    value={candidat.confirmMdp}
                    {...register("confirmMdp", {
                        onChange: (e) => {
                            handleInput(e);
                        },
                        validate: (value) => value === candidat.password,
                        })}
                    />
                    {errors.confirmMdp && (
                      <span className="error-msg">Les deux mot de passe doivent être identiques.</span>
                    )}
                </label>
            </div>
        </div>
    </div>

    <div className="divider">
        <div className="left">
            <h4>Documents</h4>
            <p className="subtext">Merci d'ajouter les documents demandés.</p>
        </div>
        <div className="right">
            <div className="input-group">
                <label>Type de document <span className='required'></span>
                    <select name="doctype" id="doctype"
                     value={candidat.doctype}
                     {...register("doctype", {
                        onChange: (e) => {
                            handleInput(e);
                        },
                        validate: (value) => parseInt(value) !== 0,
                        })}
                    >
                        <option value={0}>Selectionner le type de pièce</option>
                        <option value="Carte D'identité">Carte D'identité</option>
                        <option value="Passeport">Passeport</option>
                        {/* <option value="Carte Scolaire">Carte Scolaire</option> */}
                        <option value="Permis de Conduire">Permis de Conduire</option>
                        <option value="Carte Consulaire">Carte Consulaire</option>
                        <option value="Carte de Séjour">Carte de Séjour</option>
                        <option value="Carte d'électeur">Carte d'électeur</option>
                    </select>
                    {errors.doctype && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
                </label>
                <label>Num de pièce d'identité <span className='required'></span>
                    <input type="text" name="numpiece" id="numpiece" placeholder="Numéro de la pièce" 
                      value={candidat.numpiece}
                      {...register("numpiece", {
                        onChange: (e) => {
                          handleInput(e);
                        },
                        required: true,
                        minLength: 4,
                     })}
                   />
                    {errors.numpiece && errors.numpiece?.type === "required" && (
                     <span className="error-msg">Ce champ est obligatoire.</span>
                   )}
                    {errors.numpiece && errors.numpiece?.type === "minLength" && (
                    <span className="error-msg">
                        ce champ ne peut pas être inférieur à quatre(5) caractères.
                    </span>
                    )}
                </label>
            </div>
            <div className="input-group file-input">
                <div className="group">
                    <span className='required'>Copie de la pièce</span>
                    <label className="file-input-label">
                        <AiOutlineUpload />
                        Importer la pièce 
                        <input type="file" name="num_rc_file" accept=".jpg,.jpeg,.png,.pdf"
                          {...register("num_rc_file", {
                            onChange: (e) => {
                              handleInput(e);
                            },
                            validate: (value) => value !== "" && value.length !== 0
                         })}
                        />
                    
                    </label>
                    {candidat.num_rc_file !== '' && <span><strong>Fichier selectionné : </strong>{candidat.num_rc_file?.name}</span>}
                    {errors.num_rc_file && (
                        <span className="error-msg" style={{color:'red'}}>Veuillez choisir la copie de la pièce jointe.</span>
                      )}
                </div>
              
            </div>
            <div className='input-group'>
              <label> Parcours Academique <span className='required'></span>
                   <select name='niveau_etude' value={candidat.niveau_etude}
                    {...register("niveau_etude", {
                      onChange: (e) => {
                        handleInput(e);
                      },
                      validate: (value) => value !== "",
                      })}
                   >
                      <option value="">Selectionnez votre Parcours Academique</option>
                      <option value="Diplôme">Diplôme</option>
                      <option value="Certificat">Certificat</option>
                      <option value="Attestation">Attestation</option>
                      <option value="Pas de diplôme(N.A)">Pas de diplôme(N.A)</option>
                   </select>
                   {errors.niveau_etude && (
                      <span className="error-msg">Ce champ est obligatoire.</span>
                    )}
              </label>
              {(candidat.niveau_etude !== "" &&  candidat.niveau_etude !== "Pas de diplôme(N.A)") && 
               <div className="input-group file-input">
                  <div className="group">
                    <span className='required'>Copie du niveau d'etude</span>
                    <label className="file-input-label">
                      <AiOutlineUpload />
                       Doc.Academique
                        <input type="file" name="copie_nve" 
                         {...register("copie_nve", {
                          onChange: (e) => {
                            handleInput(e);
                          },
                          validate: (value) => value !== "" && value.length !== 0
                       })}
                        />
                    </label>
                  {candidat.copie_nve !== '' && <span><strong>Fichier selectionné : </strong>{candidat.copie_nve?.name}</span>}
                  </div>
                  {errors.copie_nve && (
                    <span className="error-msg" style={{color:'red'}}>Veuillez choisir la copie du niveau d'etude joint.</span>
                  )}
               </div>  
                
              }
            
   

              <div className="input-group file-input">
                <div className="group">
                    <span>CV</span>
                    <label className="file-input-label">
                        <AiOutlineUpload />
                        Importer le cv
                        <input type="file" name="cv" accept="pdf/*" onChange={handleInput}/>
                    </label>
                    {candidat.cv !== '' && <span><strong>Fichier selectionné : </strong>{candidat.cv?.name}</span>}
                    {/* {errors.num_rc_file && (
                        <span className="error-msg" style={{color:'red'}}>Veuillez choisir la copie de la pièce jointe.</span>
                      )} */}
                </div>
              </div>  
            </div>
        </div>
    </div>

  

   

    <div className="divider submit">
        <div className="left"></div>
        <div className="right">
           <Erreurs validation = {erreurs} />
            <p className="subtext">En vous inscrivant, vous acceptez nos <strong className='link'><Link to={"/cgu"}>conditions générales d'utilisation</Link></strong></p>
            <button className='primary' disabled={isLoading}>S'inscrire</button>
        </div>
    </div>
  </form>
</div>
  )
}

export default SignUpPro