import { useState,useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import RecruiterDetailsModal from "../../components/RecruiterDetailsModal/RecruiterDetailsModal";
import { useRecoilState } from "recoil";
import { loadingState } from "../../recoil/loadingAtom";
import Principale from "../../utils/services/Principale";
import moment from "moment/moment";
import Swal from "sweetalert2";
import { toast } from "react-hot-toast";
const RecruitersList = () => {
  const [isRecruiterDetailsModalOpen, setIsRecruiterDetailsModalOpen] = useState(false);
  const [isLoading,setIsLoading] = useRecoilState(loadingState);
  const [erreurs,setErreurs] = useState([]);
  const [recruiters,setRecruiters] = useState([]);
  const [selectedRecruiter,setSelectedRecruiter] = useState(null);
  const api = new Principale();
  // ? Agencies data
  const getRecruiters = async () => {
    setIsLoading(true);
      const {status,messages,recruitersData} = await api.get('/admin/getRecruiter');
    setIsLoading(false);
    if(status === 200){
      
      setRecruiters(recruitersData.map((recruiter,index) => {
        return{
           ord:index + 1,
           id:recruiter.user_id,
           type:recruiter.type,
           nom:recruiter.type === 'personne physique'?recruiter.prenom+" "+recruiter.prenom:recruiter.societe,
           numero_piece:recruiter.numero_piece,
           telephone:recruiter.telephone,
           email:recruiter.email,
           created_at:moment(recruiter.created_at).format('Do MMMM YYYY'),
           civilite:recruiter.civilite,
           fonction:recruiter.fonction,
           prenom:recruiter.prenom,
           nom1:recruiter.nom,
           logo:recruiter.logo,
           nif:recruiter.nif,
           photo_piece:recruiter.photo_piece,
           prefecture:recruiter.prefecture,
           commune:recruiter.commune,
           quartier:recruiter.quartier,
           rccm:recruiter.rccm,
           representant:recruiter.representant,
           type_piece:recruiter.type_piece,
           nif_photo:recruiter.nif_photo,
           autre_doc:recruiter.autre_doc,
           profession:recruiter.profession,
           username:recruiter.username,
           secteur:recruiter.secteur,
           status:recruiter.status
        }
      }));
    }else setErreurs(messages);
    
  }
  const columns = [
    {
      field: "ord",
      headerName: "Ordre",
      flex: 1,
      maxWidth: 80,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "nom",
      headerName: "Nom",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "numero_piece",
      headerName: "numero_piece",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "telephone",
      headerName: "Telephone",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "created_at",
      headerName: "Date Inscr.",
      flex: 1,
      minWidth: 150,
    },
   
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <div className="options">
            <button
              onClick={() => {
                // setSelectedAgency(params.row);
                setSelectedRecruiter(params.row);
                setIsRecruiterDetailsModalOpen(true);
              }}
            >
              Détails
            </button>
            <button
              onClick={() => {StatusUser(params.row);
                // setSelectedAgency(params.row);
                // setIsRecruiterDetailsModalOpen(true);
              }}
              className={`${parseInt(params.row.status) === 1?'delete':'active'}`}
            >
                {parseInt(params.row.status) === 1 ? 'Desactivez':'Activez'}
            </button>
          </div>
        );
      },
    },
  ];
  const StatusUser = (user) => {
   
    Swal.fire({
      title: "Êtes-vous sûr?",
      text: `${parseInt(user.status) === 1 ?'Voulez-Vous Desactivé cet utilisateur?':'Voulez-Vous Activé cet utilisateur?'}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Oui, ${parseInt(user.status) === 1 ? 'desactiver!':'activer!'}`,
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        const selectedUser = [...recruiters];
        // console.log(selectedUser[user.ord - 1]);

        var formData = new FormData();setErreurs([]);
      
        formData.append("user_id", user.id);
      
         if(selectedUser[user.ord - 1].status === "1")
            selectedUser[user.ord - 1].status = 0;
         else selectedUser[user.ord - 1].status = '1';
          setRecruiters(selectedUser);
         formData.append("status", selectedUser[parseInt(user.ord) - 1].status);
        setIsLoading(true);
         api.post('/admin/utilisateur/statusUpdate',formData).then((resp) => {
          console.log(resp)
          if(resp.status === 200)
            toast.success('Status Modifié avec succès.');
          else {
             setErreurs(resp.messages); 
             toast.error("Error !Modification non effectuée");
            } 
         });
         
        setIsLoading(false);
      }
    });
    
  }

  useEffect(() => {
    getRecruiters();
  }, []);
  return (
    <div className="recruiters-list page">
      <RecruiterDetailsModal
        isOpen={isRecruiterDetailsModalOpen}
        setIsOpen={setIsRecruiterDetailsModalOpen}
        selectedRecruiter={selectedRecruiter}
      />
      <div className="header">
          <h3>Liste des employeurs</h3>
          <p>{recruiters.length} employeur(s)</p>
      </div>
      <div className="array">
        <Box sx={{ height: 579, width: "100%" }}>
          <DataGrid
            sx={{ borderRadius: 0 }}
            components={{ Toolbar: GridToolbar }}
            rows={recruiters}
            columns={columns}
            autoPageSize
            disableSelectionOnClick
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          />
        </Box>
      </div>
    </div>
  );
};

export default RecruitersList;
