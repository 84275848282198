import { useEffect, useRef, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";
import { homeCategories } from "../../utils/staticTexts";

const ProFiltersModal = ({ isOpen, setIsOpen , metiers,preloadData,candidat,setCandidat,setProfessionnelleData,
  setIsSearch,setErreurs,setIsLoading,setEndPage,api,location}) => {
  const modalRef = useRef();

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setIsOpen(false);
    }
  };
  const [selectedCommunes,setSelectedCommunes] = useState([]);
  const [selectedQuartiers,setSelectedQuartiers] = useState([]);
  const [checkedState, setCheckedState] = useState();
  const handleInput = (e) => {
    if(e.target.name === 'prefecture_id'){
      setCandidat({...candidat,[e.target.name]:e.target.value,commune_id:'',quartier_id:''});
      if(preloadData?.communes){
        setSelectedCommunes(preloadData.communes.filter((c) => c.prefecture_id === parseInt(e.target.value)));
      }
    }
    else if(e.target.name === "commune_id"){
      setCandidat({...candidat,[e.target.name]:e.target.value,quartier_id:''});
      if(preloadData?.quartiers){
        setSelectedQuartiers(preloadData.quartiers.filter((q) => q.commune_id === parseInt(e.target.value)));
      }
    }
    else{
      setCandidat({...candidat,[e.target.name]:e.target.value});
    } 
  }
  const handleCheckChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>{
        if (index == position){
           if(item)
              return false;
           else return true;
        }
         return item;
      });
      let selectedmet = [];
    setCheckedState(updatedCheckedState)
    updatedCheckedState.map((item,index) => {
       if(item)
         selectedmet.push(metiers[index].metier_id);
    });
    setCandidat({...candidat,selectedMetiers:selectedmet})
  }
  const handleFilter = async (e) => {
    e.preventDefault();
 
    
      setIsLoading(true);
      setErreurs([]);
      setIsSearch(true);
   
      var formData = new FormData();
      
      if (location?.state) {
        const metier = location.state?.metier;
        if (metier) {
          formData.append("metierName", metier);
        }
     }
     else{
          formData.append("prefecture_id", candidat.prefecture_id);
          formData.append('commune_id',candidat.commune_id);
          formData.append('quartier_id',candidat.quartier_id);
      
          formData.append('metiers',candidat.selectedMetiers)
          formData.append("approndie", true);
     }
      
     setEndPage(8);
     const{status,messages,candidats} = await api.post('/client/search?start=1&end=8',formData);
     setIsLoading(false);setIsOpen(false);
     
     setErreurs([]);
     setIsSearch(true)
     if(status === 200)
       setProfessionnelleData(candidats);
      else setErreurs(messages);  
   
   
  }
  useEffect(() => {
    setCheckedState(new Array(metiers.length).fill(false));
    if(candidat?.prefecture_id)
      setSelectedCommunes(preloadData.communes.filter((c) => c.prefecture_id === parseInt(candidat?.prefecture_id)));
  },[metiers,candidat?.prefecture_id])

  return (
    <AnimatePresence>
      {isOpen ? (
        <div className="modal-component" onClick={closeModal} ref={modalRef}>
          <motion.div
             initial={{ opacity: 0, scale: 0 }}
             animate={{ opacity: 1, scale: 1 }}
             transition={{ duration: 0.2 }}
             exit={{ opacity: 0, scale: 0 }}
            className="modal pro-filters-modal"
          >
            <div className="close" onClick={() => setIsOpen(false)}>
              <AiOutlineCloseCircle id="close-icon" size={24}/>
            </div>
            <h2>Filtres</h2>
            <form onSubmit={handleFilter}>
              <div className="categories-list">
              <h5>Corps de metiers ({(Array.isArray(metiers) && metiers.length > 0 )?metiers.length:0})</h5>
                {(Array.isArray(metiers) && metiers.length > 0)&& metiers.map((metier,index) => (
                  <div className="checkbox" key={metier.metier_id}>
                    <label>
                      <input type="checkbox" name="metier" id="metier" value={metier.metier_id} 
                       onChange={() => handleCheckChange(index)}
                       checked = {checkedState[index]} 
                      />
                      {metier.nom}
                    </label>
                  </div>
                ))}
              </div>
              <div className="localisation">
                <h5>Situation géographique</h5>
                <div className="input-group">
                    <label>
                        Prefecture ou commune
                        <select name="prefecture_id" id="prefecture_id"
                          onChange={handleInput}
                          value={candidat.prefecture_id}
                        >
                            <option value="">Prefecture ou Commune</option>
                            {preloadData?.prefectures && preloadData.prefectures.map((prefecture) => (<option value={prefecture.prefecture_id} key={prefecture.prefecture_id}>{prefecture.nom}</option>))}
                        </select>
                    </label>
                    <label>
                        Commune
                        <select name="commune_id" id="commune_id" onChange={handleInput}
                         value={candidat.commune_id}
                        >
                            <option value="">Commune</option>
                            {selectedCommunes.length > 0 && selectedCommunes.map((commune) => (<option value={commune.commune_id} key={commune.commune_id}>{commune.nom}</option>))}
                        </select>
                    </label>
                </div>
                <div className="input-group">
                   <label>Quartier/District
                       <select name="quartier_id" id="quartier_id" onChange={handleInput}
                       value={candidat.quartier_id}
                       >
                          <option value={0}>quartier/district</option>
                          {selectedQuartiers.length > 0 && selectedQuartiers.map((quartier) => (<option value={quartier.quartier_id} key={quartier.quartier_id}>{quartier.nom}</option>))}
                       </select>
                   </label>
                   {/* <label>Addresse
                       <input type="text" placeholder="Saisir l'addresse des candidats" onChange={handleInput} value={candidat.addresse}/>  
                   </label> */}
                </div>
              </div>
              <button className="primary" type="submit">Filtrer</button>
            </form>
          </motion.div>
        </div>
      ) : null}
    </AnimatePresence>
  );
};

export default ProFiltersModal;
