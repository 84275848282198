import { useContext, useState } from "react";
import { FiMapPin } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../utils/Context/Context";
import { isEmpty } from "../../utils/services/helpers";
import Principale from "../../utils/services/Principale";
// import Tooltip from "react-simple-tooltip";
const ProfessionalCard = ({ role_id,photo, name, job, address, experience, description,candidat_id }) => {
  const {user} = useContext(UserContext);
  const [erreurs,setErreurs] = useState([]);
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const api = new Principale();
  //console.log(pathname)

  const contacter = async (id) => {
 
    if(isEmpty(user))
      navigate('/login', {state: {page: `/profile/pro/details/${id}`,}});
    else{
      //  setErreurs([]);
      //  //const{status,candidat,messages} = await api.get(`/client/details/${id}`);
    
      //  if(status === 200){
      //    console.log(candidat)
      //  }
      //  else setErreurs(messages);
      navigate(`/profile/pro/details/${id}`);
    }
  }

  return (
    <article className="professional-card">
      <div className="top">
        <div className="image">
          <img src={photo} alt="" />
        </div>
        <div className="infos">
          <p className="name">{name}</p>
          <p className="job">{job}</p>
          <div className="badges">
            <div className="address">
              <div className="icon">
                <FiMapPin size={10} />
              </div>
              <span>{address} </span>
            </div>
            <div className="experience-badge">{experience} d'exp.</div>
          </div>
        </div>
        <div className="description">
          <p>{description}</p>
        </div>
  
      </div>
      {(user?.role_id === 3 || isEmpty(user))&&
      //  <Tooltip content="Voir les details du candidat puis demandez son recrutement.">
          <div className="bottom">
            <button className="primary" onClick={() => contacter(candidat_id)} >Voir détails</button>
          </div>
        // </Tooltip>
        }
    </article>
  );
};

export default ProfessionalCard;
