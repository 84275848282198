import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import Erreurs from "../../components/Erreurs/Erreurs";
import { loadingState } from "../../recoil/loadingAtom";
import Principale from "../../utils/services/Principale";

const ProfileRecruiter = () => {
  const {id} = useParams();
  const api = new Principale();
  const[details,setDetails] = useState();
  const[isLoading,setIsLoading] = useRecoilState(loadingState);
  const[erreurs,setErreurs] = useState([]);
  const url = process.env.REACT_APP_URL + 'storage/';
  const getDetails = async (id) => {
    setErreurs([]);setIsLoading(true);
    const {status,messages,employeur} = await api.get(`/employeur/getEmployeurDetails/${id}`);
   // console.log(employeur)
    setIsLoading(false);
    if(status === 200){
       if(employeur?.length > 0)
         setDetails(employeur[0]);
    }else setErreurs(messages);

  }
  useEffect(() => {
    getDetails(id);
  },[id]);
  // console.log(details)
  if(!details) return null;
  return (
    <div className="profile-recruiter page w-50">
      { <Erreurs validation = {erreurs} />}
      <div className="logo">
        {/* <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c8/Orange_logo.svg/240px-Orange_logo.svg.png"
          alt=""
        /> */}
         <img src={details.logo?url+details.logo.replace('public/',""):""} alt={details.societe} />
      </div>
      <div className="infos">
        <h1>{details.societe}</h1>
        <div className="group">
          <span className="label">Secteurs d'activité</span>
          {/* <p>Réseau, Téléphonie et Nouvelles technologies</p> */}
          <p>{details.secteur}</p>
        </div>
        <div className="group">
          <span className="label">Adresse</span>
          <p>{details.prefecture} {details.commune}, {details.quartier}{details.addresse?"/"+details.addresse:''}</p>
        </div>
        <div className="group">
          <span className="label">Création</span>
          <p>2002</p>
        </div>
        <div className="group description">
          <span className="label">Description</span>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quod ullam
            corrupti totam illo velit consectetur corporis recusandae optio
            impedit odit at, odio fuga, placeat doloribus dicta? Magni commodi
            eum explicabo dolore optio earum voluptatum consequatur consequuntur
            perferendis eius! Voluptatibus magnam, reiciendis odit dolore
            perspiciatis a incidunt quasi, enim aut molestiae maxime at, sint
            numquam dicta recusandae aperiam repudiandae assumenda. Veniam
            nostrum non adipisci, aliquid laborum voluptatem nobis quo magni
            nisi quis voluptatibus vitae sequi ab provident quaerat inventore.
            Saepe facilis dolorum eveniet velit incidunt optio hic
            necessitatibus consectetur a animi adipisci culpa amet,
            reprehenderit illum, nam expedita, officia aperiam nobis?
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProfileRecruiter;
