import { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { loadingState } from "../../recoil/loadingAtom";
import Select from "react-select";
import { compareTime } from "../../utils/services/helpers";
import Principale from "../../utils/services/Principale";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Erreurs from "../Erreurs/Erreurs";
// const useFocus = () => {
//   const htmlElRef = useRef(null)
//   const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}
//   return [ htmlElRef, setFocus ]
// }
const DisponibiliteModal = ({ isOpen, setIsOpen, employeur, setEmployeur }) => {
  const {
    register,
    handleSubmit,
    unregister,
    formState: { errors },
  } = useForm({
    defaultValues: { employeur },
  });
  // const [customErrorMsg,setCustomErrorMsg] = useState('');
  // const [inputRef, setInputFocus] = useFocus();
  // const [inputRef1,setInputFocus1] = useFocus();

  const jours = [
    { value: "Lundi", label: "Lundi" },
    { value: "Mardi", label: "Mardi" },
    { value: "Mercredi", label: "Mercredi" },
    { value: "Jeudi", label: "Jeudi" },
    { value: "Vendredi", label: "Vendredi" },
    { value: "Samedi", label: "Samedi" },
    { value: "Dimanche", label: "Dimanche" },
  ];
  const [isLoading, setIsLoading] = useRecoilState(loadingState);
  const [erreurs, setErreurs] = useState([]);
  const api = new Principale();
  const navigate = useNavigate();
  const modalRef = useRef();

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    //setAgences(elementsData.agences);
  }, []);

  const handleInput = (e) => {
    if (
      e?.target?.name === "type_contrat" ||
      e?.target?.name === "heure_debut" ||
      e?.target?.name === "heure_fin"
    ) {
      setEmployeur({ ...employeur, [e.target.name]: e.target.value });
    } else if (e?.target?.name === "taches") {
      setEmployeur({ ...employeur, [e.target.name]: e.target.value });
    } else {
      setEmployeur({ ...employeur, selected_jours: e });
      //  setError("selectedJours");
      unregister("selected_jours");
    }
  };
  const isSelectedJours =
    Array.isArray(employeur.selected_jours) &&
    employeur.selected_jours.length > 0;
  //  const selectedJours = watch("selected_jours");
  //  if(employeur?.selected_jours){
  //   unregister("selected_jours");
  //  }
  // // console.log(selectedJours)
  const contacter = async () => {
    setErreurs([]);
    setIsLoading(true);
    var formData = new FormData();
    employeur.selected_jours.forEach(function (currentValue, index, arr) {
      formData.append("jours_proposes[]", currentValue.value);
    });
    formData.append("candidat_id", employeur.candidat_id);
    formData.append("employeur_id", employeur.employeur_id);
    formData.append("type_contrat", employeur.type_contrat);
    formData.append("status", employeur.statusCandidat);
    formData.append("dure_unit", employeur.dure_unit);
    formData.append("nombre", employeur.nombre);
    formData.append("heure_debut", employeur.heure_debut);
    formData.append("heure_fin", employeur.heure_fin);
    formData.append("taches", employeur.taches);
    const { status, messages } = await api.post(
      "/employeur/contacterCandidat",
      formData
    );
    if (status === 200) {
      toast.success(
        "Vous aviez contactez avec succès ce candidat.Merci de patientez ,vous serez contactez dans sous peu de temps.",
        { duration: 9000 }
      );
      navigate("/findprofessionals");
    } else {
      toast.error("Vous ne pouvez pas contacter ce candidat pour le moment.", {
        duration: 5000,
      });
      setErreurs(messages);
    }
    setIsLoading(false);
  };
  //console.log(employeur)
  //console.log(selectedJours)
  //console.log(compareTime(employeur.heure_debut,employeur.heure_fin));
  return (
    <AnimatePresence>
      {isOpen ? (
        <div className="modal-component" onClick={closeModal} ref={modalRef}>
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, scale: 0 }}
            className="modal"
            // style={{ right: "10.8%" }}
          >
            <div className="close" onClick={() => setIsOpen(false)}>
              <AiOutlineClose id="close-icon" />
            </div>
            <h4>Temps de Travail</h4>
            <form onSubmit={handleSubmit(contacter)}>
              <div className="input-group">
                <label>
                  Type de Contrat
                  <select
                    {...register("type_contrat", {
                      onChange: (e) => {
                        handleInput(e);
                      },
                      validate: (value) => value !== "",
                    })}
                    value={employeur?.type_contrat}
                  >
                    <option value="">Selectionnez le type de contrat</option>
                    <option value="interim">Interim</option>
                  
                    <option value="recrutement">Recrutement</option>
                    {/* <option value="saisonnier">Saisonnier</option> */}
                    <option value="mise à disposition">
                      Mise à disposition
                    </option>
                    {/* <option value="tâcheronat">Tâcheronat</option> */}
                    {/* <option value="contrat à durée determinée">Contrat à durée déterminée</option>
                         <option value="contrat à durée indeterminée">Contrat à durée indeterminée</option> */}
                    {/* <option value="contrat de mission">
                      contrat de mission
                    </option> */}
                  </select>
                  {errors.type_contrat && (
                    <span className="error-msg">Ce champ est obligatoire.</span>
                  )}
                </label>
              </div>
              <div className="input-group">
                <label>
                  Proposition des Jours de Travaux
                  <Select
                    name="selected_jours"
                    placeholder={<div>Selectionnez les jours de Travail</div>}
                    // value={selectedOptions}
                    value={employeur.selected_jours}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    {...register("selected_jours", {
                      validate: () => isSelectedJours,
                    })}
                    onChange={(e) => handleInput(e)}
                    options={jours}
                  />
                  {errors.selected_jours && (
                    <span className="error-msg">Ce champ est obligatoire.</span>
                  )}
                </label>
              </div>
              <div className="input-group">
                <label>
                  Heure debut
                  <input
                    type="time"
                    value={employeur.heure_debut}
                    {...register("heure_debut", {
                      onChange: (e) => {
                        handleInput(e);
                      },
                      required: true,
                      validate: (value) =>
                        compareTime(value, employeur.heure_fin) === -1 &&
                        employeur.heure_fin !== "",
                    })}
                  />
                  {errors.heure_debut &&
                    errors.heure_debut?.type === "required" && (
                      <span className="error-msg">
                        Ce champ est obligatoire.
                      </span>
                    )}
                  {errors.heure_debut &&
                    errors.heure_debut?.type !== "required" && (
                      <span className="error-msg">
                        L'heure de debut doit être inférieur à l'heure de fin.
                      </span>
                    )}
                </label>
                <label>
                  Heure Fin
                  <input
                    type="time"
                    value={employeur.heure_fin}
                    {...register("heure_fin", {
                      onChange: (e) => {
                        handleInput(e);
                      },
                      required: true,
                      validate: (value) =>
                        compareTime(employeur.heure_debut, value) === -1 &&
                        employeur.heure_debut !== "",
                    })}
                  />
                  {errors.heure_fin &&
                    errors.heure_fin?.type === "required" && (
                      <span className="error-msg">
                        Ce champ est obligatoire.
                      </span>
                    )}
                  {errors.heure_fin &&
                    errors.heure_fin?.type !== "required" && (
                      <span className="error-msg">
                        L'heure de fin doit être supérieur à l'heure de debut.
                      </span>
                    )}
                </label>
              </div>
              <div className="input-group">
                <label>
                  Resumé des Tâches à exécuter
                  <textarea
                    onChange={handleInput}
                    value={employeur.taches}
                    name="taches"
                  />
                </label>
              </div>
              <Erreurs validation={erreurs} />
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  onClick={() => setIsOpen(false)}
                  style={{ marginRight: "15px", backgroundColor: "#e10a0a" }}
                >
                  Fermez
                </button>
                <button disabled={isLoading}>Enregistrer</button>
              </div>
            </form>
          </motion.div>
        </div>
      ) : null}
    </AnimatePresence>
  );
};

export default DisponibiliteModal;
