import React from 'react'

const NotFound = () => {
  return (
    <div className='not-found page'>
        <h1>Oups ! Une erreur est survenue.</h1>
        <p>Cette page n'existe pas.</p>
    </div>
  )
}

export default NotFound