import { useRef } from "react";
import { FaRegWindowClose } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { Markup } from 'interweave';
import { formatStringNumber } from "../../utils/services/helpers";
const ContractDetailsModal = ({ isOpen, setIsOpen,selectedRow }) => {
  const modalRef = useRef();
  const url = process.env.REACT_APP_URL + 'storage/';
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setIsOpen(false);
    }
  };
 //console.log(selectedRow)
  return (
    <AnimatePresence>
      {isOpen ? (
        <div
          className="contract-details-modal modal-component"
          onClick={closeModal}
          ref={modalRef}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, scale: 0 }}
            className="modal"
          >
            <div className="close" onClick={() => setIsOpen(false)}>
              <FaRegWindowClose size={24} id="close-icon" />
            </div>
            {selectedRow &&
             <div className="details-contract">
              <h2>Contrat</h2>
              <div className="divider">
                <h4>De :</h4>
                <div className="company-card card">
                  <div className="card-image">
                    <img
                      src={selectedRow.PhotoEmployeur?url + selectedRow.PhotoEmployeur.replace('public/',''):'#'} 
                      alt={selectedRow.employeur}
                    />
                  </div>
                  <div className="card-infos">
                    <strong>{selectedRow.employeur}</strong>
                    <p>{selectedRow.typeemp}</p> 
                    <Link to={`/profile/recruiter/details/${selectedRow.employeur_id}`} style={{color:'green'}}>voir profil</Link>
                  </div>
                </div>
              </div>
              <div className="divider">
                <h4>Pour :</h4>
                <div className="pro-card card">
                  <div className="card-image">
                    <img
                      src={selectedRow.photo?url + selectedRow.photo.replace('public/',''):''}
                      alt={selectedRow?.nomCandidat}
                    />
                  </div>
                  <div className="card-infos">
                    <strong>{selectedRow?.nomCandidat}</strong>
                    <p>{selectedRow.metier}</p>
                    <Link to={`/profile/pro/details/${selectedRow.candidat_id}`} style={{color:'green'}}>voir profil</Link>
                  </div>
                </div>
              </div>
              <div className="divider bottom">
                <h4>Détails du contrat</h4>
                <form>
                  <div className="input-group">
                    <label className="subtext">
                      Date de début
                      <span className='input-read'>{selectedRow.date_debut && moment(selectedRow.date_debut,'DDD MMMM YYYY').format('DD/MM/YYYY')}</span>
              
                    </label>
                    <label>
                      Date de fin 
                      <span className='input-read'>{selectedRow.date_fin && moment(selectedRow.date_fin,'DDD MMMM YYYY').format('DD/MM/YYYY')}</span>
                    </label>
                  </div>
                  <div className="input-group">
                    <label className="subtext">
                      Jours de travail
                      <span className='input-read'>{selectedRow.jours_valides}</span>
                    </label>
                  </div>
                  <div className="input-group">
                    <label className="subtext">
                      Type de contrat 
                      <span className='input-read'>{selectedRow.contrat_type_valide && selectedRow.contrat_type_valide.charAt(0).toUpperCase() 
                       + selectedRow.contrat_type_valide.slice(1)}</span>
                    </label>
                  </div>
                  <div className="input-group">
                    <label className="subtext">
                       Lieu d'execution
                       <span className='input-read'>{selectedRow.lieuexecution?selectedRow.lieuexecution:'---------'}</span>
                    </label>
                  </div> 
                  <div className="input-group">
                    <label className="subtext">
                      Montant de la prestation
                      <span className='input-read'>{selectedRow.montantPrestation?formatStringNumber(selectedRow.montantPrestation)+" FG":'---------'}</span>
                    </label>
                  </div>    
                  <div className="input-group">
                    <label className="subtext">
                      <strong style={{borderBottom:'1px solid #beacac',paddingBottom:'4px'}}>Description de la mission :</strong>
                      <div><Markup content={selectedRow.description} /></div>
                    </label>
                  </div>
          
                  <div className="buttons">
                    {/* <Link to="/impression-contrat">
                      <button>Confirmer</button>
                    </Link> */}
                    <button className="delete" type="button" onClick={() => setIsOpen(false)}>Annuler</button>
                  </div>
                </form>
              </div>
            </div>}
          </motion.div>
        </div>
      ) : null}
    </AnimatePresence>
  );
};

export default ContractDetailsModal;
