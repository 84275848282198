import { useRef, useState,useEffect } from "react";
import { FaRegWindowClose } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import moment from "moment/moment";
import {  AiOutlineUpload } from "react-icons/ai";
import { useForm } from 'react-hook-form';
import profilephoto from '../../assets/images/avatar.png';
import { FirstUpperCase, objecttoFormData } from "../../utils/services/helpers";
import Principale from "../../utils/services/Principale";
import Erreurs from "../Erreurs/Erreurs";
import { toast } from "react-hot-toast";
const UpdateMetierModal = ({ isOpen, setIsOpen ,setIsLoading,setIsAction,isAction,setMetier,metier}) => {
  const modalRef = useRef();
  const url = process.env.REACT_APP_URL + "storage/";
  const [erreurs,setErreurs] = useState([]);
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setIsOpen(false);
    }
  };
  const [previews,setPreviews] = useState({profilePhoto:''});
  
  const api = new Principale();
  const {register,handleSubmit,reset,setError,formState: { errors },} = useForm({defaultValues: {metier,},}); 

  const handleInput = (e) => {
    setMetier({...metier,[e.target.name]:FirstUpperCase(e.target.value)});
  }
  const inscrire = async () => {
    setErreurs([]);setIsLoading(true);
    var formData = objecttoFormData(metier);
    const {status,messages} = await api.post('/admin/metiers/update',formData);
    // setIsLoading(false);
    if(status === 200){
       toast.success("Modification effectuée avec succès.");
       setIsAction(!isAction);setIsOpen(false);
       setMetier({...metier,nom:'',generique:'',description:''})
    }
    else{
      toast.error("Modification non effectuée");
      setErreurs(messages); 
      setIsLoading(false);
    }
  }
  useEffect(() => {
    setErreurs([]);
    reset(metier);
    
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen ? (
        <div
          className="recruiter-details-modal modal-component"
          onClick={closeModal}
          ref={modalRef}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, scale: 0 }}
            className="modal"
          >
            <div className="close" onClick={() => setIsOpen(false)}>
              <FaRegWindowClose size={24} id="close-icon" />
            </div>
            <div className="details-recruiter">
              <form onSubmit={handleSubmit(inscrire)}>
                  <h2>Ajout du Métier</h2> 
                  <div className="input-group">
                   <label>Nom du métier  <span className='required'></span>
                   <input type="text" name="nom" id="nom" placeholder="Le nom du métier" 
                      value={metier.nom}
                      {...register("nom", {
                        onChange: (e) => {
                          handleInput(e);
                        },
                        required: true,
                        minLength: 2,
                      })}
                    />
                   {errors.nom && errors.nom?.type === "required" && (
                     <span className="error-msg">Ce champ est obligatoire.</span>
                   )}
                    {errors.nom && errors.nom?.type === "minLength" && (
                    <span className="error-msg">
                        ce champ ne peut pas être inférieur à deux caractères.
                    </span>
                    )}
                   </label>
                   <label>Nom du générique  <span className='required'></span>
                   <input type="text" name="generique" id="generique" placeholder="Le nom générique du métier" 
                      value={metier.generique}
                      {...register("generique", {
                        onChange: (e) => {
                          handleInput(e);
                        },
                        required: true,
                        minLength: 2,
                      })}
                    />
                   {errors.generique && errors.generique?.type === "required" && (
                     <span className="error-msg">Ce champ est obligatoire.</span>
                   )}
                    {errors.generique && errors.generique?.type === "minLength" && (
                    <span className="error-msg">
                        ce champ ne peut pas être inférieur à deux caractères.
                    </span>
                    )}
                   </label>
                 </div>  
                 <div className="input-group">
                    <label>Description
                       <textarea rows={5} cols ={10} name="description"
                         {...register("description", {
                          onChange: (e) => {
                            handleInput(e);
                          },
                          required: false,
                          minLength: 10,
                        })}
                        value={metier.description}
                       />
                    
                    {errors.description && errors.description?.type === "minLength" && (
                    <span className="error-msg">
                        ce champ ne peut pas être inférieur à dix caractères.
                    </span>
                    )}
                    </label>
                 </div>
                 <div className="input-group">
                   <Erreurs validation = {erreurs} />
                 </div>
                 <div className="input-group">
                  <div>
                     <button className='success'>Ajouter</button>
                  </div>
                 
                </div>
              </form>
            </div>    
          </motion.div>
        </div>
      ) : null}
    </AnimatePresence>
  );
};

export default UpdateMetierModal;
