import { AiOutlineUser, AiOutlineCheck } from 'react-icons/ai'
import { RiBuilding2Line } from 'react-icons/ri'
import { IoDocumentTextOutline } from 'react-icons/io5'
import { BsBoxSeam, BsInfo, BsPen } from 'react-icons/bs'
import { FaCompass } from 'react-icons/fa'

export const signupSteps = [
    {
      position: 1,
      icon: <AiOutlineUser/>,
      description: "Etape 1 sur 4",
      name: "Informations personnelles",
    },
    {
      position: 2,
      icon: <RiBuilding2Line/>,
      description: "Etape 2 sur 4",
      name: "Informations sur la société",
    },
    {
      position: 3,
      icon: <IoDocumentTextOutline/>,
      description: "Etape 3 sur 4",
      name: "Documents",
    },
    {
      position: 4,
      icon: <AiOutlineCheck/>,
      description: "Etape 4 sur 4",
      name: "Soumission",
    },
  ];



export const recrutementStep = [
    {
      position: 1,
      icon: <FaCompass />,
      description: "Etape 1 sur 4",
      name: "Information du Candidat",
    },
    {
      position: 2,
      icon: <BsInfo />,
      description: "Etape 2 sur 4",
      name: "Informations de l'employeur",
    },
    {
      position: 3,
      icon: <BsBoxSeam />,
      description: "Etape 3 sur 4",
      name: "Information du contrat",
    },
    {
      position: 4,
      icon: <BsPen />,
      description: "Etape 4 sur 4",
      name: "Détails du contrat",
    },
  
  ];