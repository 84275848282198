import { useState,useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import { GoLocation, GoSettings } from "react-icons/go";
import { IoIosArrowDown } from "react-icons/io";
import { useRecoilState } from "recoil";
import RecruiterCard from "../../components/RecruiterCard/RecruiterCard";
import { loadingState } from "../../recoil/loadingAtom";
import { fakeRecruiters } from "../../utils/fakeData";
import Principale from "../../utils/services/Principale";

const FindRecruiters = () => {
  const[employeurs,setEmployeurs] = useState([]);
  const[start,setStart] = useState(1);
  const [end,setEnd] = useState(8);
  const [erreurs,setErreurs] = useState([]);
  const [isLoading,setIsLoading] = useRecoilState(loadingState);
  const [totalPage,setTotalPage] = useState(0);
  const [currentPage,setCurrentPage] = useState(0);
  const api = new Principale();
  const url = process.env.REACT_APP_URL + "storage/";
  const [employeurname,setEmployeurname] = useState('');
  const getEmployeurs = async (employeurName = '') => {
      setErreurs([]);setIsLoading(true)
      const {status,employeurs,messages,totalEmployeur} = await api.get(`/employeur/getEmployeur/eight?start=${start}&end=${end}&employeurname=${employeurName}`);
      setIsLoading(false);
      if(status === 200){
        if(totalPage === 0)
        setTotalPage(Math.round(totalEmployeur/8));
        setCurrentPage(currentPage + 1);
        setEmployeurs(employeurs);
      }
      else setErreurs(messages); 
  }
  const loadfn = () => {
     const _start = end;
     const _end = _start + 8;
     if(currentPage <= totalPage ){
       setStart(_start);setEnd(_end);
       if(employeurname !== '')
         getEmployeurs(employeurname);
       else getEmployeurs();
     }
  }
  window.onscroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 16)
      loadfn();
  }
  const search = async () => {
    setErreurs([]);setIsLoading(true);setStart(1);setEnd(8);
    
    const {status,employeurs,messages,totalEmployeur} = await api.get(`/employeur/getEmployeur/eight?start=${start}&end=${end}&employeurname=${employeurname}`);
    setIsLoading(false);
    if(status === 200){
      if(totalPage === 0)
      setTotalPage(Math.round(totalEmployeur/8));
      setCurrentPage(1);
      setEmployeurs(employeurs);
    }
    else setErreurs(messages); 
  }
  useEffect(() => {
     getEmployeurs();
     return (() => {
      window.onscroll = null;
     });
  },[]);
  
  return (
    <div className="find-recruiters page w-60">
      <h1>Les entreprises qui nous font confiance</h1>
      <div className="search">
        <div className="recruiter">
          <div className="icon">
            <FiSearch size={20} />
          </div>
          <input
            type="text"
            name="nomRecruiter"
            id="nomRecruiter"
            placeholder="Nom du recruteur"
            onChange={(e) => setEmployeurname(e.target.value)}
          />
        </div>

        {/* <div className="location">
          <div className="icon icon-left">
            <GoLocation size={20} />
          </div>
          <select
            name="prefecture_id"
            id="position"
            data-testid="position-select"
          >
            <option value="" data-testid="position-option">
              Préfecture ou Commune
            </option>
          </select>
          <div className="icon arrow-icon">
            <IoIosArrowDown />
          </div>
        </div> */}

        {/* <div className="activite">
          <select name="activite" id="activite" data-testid="activite-select">
            <option value="">Société</option>
          </select>
          <div className="icon arrow-icon">
            <IoIosArrowDown />
          </div>
        </div> */}

        {/* <div className="activite">
          <select name="activite" id="activite" data-testid="activite-select">
            <option value="">Domaine d'activité</option>
          </select>
          <div className="icon arrow-icon">
            <IoIosArrowDown />
          </div>
        </div> */}
        <div className="button-and-filters">
          <button className="primary" onClick={search}>Chercher</button>
        </div>
      </div>
      <div className="list">
        <div className="stats">{employeurs.length} employeur(s) trouvé(s)</div>
        <div className="recruiter-cards">
          {employeurs.map((recruiter) => (
            <RecruiterCard
              key={recruiter.employeur_id}
              id={recruiter.employeur_id}
              image={recruiter?.logo ? url + recruiter.logo.replace("public/",""):"#"}
              name={recruiter.type === "societe"? recruiter.societe:recruiter.prenom + " "+recruiter.nom}
              address={recruiter.addresse}
              // description={"Representant par " + recruiter.representant}
              activities={recruiter.secteur}
              type={recruiter.type}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FindRecruiters;
