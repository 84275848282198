import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { loadingState } from "../../recoil/loadingAtom";
import HomeSearchBar from "../../components/HomeSearchBar/HomeSearchBar";
import { FiArrowLeft, FiArrowRight, FiTool } from "react-icons/fi";
import { VscTools } from "react-icons/vsc";
import { CgToolbox } from "react-icons/cg";
import { TbToolsKitchen2 } from "react-icons/tb";
import { AiOutlineFormatPainter } from "react-icons/ai";
import { instructions, homeCategories } from "../../utils/staticTexts";
// import { fakeProfessionals } from "../../utils/fakeData";
import ProfessionalCard from "../../components/ProfessionalCard/ProfessionalCard";
import Principale from "../../utils/services/Principale";
import { isEmpty } from "../../utils/services/helpers";
import { UserContext } from "../../utils/Context/Context";

//les images
import image1 from "../../assets/images/home/image1.jpeg";
import image2 from "../../assets/images/home/image2.jpeg";
import image3 from "../../assets/images/home/image3.jpeg";
import image4 from "../../assets/images/home/image4.jpeg";
import image5 from "../../assets/images/home/image5.jpeg";
import UserDetailsModal from "../../components/UserDetailsModal/UserDetailsModal";
const Home = () => {
  const url = process.env.REACT_APP_URL + 'storage/';
  const [professionnelleData,setProfessionnelleData] = useState([]);
  const {user,setUser} = useContext(UserContext)
  const [endPage,setEndPage] = useState(8);
  const [startPage,setStartPage] = useState(1);
  const [currentPage,setcurrentPage] = useState(1);
  const [totalPage,setTotalPage] = useState(0);
  const[isLoading,setIsLoading] = useRecoilState(loadingState);
  const[metiers,setMetiers] = useState([]);
  const[candidatbyPref,setCandidatbyPref] = useState([]);
  const[searchResult,setSearchResult] = useState([]);
  const [currentScrollPosition,setCurrentScrollPosition] = useState(0);
  const [scrollAmount,setScrollAmount] = useState(350);
  const btnScrollLeft = document.getElementById("btn-scroll-left");
  const btnScrollRight = document.getElementById("btn-scroll-right");
  const sCont = document.querySelector(".card-container .cards");
  const hScroll = document.querySelector(".container5");
  const btnSroll = document.getElementsByClassName("btn-scroll");
  const api = new Principale();
  const navigate = useNavigate();


  const fnProfessionnelle = async () => {
      setIsLoading(true);
      const {status,candidats,totalCandidat,messages} = await api.get('/client/candidatofmonth');
      if(status === 200){
        // _setTotalCitoyen(totalCandidat);
        setTotalPage(Math.round(totalCandidat/8));
        setProfessionnelleData(candidats.slice(0,4));
        getProfessionnellebyPref();
         //le debut sera currentPage + 1.la fin incrementer de plus 8.
       
      }
      api.get('/client/getmetiers').then((resp) => {
        setIsLoading(false);
         if(resp.status === 200){
            setMetiers(resp.metiers);
         }
      });
      
  }
  const getProfessionnellebyPref = async () => {
    if(!isEmpty(user)){
      const {status,candidatPref,messages} = await api.get(`/getCandidate/${user?.prefecture_id}`);
      if(status === 200){
        setCandidatbyPref(candidatPref);
      }
  
    }
  
    //  const {status,candidats,totalCandidat} = await api.get('/getCandidate/');
  }
  if(btnScrollLeft !== null && typeof btnScrollLeft !== 'undefined' && typeof btnScrollRight !== 'undefined' && typeof hScroll !== 'undefined'){
    btnScrollLeft.style.top = (hScroll.offsetHeight - 48)/2 + "px"; 
    btnScrollRight.style.top = (hScroll.offsetHeight - 48)/2 + "px";
  }
  const raccourci = (e,index) => {
  
    if(index === 0)
      navigate('/signup/recruiter');
    else if(index === 1) 
      navigate('/findprofessionals');
    else if(index === 2)
      navigate('/contact');
  }

  const voirplus = async () => {
    if(isEmpty(user)){
      navigate('/login', {state: {page: 'home',}});
    }
    else{

      const start = endPage + 1; 
      setIsLoading(true);
      const currentPge = currentPage + 1;
      setcurrentPage(currentPge);
      setStartPage(start);
      const end = start + 4;
      setEndPage(end);
     if( parseInt(currentPge) <=  2){
        const candts = professionnelleData; 
        const {status,candidats,messages} = await api.get(`/client/getCandidate?start=${start}&end=${end - 1}`);
        if(status === 200){
            candidats.map((candidat) => {
            candts.push(candidat);
          }); 
        }
        setProfessionnelleData(candts);
      }
      setIsLoading(false);
    }
  }
  useEffect(() => {
    fnProfessionnelle();setcurrentPage(0);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
   
    if(btnScrollLeft){
      btnScrollLeft.disabled = true;
     
  }
    if(!isEmpty(user)){
      if(user.role_id !== 4 && user.role_id !== 3){
        navigate('/admin/dashboard')
      }
    }
    
  },[btnScrollLeft]);

  function scrollHorizontally(val) {
      let c_width = hScroll.offsetWidth,
      box_width = sCont.offsetWidth,
      amount = val * scrollAmount,
      diff = c_width - box_width;
           // to calculate the difference, we only need to subtract width of the div containing the cards.
      setCurrentScrollPosition(currentScrollPosition + amount);
      if (currentScrollPosition + amount > 0) {
          // cap start
          setCurrentScrollPosition (0);
          //  btnScrollLeft.style.opacity = "0";

           btnScrollLeft.disabled = true;
           btnScrollLeft.style.pointerEvents = 'none';
           btnScrollRight.disabled = false;
           btnScrollRight.style.pointerEvents = 'fill';
      } else if (currentScrollPosition + amount < diff) {
          // cap end
          setCurrentScrollPosition(diff);
          //  btnScrollRight.style.opacity = "0";
       
          btnScrollLeft.disabled = false;
          btnScrollLeft.style.pointerEvents = 'fill';
          btnScrollRight.disabled = true;
          btnScrollRight.style.pointerEvents = 'none';
      
      } else {
          btnScrollRight.disabled = false;
          btnScrollLeft.disabled = false;
          btnScrollLeft.style.pointerEvents = 'fill';
          btnScrollRight.style.pointerEvents = 'fill';
      }
     if((currentScrollPosition + amount === 0) || (currentScrollPosition + amount === 120) || (currentScrollPosition + amount === 80)){
       btnScrollLeft.disabled = true;
       btnScrollLeft.style.pointerEvents = 'none';
       sCont.style.marginLeft = "-40px";

      }
     else   
      sCont.style.marginLeft = currentScrollPosition + amount + "px";
   }
 
  return (
    <div className="home">
      <div className="banner">
        <div className="content">
          <div className="search-zone">
            <h1>Trouvez un professionnel du métier en quelques clics !</h1>
       
            <HomeSearchBar metiers={metiers} setSearchResult={setSearchResult}/>
            <div className="popular">
             <div className="suggestion-menu">
                <ul>
                  {searchResult.length > 0 && searchResult.map((rsult,index) => (<li key={index}>
                       <Link to={'/findprofessionals'} state={{metier: rsult.nom}}>
                        {rsult.nom}
                    </Link></li>))}
            
                </ul>
              </div>  
              <p className="subtext">
                <strong>Populaires</strong> : 
                {homeCategories.map((category) => (<Link to={'/findprofessionals'} state={{metier: category.title}}  key={category.id}>
                  {category.title + " ,"}
                </Link> ))}
              </p>
            </div>
          </div>
        </div>
        <div className="floating-icon tool">
          <FiTool size={18} />
        </div>
        <div className="floating-icon tools">
          <VscTools size={18} />
        </div>
        <div className="floating-icon toolbox">
          <CgToolbox size={18} />
        </div>
        <div className="floating-icon fork">
          <TbToolsKitchen2 size={18} />
        </div>
        <div className="floating-icon painter">
          <AiOutlineFormatPainter size={18} />
        </div>
        <div className="floating-image floating-image-1">
          <img
            src={image1}
            alt=""
          />
          <div className="bigger-image">
            <img
              src={image1}
              alt=""
            />
          </div>
        </div>
        <div className="floating-image floating-image-2">
          <img
            src={image2}
            alt=""
          />
          <div className="bigger-image">
            <img
              src={image2}
              alt=""
            />
          </div>
        </div>
        <div className="floating-image floating-image-3">
          <img
            src={image3}
            alt=""
          />
        <div className="bigger-image">
          <img
            src={image3}
            alt=""
          />
          </div>
        </div>
        <div className="floating-image floating-image-4">
          <img
            src={image4}
            alt=""
          />
         <div className="bigger-image">
          <img
            src={image4}
            alt=""
          />
          </div>
        </div>
      </div>

      <div className="container container1">
        <div className="content">
          <div className="text">
            <h2>Nous vous simplifions la vie</h2>
            <p className="subtext">
              Trouvez la personne qu'il vous faut en trois étapes simples.
            </p>
            <p className="subtext">
             Créez votre compte dès maintenant, complétez votre profil et laissez votre cible vous trouver.
            </p>
          </div>
          <div className="instructions">
            {instructions.map((instruction,index) => (
              <div className="box" key={instruction.id}>
                <div className="icon" onClick={(e) => raccourci(e,index)} >{instruction.icon}</div>
                <div className="details">
                  <h4>{instruction.title}</h4>
                  <p className="subtext">{instruction.desc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="container container2">
        <div className="content">
          <h2>Les jobs les plus demandés</h2>
          <div className="categories">
            {homeCategories.map((category) => (
              <Link to={'/findprofessionals'} state={{metier: category.title}}  key={category.id}>
                <div className="box">
                  <div className="icon">{category.icon}</div>
                  <strong>{category.title}</strong>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>

      {isEmpty(user)&&
         <div className="container container3">
         <div className="content">
          <div className="left">
            <h1>Vous êtes un professionnel ?</h1>
            <p>
            Vous êtes un professionnel en quête de nouvelles opportunités ? Ou, un employeur (personne physique ou morale) 
              à la recherche de bons profils ? <span className="bold"> WOLIKEFANYI</span> vous ouvre ses portes pour vous aider à atteindre vos objectifs. 
            </p>
            <p>Des questions ? <Link to='/contact' className="link">N'hésitez pas à nous contacter </Link>.</p>
            <Link to="/signup/pro">
              <button className="primary big">Créer mon compte WOLIKEFANYI</button>
            </Link>
          </div>
          <div className="right">
            <div className="image">
              {/* <img
                src="https://images.unsplash.com/photo-1531384441138-2736e62e0919?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
                alt=""
              /> */}
              <img src="https://images.unsplash.com/photo-1621905252472-943afaa20e20?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80" alt=""/>
              <div className="losange"></div>
              <div className="dots">
                <div className="group">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
                <div className="group">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
                <div className="group">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
       </div>}

       {professionnelleData.length >= 1 && 
        <div className="container container4">
          <div className="content">
            <h2>Les wolikefanyis du mois</h2>
              <div className="cards">
                {professionnelleData.map((item,index) => {
                
                  return(
                  <ProfessionalCard
                    key={item.candidat_id + Math.random()}
                    photo={ url + item.photoProfile.replace('public/','')}
                    name={item.prenomCandidat+ " "+ item.nomCandidat}
                    job={item.metier}
                    address={item.prefecture}
                    experience={item.experience}
                    description={`Agé(e) de  ${item?.age} ans de sexe ${item?.sexe === 'M'?"Masculin":"Féminin"} 
                    habitant dans le quartier/district de ${item?.quartier} de la commune de ${item?.commune}.\n Diplôme: ${item?.diplome ?item?.diplome?.charAt(0).toUpperCase() + item?.diplome?.slice(1):'NA'}`}
                    candidat_id = {item.candidat_id}
                  />)
                  
               })}
              </div>
            {(professionnelleData.length >= 4 && currentPage <= 2)&& 
            <div className="more" onClick={ voirplus} >
              Voir plus 
            </div>}
         </div>
       </div>}
      { candidatbyPref.length > 0 &&
       <div className="container container5">
     
          <h2>Les Wolikefanyis prôches de vous</h2>
           
              <button className="btn-scroll" id="btn-scroll-left" onClick={() => scrollHorizontally(1)}>
                 <FiArrowLeft />
              </button>
              <button className="btn-scroll" id="btn-scroll-right" onClick={() => scrollHorizontally(-1)}>
                 <FiArrowRight />
              </button>
               <div className="card-container">
                <div className="cards">
                    {candidatbyPref.map((item) => (
                      <ProfessionalCard
                        key={item.candidat_id}
                        photo={ url + item.photoProfile.replace('public/','')}
                        name={item.prenomCandidat+ " "+ item.nomCandidat}
                        job={item.metier}
                        address={item.prefecture}
                        experience={item.experience}
                        description={`Agé(e) de  ${item?.age} ans de sexe ${item?.sexe === 'M'?"Masculin":"Féminin"} 
                        habitant dans le quartier/district de ${item?.quartier} de la commune de ${item?.commune}.\n Diplôme: ${item?.diplome ?item?.diplome?.charAt(0).toUpperCase() + item?.diplome?.slice(1):'NA'}`}
                        candidat_id = {item.candidat_id}
                      />
                    ))}
                
              
                  </div>  
               </div>
         
       </div>
       }
    </div>
  );
};

export default Home;