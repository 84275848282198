import { useNavigate } from "react-router-dom";


const RecruiterCard = ({ id,image, name, address, description, activities, type }) => {
 const badgeColor = type === "societe" ? "purple" : "green";
 const navigate = useNavigate();
 const details = () => {
   navigate(`/profile/recruiter/details/${id}`);
 }
 
  return (
    <article className="recruiter-card">
        <div className="image">
            <img src={image} alt="" />
        </div>
        <div className="infos">
            <p className="name">{name}</p>
            <p className="address">{address}</p>
            {activities && <p className="activities"> {activities.toString()} </p>}
            <p className="description">{description}</p>
        </div>
        {/* <div className={`badge ${badgeColor}`}>{type}</div> */}
         <button className="primary" onClick={details}>Voir profil</button>
    </article>
  )
}

export default RecruiterCard