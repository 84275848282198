import React, { useState ,useEffect} from "react";
import { Markup } from 'interweave';
import moment from "moment";
import logo from "../../assets/images/logosig.png";
import { formatStringNumber } from "../../utils/services/helpers";
import { ToWords } from 'to-words';


const ContratInterim = React.forwardRef(({recrutement,setRecrutement,setError}, ref) => { 
  const toWords = new ToWords({
    localeCode: 'fr-FR',
    converterOptions: {
      currency: false,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
    }
  });
  const [mois,setMois] = useState(0);
  const[diffHour,setDiffHour] = useState(0);
  useEffect(() => {
    if(recrutement.emploie.date_debut && recrutement.emploie.date_fin)
      setMois(Math.abs(getDate(recrutement?.emploie?.date_debut).diff(getDate(recrutement?.emploie?.date_fin), 'months')));
      const end = moment(recrutement.emploie.heure_fin,'HH:mm a');
      const start = moment(recrutement.emploie.heure_debut,'HH:mm a');
    
     var duration = moment.duration(end.diff(start));
     var hours = duration.asHours();  
     setDiffHour(hours);
  },[]);
  const getDate = (date) => moment(date).startOf('month');
  const handleInput = (e) => {
    setError(null);
    setRecrutement({...recrutement,emploie:{...recrutement.emploie,[e.target.name]:e.target.files[0]}});
  }
  //console.log(recrutement)
  return (
    <>
    <div style={{width:'40%',float:'right',height:'20px',marginTop:'-25px',marginRight:'5px'}} className="input-group">
     <label>Le Contrat signé
      <input type="file" name="copie_contrat"  accept=".pdf" onChange={(e) => handleInput(e)}/>
     </label> 
    </div>
    <div className="contract-doc" ref={ref}>
     
      <h1>Contrat d'intérimaire</h1>
      <div className="presentation">
      <div className="logo">{logo?<img src={logo} style={{width:'85px',marginBottom:'20px',marginTop:'-23px'}}/>:'LOGO'}</div>
        <div className="employeur">
          <p>
            <strong>Entre les soussigné(e)s :</strong>
          </p>
          <p>
            La société <strong>Innovent Group Service SARLU </strong> au capitale de 10 0000 GNF immatriculée au registre du
            commerce et des sociétés sous le numéro <strong>UI1038DEUIE</strong> dont le siège social
            est situé à xxx, représentée par  Monsieur <strong>Youssouf FOFANA</strong>, contact : <strong>628 26 10 39</strong>.
          </p>
          <p>
            D'une part, ci-après dénomée l'<strong>Employeur.</strong>
          </p>
        </div>
        <div className="client">
          <p>
            <strong>Et :</strong>
          </p>
          {recrutement?.employeur.typeEmp === 'societe' &&
              <p>
              La société <strong>{recrutement?.employeur?.societe}</strong> au capitale de { !isNaN(recrutement?.employeur?.capital) === true ?<strong>{formatStringNumber(recrutement.employeur?.capital)} GNF</strong>:'-------'} immatriculée au registre du
              commerce et des sociétés sous le numéro <strong>{recrutement?.employeur?.nif}</strong> dont le siège social
              est situé à <strong>{recrutement?.employeur?.prefecture+"/"+recrutement?.employeur?.commune+"/"+recrutement?.employeur?.quartier}</strong>, représentée par {recrutement?.employeur?.civilite} <strong>{ recrutement?.employeur?.representant}</strong>
              , contact : <strong>{recrutement?.employeur?.telephone}</strong>.
            </p>}
            {recrutement.employeur.typeEmp === 'personne physique' && 
              <p>
                {recrutement?.employeur?.civilite} <strong>{ recrutement?.employeur?.prenomEmp + " "+ recrutement?.employeur?.nomEmp }</strong>
              , contact : <strong>{recrutement?.employeur?.telephone}</strong>, domicilié à l'addresse {recrutement?.employeur?.prefecture +"/" +recrutement?.employeur?.commune+"/"
                +recrutement?.employeur?.quartier}{(recrutement?.employeur?.addresse && recrutement?.employeur?.addresse !== 'null')?"/"+recrutement?.employeur?.addresse:""}
              </p>
            }
          <p>
            D'une part, ci-après dénomée le <strong>Client.</strong>
          </p>
        </div>
      </div>
      <p className="convention">Il a été convenu et arreté ce qui suit :</p>
      <div className="articles">
        <div className="article">
          <h4>Article 1 : Objet du contrat</h4>
          <p>
            Mise à disposition temporaire de <strong>{recrutement?.candidat?.sexe === "M"?"Monsieur ":"Madame/Mademoiselle "} {recrutement?.candidat?.prenom + " "+recrutement?.candidat?.nom}</strong> par la Société 
            <strong> IGS </strong>
            auprès du client : {recrutement?.employeur.typeEmp === 'societe'?<strong>{recrutement?.employeur?.societe}</strong>:<strong>{recrutement?.employeur?.prenomEmp + " "+ recrutement?.employeur?.nomEmp}</strong>} conformément à la législation du travail,
            portant Code du Travail de la République de Guinée, et les textes
            pris pour son application.
          </p>
        </div>
        <div className="article">
          <h4>Article 2 : Durée du contrat</h4>
          <p>
            Ce contrat prend effet à la date dès après la signature du dit contrat pour une période de 
            <strong> {mois <= 1 ?Math.ceil((new Date(recrutement?.emploie?.date_fin) - new Date(recrutement?.emploie?.date_debut))/(1000 * 3600 * 24))+" jour (s)":mois+" mois"}</strong>
            . Il pourra être renouvelé en d'autres termes et conditions
            modifiées (si nécessaires) décidés d'un commun accord par les deux
            parties suivant une période de <strong>{mois <= 1 ?Math.ceil((new Date(recrutement?.emploie?.date_fin) - new Date(recrutement?.emploie?.date_debut))/(1000 * 3600 * 24))+" jour (s)":mois+" mois"}</strong> à moins que l'une des
            deux parties mettent fin à ce contrat en informant l'autre partie
            avec un préavis de deux mois soit avant le 1er novembre.
          </p>
        </div>
        <div className="article">
          <h4>Article 3 : Mission</h4>
          <p>
            L'entreprise utilisatrice requiert la mise à disposition d'un
            travailleur temporaire pour les motifs suivant :
          </p>
          <div><Markup content={recrutement?.emploie.taches} /></div>
        </div>
        <div className="article">
          <h4>Article 4 : Durée de la mission</h4>
          <p>
            Le salarié intérim effectuera son travail pour une durée de <strong> {mois <= 1 ?Math.ceil((new Date(recrutement?.emploie?.date_fin) - new Date(recrutement?.emploie?.date_debut))/(1000 * 3600 * 24))+" jour (s)":mois+" mois"}</strong> pour la mission de <strong>{recrutement?.candidat?.metier}</strong>.<br />Cependant la durée
            totale de ses missions ne doit pas dépassée la date échéance de son
            contrat de travail intérim.
          </p>
        </div>
        <div className="article">
          <h4>Article 5 : Horaire de la mission</h4>
          <p>
            La durée hebdomadaire du travail est de <strong>{diffHour > 0 ? diffHour +" heure(s)":moment.duration(moment(recrutement.emploie.heure_fin,'HH:mm a').diff(moment(recrutement.emploie.heure_debut,'HH:mm a'))).asMinutes()}</strong> soit
             <strong>{Array.isArray(recrutement?.emploie?.selectedjours) && recrutement?.emploie?.selectedjours.length > 0 ?" " +recrutement?.emploie?.selectedjours.length + " jour(s)/semaine(s)":' ------------'}</strong>.Toutefois cette durée peut être modifié et
            allongée si les conditions de travail l’exigent. De ce fait, le
            salarié intérim doit être indemnisé pour ses heures supplémentaires.
          </p>
        </div>
        <div className="article">
          <h4>Article 6 : Période d'essai</h4>
          <p>
            Le salarié mis à disposition effectuera une période d'essai d'une semaine
            , à compter du {moment(recrutement?.emploie?.date_debut).format("DD/MM/YYYY")} au {moment(recrutement?.emploie?.date_debut).add(8, 'days').format('DD/MM/YYYY')}.
          </p>
        </div>
        <div className="article">
          <h4>Article 7 : Poste</h4>
          <p>
            Le salarié mis à disposition sera affecté et employé au compte de <strong>{recrutement?.employeur.typeEmp === 'societe'?recrutement?.employeur?.societe:recrutement?.employeur?.prenomEmp + " "+ recrutement?.employeur?.nomEmp}</strong>, en
            qualité de <strong>{recrutement?.candidat?.generique ? recrutement?.candidat?.generique : recrutement?.candidat?.metier}</strong>
          </p>
        </div>
        <div className="article">
          <h4>Article 8 : Lieu de la mission</h4>
          <p>
            La mission dont le salarié intérim est chargé s'effectuera au niveau
            de <strong>{recrutement?.emploie?.lieuexecution}</strong>
          </p>
        </div>
        <div className="article">
          <h4>Article 9 : Règlement</h4>
          <p>
            Le salarié intérimaire est soumis au règlement intérieur de la
            société utilisatrice
          </p>
        </div>
        <div className="article">
          <h4>Article 10 : Rémunération</h4>
          <p>
            L'entreprise utilisatrice (cliente) a souscrit une garantie
            financière auprès de la Société IGS pour un montant de :{recrutement?.emploie?.montantprestation?toWords.convert(parseInt(recrutement?.emploie?.montantprestation) * 0.18 + parseInt(recrutement?.emploie?.montantprestation)):"zero"} <strong>( {formatStringNumber(recrutement?.emploie?.montantprestation?parseInt(recrutement?.emploie?.montantprestation) * 0.18 + parseInt(recrutement?.emploie?.montantprestation):0)} )</strong> GNF/
            mois soit <strong style={{color:'#76760d'}}>18 % de TVA</strong> versé dans le compte bancaire Nᵒ ------------------------ (Nom de la Banque)
          </p>
          <p>
            Conformement à l'article L.1251-49 du Code du travail, tout
            entrepreneur de travail temporaire est tenu; à tout moment, de
            justifier d'une garantie financière assurant, en cas de défaillance
            de sa part, le paiement :
            <ul>
              <li>Des salaires et de leurs accessoires</li>
              <li>Des indemnités résultant du présent chapitre</li>
              <li>
                Des cotisations obligatoires dues à des organisations de
                Sécurité sociale ou à des institutions sociales, le cas échéant,
                des remboursements qui peuvent incomber aux employeurs à l'égard
                de ces organismes et institutions dans les conditions prévues à
                l'article L.244-8 du Code de la Sécurité sociale.
              </li>
            </ul>
          </p>
        </div>
        <div className="article">
          <h4>Article 11 : Litige</h4>
          <p>
            Tout litige relevant de l'exécution du présent contrat sera réglé à
            l'amiable : à défaut, il sera soumis à la juridiction compétente de
            CONAKRY.
          </p>
        </div>
        <div className="article">
          <h4>Article 12 : Conclusion du contrat</h4>
          <p>
            Conformément aux dispositions de l'article L.1251-42 et suivants du
            Code du travail, ce contrat doit être conclu par écrit au plus tard
            dans les 2 jours ouvrables suivant la mise à disposition.
          </p>
        </div>
        <p className="note">
          Fait en double exemplaire, dont un est remis à chacune des parties du
          présent avenant.
        </p>
        <p className="date">A Conakry, le {moment().format('Do MMMM YYYY')}</p>
        <p className="nb">
          Faire précéder la signature du client de la mention manuscrite "lu et
          approuvé".
        </p>
        <div className="signatures">
          <p>
            <strong>La société IGS</strong>
          </p>
          <p>
           Le client {recrutement?.employeur.typeEmp === 'societe'? <strong>{recrutement?.employeur.societe}</strong>:<strong>{recrutement?.employeur?.civilite + " " +recrutement?.employeur?.prenomEmp + " "+ recrutement?.employeur?.nomEmp}</strong>}
          </p>
        </div>
      </div>
    </div>
    </>
  );
});

export default ContratInterim;
